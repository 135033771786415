import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import eliminar_venta from "../../functions/Cultivo_en_proceso/eliminar_venta";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";

function ModalCostos_desglose({
  isModalCostos_desglose,
  setisModalCostos_desglose,
  Boton_seleccionado_costos,
  productos_boton,
  Producto_seleccionado_desglose,
}) {

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

const [Productos_filtrados, setProductos_filtrados] = React.useState([]); 

 function Categorias() {
   console.log(productos_boton)
   console.log(Producto_seleccionado_desglose)
   
   //Con esto filtro los productos por la categoria seleccionada
   var auxiliar_productos = [];
   productos_boton.map((dato)=>{
    if(dato.categoria==Producto_seleccionado_desglose){
      auxiliar_productos.push(dato)
    }
   })
   filtrar_productos(auxiliar_productos)
  }


function filtrar_productos(auxiliar_productos){
    console.log(auxiliar_productos)
          var auxiliar_100 = []; 
          auxiliar_productos.map((valor)=>{
            var auxiliar_102 = valor;
            var auxiliar_103= false;
    
                      //Este le agrega el primero directo
                      if(auxiliar_100.length==0){
                        var auxiliar_101 = valor;
                        auxiliar_100.push(auxiliar_101);
                      }
    
                      if(auxiliar_100.length>0){
                            auxiliar_100.map((dato)=>{
                              if(valor.nombre == dato.nombre){
                                auxiliar_103 = true;
                              }
                            })
                      }
    
                if(auxiliar_103==false){
                  auxiliar_100.push(auxiliar_102);
                }
    
          })
          console.log(auxiliar_100)
    sumar_total_productos_iguales(auxiliar_100, auxiliar_productos )
    }

function sumar_total_productos_iguales(auxiliar_100, auxiliar_productos) {

  auxiliar_100.map((auxiliar)=>{
    auxiliar['Cantidad_total'] = 0;
    auxiliar['Total_sumado'] = 0;
  })

  auxiliar_100.map((valor)=>{
    auxiliar_productos.map((dato)=>{
      if(valor.nombre==dato.nombre){

        var auxiliar212 = formatter.format(+(dato.cantidad) + +valor.Cantidad_total);
        const str = (auxiliar212).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        var nuevaStr4 = nuevaStr3.replace(",", "");
        valor['Cantidad_total'] = nuevaStr4;




        const str2 = (dato.total).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr22 = str2.replace("$", "");
        const nuevaStr222 = nuevaStr22.replace(",", "");
        const nuevaStr322 = nuevaStr222.replace(",", "");
        var nuevaStr422 = nuevaStr322.replace(",", "");

        const str23 = (valor.Total_sumado).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr223 = str23.replace("$", "");
        const nuevaStr2223 = nuevaStr223.replace(",", "");
        const nuevaStr3223 = nuevaStr2223.replace(",", "");
        var nuevaStr4223 = nuevaStr3223.replace(",", "");

        valor['Total_sumado'] = formatter.format(+nuevaStr422 + +nuevaStr4223);
      }
    })
  })

//Con esto los ordeno por alfabeto
  auxiliar_100.sort(function (a,b){
    if(a.nombre > b.nombre){
      return 1;
    }

    if(a.nombre < b.nombre){
      return -1;
    }

    return 0;
  })

  setProductos_filtrados(auxiliar_100);
}

  React.useEffect(() => {
    Categorias();
  }, []); 

  return (
   <div>
     <Modal id="Modal_Logs" className="container-fluid" show={isModalCostos_desglose} onHide={() => setisModalCostos_desglose(false)}>
     <Modal.Header>
       <Modal.Title>Desglose de "{Producto_seleccionado_desglose}" de la cosecha {Boton_seleccionado_costos}</Modal.Title>
       <Button variant="secondary" onClick={() => setisModalCostos_desglose(false)}>
         Salir
       </Button>
     </Modal.Header>
     <Modal.Body>
      <Table style={{ fontSize: "120%"  }}>
        <thead>
          <tr>
            <th>Fecha</th>
            <th style={{ textAlign: "right" }}>Cantidad</th>
            <th style={{ textAlign: "right" }}>Total MXN</th>
          </tr>
        </thead>
        <tbody>
        {Productos_filtrados.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td style={{ textAlign: "right" }}>{new Intl.NumberFormat('es-MX').format(producto.Cantidad_total)}</td>
                <td style={{ textAlign: "right" }}>{producto.Total_sumado}</td>
               </tr>   
          ))}
        </tbody>
      </Table>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={() => setisModalCostos_desglose(false)}>
         Salir
       </Button>
     </Modal.Footer>
   </Modal>
   </div> 
   
  );
}

export default ModalCostos_desglose;
