import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_productos_tablas from "../../functions/Bancos/añadir_productos_tablas";
import getProductos_tablas from "../../functions/Bancos/getProductos_tablas";
import getProductos_afectaciones_fiscales from "../../functions/Bancos/getProductos_afectaciones_fiscales";
import {DatePicker} from "@material-ui/pickers";
import añadir_productos_tablas_sin_afectacion from "../../functions/Bancos/añadir_productos_tablas_sin_afectacion";
import getProveedores from "../../functions/Bancos/getProveedores";
import Swal from "sweetalert2";

function ModalEditar_tablas({
  Modal_editar_producto,
  setModal_editar_producto,
  Tablasdata,
  actualizar_productos_tablas,
  usuario2,
  Nombre_de_boton,
  setTablasdata,
  setTotal,
}) {

//Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

  async function actualizar2(){
    await getProductos_tablas(Nombre_de_boton).then((TablasDB) => {
          //Ordena de menor a mayor por fecha
          TablasDB.sort((a,b)=> (a.fecha2) - (b.fecha2));
          var auxiliar = 0;
          TablasDB.map((datos)=>{
                const str = auxiliar.toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                var nuevaStr4 = nuevaStr3.replace(",", "");
            if(nuevaStr4 != 0){
              if(datos.entrada_salida=="Entrada"){
                auxiliar = formatter.format(+nuevaStr4 + +datos.cantidad_total);
                datos['Saldo_actual'] = auxiliar;
                //console.log("entrada")
              }

              if(datos.entrada_salida=="Salida"){
                auxiliar = formatter.format(nuevaStr4 - datos.cantidad_total);
                datos['Saldo_actual'] = auxiliar;
                //console.log("salida")
              }
            }
            
            //Con este asigno el saldo iniciar y lo guardo en la variable auxiliar
            if(datos.producto=="Saldo inicial"){
              auxiliar = formatter.format(datos.cantidad_total);
              datos['Saldo_actual'] = auxiliar;
              //console.log("HOla")
            }
          })

          //Ordena de mayor a menor por fecha
          TablasDB.sort((a,b)=> (b.fecha2) - (a.fecha2));
        setTablasdata(TablasDB);
        //actualizar_datos_gastos(fechas_organizadas);
   });
  }

//Con esto se traen todas las afectaciones fiscales actuales
const [Afectaciones_fiscales_DB, setAfectaciones_fiscales_DB] = React.useState([]);
const [AuxiliarBancoDDLS, setAuxiliarBancoDDLS] = React.useState(false);

async function traer_afectaciones_fiscales() {
  console.log(Nombre_de_boton);
  await getProductos_afectaciones_fiscales().then((FiscalesDB) => {
    setAfectaciones_fiscales_DB(FiscalesDB);
    if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
      setAuxiliarBancoDDLS(true);
      console.log("Hola")
    }
   });
 }

 //Traer todos los proveedores
 const [Proveedores_BD, setProveedores_BD] = React.useState([]);

 async function traer_proveedores() {
  await getProveedores().then((ProveedoresDB) => {
    setProveedores_BD(ProveedoresDB);
   });
 }

//Traer productos del almacen campo
const [Cantidad_actual, setCantidad_actual] = React.useState(0);

  async function actualizar() {
    console.log(Nombre_de_boton)
    await getProductos_tablas(Nombre_de_boton).then((productos) => {
      setTablasdata(productos);   
      //console.log(productos)
      //Esto es para saber cuantos productos tiene la tabla y darselo de nombre
      var valores_productos_concentrados = [];
      Tablasdata.map((dato)=>{
        valores_productos_concentrados.push(dato.nombre)
      })
      var valor_maximo = 0;
      if(valores_productos_concentrados.length==0){
        valor_maximo = 0;
      }else{
        valor_maximo = Math.max(...valores_productos_concentrados)
      }
      
      setCantidad_actual(valor_maximo+1);
      //console.log(valores_productos_concentrados);
      //console.log(valor_maximo+1);
     });
   }

   
//Fecha
const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  async function añadirProductoModal(usuario2, Nombre_de_boton) {
   //Esto sirve para la seleccion de la fecha
   let date =fechaSeleccionada;
   let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
   let fecha_actual3 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
   let fecha_actual2 = date;
   //obtener infor del formulario
    const fecha = fecha_actual;
    const fecha2 = fecha_actual2;
    const fecha3 = fecha_actual3;
    const entrada_salida = document.getElementById("entrada_salida").value;
    const cantidad_total = document.getElementById("cantidad_total").value;
    const Proveedor_elegido = document.getElementById("proveedores").value;
    var entrada = "";
    var salida = "";
    
    if(entrada_salida=="Entrada"){
      entrada = cantidad_total;
    }
    if(entrada_salida=="Salida"){
      salida = cantidad_total;
    }
    const usuario = usuario2.email;
    const nombre = Cantidad_actual;
    const banco = Nombre_de_boton.nombre_banco;
    const producto = document.getElementById("producto").value;
    const afectacion_si_no = true;


    // enviar informacion a firebase
    const infoProducto = { Proveedor_elegido, fecha, usuario, entrada_salida, nombre, producto, entrada, 
                           salida, banco, fecha2, cantidad_total, fecha3, afectacion_si_no};

        añadir_productos_tablas_sin_afectacion(infoProducto, Nombre_de_boton, Cantidad_actual );
        // cerrar modal
        await timeout(500);
        actualizar2()
        setModal_editar_producto(false);
  }

  React.useEffect(() => {
    //traer_afectaciones_fiscales();
    traer_proveedores();
    actualizar();
  }, []); 

  return (
    <Modal show={Modal_editar_producto} onHide={() => setModal_editar_producto(false)}>
      <Modal.Header>
        <Modal.Title>Añadir Movimiento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
          <br/> 
          <Form.Control
              style={{ fontSize: "90%" }}
              id="producto"
              placeholder="Ingresar nombre del concepto"
              type="text"
              className="mb-1"
            />
            <Form.Control
              style={{ fontSize: "90%" }}
              id="cantidad_total"
              placeholder="Ingresar cantidad total del concepto"
              type="number"
              className="mb-1"
            />
{/*             {AuxiliarBancoDDLS && (
              <Form.Control
              style={{ fontSize: "90%" }}
              id="precio_dolar"
              placeholder="Ingresar precio del dolar"
              type="number"
              className="mb-1"
              />
            )} */}
            <br/>  
            <h6>Seleccionar si es entrada o salida</h6>
              <select id="entrada_salida" type="text">
                 <option >Entrada</option>
                 <option >Salida</option>
              </select>
            <br/>

            <h6>Seleccionar proveedor</h6>
            {Proveedores_BD ? 
                <select 
                id="proveedores" 
                type="text"
                >
                  {Proveedores_BD.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>                
            :
            null
            }  
            <br/>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_editar_producto(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=> añadirProductoModal(usuario2, Nombre_de_boton)}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_tablas;
