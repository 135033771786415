import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../functions/añadirProducto";
import getProveedores from "../functions/Productos/getProveedores"
import getCategorias from "../functions/Productos/getCategorias";
import getAllproducts from "../functions/Productos/getAllproducts";
import Swal from "sweetalert2";


function ModalAñadir_productos({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
  setProductos,
  Traer_secciones,
}) {

  async function actualizar() {
    await getAllproducts().then((productos) => {
      console.log(productos)
       setProductos(productos);  
       Traer_secciones(productos);  
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }


//Traer proveedores de base de datos
const [Datosproveedores, setDatosproducto] = React.useState([]);
async function productos_basededatos(){
  await getProveedores().then((proveedoresDB) => {
    setDatosproducto(proveedoresDB);
    //console.log(proveedoresDB)
  });
}

//Traer categorias de base de datos
const [Datoscategorias, setDatoscategorias] = React.useState([]);
async function categorias_basededatos(){
  await getCategorias().then((categoriasDB) => {
    setDatoscategorias(categoriasDB);
    //console.log(proveedoresDB)
  });
}

//Esto sirve para ver si agarro pallet le tenga que agregar cuantas cajas tiene y los kilos de cada caja
const [Cajas_kilos, setCajas_kilos] = React.useState(false);

function valorar_si_es_pallet(){
  const unidad = document.getElementById("unidad").value;
    if(unidad=="Pallet"){
      setCajas_kilos(true);
    }else{setCajas_kilos(false);}
}

  async function añadirProductoModal() {
    //obtener infor del formulario
    const nombre2 = document.getElementById("nombre").value;
    //la función trim() elimina los espacios al inicio y final del string
    const nombre = nombre2.trim();
    const precio = document.getElementById("precio").value;
    if(precio>0){
    }else{
      Swal.fire({
        icon: 'error',
        text: "La cantidad ingresada en precio es menor o igual a 0",
        footer: '¿Que hacer? <br/> Se debe agregar un valor mayor a 0 en el apartado de "Precio"'
      })
      setIsModalAñadir(false);
    }
    const unidad = document.getElementById("unidad").value;
    const proveedor = document.getElementById("proveedor").value;
    const categoria = document.getElementById("categoria").value;
    const lleva_iva = document.getElementById("lleva_iva").value;
    const cantidad = "0";
    const moneda = document.getElementById("moneda").value;
    var cajas_por_pallet = "";
    var kilos_por_cajas_por_pallet = "";
    if(Cajas_kilos){
     cajas_por_pallet = document.getElementById("cajas_por_pallet").value;
     kilos_por_cajas_por_pallet = document.getElementById("kilos_por_cajas_por_pallet").value;
    }
    
    //const sku = document.getElementById("sku").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, unidad, proveedor, categoria, cantidad, moneda, cajas_por_pallet, kilos_por_cajas_por_pallet, lleva_iva};
    añadirProducto(infoProducto, usuario.email);
    // cerrar modal
    await timeout(500);
    actualizar();
    setIsModalAñadir(false); 
  }

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Agregar nombre"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="precio"
              placeholder="Agregar precio"
              type="number"
              className="mb-1"
            />
            <br/>
            <h6>¿Lleva iva?</h6>
            <select id="lleva_iva" type="text" > 
              <option >No</option>
              <option >Si</option>
              <option >NA</option>
            </select>
            <br/>
            <h6>Seleccionar unidad de medida</h6>
            <select id="unidad" type="text" onClick={() => valorar_si_es_pallet()}> 
              <option >Seleccionar</option>
              <option >NA</option>
              <option >Bolsa</option>
              <option >Caja</option>
              <option >Galon</option>
              <option >Frasco</option>
              <option >KG</option>
              <option >Litro</option>
              <option >Millares</option>
              <option >M^2</option>
              <option >M^3</option>             
              <option >Pieza</option>
              <option >Pallet</option>
              <option >Rollo</option>
              <option >Saco</option>
              <option >Sobre</option>
              <option >Tonelada</option>
            </select>
            <br/>
            {Cajas_kilos && (
              <div>
              <Form.Control
              id="cajas_por_pallet"
              placeholder="Agregar cuantas cajas tiene el pallet"
              type="number"
              className="mb-1"
            />
            <Form.Control
              id="kilos_por_cajas_por_pallet"
              placeholder="Agregar cuantos kilos tiene tiene cada caja"
              type="number"
              className="mb-1"
            />
            </div>
            )}

            <h6>Seleccionar categoria</h6>
            <select id="categoria" type="text" onClick={() => categorias_basededatos()}>
            <option key="1000">Seleccionar</option>
            {Datoscategorias.map((datos,index)=>( 
                <option key={index}>{datos.categoria}</option>
            ))}
            </select>

            <br/>
            <h6>Seleccionar proveedor</h6>
            <select id="proveedor" type="text" onClick={() => productos_basededatos()}>
            <option key="1000">Seleccionar</option>
            <option >NA</option>
            {Datosproveedores.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>
            <h6>Seleccionar moneda</h6>
            <select id="moneda" type="text" > 
              <option >Pesos</option>
              <option >Dolares</option>
              <option >NA</option>
            </select>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_productos;

