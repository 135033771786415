import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_QR from "../../functions/codigos_QR/añadir_QR";
import Swal from "sweetalert2";

function ModalAñadir_QR({
  isModalAñadir,
  setisModalAñadir,
  actualizardatos,
}) {

  async function añadirProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("nombre_etiqueta").value;
    const direccion = document.getElementById("direccion").value;
    const telefono = document.getElementById("telefono").value;
    const producto = document.getElementById("producto").value;

    // enviar informacion a firebase
    const infoProducto = {nombre, direccion, telefono, producto };
    añadir_QR(infoProducto)
    // cerrar modal
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se agrego correctamente.',
      showConfirmButton: false,
      timer: 1500
    })
    actualizardatos();
    setisModalAñadir(false);
  }

  return (
    <Modal show={isModalAñadir} onHide={() => setisModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir etiqueta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <Form.Control
              id="nombre_etiqueta"
              placeholder="Agregar nombre de la etiqueta"
              type="text"
              className="mb-1"
            />  
          <Form.Control
              id="direccion"
              placeholder="Agregar dirección"
              type="text"
              className="mb-1"
            /> 
          <Form.Control
              id="telefono"
              placeholder="Agregar teléfono"
              type="text"
              className="mb-1"
            />  
           <Form.Control
              id="producto"
              placeholder="Agregar producto"
              type="text"
              className="mb-1"
            />                        
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setisModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_QR;
