import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React, {useState} from 'react';

function añadir_movimiento_nominas(infoProducto, auxiliar, auxiliar2) {
  const db = getFirestore(firebaseApp);
  const fecha = infoProducto.fecha;
  let collectionRef = collection(db, "Nominas/"+auxiliar+"/"+auxiliar2+"/".replace(/ /g, ""));   
  const docRef = doc(collectionRef, fecha);
  setDoc(docRef, infoProducto);   
}

export default añadir_movimiento_nominas;
