import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import eliminarFiscal from "../../functions/Bancos/eliminarFiscal";
import getProductos_afectaciones_fiscales from "../../functions/Bancos/getProductos_afectaciones_fiscales";
import Swal from "sweetalert2";


function ModalEliminar_afectacion({
  Eliminar_afectacion,
  setEliminar_afectacion,
}) {

  const [Fiscales, setFiscales] = React.useState([]);

  async function traer_fiscales(){
    await getProductos_afectaciones_fiscales().then((FiscalesDB) => {
        console.log(FiscalesDB) 
        setFiscales(FiscalesDB);
   });
  }


  function eliminarProductoModal() {
    //obtener infor del formulario
    const valor = document.getElementById("nombre_de_afectacion").value;
    // enviar informacion a firebase
    const infoProducto = {valor};
    eliminarFiscal(infoProducto);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se elimino correctamente.',
      showConfirmButton: false,
      timer: 1500
    })
    // cerrar modal
    setEliminar_afectacion(false); 
  }
 
  React.useEffect(() => {
    traer_fiscales();
  }, []); 

  return (
    <Modal show={Eliminar_afectacion} onHide={() => setEliminar_afectacion(false)}>
      <Modal.Header>
      <Modal.Title>Eliminar afectación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Seleccionar la afectación a eliminar</h6>
            <select id="nombre_de_afectacion" type="text">
            {Fiscales.map((datos,index)=>( 
                <option key={index}>{datos.nombre_afectacion_fiscal}</option>
            ))}
            </select>
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEliminar_afectacion(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>eliminarProductoModal()}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEliminar_afectacion;