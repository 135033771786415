import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import añadirCliente_Embarque from "../../functions/Produccion/añadirCliente_Embarque";
import eliminarClientes_Embarque from "../../functions/Produccion/eliminarClientes_Embarque";
import getClientes_Embarque from "../../functions/Produccion/getClientes_Embarque";
import Swal from "sweetalert2";

function ModalClientes_Embarque({
  isModalClientes,
  setisModalClientes,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
}) {

 //Eliminar con sweetalert2
 function eliminar_sweetalert(producto) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el cliente?  "'+ producto.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El cliente ha sido eliminado.',
        'success'
      )
      eliminarClientes_Embarque(producto).then(
        (confirmacion) => {
          Inicio();
        }
      );
     }
  })
}

  //Modal para agregar categorias
  const [Agregar_clienteON, setAgregar_clienteON] = React.useState(false);

  function Agregar_cliente(){
    setAgregar_clienteON(true)
    setInicio_modal(false);
  }

const [ExportacionON, setExportacionON] = React.useState(false);

function exportacion(){
  
  var auxiliar200 = document.getElementById("nacional_exportacion").value;
  console.log(auxiliar200)
  if(auxiliar200=="Exportación"){
    setExportacionON(true);
  }else{
    setExportacionON(false);
  }
}

  function salir(){
    setisModalClientes(false);
  }

  function regresar_categorias(){
    setAgregar_clienteON(false);
    setInicio_modal(true);
  }

  const [Inicio_modal, setInicio_modal] = React.useState(false);
  const [Clientes, setClientes] = React.useState([]);

  async function Inicio(){
    await getClientes_Embarque().then((clientesDB) => {
      setClientes(clientesDB);
      setInicio_modal(true);
      console.log("HOLA")
      });
    
  }

  function añadir_categoria(){
     //obtener infor del formulario
    //const proveedor = document.getElementById("proveedor").value;
    const nombre2 = document.getElementById("nombre_cliente").value;
    const nombre = nombre2.trim();
    const nacional_exportacion = document.getElementById("nacional_exportacion").value;
    const ciudad = document.getElementById("ciudad").value;
    const direccion = document.getElementById("direccion").value;

    var rfc = "";
    if(ExportacionON==true){
      rfc = "XEXX010101000";
    }else{
      rfc = document.getElementById("rfc").value;
    }
    
    const deuda = 0;
    // enviar informacion a firebase
    const infoProducto = {nombre, nacional_exportacion, ciudad, direccion, rfc, deuda};
    añadirCliente_Embarque(infoProducto);
    // cerrar modal
    //actualizarEstadoProductos();
    //setisModalClientes(false)
    setAgregar_clienteON(false);
    Inicio();
  }
  
  React.useEffect(() => {
    Inicio();
  }, []);




  return (
   <div >
    <Modal id="Modal_Logs" show={Inicio_modal} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Clientes de venta</Modal.Title>
        <Button  onClick={() => {
                      Agregar_cliente();
                    }}>
          Agregar cliente
        </Button>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
        

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Clientes</th>
            <th>Nacional o Exportación</th>
            <th>Ciudad</th>
            <th>Dirección</th>
            <th>RFC</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Clientes.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td>{producto.nacional_exportacion}</td>
                <td>{producto.ciudad}</td>
                <td>{producto.direccion}</td>
                <td>{producto.rfc}</td>
                  <td>
                          {/* <Button
                            variant="dark"
                            onClick={() => {
                              salir();
                            }}
                          >
                            Editar
                          </Button> */}
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminar_sweetalert(producto) 
                            }}
                          >
                            Eliminar
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>
    
    
    <Modal id="Modal_Logs" show={Agregar_clienteON} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Añadir nuevo cliente</Modal.Title>

      </Modal.Header>
      <Modal.Body> 
      <Form>
          <Stack>
            <Form.Control
              id="nombre_cliente"
              placeholder="Ingresar nombre del nuevo cliente"
              type="text"
              className="mb-1"
            />
            <br/>
            <h6>Seleccionar si es nacional o exportación</h6>
            <select 
            id="nacional_exportacion" 
            type="text"
            onChange={() => {
              exportacion();
            }}>
             <option>Nacional</option>
             <option>Exportación</option>
            </select>
            <br/>       
          
            {ExportacionON ?  
              <div>
              <Form.Control
              id="ciudad"
              placeholder="Ingresar ciudad"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="direccion"
              placeholder="Ingresar dirección"
              type="text"
              className="mb-1"
            />
            </div>

              :
              <div>
              <Form.Control
              id="ciudad"
              placeholder="Ingresar ciudad"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="direccion"
              placeholder="Ingresar dirección"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="rfc"
              placeholder="Ingresar RFC"
              type="text"
              className="mb-1"
            />
            </div>
     
            }
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button  onClick={() => {
                      añadir_categoria();
                    }}>
          Añadir
        </Button>
        <Button variant="secondary" onClick={() => {
                      regresar_categorias();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  );
}

export default ModalClientes_Embarque;