import React from "react";
import { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import loginEmailPassword from "../functions/loginEmailPassword";
import Navbar from "../../Navbars/Navbar";
import Swal from "sweetalert2";
import firebaseApp from "../firebase/credenciales";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);
//aaronmirandamedina@hotmail.com
const resetPassword = (email) => sendPasswordResetEmail(auth, email);

function Login2() {
  //console.log("LOGIN2")
  async function submitHandler(e) {
    e.preventDefault();
    const correo = document.getElementById("formCorreo").value;
    const contra = e.target.formContra.value;
    await loginEmailPassword(correo, contra);
  }

  //RECUPERAR CONTRASEÑA
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ target: { value, name } }) =>
    setUser({ ...user, [name]: value });

    const handleResetPassword = async (e) => {
      e.preventDefault();
      if (!user.email) 
      return 
      //alert("Porfavor ingresa tu correo electronico")
      console.log("1");
      /* Swal.fire({
        icon: 'error',
        text: "Usuario no encontrado",
        footer: '¿Que hacer? <br/> Se debe ingresar un email el cual ya se encuentre registrado en la empresa.'
      }) */
      try {
        await resetPassword(user.email);
        console.log("3");
        //alert("Hemos enviado un link a tu correo electronico para que puedas restablecer tu contraseña")
        Swal.fire({
          icon: 'success',
          text: "Hemos enviado un link a tu correo electronico para que puedas restablecer tu contraseña",
          footer: '¿Que hacer? <br/> Ingresar a la bandeja de entrada de su correo corresondiente y seleccionar el correo que se acaba de enviar.'
        })
      } catch (error) {
        console.log("2");
        //alert(error.message);
        //alert("Usuario no encontrado");
        Swal.fire({
          icon: 'error',
          text: "Usuario no encontrado",
          footer: '¿Que hacer? <br/> Se debe ingresar un email el cual ya se encuentre registrado en la empresa.'
        })

      }
    };

  return (
   <div id="Trabajo"> 
    <Navbar/> 
    <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

    <Container id="Login_contraseña">
      <h1>Inicia sesión, por favor</h1>
      <br/>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="formCorreo">
          <Form.Label>Correo</Form.Label>
          <Form.Control type="email" placeholder="Ingresa tu correo electrónico" onChange={handleChange} name="email"/>
        </Form.Group>
        <br/>
        <Form.Group controlId="formContra">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" placeholder="Contraseña" onChange={handleChange} name="password" />
        </Form.Group>
        <br/>
        <div className="flex items-center justify-between">
          <Button variant="primary" type="submit">
            Iniciar sesión
          </Button>
          <a href="" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" id="RecuperarAcc"
          onClick={handleResetPassword}>¿Olvidaste tu contraseña?</a>
        </div>
        <br/>
        <br/>
      </Form>
    </Container>
   </div> 
  );
}

export default Login2;
