import React from "react";

import { Modal, Stack, Form, Button } from "react-bootstrap";

import editarProducto_orden from "../functions/editarProducto_orden";

function ModalEditar_ordenes({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,  
  usuario,
  productofolio,
}) {
  function editarProductoModal() {
    //obtener infor del formulario
    //const proveedor = document.getElementById("proveedor").value;
    const producto = document.getElementById("producto").value;
    const cantidad = document.getElementById("cantidad").value;
    const precio = document.getElementById("precio").value;
    const unidad = document.getElementById("unidad").value;
    //const moneda = document.getElementById("moneda").value;
    // enviar informacion a firebase
    const infoProducto = {cantidad, unidad, producto, precio};
    editarProducto_orden(infoProducto, usuario.email,productofolio);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            {/* <Form.Control
              id="proveedor"
              placeholder="Agregar proveedor"
              type="text"
              className="mb-1"
              value={productoEstado?.proveedor}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  proveedor: e.target.value,
                })
              }
            /> */}
            <Form.Control
              id="producto"
              placeholder="Agregar producto"
              type="text"
              className="mb-1"
              value={productoEstado?.producto}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  producto: e.target.value,
                })
              }
            />
            <Form.Control
              id="cantidad"
              placeholder="Agregar cantidad"
              type="text"
              className="mb-1"
              value={productoEstado?.cantidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  cantidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="unidad"
              placeholder="Agregar unidad"
              type="text"
              className="mb-1"
              value={productoEstado?.unidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  unidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="precio"
              placeholder="Agregar precio"
              type="text"
              className="mb-1"
              value={productoEstado?.precio}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  precio: e.target.value,
                })
              }
            />
            {/* <Form.Control
              id="moneda"
              placeholder="Agregar moneda"
              type="text"
              className="mb-1"
              value={productoEstado?.moneda}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  moneda: e.target.value,
                })
              }
            /> */}
          </Stack>
        </Form>
        
      </Modal.Body>

      <Modal.Footer>       
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 

      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_ordenes;

