import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getProductos_presentaciones2(articulo) {
    

    console.log(articulo);
    const presentacionesBD =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Produccion/Lineas_produccion/"+articulo.replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      presentacionesBD.push(doc.data());
    });
    return presentacionesBD;
    

}
