import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import escribirLog from "./escribirLog";

function añadirProducto(infoProducto, autor,pagina,start) {
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, window.location.pathname);
  console.log(infoProducto)
  if(start){ 
      collectionRef = collection(db, "Almacen_campo/"+pagina+"/Productos".replace(/ /g, ""))
      }else{ collectionRef = collection(db, window.location.pathname)}
      
  const docRef = doc(collectionRef, infoProducto.nombre);
  updateDoc(docRef, infoProducto);

  //escribirLog("Edición", infoProducto, autor);
}

export default añadirProducto;
