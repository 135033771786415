import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarPagos_manifiestos(producto2) {
  console.log(producto2);
  const coleccionRef = collection(db, "Manifiestos/Pagos/"+producto2.manifiesto+"/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto2.fecha);
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
