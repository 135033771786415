import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllcodigos_QR() {
  const Codigos_QR_DB = [];
  console.log("Hola")
  let collectionRef = collection(db, "Codigos_QR/".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    Codigos_QR_DB.push(doc.data());
  });
  return Codigos_QR_DB;
}
