import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

export default async function eliminarLog_almacen(producto, pagina, cantidad_actual) {
 console.log(pagina, producto)
  if(producto.accion=="Agregar"){
    //Esto es para restar si se agrego desde el almacen
    var auxiliar = formatter.format(cantidad_actual - producto.Cantidad_agregada);
    const str = auxiliar.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const nuevaStr = str.replace("$", "");
    const nuevaStr2 = nuevaStr.replace(",", "");
    const nuevaStr3 = nuevaStr2.replace(",", "");
    var cantidad_actualizada = nuevaStr3.replace(",", "");

    //var cantidad_actualizada = parseFloat(cantidad_actual) - parseFloat(producto.Cantidad_agregada)
    const collectionRef = collection(db, "Almacen_campo/"+pagina+"/Productos/".replace(/ /g, ""))
    const docuRef = doc(collectionRef, producto.Producto_retirado);
    await updateDoc(docuRef,{
      cantidad: cantidad_actualizada
    });
    //Con esto se elimina del LOGS
    const collectionRef2 = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""))
    const docuRef2 = doc(collectionRef2, producto.Titulo);
    await deleteDoc(docuRef2);
  }
  
  if(producto.accion=="Retiro"){
    //Esto es para restar si se agrego desde alguna parte del campo
     var auxiliar = formatter.format(+cantidad_actual + +producto.Cantidad_retirada);
     const str = auxiliar.toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
     const nuevaStr = str.replace("$", "");
     const nuevaStr2 = nuevaStr.replace(",", "");
     const nuevaStr3 = nuevaStr2.replace(",", "");
     var cantidad_actualizada = nuevaStr3.replace(",", "");

    //var cantidad_actualizada = parseFloat(cantidad_actual) + parseFloat(producto.Cantidad_retirada)
    const collectionRef = collection(db, "Almacen_campo/"+pagina+"/Productos/".replace(/ /g, ""))
    const docuRef = doc(collectionRef, producto.Producto_retirado);
    await updateDoc(docuRef,{
      cantidad: cantidad_actualizada
    });
    //Con esto se elimina del LOGS
    const collectionRef2 = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""))
    const docuRef2 = doc(collectionRef2, producto.Titulo);
    await deleteDoc(docuRef2);

    //Con esto se elimina de cultivo en proceso de su respectiva tabla
    const collectionRef3 = collection(db, "Cultivo_en_proceso/"+producto.Seccion+"/Productos/")
    const docuRef3 = doc(collectionRef3, producto.fecha2);
    await deleteDoc(docuRef3);
  }
}
