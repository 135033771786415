import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getOrden_1_sola(Enviarproducto) {
    

    console.log(Enviarproducto.proveedor);
    var proveedoresDB =  [];
    const docRef = doc(db,"Proveedores",Enviarproducto.proveedor);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()){
      console.log("La orden es :", docSnap.data())
      proveedoresDB = docSnap.data();
    }else{
      console.log("No se encontro orden")
    }

    return proveedoresDB;
    

}


//"+productofolio+"
