import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
import escribirLog from "../escribirLog";
const db = getFirestore(firebaseApp);

export default async function eliminarCategoria(producto) {
  const coleccionRef = collection(db, "Categorias_productos".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.categoria);
  const eliminado = await deleteDoc(docuRef);

  //escribirLog("Eliminación", producto, usuario);

  return eliminado;
}
