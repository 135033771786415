import firebaseApp from "../../firebase/credenciales";
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";
const auth = getAuth(firebaseApp);

async function Crearusuario(Correo,Contraseña) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, Correo, Contraseña)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
}

export default Crearusuario;
