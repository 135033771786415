import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllPallets(producto) {
    console.log(producto.manifiesto);
    const PalletsDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Manifiestos/Tarimas/"+producto.manifiesto+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PalletsDB.push(doc.data());
    });
    return PalletsDB;
    

}
