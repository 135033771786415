import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarProductoBancos(producto,Nombre_de_boton) {
  console.log(Nombre_de_boton.nombre_banco);
  console.log(producto.nombre);
  const coleccionRef = collection(db, "Bancos/Bancos/"+Nombre_de_boton.nombre_banco+"/");
  const docuRef = doc(coleccionRef, producto.nombre.toString());
  const eliminado = await deleteDoc(docuRef);

  //Con este elimino en las afectaciones concentrado
 //Eliminar a afectación--------------------------------------------------------------------------------------------------------------------
 console.log(producto);
 console.log(producto.afectacion_fiscal_1, (producto.fecha3).toString() )
 if(producto.afectacion_fiscal_1==""){
}else{
  const coleccionRef2 = collection(db, "Bancos/Afectaciones_fiscales_productos/"+producto.afectacion_fiscal_1+"/");
  const docuRef2 = doc(coleccionRef2, (producto.fecha3).toString());
  await deleteDoc(docuRef2);
}

if(producto.afectacion_fiscal_2==""){
}else{
  const coleccionRef2 = collection(db, "Bancos/Afectaciones_fiscales_productos/"+producto.afectacion_fiscal_2+"/");
  const docuRef2 = doc(coleccionRef2, producto.fecha3.toString());
  await deleteDoc(docuRef2);
}

if(producto.afectacion_fiscal_3==""){
}else{
  const coleccionRef2 = collection(db, "Bancos/Afectaciones_fiscales_productos/"+producto.afectacion_fiscal_3+"/");
  const docuRef2 = doc(coleccionRef2, producto.fecha3.toString());
  await deleteDoc(docuRef2); 
}

if(producto.afectacion_fiscal_4==""){
}else{
  const coleccionRef2 = collection(db, "Bancos/Afectaciones_fiscales_productos/"+producto.afectacion_fiscal_4+"/");
  const docuRef2 = doc(coleccionRef2, producto.fecha3.toString());
  await deleteDoc(docuRef2);
}

if(producto.afectacion_fiscal_5==""){
}else{
  const coleccionRef2 = collection(db, "Bancos/Afectaciones_fiscales_productos/"+producto.afectacion_fiscal_5+"/");
  const docuRef2 = doc(coleccionRef2, producto.fecha3.toString());
  await deleteDoc(docuRef2);
}

  return eliminado;
}
