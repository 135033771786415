import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import React, {useState} from 'react';

function guardar_semana_pagos(infoProducto, auxiliar, semana_actual_nominas, año_actual_nominas) {
  console.log(infoProducto);
  console.log(auxiliar, semana_actual_nominas, año_actual_nominas);
  const db = getFirestore(firebaseApp);
  var docRef = "";
  let collectionRef = collection(db, "Nominas/"+auxiliar+"/"+año_actual_nominas+"/"+semana_actual_nominas+"/Trabajadores/".replace(/ /g, ""));   
  
  infoProducto.map((trabajador)=>{
    docRef = doc(collectionRef, trabajador.nombre)
    setDoc(docRef, trabajador);
  })

}

export default guardar_semana_pagos;
