import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

import React, {useState} from 'react';

function añadirProducto(infoProducto, autor,) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Campo_secciones/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);
  //console.log(window.location.pathname)
  //escribirLog("Creación", infoProducto, autor, pagina);

  return(
        <div>
            
            
          </div>
        )
      
}

export default añadirProducto;
