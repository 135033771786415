import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_restar_producto_terminado_embarque(accion, usuario, valor_cantidad_ingresado,nombreproducto, línea,
  fecha_actual, zona, precio2, Manifiesto_Seleccionado, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, cajas_por_pallet, 
  Manifiesto, fecha_actual3) {
    
  const persona= usuario.email;   
  const agregado = "NA";
  var manifiesto2 = "NA";
  if(Manifiesto_Seleccionado.manifiesto == undefined){
    manifiesto2 = "NA";
  }else{
    manifiesto2=Manifiesto_Seleccionado.manifiesto;
  }
  let date = new Date();
  if(fecha_actual3==undefined){
    fecha_actual3 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  }

  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  console.log(cajas_por_pallet)
  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, fecha_actual);
  const data = {
    accion,
    embarque: zona,
    fecha: fecha_actual3,
    fecha2: fecha_actual,
    Hora: hora_actual,
    Autor: persona,
    Seccion: línea,
    Cantidad_retirada: valor_cantidad_ingresado,
    Cantidad_agregada: agregado,
    Producto_retirado: nombreproducto,
    Titulo: fecha_actual,
    Precio: precio2,
    Manifiesto: manifiesto2,
    Tarima_retirada: tarima_retirada_seleccionada,
    Esquinero_retirado: esquinero_retirado_seleccionado,
    fecha_para_eliminar_tarima: date2.toString(),
    fecha_para_eliminar_esquinero: date3.toString(),
    Cajas_por_pallet: cajas_por_pallet,
  };
  console.log(data)
  setDoc(docuRef, data)
  //setDoc(docuRef, data);
}


//-------------------------------------------------------------------------------------------antes

/* import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_retirar_producto(accion, usuario, valor_cantidad_ingresado,nombreproducto, línea, fecha_actual, zona, precio2, Manifiesto_Seleccionado) {
  const persona= usuario.email;   
  const agregado = "NA";
  var manifiesto2 = "NA"
  if(Manifiesto_Seleccionado.manifiesto > 0){
    manifiesto2=Manifiesto_Seleccionado.manifiesto;
  }
  let date = new Date();
  let fecha_actual2 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  console.log(accion)
  console.log(valor_cantidad_ingresado)
  console.log(nombreproducto)
  console.log(línea)
  console.log(fecha_actual)
  console.log(zona)
  console.log(precio2)
  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, nombreproducto+","+new Date().toString());
  const data = {
    accion,
    embarque: zona,
    fecha: fecha_actual2,
    fecha2: fecha_actual,
    Hora: hora_actual,
    Autor: persona,
    Seccion: línea,
    Cantidad_retirada: valor_cantidad_ingresado,
    Cantidad_agregada: agregado,
    Producto_retirado: nombreproducto,
    Titulo: nombreproducto+","+new Date().toString(),
    Precio: precio2,
    Manifiesto: manifiesto2,
    
  };

  setDoc(docuRef, data)
  //setDoc(docuRef, data);
}
 */