import firebaseApp from "../../firebase/credenciales";
import { getAuth, deleteUser} from "firebase/auth";
const auth = getAuth(firebaseApp);

async function EliminarUsuario(Correo) {
  

  /* deleteUser(auth, Correo).then(() => {
    // User deleted.
  }).catch((error) => {
    // An error ocurred
    // ...
  }); */
}

export default EliminarUsuario;
