import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_movimiento_nominas from "../../functions/Seguro_social/añadir_movimiento_nominas";


function ModalAñadir({
  Modal_añadir_fecha_actual,
  setModal_añadir_fecha_actual,
  fecha_actual_nominas,
  semana_actual_nominas,
  año_actual_nominas,
}) {
  async function actualizar() {
/*     await getAlldata().then((productos) => {
      console.log(productos)
       //setProductos(productos);   
       //Traer_secciones(productos);
     });  */
   }

  async function añadirProductoModal() {
    //obtener infor del formulario
    const semana = document.getElementById("semana").value;
    const rango_fecha = document.getElementById("rango_fecha").value;
    const año = document.getElementById("año").value;
    const fecha = "fecha";
    // enviar informacion a firebase
    const infoProducto = { semana, rango_fecha, año, fecha};
    const auxiliar = "Fecha_para_nominas";
    const auxiliar2 = "Nombres";
    añadir_movimiento_nominas(infoProducto, auxiliar, auxiliar2);
    // cerrar modal
    //actualizar();
    setModal_añadir_fecha_actual(false);
  }

  return (
    <Modal show={Modal_añadir_fecha_actual} onHide={() => setModal_añadir_fecha_actual(false)}>
      <Modal.Header>
        <Modal.Title>Añadir fecha de nomina</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Ingresar numero de semana actual</h6>
           <Form.Control
              key="700"
              id="semana"
              placeholder="Ingresar el numero de semana"
              type="text"
              className="mb-1"
              defaultValue={semana_actual_nominas}
            />
            <br/>
            <h6>Ingresar rango de fechas</h6>
            <Form.Control
              key="701"
              id="rango_fecha"
              placeholder="Ejemplo: Del lunes 29 de septiembre al 5 de octubre del 2023"
              type="text"
              className="mb-1"
              defaultValue={fecha_actual_nominas}
            />
           <br/>
            <h6>Ingresar el año</h6>
            <Form.Control
              key="701"
              id="año"
              placeholder="Año"
              type="number"
              className="mb-1"
              defaultValue={año_actual_nominas}
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_añadir_fecha_actual(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
