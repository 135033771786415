import firebaseApp from "../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarProductoHome(producto, usuario,pagina,start) {
  let collectionRef = collection(db, window.location.pathname);
  if(start){ 
      collectionRef = collection(db, "Almacen_campo/"+pagina+"/Productos".replace(/ /g, ""))
      }else{ collectionRef = collection(db, window.location.pathname)}

  const docuRef = doc(collectionRef, producto.nombre);
  const eliminado = await deleteDoc(docuRef);
  //escribirLog("Eliminación", producto, usuario);

  return eliminado;
}
