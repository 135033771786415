import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import escribirLog_retirar_producto from "./escribirLog_retirar_producto";

/* function añadirProducto({producto_retirado_final, autor,nombrecategoria,nombre2}) {
  const objeto= Object.assign({}, producto_retirado_final);


  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Almacen_campo/"+nombrecategoria+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, nombre2.toString());
  console.log(docRef)
  console.log(objeto[0] )
  setDoc(docRef, objeto[0] );
  escribirLog("Edición", producto_retirado_final, autor);
}

export default añadirProducto;
 */
async function añadirProducto({ 
  autor,
  nombrecategoria,
  nombre2,
  cantidad_restante_en_almacen_campo,
  valor_cantidad_ingresado,
  producto_retirado_final,
   pagina}) {
  const objeto= Object.assign({}, producto_retirado_final);


  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Almacen_campo/"+nombrecategoria+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, nombre2.toString());
  /* console.log(docRef)
  console.log(objeto[0] ) */
  //setDoc(docRef, objeto[0] );
  await updateDoc(docRef, {
    cantidad: cantidad_restante_en_almacen_campo
  })
  //escribirLog_retirar_producto("Edición", autor);
}

export default añadirProducto;
