import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editarProducto from "../../functions/Almacen_empaque/editarProducto";
import escribirLog_agregar_producto_empaque from "../../functions/Produccion/escribirLog_agregar_producto_empaque";
import getAllProducts from "../../functions/Almacen_empaque/getAllProducts";
import {DatePicker} from "@material-ui/pickers";

function ModalEditar({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
  setProductos,
}) {

  async function actualizar(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);    
     });
   }

        //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  
   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

//Guardar en log lo que se agrego al almacen
  async function agregar_log(){
    const nombreproducto = productoEditar.nombre;
    const valor_cantidad_ingresado = document.getElementById("agregar_retirar").value;
    console.log(nombreproducto,valor_cantidad_ingresado );
    const Presentacion = "NA";
    var date = fechaSeleccionada;
  await escribirLog_agregar_producto_empaque("Agregar", usuario, valor_cantidad_ingresado, nombreproducto, Presentacion, date);
  }



  async function editarProductoModal() {
    //obtener infor del formulario
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = productoEditar.moneda;
    const cantidad = document.getElementById("cantidad").value;
    const unidad = productoEditar.unidad;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    await timeout(500);
    actualizar(pagina,start);
    setIsModalEditar(false);
  }

  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  async function agregarProductoModal(e) { 
    //obtener infor del formulario
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = productoEditar.moneda;

            //Esto es para restar si se agrego desde el almacen
            var auxiliar = formatter.format(+productoEditar.cantidad + +(document.getElementById("agregar_retirar").value).toString());
            const str = auxiliar.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var cantidad = nuevaStr3.replace(",", "");


    //const cantidad = ((+productoEditar.cantidad) + (+document.getElementById("agregar_retirar").value)).toString();
    const unidad = productoEditar.unidad;
    
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    agregar_log();
    setProductoEditar(null);
    await timeout(500);
    actualizar(pagina,start);
    setIsModalEditar(false);
  }
//window.location.reload(true);
  function retirarProductoModal() {
    //obtener infor del formulario
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = productoEditar.moneda;
    const cantidad = ((+productoEditar.cantidad) - (+document.getElementById("agregar_retirar").value)).toString();
    const unidad = productoEditar.unidad;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }


  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p id="Modal_movimiento">Producto actual (se puede editar la moneda o agregar cantidad del producto seleccionado)</p> 
        <Form>
          <Stack>
            <br/>
            <h6>Seleccionar moneda</h6>
            <select id="moneda" type="text">
              <option>Pesos</option>
              <option>Dolares</option>
            </select>
            <br/>
          </Stack>
        </Form>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 
        <hr/>
        <br/> 

        <p style={{marginRight: "32%"}}>Cantidad del producto que se desea agregar</p>  
        <Form.Control
        id="agregar_retirar"
        placeholder="ingresar cantidad a agregar"
        type="text"
        className="mb-1"
        /> 

        <br/> 
        <h6>Fecha final</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
        <br/> 

      </Modal.Body>

      <Modal.Footer>  

        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
  
        <Button variant="primary" onClick={agregarProductoModal}>
          Agregar
        </Button>
        {/* <Button variant="primary" onClick={retirarProductoModal}>
          Retirar
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar;
