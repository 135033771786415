import firebaseApp from "../../firebase/credenciales";
import React from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    
export default async function getProductos(pagina2) {
  console.log(pagina2)
  const infoProducto =  [];
  const collectionRef = collection(db, "Productos".replace(/ /g, ""));
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    const valor = doc.data();
    if(valor.categoria===pagina2)
    {
      infoProducto.push(doc.data());
     /* console.log(infoProducto) */
    }
  });
    return infoProducto;
    

}


//"+productofolio+"
