import React from "react";
import ErrorBoundary from '../components/Salvavidas'
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home_Codigos_QR from "../views/Home_Codigos_QR";
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD_Codigos_QR(){
const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
   <ErrorBoundary>
    <Container fluid>
      {usuario ? <Home_Codigos_QR usuario={usuario}></Home_Codigos_QR>: <Login2 />}
    </Container>
    </ErrorBoundary>   
    );
}




export default CRUD_Codigos_QR;