import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_pallet_nuevo(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Productos/");
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);      
}

export default añadir_pallet_nuevo;
