import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminar(producto, usuario,productofolio) {
  const coleccionRef = collection(db, "/Seguro_social".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.NSS);
  const eliminado = await deleteDoc(docuRef);

  //escribirLog("Eliminación", producto, usuario);

  return eliminado;
}
