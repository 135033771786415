import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import EliminarUsuario from "../../functions/Usuarios/EliminarUsuario";

function ModalEliminar({
  Modal_Eliminar,
  setModal_Eliminar,
}) {

  async function Eliminar(){
    const Correo = document.getElementById("Correo").value;
  await EliminarUsuario(Correo);
    setModal_Eliminar(false); 
  }


  return (
    <Modal
      show={Modal_Eliminar}
      onHide={() => {
        setModal_Eliminar(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Eliminar usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <Form>
          <Stack>
            <Form.Control
              id="Correo"
              placeholder="Correo"
              type="text"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>

      <Modal.Footer>  
        <Button
          variant="secondary"
          onClick={() => {
            setModal_Eliminar(false);
          }}
        >
          Cancelar
        </Button>
  
        {/* <Button variant="primary" onClick={() => {
            Eliminar(false);
          }}>
          Eliminar
        </Button> */}
        {/* <Button variant="primary" onClick={retirarProductoModal}>
          Retirar
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEliminar;

