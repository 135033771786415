import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_proveedor from "../../functions/Bancos/añadir_proveedor";
import getProveedores from "../../functions/Bancos/getProveedores";

function ModalEditar_proveedor({
  Agregar_proveedor,
  setAgregar_proveedor,
  Producto_a_editar,
  Nombre_de_boton,
}) {

//Traer todos los proveedores
const [Proveedores_BD, setProveedores_BD] = React.useState([]);

async function traer_proveedores() {
  await getProveedores().then((ProveedoresDB) => {
    setProveedores_BD(ProveedoresDB);
   });
 }

 function añadirProductoModal(){
  var proveedor_a_modificar = document.getElementById("proveedores").value;
  var auxiliar = {
    Proveedor_elegido: proveedor_a_modificar
  }
  añadir_proveedor(Producto_a_editar, Nombre_de_boton, auxiliar);
  setAgregar_proveedor(false);

 }
   
  React.useEffect(() => {
    traer_proveedores() 
  }, []); 

  return (
    <Modal show={Agregar_proveedor} onHide={() => setAgregar_proveedor(false)}>
      <Modal.Header>
        <Modal.Title>Añadir Proveedor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>

            <h6>Seleccionar proveedor</h6>
            {Proveedores_BD ? 
                <select 
                id="proveedores" 
                type="text"
                >
                  {Proveedores_BD.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>                
            :
            null
            }  
            <br/>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAgregar_proveedor(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=> añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_proveedor;
