import firebaseApp from "../../Firebase/firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getCategorias_botones_empaque() {
    const Categorias_botonesDB =  [];
    console.log("Hola");
    const collectionRef = collection(db, "Botones_categorias_almacen_empaque".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Categorias_botonesDB.push(doc.data());
    });
    return Categorias_botonesDB;
    
    

}


//"+productofolio+"
