import React, {useState} from "react";
import { Modal, Stack, Form, Button, Table } from "react-bootstrap";
import añadirProducto from "../../functions/Campo_secciones/añadirProducto";
import getLogs_almacen_campo from "../../functions/getLogs_almacen_campo";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import eliminarLogs_Finciclo from "../../functions/Campo_secciones/eliminarLogs_Finciclo";

function ModalFinciclo({
  ModalFin,
  setFin,
  actualizarEstadoProductos,
  usuario,
  productoEditar,
}) {

//Eliminar de Logs los productos al imprimir el excel de fin ciclo
async function Delete(){

  
  await Data.map((producto)=>{
    eliminarLogs_Finciclo(producto)
  })
  //eliminarLogs_Finciclo(CultivoFin)
}
//Iniciar Modal
const [Modal_start, setModal_start] = useState(false);
function IniciarModal(){
  setModal_start(true);
  setModal_filtrados(false);
}

//Filtrar de los LOGS de almacen de campo, por la tabla o sección primero
async function Historial(){
  const producto = productoEditar;
  const Datofinciclo = 1;
  console.log(producto)
  await getLogs_almacen_campo(producto, Datofinciclo).then((Logs) => {
    console.log(Logs)
    FiltrarLogs_cultivo(Logs)
  });
}

const [Modal_filtrados, setModal_filtrados] = useState(false);
const [Data, setData] = useState([]);
const [Fecha, setFecha] = useState("");
const [CultivoFin, setCultivoFin] = useState("");
const [CultivoInicio, setCultivoInicio] = useState("");

async function FiltrarLogs_cultivo(Logs){
  const cultivo = productoEditar.producto1;
  const Inicio = productoEditar.producto1_fecha;
  console.log(cultivo)
  const Datos_filtrados = [];
  
   await Logs.map((Dato)=>{
      if(cultivo==Dato.Cosecha){
        Datos_filtrados.push(Dato)
      }
      console.log(Datos_filtrados)
     })
//Fecha actual
let date = new Date();
let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
     setCultivoInicio(Inicio);
     setCultivoFin(cultivo);
     setFecha(fecha_actual);
     setData(Datos_filtrados);
     setModal_start(false);
     setModal_filtrados(true)
     console.log(Fecha)
}

//Cuando quieren finalizar el segundo cultivo
async function Historial2(){
  const producto = productoEditar;
  const Datofinciclo = 1;
  console.log(producto)
  await getLogs_almacen_campo(producto, Datofinciclo).then((Logs) => {
    console.log(Logs)
    FiltrarLogs_cultivo2(Logs)
  });
}

async function FiltrarLogs_cultivo2(Logs){
  const cultivo = productoEditar.producto2;
  const Inicio = productoEditar.producto2_fecha;
  console.log(Inicio)
  const Datos_filtrados = [];
  
   await Logs.map((Dato)=>{
      if(cultivo==Dato.Cosecha){
        Datos_filtrados.push(Dato)
      }
      console.log(Datos_filtrados)
     })

//Fecha actual
let date = new Date();
let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
     setCultivoInicio(Inicio);
     setCultivoFin(cultivo);
     setFecha(fecha_actual);
     setData(Datos_filtrados);
     setModal_start(false);
     setModal_filtrados(true)
}




//Ver cuales cultivos estan activos
  const [Cultivo1, setCultivo1] = useState(false);
  const [Cultivo2, setCultivo2] = useState(false);

  function cultivos_actuales() {
    if(productoEditar.producto1=="NA"){
      setCultivo1(true)
    }

    if(productoEditar.producto2=="NA"){
      setCultivo2(true)
    }
  }

  React.useEffect(() => {
   cultivos_actuales();
   IniciarModal();
  }, []);

  return (
    <div>
    <Modal show={Modal_start} onHide={() => setFin(false)}>
      <Modal.Header>
        <Modal.Title>Seleccionar cultivo a finalizar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          {Cultivo1?
            null
            :
            <div>
              <h6>¿Esta seguro de finalizar el ciclo del Cultivo 1?</h6>
              <Button variant="primary" onClick={Historial}>
                Fin ciclo cultivo 1
              </Button>
              <br/>
              <br/>
              <hr/> 
             </div> 
          }  

          {Cultivo2?
            null
            :
            <div>
              <h6>¿Esta seguro de finalizar el ciclo del Cultivo 2?</h6>
              <Button variant="primary" onClick={Historial2}>
                Fin ciclo cultivo 2
              </Button>
            </div> 
          } 
          {Cultivo1 && Cultivo2 ?
          <h6>No existe ningun cultivo actualmente en esta tabla.</h6>
          :
          null
          
          
          }
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setFin(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>

{/* Segundo Modal */}

    <Modal id="Modal_Logs" className="container-fluid" show={Modal_filtrados} onHide={() => setFin(false)}>
      <Modal.Header>
        <Modal.Title>Historico de movimientos</Modal.Title>
        
        <ReactHTMLTableToExcel
        id="botonExportarExcel"
        className="btn btn-success"
        table="Tabla_logs"
        filename={"Fin " + CultivoFin + " " + Fecha}
        sheet={CultivoInicio + " al " + Fecha + " "}
        buttonText="Descargar Excel"
        />
        <Button variant="secondary" onClick={() => {Delete()}}>
          Eliminar historial de cultivo
        </Button>
        <Button variant="secondary" onClick={() => {
          setModal_filtrados(false)
          setModal_start(true)}}>
          Salir
        </Button>
      </Modal.Header>
      <Modal.Body>
     <h6 style={{ color: "red", fontSize: 20 }}>Nota importante!!: Es necesario descargar el documento Excel antes de eliminar
       el historial, ya que si se borra no se podra descargar el documento Excel.</h6>   
      <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            <th>#</th>
            <th>Autor</th>
            <th>Producto retirado</th>
            <th>Cantidad retirada</th>
            <th>Cantidad agregada</th>
            <th>Zona o tabla donde se uso</th>
            <th>Cosecha</th>
            <th>Fecha</th>
            <th>Hora</th>
            
          </tr>
        </thead>
        <tbody>
          {
            Data.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.Autor}</td>
                <td>{producto.Producto_retirado}</td>
                <td>{producto.Cantidad_retirada}</td>
                <td>{producto.Cantidad_agregada}</td>
                <td>{producto.Seccion}</td>
                <td>{producto.Cosecha}</td>
                <td>{producto.fecha}</td>
                <td>{producto.Hora}</td>
                <td>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          setModal_filtrados(false)
          setModal_start(true)}}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>


    </div>


  );
}

export default ModalFinciclo;
