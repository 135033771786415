import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_categoria from "../funciones/añadir_categoria";
import React from "react";


function ModalAñadir({
  Modal_categoria,
  setModal_categoria,
  Categoria,
  actualizar_botones,
}) {


  function añadirProductoModal() {
    //obtener infor del formulario
    const valor = document.getElementById("categoria").value;
    // enviar informacion a firebase
    const infoProducto = {valor};
    añadir_categoria(infoProducto);
    // cerrar modal
    actualizar_botones();
    setModal_categoria(false); 
  }
 
  return (
    <Modal show={Modal_categoria} onHide={() => setModal_categoria(false)}>
      <Modal.Header>
        <Modal.Title>Nueva categoria, 12 como maximo.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar categoría existente en productos</h6>
            <select id="categoria" type="text">
            {Categoria.map((datos,index)=>( 
                <option key={index}>{datos.categoria}</option>
            ))}
            </select>
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_categoria(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;