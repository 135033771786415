import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";
import getCategorias from '../Firebase/functions/Productos/getCategorias';
import ModalAñadir from './Modales/ModalAñadir';
import getCategorias_botones from './funciones/getCategorias_botones';
import ModalEliminar from './Modales/ModalEliminar';


const Listainventario = ({setvalor,}) =>{   
    const [Modal_categoria, setModal_categoria] = React.useState(false);
    const [Modal_eliminar, setModal_eliminar] = React.useState(false);
    const [Categoria, setCategoria] = React.useState([]);
    const [Botones_campo, setBotones_campo] = React.useState([]);
    
   async function Categorias(){
        await getCategorias().then((CategoriasDB) => {
            console.log(CategoriasDB) 
            setCategoria(CategoriasDB);
       });
       setModal_categoria(true);
     }

    async function eliminar(){
      setModal_eliminar(true);
    }

     async function botones(){
        await getCategorias_botones().then((Categorias_botonesDB) => {
            console.log(Categorias_botonesDB) 
            setBotones_campo(Categorias_botonesDB);
       });
     }

    async function Seleccionar_almacen(prop){
       console.log(prop);
       setvalor(prop);
     }
     
    
     React.useEffect(() => {
       botones();
      }, []);

    return(
      <div> 
        {Modal_categoria && (
        <ModalAñadir
          Modal_categoria={Modal_categoria}
          setModal_categoria={setModal_categoria}
          Categoria={Categoria}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />)} 
         
        {Modal_eliminar && (
        <ModalEliminar
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Categoria={Categoria}
          actualizar_botones={botones}
          Botones_campo={Botones_campo}
          className="modal-dialog modal-fullscreen-sm-down"
        />)}
         
      <div id="Botones_agregareliminar_categoria_almacen_campo">
         <Button id="Botones_01" onClick={()=>{Categorias()}}> Agregar categoría de almacén</Button>
         <Button id="Botones_02" onClick={()=>{eliminar()}}> Eliminar categoría de almacén</Button>
      </div>

            <div id="Lista_empaque">
                
                {Botones_campo.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" onClick={() => Seleccionar_almacen((datos.valor))} key={index}>{datos.valor}</button>
                ))}
           
            </div>
      </div>    
    )
}

export default Listainventario;

//-----------------------------------------------------------------------------

/* import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";


const Listainventario = (props) =>{   
    const {Fertilizantes, Ferreteria, Semillas, Rafia}=props;
    

    return(
        <div id="Trabajo">
            <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
            <br/>
            <br/>
            <br/>
            <br/>
                <div  id="Lista_empaque" className="list-group">
                    <button onClick={() => Fertilizantes()}>Fertilizantes y Agroquimicos</button>
                    <br/>
                    <br/>
                    <button onClick={() => Ferreteria()}>Ferreteria</button>
                    <br/>
                    <br/>
                    <button onClick={() => Semillas()}>Semillas</button>
                    <br/>
                    <br/>
                    <button onClick={() => Rafia()}>Rafia</button>
                    
                </div>    
        </div>
    )
}

export default Listainventario */