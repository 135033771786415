import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import getClientes_Embarque from "../../functions/Produccion/getClientes_Embarque";
import añadirManifiesto from "../../functions/Produccion/añadirManifiesto";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";

function ModalAñadir_Embarque({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoManifiestos,
  productoEditar,
  productos_manifiestos,
  usuario,
}) {


//Traer clientes de embarque.
const [Clientes, setClientes] = React.useState([]);
const [Cantidad_actual, setCantidad_actual] = React.useState(0);

async function Clientes_actuales(){
  console.log(productos_manifiestos);
  await getClientes_Embarque().then((clientesDB) => {
    setClientes(clientesDB);

     //Esto es para saber cuantos productos tiene la tabla y darselo de numero de manifiesto
     var valores_productos_concentrados = [];
     productos_manifiestos.map((dato)=>{
       valores_productos_concentrados.push(dato.manifiesto)
     })
     var valor_maximo = 0;
     if(valores_productos_concentrados.length==0){
       valor_maximo = 0;
     }else{
       valor_maximo = Math.max(...valores_productos_concentrados)
     }
     
     setCantidad_actual(valor_maximo+1);
     console.log(valores_productos_concentrados);
     console.log(valor_maximo+1);
    });
}

const [ExportacionON, setExportacionON] = React.useState(false);
const [Cliente_seleccionado, setCliente_seleccionado] = React.useState([]);

function exportacion_o_nacional(){
  const cliente = document.getElementById("cliente").value;
  console.log(cliente);

  //Con esto verifico si el cliente seleccionado es exportacion o no
  var exportacion_si_no = false;
  Clientes.map((valor)=>{
    if(valor.nombre==cliente){
      if(valor.nacional_exportacion=="Exportación"){
        exportacion_si_no = true;
        setCliente_seleccionado(valor);
      }
      if(valor.nacional_exportacion=="Nacional"){
        exportacion_si_no = false;
        setCliente_seleccionado(valor);
      }
    }
  })

  if(exportacion_si_no==true){
    setExportacionON(true);
    console.log("true")
  }else{
    setExportacionON(false);
    console.log("False")
  }
}

  //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  function añadirProductoModal() {
    //Esto sirve para la seleccion de la fecha
    let date =fechaSeleccionada;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
    let fecha_actual3 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
    let fecha_actual2= date;



    //obtener infor del formulario
    const fecha = fecha_actual;
    const cliente = document.getElementById("cliente").value;
    var datos_cliente_seleccionado = [];
        Clientes.map((dato)=>{
          if(dato.nombre == cliente){
            datos_cliente_seleccionado=dato;
          }
        })
        console.log(datos_cliente_seleccionado)
    const nacional_exportacion2=datos_cliente_seleccionado.nacional_exportacion;
    console.log(nacional_exportacion2)
    const chofer = document.getElementById("chofer").value;
    const placas = document.getElementById("placas").value;
    const caja = document.getElementById("caja").value;
    const placas_caja = document.getElementById("placas_caja").value;
    const celular = document.getElementById("celular").value;
    const total_flete = document.getElementById("total_flete").value;
    //var manifiesto = Cantidad_actual.toString();
    const manifiesto = document.getElementById("manifiesto_agregado").value;
    //Esto es para confirmar que no exista otro manifiesto con el mismo numero del manifiesto
    var auxiliar500 = false;
    productos_manifiestos.map((datos)=>{
      if(datos.manifiesto==manifiesto){
          Swal.fire({
            icon: 'error',
            text: "El número del manifiesto ya existe.",
            footer: '¿Que hacer? <br/> Se debe ingresar un un número de manifiesto que no exista. El número que se quiere ingresar es ' +manifiesto
          })
          auxiliar500 = true;
      }
    })



    if(auxiliar500==true){

    }else{
//const embarque = document.getElementById("embarque").value;
    /* const pagado_si_no = document.getElementById("pagado_si_no").value; */
    const datos_transporte = document.getElementById("datos_transporte").value;
    const tem_f = document.getElementById("tem_f").value;
    //const fecha_salida = document.getElementById("fecha_salida").value; fecha_actual
    var fecha_salida = fecha_actual;
    const hora_salida = document.getElementById("hora_salida").value;
    var factura = "";
    const pagado = "$0";
    const usuario2 = usuario.email
    var scac = "";
    var agencia_aduanal_mx = "";
    var pedimiento_semanal = "";
    var rfc = Cliente_seleccionado.rfc;
    var id_trib = "";
    if(ExportacionON==true){
     rfc =  Cliente_seleccionado.rfc;
     scac = document.getElementById("scac").value;
     id_trib = document.getElementById("id_trib").value;
     agencia_aduanal_mx = document.getElementById("agencia_aduanal_mx").value;
     pedimiento_semanal = document.getElementById("pedimiento_semanal").value;
     factura = document.getElementById("factura").value;
    }
    // enviar informacion a firebase
    const infoProducto = { fecha, cliente, chofer, placas,
       caja, placas_caja, celular, total_flete, manifiesto, 
       usuario2, nacional_exportacion2,
       rfc, datos_transporte,scac, agencia_aduanal_mx, pedimiento_semanal,
       tem_f, fecha_salida, hora_salida, factura, pagado, fecha_actual3, fecha_actual2, id_trib,
      };
    console.log(infoProducto)
    añadirManifiesto(infoProducto );
    // cerrar modal
    actualizarEstadoManifiestos();
    setIsModalAñadir(false); 
    }
  }

  
  React.useEffect(() => {
    Clientes_actuales();
    /* actualizarEstadoProductos2(); */
    /* productos_basededatos();
    traer_folio_nuevo(); */
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => {
    setIsModalAñadir(false);
    }}>
      <Modal.Header>
        <Modal.Title>Nuevo manifiesto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar cliente</h6>
            <select id="cliente" type="text" onChange={() => exportacion_o_nacional()}>
            <option >Seleccionar</option>
            {Clientes.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>
          {ExportacionON && (
            <div>
              <Form.Control
              id="scac"
              placeholder="Agregar SCAC"
              type="text"
              className="mb-1"
            />   
            <Form.Control
              id="id_trib"
              placeholder="Agregar el ID TRIB"
              type="text"
              className="mb-1"
            />                   
          <Form.Control
              id="agencia_aduanal_mx"
              placeholder="Agregar agencia aduanal mx"
              type="text"
              className="mb-1"
            /> 
          <Form.Control
              id="pedimiento_semanal"
              placeholder="Agregar pedimiento semanal"
              type="text"
              className="mb-1"
            />   
           <Form.Control
              id="factura"
              placeholder="Agregar factura"
              type="text"
              className="mb-1"
            />   
            </div>
          )}
                    
          <Form.Control
              id="datos_transporte"
              placeholder="Agregar datos transporte"
              type="text"
              className="mb-1"
            /> 
          <Form.Control
              id="chofer"
              placeholder="Agregar chofer"
              type="text"
              className="mb-1"
            />   
            <Form.Control
              id="placas"
              placeholder="Agregar placas del camíon"
              type="text"
              className="mb-1"
            />   
            <Form.Control
              id="caja"
              placeholder="Agregar numero de caja"
              type="text"
              className="mb-1"
            />   
            <Form.Control
              id="placas_caja"
              placeholder="Agregar placas de la caja"
              type="text"
              className="mb-1"
            />   
            <Form.Control
              id="celular"
              placeholder="Agregar celular"
              type="text"
              className="mb-1"
            />  
            <Form.Control
              id="total_flete"
              placeholder="Agregar total del flete"
              type="text"
              className="mb-1"
            />  
          <Form.Control
              id="tem_f"
              placeholder="Agregar TEMP. F"
              type="text"
              className="mb-1"
            />     

            {/* <h6>Flete pagado Si/No</h6>
            <select id="pagado_si_no" type="text">
              <option >No</option>
              <option >Si</option>
            </select> */}
{/*             <Form.Control
              id="fecha_salida"
              placeholder="Agregar fecha de salida"
              type="text"
              className="mb-1"
            />  */}
            <br/>
            <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>

            <br/>
            <Form.Control
              id="hora_salida"
              placeholder="Agregar hora de salida"
              type="text"
              className="mb-1"
            /> 
{/*             <Form.Control
              id="factura"
              placeholder="Agregar factura"
              type="text"
              className="mb-1"
            />   */} 
{/*             <Form.Control
              id="embarque"
              placeholder="Agregar embarque"
              type="text"
              className="mb-1"
            />   */}
           <Form.Control
              id="manifiesto_agregado"
              placeholder="Agregar manifiesto"
              type="number"
              className="mb-1"
            /> 
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_Embarque;