import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_boton_bancos(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Botones/Productos/");
  const docRef = doc(collectionRef, infoProducto.nombre_banco);
  setDoc(docRef, infoProducto);      
}

export default añadir_boton_bancos;
