import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc, updateDoc, query, where, getDocs} from "firebase/firestore";
import getAllConsumibles from "./getAllConsumibles";
const db = getFirestore(firebaseApp);

export default async function eliminarManifiesto_tarima(producto, Lugar_cajaDB, cantidad_actual) {
  console.log(producto)

//Con esto elimino la tarima--------------- --------------------------------------- ---------------------------------------  
const coleccionRef = collection(db, "Manifiestos/Tarimas/"+producto.manifiesto+"/".replace(/ /g, ""));
const docuRef = doc(coleccionRef, producto.fecha);
var eliminado = await deleteDoc(docuRef);
//--------------------------------------- --------------------------------------- --------------------------------------- 


//Todo esto es para eliminar la tarima junto y desocupar los lugares que tenia----------------------------------------------- 
  var lugar_caja_vacio = Lugar_cajaDB.lugar_caja_vacio;
(producto.checkbox_seleccionados).map((valor)=>{
  const str = valor.toString();
  var nuevaStr = str.replace(",", "");
  console.log(nuevaStr);
  var auxiliar_100 = false;

  lugar_caja_vacio.map((dato, numero)=>{
    //console.log(numero, nuevaStr)
      if(numero == nuevaStr){
        //console.log(dato, numero)
        //console.log(dato)
        //console.log(dato.ocupado)
        //console.log("HOla")
        auxiliar_100 = true;
        lugar_caja_vacio[nuevaStr].ocupado = !dato.ocupado;
        lugar_caja_vacio[nuevaStr].nombre_producto = "";
      }
  })   
})
  //console.log(lugar_caja_vacio)
  //console.log("producto_nombre")
  const collectionRef3 = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+producto.manifiesto+"/".replace(/ /g, ""));
  const docRef3 = doc(collectionRef3, (producto.manifiesto).toString());
  updateDoc(docRef3, {lugar_caja_vacio});
//-----------------------------------------------------------------------------------------------------------------
//Esto es para sumar las cajas que se retiraron en el almacen
console.log(cantidad_actual, producto.cantidad_retirada )
var auxiliar111 = producto.cantidad_retirada * producto.cajas_por_pallet;
var cantidad_actualizada_almacen = parseInt(cantidad_actual) + parseInt(auxiliar111)
const collectionRef6 = collection(db, "Almacen_empaque/Producto terminado/Productos/")
const docuRef6 = doc(collectionRef6, producto.tarima_seleccionada);
await updateDoc(docuRef6,{
    cantidad: cantidad_actualizada_almacen
});

//-----------------------------------------------------------------------------------------------------------------
//Esto es para traer el log con toda la informacion necesaria para restar las tarimas y esquineros y asi eliminar su log
const OrdenNuevaDB =  [];
const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS/");
const q =  query(collectionRef, where("fecha2", "==", producto.fecha));
const querySnapshot = await getDocs(q);
querySnapshot.forEach((doc) => {
    OrdenNuevaDB.push(doc.data());
  });
console.log(OrdenNuevaDB)
//Esto es para sumar las tarimas y esquineros que se retiraron en el almacen
await getAllConsumibles().then((ConsumiblesDB) => {
  console.log(ConsumiblesDB);
      ConsumiblesDB.map((dato)=>{
        //Con esto elimino el log de la tarima seleccionada y sumo lo que se elimino en el almacen
        if(dato.nombre == OrdenNuevaDB[0].Tarima_retirada)
          {
            console.log(OrdenNuevaDB[0].fecha_para_eliminar_tarima)
            //Elimino
            const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef2 = doc(coleccionRef2, OrdenNuevaDB[0].fecha_para_eliminar_tarima);
            eliminado = deleteDoc(docuRef2);

            //Sumo en almacen
            var Tarimas_retiradas = (OrdenNuevaDB[0].Cantidad_retirada) / (OrdenNuevaDB[0].Cajas_por_pallet);
            console.log(OrdenNuevaDB[0].Cantidad_retirada, OrdenNuevaDB[0].Cajas_por_pallet, dato.cantidad, Tarimas_retiradas)
            var cantidad_actualizada_almacen = parseInt(dato.cantidad) + parseInt(Tarimas_retiradas)
            const collectionRef6 = collection(db, "Almacen_empaque/Consumibles/Productos/")
            const docuRef6 = doc(collectionRef6, OrdenNuevaDB[0].Tarima_retirada);
             updateDoc(docuRef6,{
                cantidad: cantidad_actualizada_almacen
            });
            console.log("Hola");
          }



      //Con esto elimino el log del esquinero seleccionado y sumo lo que se elimino en el almacen 
        if(dato.nombre == OrdenNuevaDB[0].Esquinero_retirado)
          {
            //Elimino
            const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef2 = doc(coleccionRef2, OrdenNuevaDB[0].fecha_para_eliminar_esquinero);
            eliminado =  deleteDoc(docuRef2);

            //Sumo en almacen
            console.log("Hola")
            var auxiliar10 = OrdenNuevaDB[0].Cantidad_retirada / OrdenNuevaDB[0].Cajas_por_pallet;
            //Aqui se multiplica por 4 la cantidad de tarimas ya que se ocupan 4 esquineros por tarima
            var Esquineros_retirados = auxiliar10 * 4;
            var cantidad_actualizada_almacen = parseInt(dato.cantidad) + parseInt(Esquineros_retirados)
            const collectionRef6 = collection(db, "Almacen_empaque/Consumibles/Productos/")
            const docuRef6 = doc(collectionRef6, OrdenNuevaDB[0].Esquinero_retirado);
             updateDoc(docuRef6,{
                cantidad: cantidad_actualizada_almacen
            });
          }  
      })   
 });


//Con este elimino del log de almacen de empaque--------------------------------- --------------------------------------- 
const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
const docuRef2 = doc(coleccionRef2, producto.fecha);
eliminado = await deleteDoc(docuRef2);

  return eliminado;
}
