import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs} from "firebase/firestore";
const db = getFirestore(firebaseApp);

    

export default async function getProductos_afectaciones(Nombre_de_boton) {
    const AfectacionesDB =  [];
    console.log(Nombre_de_boton.nombre_afectacion_fiscal);
    const collectionRef = collection(db, "Bancos/Afectaciones_fiscales_productos/"+Nombre_de_boton.nombre_afectacion_fiscal+"/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      AfectacionesDB.push(doc.data());
    });
    return AfectacionesDB;
}