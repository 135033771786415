import React from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import getAllcodigos_QR from "../functions/codigos_QR/getAllcodigos_QR";
import eliminarCodigo_QR from "../functions/codigos_QR/eliminarCodigo_QR";
import Swal from "sweetalert2";
//modales
import ModalAñadir_QR from "../components/Codigos_QR/ModalAñadir_QR";
import ModalEditar_QR from "../components/Codigos_QR/ModalEditar_QR";
import ModalImprimir_QR from "../components/Codigos_QR/ModalImprimir_QR";

function Home_Codigos_QR({ usuario,pagina,start  }) {

 //Eliminar con sweetalert2
 function eliminar_sweetalert(producto) {
  Swal.fire({
    title: '¿Esta seguro de eliminar la etiqueta?  "'+ producto.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'La etiqueta ha sido eliminada.',
        'success'
      )
      eliminarCodigo_QR(producto).then(
          (confirmacion) => {
            actualizardatos();
          }
        );
  
    }
  })
}


  const [isModalAñadir, setisModalAñadir] = React.useState(false);
  const [isModalEditar, setisModalEditar] = React.useState(false);
  const [imprimir_QR, setimprimir_QR] = React.useState(false);
  const [ProductoEditar, setProductoEditar] = React.useState([]);
  const [productos, setproductos] = React.useState([]);
  
async function actualizardatos(){
  await getAllcodigos_QR().then((Codigos_QR_DB) => {
    setproductos(Codigos_QR_DB);
    console.log(Codigos_QR_DB);
  });
} 


  React.useEffect(() => {
    actualizardatos();
  }, []);
  

  return(
    <div>
      <Navbartrabajo/>
      <Stack id="Navbar_trabajo_home" direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20  }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname}</p>
        <Button onClick={() => {setisModalAñadir(true)}}>Añadir etiqueta</Button>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
        <hr/>


      {isModalAñadir && (
      <ModalAñadir_QR
        isModalAñadir={isModalAñadir}
        setisModalAñadir={setisModalAñadir}
        usuario={usuario}
        pagina={pagina}
        start={start}
        actualizardatos={actualizardatos}
      />
      )}

      {isModalEditar && (
        <ModalEditar_QR
          isModalEditar={isModalEditar}
          setisModalEditar={setisModalEditar}
          ProductoEditar={ProductoEditar}
          setProductoEditar={setProductoEditar}
          actualizardatos={actualizardatos}
          usuario={usuario}
          pagina={pagina}
          start={start}
        />
      )}
     {imprimir_QR && (
        <ModalImprimir_QR
          imprimir_QR={imprimir_QR}
          setimprimir_QR={setimprimir_QR}
          ProductoEditar={ProductoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          pagina={pagina}
          start={start}
        />
      )}


      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre de etiqueta</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                <td>
                 <Button
                    variant="success"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setimprimir_QR(true);
                    }}
                  >
                    Imprimir
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setisModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, usuario.email)
                    }}
                  >
                    Eliminar
                  </Button>

                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
    
  )
}

export default Home_Codigos_QR;