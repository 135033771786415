import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc, updateDoc, query, where, getDocs} from "firebase/firestore";
import getAllCosecha from "./getAllCosecha";
import getAllCarton from "./getAllCarton";
import getAllProducto_terminado from "./getAllProducto_terminado";
const db = getFirestore(firebaseApp);

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

export default async function eliminarAlmacen_empaque_carton_KGCULTIVO(producto) {
  console.log(producto)

  
//Eliminar de produccion diaria
const coleccionRef10 = collection(db, "Produccion/Produccion_diario/Informe_diario/".replace(/ /g, ""));
const docuRef10 = doc(coleccionRef10,producto.fecha2);
var eliminado = deleteDoc(docuRef10);

//EliminAR de las lineas de produccion
const coleccionRef2 = collection(db, "Produccion/Lineas_produccion/"+producto.present.replace(/ /g, ""));
const docuRef2 = doc(coleccionRef2,producto.fecha2);
eliminado = deleteDoc(docuRef2);


//-----------------------------------------------------------------------------------------------------------------
//Esto es para sumar los KG de cosecha en el almacen y eliminar el log
await getAllCosecha().then((CosechaDB) => {
  console.log(CosechaDB);
      CosechaDB.map((dato)=>{
        //Con esto elimino el log de la tarima seleccionada y sumo lo que se elimino en el almacen
        if(dato.nombre == producto.Cosecha_seleccionada_retirada)
          {
            //Elimino
            const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef2 = doc(coleccionRef2,producto.fecha_eliminar_cosecha);
            eliminado = deleteDoc(docuRef2);

            //Sumo en almacen
            var Total_kilos_restantes = formatter.format(+dato.cantidad + +producto.Total_KG_retirados)
            var n_str = Total_kilos_restantes.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const n_nuevaStr = n_str.replace("$", "");
            const n_nuevaStr2 = n_nuevaStr.replace(",", "");
            const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4_kilos = n_nuevaStr3.replace(",", "");
            console.log(producto.Total_KG_retirados, dato.cantidad, n_nuevaStr4_kilos)
            //var cantidad_actualizada_almacen = parseInt(dato.cantidad) + parseInt(Total_KG_retirados)
            const collectionRef6 = collection(db, "Almacen_empaque/Cosecha/Productos/")
            const docuRef6 = doc(collectionRef6,producto.Cosecha_seleccionada_retirada);
             updateDoc(docuRef6,{
                cantidad: n_nuevaStr4_kilos
            });
            console.log("Hola");
          }  
      })   
 });

 //Esto es para sumar el carton en el almacen y eliminar el log
await getAllCarton().then((CartonDB) => {
  console.log(CartonDB);
     CartonDB.map((dato)=>{
        //Con esto elimino el log de la tarima seleccionada y sumo lo que se elimino en el almacen
        if(dato.nombre == producto.carton_seleccionado_retirado)
          {
            //Elimino
            const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef2 = doc(coleccionRef2,producto.fecha_eliminar_carton);
            eliminado = deleteDoc(docuRef2);

            //Sumo en almacen
            var Total_cartones_restantes = formatter.format(+dato.cantidad + +producto.Total_Cartones_retirados)
            var n_str = Total_cartones_restantes.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const n_nuevaStr = n_str.replace("$", "");
            const n_nuevaStr2 = n_nuevaStr.replace(",", "");
            const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4_carton = n_nuevaStr3.replace(",", "");
            console.log(producto.Total_Cartones_retirados, dato.cantidad, n_nuevaStr4_carton)
            //var cantidad_actualizada_almacen = parseInt(dato.cantidad) + parseInt(Total_KG_retirados)
            const collectionRef6 = collection(db, "Almacen_empaque/Carton/Productos/")
            const docuRef6 = doc(collectionRef6,producto.carton_seleccionado_retirado);
            updateDoc(docuRef6,{
                cantidad: n_nuevaStr4_carton
            });
            console.log("Hola");
          }  
      })    
 });

  //Esto es para restar las cajas en el almacen y eliminar el log
await getAllProducto_terminado().then((Producto_terminadoDB) => {
  console.log(Producto_terminadoDB);
     Producto_terminadoDB.map((dato)=>{
        //Con esto elimino el log de la tarima seleccionada y sumo lo que se elimino en el almacen
        if(dato.nombre == producto.nombre)
          {
            //Elimino
            const coleccionRef2 = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef2 = doc(coleccionRef2,producto.fecha_eliminar_caja);
            eliminado = deleteDoc(docuRef2);

            //Sumo en almacen
            var Total_cajas_restantes = formatter.format(dato.cantidad - producto.cantidad)
            var n_str = Total_cajas_restantes.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const n_nuevaStr = n_str.replace("$", "");
            const n_nuevaStr2 = n_nuevaStr.replace(",", "");
            const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4_cajas = n_nuevaStr3.replace(",", "");
            console.log(producto.cantidad,dato.cantidad, n_nuevaStr4_cajas)
            //var cantidad_actualizada_almacen = parseInt(dato.cantidad) + parseInt(Total_KG_retirados)
            const collectionRef6 = collection(db, "Almacen_empaque/Producto"+" "+"terminado/Productos/")
            const docuRef6 = doc(collectionRef6,producto.nombre);
            updateDoc(docuRef6,{
                cantidad: n_nuevaStr4_cajas
            });
            console.log("Hola");
          }  
      })    
 });

  return eliminado;
}
