import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarProductoGastos(producto,Nombre_de_boton) {
  console.log(Nombre_de_boton.valor);
  console.log(producto.nombre);
  const coleccionRef = collection(db, "Gastos_productos_tablas/"+Nombre_de_boton.valor+"/Productos".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.nombre.toString());
  const eliminado = await deleteDoc(docuRef);

  //escribirLog("Eliminación", producto, usuario);

  return eliminado;
}
