import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

async function editarCantidad_cosecha({ 
  n_nuevaStr4_kilos,
  cosecha_seleccionada,
   }) {

  console.log(cosecha_seleccionada)
  console.log(n_nuevaStr4_kilos)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Almacen_empaque/Cosecha/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, (cosecha_seleccionada).toString());
  await updateDoc(docRef, {
    cantidad: n_nuevaStr4_kilos
  })
}

export default editarCantidad_cosecha;
