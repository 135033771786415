import React from "react";
import { Container, Stack, Table, Button} from "react-bootstrap";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import getbotones_Bancos from "../functions/Bancos/getbotones_Bancos";
import Swal from "sweetalert2";
//Tablas
import getProductos_tablas from "../functions/Bancos/getProductos_tablas";
import ModalAñadir_tablas from "../components/Bancos/ModalAñadir_tablas";
import ModalEliminar_tablas from "../components/Bancos/ModalEliminar_tablas";
import eliminarProductoBancos from "../functions/Bancos/eliminarProductoBancos";
//Modales
import ModalAñadir from "../components/Bancos/ModalAñadir";
import ModalEliminar from "../components/Bancos/ModalEliminar";
import ModalTotal_concepto from "../components/Gastos/ModalTotal_concepto";
import ModalAñadir_fiscales from "../components/Bancos/ModalAñadir_fiscales";
import Modal_Afectaciones_concentrado from "../components/Bancos/Modal_Afectaciones_concentrado";
import ModalEliminar_afectacion from "../components/Bancos/ModalEliminar_afectacion";
import ModalEditar_tablas from "../components/Bancos/ModalEditar_tablas";
import ModalEditar_proveedor from "../components/Bancos/ModalEditar_proveedor";

function Home_Bancos({ usuario }) {
  
//Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

 //Eliminar con sweetalert2
 function eliminar_sweetalert(producto,Nombre_de_boton) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el concepto?  "'+ producto.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El concepto ha sido eliminado.',
        'success'
      )
      eliminarProductoBancos(producto,Nombre_de_boton).then(
        (confirmacion) => {
          actualizar_productos_tablas(Nombre_de_boton);
        }
      );
    }
  })
}

//Botones----------------------------------------------------------
const [ModalAñadir_boton, setModalAñadir_boton] = React.useState(false);
const [ModalAñadir_boton_fiscales, setModalAñadir_boton_fiscales] = React.useState(false);
const [Modal_eliminar, setModal_eliminar] = React.useState(false);
const [Modal_Concentrado, setModal_Concentrado] = React.useState(false);
const [Botonesdata, setBotonesdata] = React.useState([]);


async function botones(){
  await getbotones_Bancos().then((BotonesDB) => {
      console.log(BotonesDB) 
      setBotonesdata(BotonesDB);
 });
}

async function eliminar(){
 setModal_eliminar(true);
}

//Tablas de los botones
const [Tabla_botones_On, setTabla_botones_On] = React.useState(false);
const [Modal_añadir_producto, setModal_añadir_producto] = React.useState(false);
const [Modal_editar_producto, setModal_editar_producto] = React.useState(false);
const [Modal_eliminar_producto, setModal_eliminar_producto] = React.useState(false);
const [Tablasdata, setTablasdata] = React.useState([]);
const [Nombre_de_boton, setNombre_de_boton] = React.useState([]);
const [Eliminar_afectacion, setEliminar_afectacion] = React.useState(false);
const [Concepto_totales, setConcepto_totales] = React.useState(false);
const [Total, setTotal] = React.useState(0);

async function actualizar_productos_tablas(datos){
  var Nombre_de_boton = datos;
  await getProductos_tablas(Nombre_de_boton).then((TablasDB) => {
    //console.log(TablasDB);
     //Ordena de menor a mayor por fecha
     TablasDB.sort((a,b)=> (a.fecha2) - (b.fecha2));
     var auxiliar = 0;
     TablasDB.map((datos)=>{
          const str = auxiliar.toString();
          //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          var nuevaStr4 = nuevaStr3.replace(",", "");
          //console.log(nuevaStr4)
      if(nuevaStr4 != 0){
        if(datos.entrada_salida=="Entrada"){
          auxiliar = formatter.format(+nuevaStr4 + +datos.cantidad_total);
          datos['Saldo_actual'] = auxiliar;
          //console.log("entrada")
        }

        if(datos.entrada_salida=="Salida"){
          auxiliar = formatter.format(nuevaStr4 - datos.cantidad_total);
          datos['Saldo_actual'] = auxiliar;
          //console.log("salida")
        }
      }
      
       //Con este asigno el saldo iniciar y lo guardo en la variable auxiliar
       if(datos.producto=="Saldo inicial"){
        auxiliar = formatter.format(datos.cantidad_total);
        datos['Saldo_actual'] = auxiliar;
        //console.log("HOla")
      }
     })

    //Ordena de mayor a menor por fecha
    TablasDB.sort((a,b)=> (b.fecha2) - (a.fecha2));
     //console.log(TablasDB);
      setTablasdata(TablasDB);
      //actualizar_datos_gastos(TablasDB);
 });
}

function Seleccionar_gastos(datos){
  actualizar_productos_tablas(datos);
  //traer_fiscales();
  setNombre_de_boton(datos);
  setTabla_botones_On(true);
}

const [Producto_a_editar, setProducto_a_editar] = React.useState([]);

function Editar_producto(producto,Nombre_de_boton){
  setProducto_a_editar(producto);
 /*  setModal_editar_producto(true); */
 setModal_añadir_producto(true)
}

async function actualizar_datos_gastos(TablasDB){
  var total_acumulado=0;
  console.log(TablasDB)
  TablasDB.map((dato)=>{
    //Con esto convierto el saldo a valor flotante ya que tiene un $
    const str = total_acumulado.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const nuevaStr = str.replace("$", "");
    const nuevaStr2 = nuevaStr.replace(",", "");
    const nuevaStr3 = nuevaStr2.replace(",", "");
    var nuevaStr4 = nuevaStr3.replace(",", "");
      if(dato.entrada_salida=="Entrada"){
        total_acumulado = formatter.format(+nuevaStr4 + +dato.entrada);
        console.log(nuevaStr4, dato.entrada)
      }
      if(dato.entrada_salida=="Salida"){
        total_acumulado = formatter.format(nuevaStr4 - dato.salida);
        console.log(nuevaStr4, dato.salida)
      }
  })
  //Se ocupa este timeout para que muestre el total
  await timeout(500);
  setTotal(total_acumulado);
  console.log(total_acumulado)

  //Guardar_total_gastos(total_acumulado,Nombre_de_boton)
}
//-----------------------------------------------------------------
//--------Buscar producto------------------------------------------------------------
//Busqueda producto por nombre del producto seleccionado
const [BusquedaON, setBusquedaON] = React.useState(false);
const [Busqueda_datos, setBusqueda_datos] = React.useState([]);
const [Producto_filtrados, setProducto_filtrados] = React.useState([]);
const [Total_Producto_filtrados, setTotal_Producto_filtrados] = React.useState([]);

function filtrar_productos(){
//El primer if evita que se repita el filtrado al elegir un producto
if(Busqueda_datos.length==0){
      var auxiliar_100 = []; 
      Tablasdata.map((valor)=>{
        var auxiliar_102 = valor.Proveedor_elegido;
        var auxiliar_103= false;

                  if(auxiliar_100.length==0){
                    var auxiliar_101 = valor.Proveedor_elegido;
                    console.log(valor.Proveedor_elegido);
                    auxiliar_100.push(auxiliar_101);
                  }

                  if(auxiliar_100.length>0){
                        auxiliar_100.map((dato)=>{
                          if(valor.Proveedor_elegido == dato){
                            auxiliar_103 = true;
                          }
                        })
                  }

            if(auxiliar_103==false){
              auxiliar_100.push(auxiliar_102);
            }

      })
      console.log(auxiliar_100)
      setBusqueda_datos(auxiliar_100); 
}
 
}


function aceptar_busqueda(){
  const nombre_afectacion_fiscal = document.getElementById("productos_lista").value;  
  var datos_filtrados = [];  
  //console.log(Tablasdata)
    Tablasdata.map((valor)=>{
      console.log(valor.Proveedor_elegido, nombre_afectacion_fiscal)
      if(valor.Proveedor_elegido == nombre_afectacion_fiscal){
        datos_filtrados.push(valor);
      }
    })
  //console.log(datos_filtrados);
  var auxiliar_suma_total = 0;  
    datos_filtrados.map((dato)=>{
      const costoygastos_str = auxiliar_suma_total.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
      const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
      const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
      var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");
  
      auxiliar_suma_total = formatter.format(+costoygastos_nuevaStr4 + +dato.cantidad_total);
    })
  
  setTotal_Producto_filtrados(auxiliar_suma_total);
  //Filtrar por fecha
  //Logs.sort((a,b)=>  (b.fecha3) - (a.fecha3));
  setProducto_filtrados(datos_filtrados);
  setBusquedaON(true);
}

function reset_busqueda(){
  setBusquedaON(false);
  setBusqueda_datos([]);
  setTotal_Producto_filtrados(0);
  //Filtrar por fecha
  Tablasdata.sort((a,b)=>  (b.fecha3) - (a.fecha3));
  setTablasdata(Tablasdata);
}

function regresar_concentrado_principal(){
  setBusqueda_datos([]);
  setTotal_Producto_filtrados(0);
  //Pagina_principal()
}
//--------------------------------------------------------------------------

const [Agregar_proveedor, setAgregar_proveedor] = React.useState(false);

function Agregar_proveedor_ok(producto, Nombre_de_boton){
  setProducto_a_editar(producto);
  setNombre_de_boton(Nombre_de_boton);
  setAgregar_proveedor(true);
}

  React.useEffect(() => {
    botones();
  }, []); 

  return (
  <div>
    {Tabla_botones_On?
    <div>
      <Navbartrabajo/>
      <Container fluid id="Navbar_trabajo_home">
          <br/>
          <Stack direction="horizontal" className="justify-content-between">
            <p style={{ fontSize: 20 }}>Actualmente estas en /Banco "{Nombre_de_boton.nombre_banco}"</p>
            <Button style={{ marginRight: "0%" }} onClick={() => {setTabla_botones_On(false)}}>Regresar</Button>
            {/* <Button id="" onClick={()=>{setModal_añadir_producto(true)}}> Agregar movimiento</Button> */}
            <Button id="" onClick={()=>{setModal_editar_producto(true)}}> Agregar movimiento</Button>
          </Stack>
          <hr/>
      {/* Con este busco por selección */}
      <Stack direction="horizontal" className="justify-content-between">
           <>
            <select 
            id="productos_lista"
            style={{ width: "35%" , marginLeft: "10%" }}
            type="text"
            onClick={() => {
              filtrar_productos()
                    }} 
             >
              <option>Seleccionar</option>
            {Busqueda_datos.map((datos,index)=>( 
                <option key={index}>{datos}</option>
            ))}
            </select>
            <Button style={{ fontSize: 16, marginRight: "2%", marginLeft: "1%" }} onClick={()=>{aceptar_busqueda()}}> Buscar</Button>
            <Button variant= "secondary" style={{ fontSize: 16, marginRight: "1%", marginLeft: "1%" }} onClick={()=>{reset_busqueda()}}> Reset</Button>
            <p style={{ fontSize: 14, marginRight: "5%", marginLeft: "0%" }}> Total de salida del proveedor {(Total_Producto_filtrados)} MXN</p>
            </> 
          </Stack>
          <hr />
          {/* <Stack direction="horizontal" className="justify-content-between">
            <p style={{ fontSize: 14 }}>El total de dinero en el banco actualmente es {Total}</p>
          </Stack>
          <hr /> */}
              {Modal_añadir_producto && (
              <ModalAñadir_tablas
                Modal_añadir_producto={Modal_añadir_producto}
                setModal_añadir_producto={setModal_añadir_producto}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario2={usuario}
                Nombre_de_boton={Nombre_de_boton}
                setTablasdata={setTablasdata}
                setTotal={setTotal}
                className="modal-dialog modal-fullscreen-sm-down"
                Producto_a_editar={Producto_a_editar}
              />)}

          {Modal_editar_producto && (
              <ModalEditar_tablas
                Modal_editar_producto={Modal_editar_producto}
                setModal_editar_producto={setModal_editar_producto}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario2={usuario}
                Nombre_de_boton={Nombre_de_boton}
                setTablasdata={setTablasdata}
                setTotal={setTotal}
                className="modal-dialog modal-fullscreen-sm-down"
              />)}
               
            {Modal_eliminar_producto && (
            <ModalEliminar_tablas
                Modal_eliminar_producto={Modal_eliminar_producto}
                setModal_eliminar_producto={setModal_eliminar_producto}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario={usuario}
                /* Botones_campo={Botones_campo} */
                className="modal-dialog modal-fullscreen-sm-down"
             />)}

             {Concepto_totales && (
            <ModalTotal_concepto
                Concepto_totales={Concepto_totales}
                setConcepto_totales={setConcepto_totales}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario2={usuario}
                Nombre_de_boton={Nombre_de_boton}
                setTablasdata={setTablasdata}
                className="modal-dialog modal-fullscreen-sm-down"
             />)}

          {Agregar_proveedor && (
            <ModalEditar_proveedor
                Agregar_proveedor={Agregar_proveedor}
                setAgregar_proveedor={setAgregar_proveedor}
                Producto_a_editar={Producto_a_editar}
                Nombre_de_boton={Nombre_de_boton}
                className="modal-dialog modal-fullscreen-sm-down"
             />)}

          <Table style={{ fontSize: "90%" }}>
            <thead>
             <tr >
             <th>Fecha</th>
             <th>Proveedor</th>
             <th>Concepto</th>
             <th>Entrada</th>
             <th>Salida</th>
             {/* <th>Saldo</th> */}
             <th>Acciones</th>   
                </tr>
            </thead>
            <tbody>
            {BusquedaON? 
             /* Con este muestra los productos filtrados */ 
             <>
            {Producto_filtrados &&
                Producto_filtrados.map((producto, index) => ( 
                  <tr key={index}>
                    <td>{producto.fecha}</td>
                    <td>{producto.Proveedor_elegido}</td>
                    <td>{producto.producto}</td>
                    <td>{formatter.format(producto.entrada)}</td>
                    <td>{formatter.format(producto.salida)}</td>
                    {/* <td>{producto.Saldo_actual}</td> */}
                    <td>
                     {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                     {producto.afectacion_si_no? 
                       <>
                       <Button
                       variant="dark"
                       onClick={() => {
                         Editar_producto(producto,Nombre_de_boton);
                       }}
                     >
                       Editar
                     </Button>
                       
                       <Button
                           variant="danger"
                           onClick={() => {
                             eliminar_sweetalert(producto,Nombre_de_boton);
                           }}
                         >
                           Eliminar
                       </Button>

                       <Button
                           variant="success"
                           onClick={() => {
                            Agregar_proveedor_ok(producto, Nombre_de_boton)
                           }}
                         >
                           Agregar proveedor
                       </Button>

                       </> 
                      :  
                      <>  
                      <Button
                        variant="danger"
                        onClick={() => {
                            eliminar_sweetalert(producto,Nombre_de_boton);
                          }}
                        >
                          Eliminar
                      </Button>
                      <Button
                      variant="success"
                      onClick={() => {
                        Agregar_proveedor_ok(producto, Nombre_de_boton)
                      }}
                    >
                      Agregar proveedor
                  </Button>
                    </> 
                      }
                     </td>
                  </tr>  
              ))}
             </>
             :
             <>
               {Tablasdata &&
                Tablasdata.map((producto, index) => ( 
                  <tr key={index}>
                    <td>{producto.fecha}</td>
                    <td>{producto.Proveedor_elegido}</td>
                    <td>{producto.producto}</td>
                    <td>{formatter.format(producto.entrada)}</td>
                    <td>{formatter.format(producto.salida)}</td>
                    <td>{producto.Saldo_actual}</td>
                    <td>
                     {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                     {producto.afectacion_si_no? 
                       <>
                       <Button
                       variant="dark"
                       onClick={() => {
                         Editar_producto(producto,Nombre_de_boton);
                       }}
                     >
                       Editar
                     </Button>
                       
                       <Button
                           variant="danger"
                           onClick={() => {
                             eliminar_sweetalert(producto,Nombre_de_boton);
                           }}
                         >
                           Eliminar
                       </Button>

                       <Button
                           variant="success"
                           onClick={() => {
                            Agregar_proveedor_ok(producto, Nombre_de_boton)
                           }}
                         >
                           Agregar proveedor
                       </Button>

                       </> 
                      :  
                      <>  
                      <Button
                        variant="danger"
                        onClick={() => {
                            eliminar_sweetalert(producto,Nombre_de_boton);
                          }}
                        >
                          Eliminar
                      </Button>
                      <Button
                      variant="success"
                      onClick={() => {
                        Agregar_proveedor_ok(producto, Nombre_de_boton)
                      }}
                    >
                      Agregar proveedor
                  </Button>
                    </> 
                      }
                     </td>
                  </tr>  
              ))}
             </>
            }
            </tbody>

          </Table>
        </Container> 
    </div>  
    :
    <div>
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 16 }}>Bienvenido {usuario.email}, Actualmente estas en /Bancos </p>
        <Button id="" onClick={()=>{setModalAñadir_boton(true);}}> Agregar banco</Button>
        <Button id="" onClick={()=>{setModalAñadir_boton_fiscales(true);}}> Agregar afectación fiscal</Button>
        <Button id="" onClick={()=>{setEliminar_afectacion(true);}}> Eliminar afectación fiscal</Button>
        <Button id="" onClick={()=>{eliminar()}}> Eliminar banco</Button>
        <Button id="" onClick={()=>{setModal_Concentrado(true)}}>Afectaciones concentrado</Button>
                         
      </Stack>
{/* BOTONES */}
    <div> 
        {ModalAñadir_boton && (
        <ModalAñadir
          ModalAñadir_boton={ModalAñadir_boton}
          setModalAñadir_boton={setModalAñadir_boton}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

      {ModalAñadir_boton_fiscales && (
        <ModalAñadir_fiscales
          ModalAñadir_boton_fiscales={ModalAñadir_boton_fiscales}
          setModalAñadir_boton_fiscales={setModalAñadir_boton_fiscales}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

      {Eliminar_afectacion && (
        <ModalEliminar_afectacion
          Eliminar_afectacion={Eliminar_afectacion}
          setEliminar_afectacion={setEliminar_afectacion}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          /* Botones_campo={Botones_campo} */
          className="modal-dialog modal-fullscreen-sm-down"
        />)}

        {Modal_eliminar && (
        <ModalEliminar
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          /* Botones_campo={Botones_campo} */
          className="modal-dialog modal-fullscreen-sm-down"
        />)}

        {Modal_Concentrado && (
        <Modal_Afectaciones_concentrado
          Modal_Concentrado={Modal_Concentrado}
          setModal_Concentrado={setModal_Concentrado}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}
            <div id="Lista_empaque" style={{ background: "white" }}>        
                {Botonesdata.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" onClick={() => Seleccionar_gastos((datos))} key={index}>{datos.nombre_banco}</button>
                ))}
            </div>
      </div> 

    </Container>
    </div> 
    }
  </div>  
 
  );
}

export default Home_Bancos;
