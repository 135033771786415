import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAlldata_consultar(seleccionado) {
  console.log("traer");
  const dataDB = [];
  let collectionRef = collection(db, "/Nominas/"+seleccionado+"/Nombres/".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  
  snapshot.forEach((doc) => {
    dataDB.push(doc.data());
  });

  return dataDB;
}
