import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";
import getCategorias from '../Firebase/functions/Productos/getCategorias';
import ModalAñadir_cultivo_en_proceso from './Modales/ModalAñadir_cultivo_en_proceso';
import getCategorias_botones_cultivo_en_proceso from './funciones/getCategorias_botones_cultivo_en_proceso';
import ModalEliminar_cultivo_en_proceso from "./Modales/ModalEliminar_cultivo_en_proceso";

const Lista_cultivo_en_proceso = ({setvalor,Gastos_indi_produccion,Costos, setbotones}) =>{   
    const [Modal_categoria, setModal_categoria] = React.useState(false);
    const [Modal_eliminar, setModal_eliminar] = React.useState(false);
    const [Categoria, setCategoria] = React.useState([]);
    const [Botones_campo, setBotones_campo] = React.useState([]);
    
   async function Categorias(){
        await getCategorias().then((CategoriasDB) => {
            console.log(CategoriasDB) 
            setCategoria(CategoriasDB);
       });
       setModal_categoria(true);
     }

     async function eliminar(){
      setModal_eliminar(true);
    }

     async function botones(){
        await getCategorias_botones_cultivo_en_proceso().then((Categorias_botonesDB) => {
            console.log(Categorias_botonesDB) 
            setBotones_campo(Categorias_botonesDB);
       });
     }

    async function Seleccionar_almacen(prop){
       console.log(prop);
       setvalor(prop);
       console.log(Botones_campo);
       setbotones(Botones_campo);
     }
     
    
     React.useEffect(() => {
       botones();
      }, []);

    return(
      <div> 
        {Modal_categoria && (
        <ModalAñadir_cultivo_en_proceso
          Modal_categoria={Modal_categoria}
          setModal_categoria={setModal_categoria}
          Categoria={Categoria}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

        {Modal_eliminar && (
        <ModalEliminar_cultivo_en_proceso
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Categoria={Categoria}
          actualizar_botones={botones}
          Botones_campo={Botones_campo}
          className="modal-dialog modal-fullscreen-sm-down"
        />)}

      <div id="Botones_agregareliminar_categoria_cultivo_en_proceso">
         <Button id="Botones_01" onClick={()=>{Categorias()}}> Agregar categoría de cultivo</Button>
         <Button id="Botones_02" onClick={()=>{eliminar()}}> Eliminar categoría de cultivo</Button>
         {/* <Button id="Botones_03" onClick={() => Gastos_indi_produccion()}>Gastos indirectos de producción</Button> */}
         <Button id="Botones_03" onClick={() => Costos(Botones_campo)}>Costos</Button>
                     
      </div>
            <div id="Lista_cultivo_en_proceso">
                
                {Botones_campo.map((datos,index)=>( 
                    <button id="boton_lista_cultivo_en_proceso" onClick={() => Seleccionar_almacen((datos.nombre))} key={index}>{datos.nombre}</button>
                ))}
           
            </div>
      </div>    
    )
}


export default Lista_cultivo_en_proceso





//-----------------------------------------------------------------------------------------------------------------------

/* import React from 'react';
import '../../index.css'


const Lista_cultivo_en_proceso = (props) =>{   
    const {
         Casa_sombra_1,
         Casa_sombra_2,
         Casa_sombra_3,
         Casa_sombra_4,
         Casa_sombra_5,
         Casa_sombra_6,
         Casa_sombra_7,
         Casa_sombra_8,
         T_9_Fausta,
         T_10_Fausta,
         T_11_Fausta,
         T_12_Fausta,
         T_13_Fausta,
         T_14_Fausta,
         T_15_Fausta,
         T_16_Woller,
         T_17_Woller,
         T_18_Woller,
         T_19_Woller,
         T_20_Woller,
         T_21_Woller,
         T_22_Woller,
         T_1_Las_40,
         T_2_Las_40,
         T_3_Las_40,
         T_4_Las_40,
         T_5_Las_40,
         T_6_Las_70,
         T_7_Los_Pinos,
         T_8_Los_Pinos,
         T_9_Los_Pinos,
         T_10_Los_Pinos,
         T_11_Los_Pinos,
         T_12_Los_Pinos,
         T_1_Del_Eden,
         T_2_Del_Eden,
         T_3_Del_Eden,
         T_4_Del_Eden,
         T_5_Del_Eden,
         T_6_Del_Eden,
         T_7_Del_Eden,
         T_8_Del_Eden,
         Costos,
         Gastos_indi_produccion
        }=props;
    

    return(
       
                <div  id="Trabajo" >
                    <div>
                      <div>
                      <button style={{ fontSize: 20, padding: "5px", marginRight: "60%",}} onClick={() => Gastos_indi_produccion()}>Gastos indirectos de producción</button>
                      <button style={{ marginLeft: "5%", fontSize: 20, padding: "5px", marginTop: "5%" }} onClick={() => Costos()}>Costos</button>
                      </div>  
                    <h3 id="Titulo_secciones">Casa sombra</h3>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_1()}>1</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_2()}>2</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_3()}>3</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_4()}>4</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_5()}>5</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_6()}>6</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_7()}>7</button>
                    <button className="btn btn-secondary" onClick={() => Casa_sombra_8()}>8</button>
                    </div>
                    <br/>

                    <div>
                    <h3 id="Titulo_secciones">Fausta</h3>
                    <button className="btn btn-secondary" onClick={() => T_9_Fausta()}>T-9</button>
                    <button className="btn btn-secondary" onClick={() => T_10_Fausta()}>T-10</button>
                    <button className="btn btn-secondary" onClick={() => T_11_Fausta()}>T-11</button>
                    <button className="btn btn-secondary" onClick={() => T_12_Fausta()}>T-12</button>
                    <button className="btn btn-secondary" onClick={() => T_13_Fausta()}>T-13</button>
                    <button className="btn btn-secondary" onClick={() => T_14_Fausta()}>T-14</button>
                    <button className="btn btn-secondary" onClick={() => T_15_Fausta()}>T-15</button>
                    </div>
                    <br/>

                    <div>
                    <h3 id="Titulo_secciones">Woller</h3>
                    <button className="btn btn-secondary" onClick={() => T_16_Woller()}>T-16</button>
                    <button className="btn btn-secondary" onClick={() => T_17_Woller()}>T-17</button>
                    <button className="btn btn-secondary" onClick={() => T_18_Woller()}>T-18</button>
                    <button className="btn btn-secondary" onClick={() => T_19_Woller()}>T-19</button>
                    <button className="btn btn-secondary" onClick={() => T_20_Woller()}>T-20</button>
                    <button className="btn btn-secondary" onClick={() => T_21_Woller()}>T-21</button>
                    <button className="btn btn-secondary" onClick={() => T_22_Woller()}>T-22</button>
                    </div>
                    <br/>

                    <div>
                    <h3 id="Titulo_secciones">Las 40 y 70</h3>
                    <button className="btn btn-secondary" onClick={() => T_1_Las_40()}>T-1  </button>
                    <button className="btn btn-secondary" onClick={() => T_2_Las_40()}>T-2  </button>
                    <button className="btn btn-secondary" onClick={() => T_3_Las_40()}>T-3  </button>
                    <button className="btn btn-secondary" onClick={() => T_4_Las_40()}>T-4  </button>
                    <button className="btn btn-secondary" onClick={() => T_5_Las_40()}>T-5  </button>
                    <button className="btn btn-secondary" onClick={() => T_6_Las_70()}>T-6  </button>
                    </div>
                    <br/>

                    <div id="Titulo_secciones">
                    <h3>Los Pinos</h3>
                    <button className="btn btn-secondary" onClick={() => T_7_Los_Pinos()}>T-7  </button>
                    <button className="btn btn-secondary" onClick={() => T_8_Los_Pinos()}>T-8  </button>
                    <button className="btn btn-secondary" onClick={() => T_9_Los_Pinos()}>T-9  </button>
                    <button className="btn btn-secondary" onClick={() => T_10_Los_Pinos()}>T-10  </button>
                    <button className="btn btn-secondary" onClick={() => T_11_Los_Pinos()}>T-11  </button>
                    <button className="btn btn-secondary" onClick={() => T_12_Los_Pinos()}>T-12  </button>
                    </div>
                    <br/>

                    <div id="Titulo_secciones">
                    <h3>Del Eden</h3>
                    <button className="btn btn-secondary" onClick={() => T_1_Del_Eden()}>T-1  </button>
                    <button className="btn btn-secondary" onClick={() => T_2_Del_Eden()}>T-2  </button>
                    <button className="btn btn-secondary" onClick={() => T_3_Del_Eden()}>T-3  </button>
                    <button className="btn btn-secondary" onClick={() => T_4_Del_Eden()}>T-4  </button>
                    <button className="btn btn-secondary" onClick={() => T_5_Del_Eden()}>T-5  </button>
                    <button className="btn btn-secondary" onClick={() => T_6_Del_Eden()}>T-6  </button>
                    <button className="btn btn-secondary" onClick={() => T_7_Del_Eden()}>T-7  </button>
                    <button className="btn btn-secondary" onClick={() => T_8_Del_Eden()}>T-8  </button>
                    </div>
                    
                </div>    
    )
}


export default Lista_cultivo_en_proceso */