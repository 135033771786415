import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import añadirCategoria from "../../functions/Productos/añadirCategoria";
import eliminarCategoria from "../../functions/Productos/eliminarCategoria";

function ModalCategorias({
  CategoriasON,
  setCategoriasON,
  Categorias_productos,
  actualizarEstadoProductos
}) {

  //Modal para agregar categorias
  const [Agregar_categoriasON, setAgregar_categoriasON] = React.useState(false);

  function Agregar_cat(){
    setAgregar_categoriasON(true)
    setInicio_modal(false);
  }


  //console.log(Categorias_productos)
  function salir(){
    setCategoriasON(false)
  }

  function regresar_categorias(){
    setAgregar_categoriasON(false)
    setInicio_modal(true);
  }

  const [Inicio_modal, setInicio_modal] = React.useState(false);

  function Inicio(){
    setInicio_modal(true);
  }

  function añadir_categoria(){
    let valor_categoria = document.getElementById("nombre_categoria").value;
    añadirCategoria(valor_categoria);
    actualizarEstadoProductos();
    setAgregar_categoriasON(false)
    setInicio_modal(true);
  }
  
  React.useEffect(() => {
    Inicio();
  }, []);




  return (
   <div >
    <Modal id="Modal_Logs" show={Inicio_modal} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Categorias de los productos</Modal.Title>
        <Button  onClick={() => {
                      Agregar_cat();
                    }}>
          Agregar categoria
        </Button>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
        

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Categorias</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {
          Categorias_productos.map((producto, index) => (
              <tr key={index}>
                <td>{producto.categoria}</td>
                  <td>
                          {/* <Button
                            variant="dark"
                            onClick={() => {
                              salir();
                            }}
                          >
                            Editar
                          </Button> */}
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminarCategoria(producto);
                              actualizarEstadoProductos();
                            }}
                          >
                            Eliminar
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>

      </Modal.Body>
      <Modal.Footer>
      <Button  onClick={() => {
                      Agregar_cat();
                    }}>
          Agregar categoria
        </Button>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
    
    
    <Modal id="Modal_Logs" show={Agregar_categoriasON} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Añadir una categoria</Modal.Title>

      </Modal.Header>
      <Modal.Body> 
      <Form>
          <Stack>
            <Form.Control
              id="nombre_categoria"
              placeholder="Insertar el nombre de la nueva categoria"
              type="text"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button  onClick={() => {
                      añadir_categoria();
                    }}>
          Añadir
        </Button>
        <Button variant="secondary" onClick={() => {
                      regresar_categorias();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  );
}

export default ModalCategorias;
/* {Agregar_categoriasON && (
    <ModalCategorias
      Agregar_categoriasON={Agregar_categoriasON}
      setAgregar_categoriasON={setAgregar_categoriasON}
      Categorias_productos={Categorias_productos}
      actualizarEstadoProductos={actualizarEstadoProductos}
    />
  )} */