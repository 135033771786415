import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import "../../index.css"
import firebaseApp from '../Firebase/firebase/credenciales';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const Navbartrabajo = () =>{

  const [user, setUser] = useState(null);

  //Estos son los niveles de acceso
  const [Acceso_admin, setAcceso_admin] = useState(null);
  const [Acceso_Usuario_campo, setAcceso_Usuario_campo] = useState(null);
  const [Acceso_Usuario_empaque, setAcceso_Usuario_empaque] = useState(null);
  const [Acceso_Compras, setAcceso_Compras] = useState(null);
  const [Acceso_Contabilidad, setAcceso_Contabilidad] = useState(null);
  const [Acceso_RH, setAcceso_RH] = useState(null);

  async function getRol(uid) {
    //console.log(uid)
    const docuRef = doc(firestore, `Usuarios/${uid}`);
    const docuCifrada = await getDoc(docuRef);
    const infoFinal = docuCifrada.data().rol;
    return infoFinal;
  }

  function setUserWithFirebaseAndRol(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then((rol) => {
      const userData = {
        uid: usuarioFirebase.uid,
        email: usuarioFirebase.email,
        rol: rol,
      };
      setUser(userData);
      //console.log("userData final", userData);

      {if(userData.rol == "Administrador"){
        setAcceso_admin(true);
        setAcceso_Usuario_campo(false);
        setAcceso_Usuario_empaque(false);
        setAcceso_Compras(false);
        setAcceso_Contabilidad(false);
        setAcceso_RH(false);
      }}
      {if(userData.rol == "Usuario campo"){
        setAcceso_admin(false);
        setAcceso_Usuario_campo(true);
        setAcceso_Usuario_empaque(false);
        setAcceso_Compras(false);
        setAcceso_Contabilidad(false);
        setAcceso_RH(false);
      }}
      {if(userData.rol == "Usuario empaque"){
        setAcceso_admin(false);
        setAcceso_Usuario_campo(false);
        setAcceso_Usuario_empaque(true);
        setAcceso_Compras(false);
        setAcceso_Contabilidad(false);
        setAcceso_RH(false);
      }}
      {if(userData.rol == "Compras"){
        setAcceso_admin(false);
        setAcceso_Usuario_campo(false);
        setAcceso_Usuario_empaque(false);
        setAcceso_Compras(true);
        setAcceso_Contabilidad(false);
        setAcceso_RH(false);
      }}
      {if(userData.rol == "Contabilidad"){
        setAcceso_admin(false);
        setAcceso_Usuario_campo(false);
        setAcceso_Usuario_empaque(false);
        setAcceso_Compras(false);
        setAcceso_Contabilidad(true);
        setAcceso_RH(false);
      }}
      {if(userData.rol == "RH"){
        setAcceso_admin(false);
        setAcceso_Usuario_campo(false);
        setAcceso_Usuario_empaque(false);
        setAcceso_Compras(false);
        setAcceso_Contabilidad(false);
        setAcceso_RH(true);
      }}
    });
  }

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      //funcion final

      if (!user) {
        setUserWithFirebaseAndRol(usuarioFirebase);
      }
    } else {
      setUser(null);
    }
  });

    return(
      <div id="Navbar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light" >
          <div className="container-fluid" >
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
             {Acceso_admin?
               <ul className="navbar-nav me-auto mb-2 mb-lg-0">
               <li className="nav-item">
                 <Link className="nav-link active" to='/Inicio'>Inicio</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Ordenes'>Ordenes de compra</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Almacen_campo' >Almacen del campo</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Cultivo_en_proceso'>Cultivo en proceso</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Almacen_empaque' >Almacen del empaque</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Produccion'>Producción</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Productos'>Productos</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
               </li>
               {/* <li className="nav-item">
                 <Link className="nav-link active" to='/Iniciofin_ciclo'>Inicio o fin de ciclo</Link>
               </li> */}
               {/* <li className="nav-item">
                 <Link className="nav-link active" to='/Campo_secciones'>Secciones del campo</Link>
               </li> */}
               <li className="nav-item">
                 <Link className="nav-link active" to='/Usuarios'>Usuarios</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Nominas'>Nominas</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Gastos'>Concentrado</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Bancos'>Bancos</Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link active" to='/Codigos_QR'>Codigos_QR</Link>
               </li>
               

             </ul>
             : null}
          

            {Acceso_Usuario_campo?
             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
             <li className="nav-item">
               <Link className="nav-link active" to='/Inicio'>Inicio</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Almacen_campo' >Almacen del campo</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Cultivo_en_proceso'>Cultivo en proceso</Link>
             </li>
           {/*   <li className="nav-item">
               <Link className="nav-link active" to='/Almacen_empaque' >Almacen del empaque</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Produccion'>Producción</Link>
             </li> */}
             <li className="nav-item">
               <Link className="nav-link active" to='/Productos'>Productos</Link>
             </li>
            {/*  <li className="nav-item">
               <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
             </li> */}
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Iniciofin_ciclo'>Inicio o fin de ciclo</Link>
             </li> */}
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Campo_secciones'>Secciones del campo</Link>
             </li> */}

           </ul>: null}

           {Acceso_Usuario_empaque?
             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
             <li className="nav-item">
               <Link className="nav-link active" to='/Inicio'>Inicio</Link>
             </li>
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Almacen_campo' >Almacen del campo</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Cultivo_en_proceso'>Cultivo en proceso</Link>
             </li> */}
             <li className="nav-item">
               <Link className="nav-link active" to='/Almacen_empaque' >Almacen del empaque</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Produccion'>Producción</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Productos'>Productos</Link>
             </li>
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
             </li> */}
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Iniciofin_ciclo'>Inicio o fin de ciclo</Link>
             </li> */}
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Campo_secciones'>Secciones del campo</Link>
             </li> */}

           </ul>: null}
           
            {Acceso_Compras?
             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
             <li className="nav-item">
               <Link className="nav-link active" to='/Inicio'>Inicio</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Ordenes'>Ordenes de compra</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Productos'>Productos</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
             </li>
             {/* <li className="nav-item">
               <Link className="nav-link active" to='/Iniciofin_ciclo'>Inicio o fin de ciclo</Link>
             </li> */}             

           </ul>:null}

           {Acceso_Contabilidad?
             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
             <li className="nav-item">
               <Link className="nav-link active" to='/Inicio'>Inicio</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
             </li>      
             <li className="nav-item">
                 <Link className="nav-link active" to='/Bancos'>Bancos</Link>
               </li>
           </ul>:null}

           {Acceso_RH?
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to='/Inicio'>Inicio</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Ordenes'>Ordenes de compra</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Almacen_campo' >Almacen del campo</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Cultivo_en_proceso'>Cultivo en proceso</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Almacen_empaque' >Almacen del empaque</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Produccion'>Producción</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Productos'>Productos</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to='/Proveedores'>Proveedores</Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link active" to='/Iniciofin_ciclo'>Inicio o fin de ciclo</Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link active" to='/Campo_secciones'>Secciones del campo</Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link active" to='/Usuarios'>Usuarios</Link>
            </li>
            <li className="nav-item">
                 <Link className="nav-link active" to='/Seguro_social'>Seguro social</Link>
            </li>
            

          </ul>:null}


            </div>
          </div>
        </nav>
      </div>  
    );
}

export default Navbartrabajo;