import React, {useState, useEffect} from 'react';
import "../../../index.css"
import Navbar from '../../Navbars/Navbar';
import ClipLoader from "react-spinners/ClipLoader";



const Tomate= () =>{

    const [Loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true);
        setTimeout(()=>{
          setLoading(false);
        },300)
      }, [])

      


    return(
      <div>
        {Loading?
        <div id="Loading_pages">
        <ClipLoader 
        color={'#36D7B7'} 
        Loading={Loading}  
        size={150} />
        </div>
      :
        <div >
            <Navbar/>
            <div id="Padre_tomate">
                <img id="Tomate_img" className="img-fluid" src="/Imagenes/Tomate_campo.jpeg" width="100%" height="auto"></img>
                <div className="Hijo_tomate">
                   <div id="Hijo_tomate_texto">
                     <h2 >TOMATE ROMA</h2>
                     <br/>
                     <center id="Descripcion">Totalmente sembrado, cosechado y empaquetado por nosotros, por lo que 
                         se esta manera se asegura la calidad e inocuidad de todos nuestros productos.
                     </center>
                     <br/>
                     <br/>
                     <h3 id="Presentaciones">Presentaciones:</h3>
                     <br/>
                     <br/>
                     
                     <ul id="Presentaciones_tamaños">
                         <li> 1 Kg.</li>
                         <li> 5 Kg.</li>
                         <li>10 Kg.</li>
                     </ul>
                   </div>  
                </div>
            </div>
        </div>
        }
    </div>    
    )
}



export default Tomate;






        /* <div>
            <Grid container>
                <Grid item xs={12} id="Padre_tomate">
                   <Box>
                    <Navbar/>
                     <img id="Tomate_img" className="img-fluid" src="/Imagenes/Tomate_hombre.jpg" width="auto" height="auto"></img>
                    <Box border={2} className="Hijo_tomate">
                        <Box border={2}>
                            <h1 >TOMATE ROMA</h1>
                        </Box>
                        <br/>
                        <Box border={2}>
                            <center>Totalmente sembrado, cosechado y empaquetado por nosotros, por lo que 
                                se esta manera se asegura la calidad e inocuidad de todos nuestros productos.
                            </center>
                        </Box>
                        
                        <br/>
                        <Box border={2}>
                             <h3 id="Presentaciones">Presentaciones:</h3>
                        </Box>
                        
                        <br/>
                        <br/>
                        <Box border={2}>
                            <ul >
                                <li> 1 Kg.</li>
                                <li> 5 Kg.</li>
                                <li>10 Kg.</li>
                            </ul>
                        </Box>
  
                    </Box>
                </Box>   
                </Grid>
            </Grid>
        </div> */