import React from "react";
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home_Cultivo_en_proceso from '../views/Home_Cultivo_en_proceso'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD_Cultivo_en_proceso({botones, pagina, start, Pagina_principal}){
  
    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
    <div >
      {usuario ? <Home_Cultivo_en_proceso usuario={usuario} botones={botones} pagina={pagina} start={start} Pagina_principal={Pagina_principal}></Home_Cultivo_en_proceso>: <Login2 />}
      
    </div>
    );
}




export default CRUD_Cultivo_en_proceso;