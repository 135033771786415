import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarLogs_Finciclo(producto) {
  const coleccionRef = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.Titulo);
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
