import React, {useState} from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_asegurado from "../../functions/Seguro_social/añadir_movimiento_nominas";
import getAll_cuadrilleros from "../../functions/Seguro_social/getAll_cuadrilleros";
import Swal from "sweetalert2";

function ModalAñadir_cuadrillero({
  isModalAñadir,
  setIsModalAñadir,
}) {

  const [Cuadrillero_select, setCuadrillero_select] = React.useState(false);
  const [Trabajador_select, setTrabajador_select] = React.useState(false);
  const [Actividad_select, setActividad_select] = React.useState(false);
  const [Importe_select, setImporte_select] = React.useState(false);
  const [Cargo_select, setCargo_select] = React.useState(false);

  const [Cuadrilleros, setCuadrilleros] = React.useState();

  async function traer_cuadrilleros(){
      await getAll_cuadrilleros().then((CuadrillerosDB) => {
          console.log(CuadrillerosDB);
          setCuadrilleros(CuadrillerosDB);
      });   
  }

  function seleccion(){

    if((document.getElementById("lista").value)=="Cuadrillero"){
      console.log("cuadrillero")
      setCuadrillero_select(true);
      setTrabajador_select(false);
      setActividad_select(false);
      setImporte_select(false);
      setCargo_select(false);
    }

    if((document.getElementById("lista").value)=="Trabajador"){
      console.log("Trabajador")
      console.log(Cuadrilleros);
      setCuadrillero_select(false);
      setTrabajador_select(true);
      setActividad_select(false);
      setImporte_select(false);
      setCargo_select(false);
    }

    if((document.getElementById("lista").value)=="Actividad"){
      console.log("Actividad")
      setCuadrillero_select(false);
      setTrabajador_select(false);
      setActividad_select(true);
      setImporte_select(false);
      setCargo_select(false);
    }

    if((document.getElementById("lista").value)=="Importe"){
      console.log("Importe")
      setCuadrillero_select(false);
      setTrabajador_select(false);
      setActividad_select(false);
      setImporte_select(true);
      setCargo_select(false);
    }

    if((document.getElementById("lista").value)=="Cargo"){
      console.log("Cargo")
      setCuadrillero_select(false);
      setTrabajador_select(false);
      setActividad_select(false);
      setImporte_select(false);
      setCargo_select(true);
    }
  }

  function salir(){
        //Este se ocupa para que no aparezca nada al abrir
        setCuadrillero_select(false);
        setTrabajador_select(false);
        setActividad_select(false);
        setImporte_select(false);
        setCargo_select(false);
        setIsModalAñadir(false)
  }

  function swal_exitoso(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se agrego correctamente.',
      showConfirmButton: false,
      timer: 1500
    });
  }

  
  async function añadirProductoModal() {
  //Fecha
  let date = new Date();
  let fecha = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
   
  if((document.getElementById("lista").value)=="Cuadrillero"){
        //obtener infor del formulario
        const nombre = document.getElementById("nombre").value;
        // enviar informacion a firebase
        const infoProducto = { nombre, fecha};
        const auxiliar = "Cuadrillero";
        const auxiliar2 = "Nombres";
        añadir_asegurado(infoProducto, auxiliar, auxiliar2 );
        // cerrar modal
        traer_cuadrilleros();
        setIsModalAñadir(false);
        swal_exitoso();
    }

    if((document.getElementById("lista").value)=="Trabajador"){
        //obtener infor del formulario
        const nombre = document.getElementById("nombre_trabajador").value;
        const NSS_trabajador = document.getElementById("NSS_trabajador").value;
        const curp_trabajador = document.getElementById("curp_trabajador").value;
        const RFC_trabajador = document.getElementById("RFC_trabajador").value;
        const banco_trabajador = document.getElementById("banco_trabajador").value;
        const trabajador_cuadrillero = document.getElementById("trabajador_cuadrillero_seleccionado").value;
        const trabajador_fletero_de_cuadrillero = document.getElementById("trabajador_fletero_de_cuadrillero_seleccionado").value;
        const cuenta_trabajador = document.getElementById("cuenta_trabajador").value;

        let fecha_alta = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear();
   
        // enviar informacion a firebase
        //Con esto agrego los 7 dias de la semana en 0-------------------------------------------------------------------------------------------------
        const importe = 0;
        const importe_2 = 0;
        const suma_importes = 0;
        const actividad = "";
        const cargo = "";
        const actividad_2 = "";
        const cargo_2 = "";
        const Viernes = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Sabado ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Domingo = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Lunes= {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Martes ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Miercoles = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const Jueves ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
        const descuento = 0;
        const total = 0;

        //---------------------------------------------------------------------------------------------------------------------------------------------
        const infoProducto = {nombre, NSS_trabajador, curp_trabajador, RFC_trabajador, banco_trabajador, trabajador_cuadrillero, trabajador_fletero_de_cuadrillero,
           cuenta_trabajador, fecha, fecha_alta, Viernes, Sabado, Domingo, Lunes, Martes, Miercoles, Jueves, descuento, total};
        const auxiliar = "Trabajador";
        const auxiliar2 = "Nombres";
        añadir_asegurado(infoProducto, auxiliar, auxiliar2 );
        // cerrar modal
        setIsModalAñadir(false);
        swal_exitoso();
    }

    if((document.getElementById("lista").value)=="Actividad"){
         //obtener infor del formulario
         const nombre = document.getElementById("nombre_actividad").value;
         // enviar informacion a firebase
         const infoProducto = {nombre, fecha};
         const auxiliar = "Actividad";
         const auxiliar2 = "Nombres";
         añadir_asegurado(infoProducto, auxiliar, auxiliar2 );
         // cerrar modal
         setIsModalAñadir(false);
         swal_exitoso();
    }

    if((document.getElementById("lista").value)=="Importe"){
         //obtener infor del formulario
         const nombre = document.getElementById("nombre_importe").value;
         // enviar informacion a firebase
         const infoProducto = {nombre, fecha};
         const auxiliar = "Importe";
         const auxiliar2 = "Nombres";
         añadir_asegurado(infoProducto, auxiliar, auxiliar2 );
         // cerrar modal
         setIsModalAñadir(false);
         swal_exitoso();
    }

    if((document.getElementById("lista").value)=="Cargo"){
         //obtener infor del formulario
         const nombre = document.getElementById("nombre_cargo").value;
         // enviar informacion a firebase
         const infoProducto = {nombre, fecha};
         const auxiliar = "Cargo";
         const auxiliar2 = "Nombres";
         añadir_asegurado(infoProducto, auxiliar, auxiliar2 );
         // cerrar modal
         setIsModalAñadir(false);
         swal_exitoso();
    }

  }

  React.useEffect(() => {
    traer_cuadrilleros();
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
              <h6>Elegir movimiento a crear</h6>
              <select id="lista" type="text"
                onClick={() => {
                  seleccion()
                }}
              >
                <option>Seleccionar</option>
                <option>Cuadrillero</option>
                <option>Trabajador</option>
                <option>Actividad</option>
                <option>Importe</option>
                <option>Cargo</option>
              </select>
            <br/>
              {Cuadrillero_select ?
              <div>
                <Form.Control
                  id="nombre"
                  placeholder="Nombre"
                  type="text"
                  className="mb-1"
                />
              </div>
              :  
              <></>
              }

            {Trabajador_select ?
              <div>
                <Form.Control
                  id="nombre_trabajador"
                  placeholder="Nombre"
                  type="text"
                  className="mb-1"
                />
                <Form.Control
                  id="NSS_trabajador"
                  placeholder="Ingresar NSS"
                  type="number"
                  className="mb-1"
                /> 
                <Form.Control
                  id="curp_trabajador"
                  placeholder="Ingresar CURP"
                  type="text"
                  className="mb-1"
                />
                <Form.Control
                  id="RFC_trabajador"
                  placeholder="Ingresar RFC"
                  type="text"
                  className="mb-1"
                />
              <br/>
              <h6>Elegir si el pago es a banco o efectivo</h6>
              <select id="banco_trabajador" type="text"
              >
                <option>Bancomer</option>
                <option>Santander</option>
                <option>Efectivo</option>
                <option>NA</option>
              </select>
              <br/>
              <br/>
              <h6>Seleccionar cuadrillero</h6>
                <select id="trabajador_cuadrillero_seleccionado" type="text">
                {Cuadrilleros.map((datos,index)=>( 
                    <option key={index}>{datos.nombre}</option>
                ))}
                </select>
              <br/>
              <br/>
              <h6>¿Es fletero del cuadrillero seleccionado?</h6>
                <select id="trabajador_fletero_de_cuadrillero_seleccionado" type="text">
                <option>No</option>
                <option>Si</option>
                </select>
              <br/>
              <br/>
               <Form.Control
                  id="cuenta_trabajador"
                  placeholder="Ingresar cuenta"
                  type="text"
                  className="mb-1"
                />
              </div>
              :  
              <></>
              }

              {Actividad_select ?
              <div>
                <Form.Control
                  id="nombre_actividad"
                  placeholder="Nombre"
                  type="text"
                  className="mb-1"
                />
              </div>
              :  
              <></>
              }

              {Importe_select ?
              <div>
                <Form.Control
                  id="nombre_importe"
                  placeholder="Nombre"
                  type="text"
                  className="mb-1"
                />
              </div>
              :  
              <></>
              }

              {Cargo_select ?
              <div>
                <Form.Control
                  id="nombre_cargo"
                  placeholder="Nombre"
                  type="text"
                  className="mb-1"
                />
              </div>
              :  
              <></>
              }
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => salir()}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Agregar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_cuadrillero;
