import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function añadirprecio_pallets(Manifiesto_Seleccionado, PreciosDB, actualizar) {
  const db = getFirestore(firebaseApp);
  console.log(PreciosDB);
  console.log(PreciosDB[0]);
  console.log(Manifiesto_Seleccionado)
  var Pallets = PreciosDB;
  if(actualizar==true){
    Pallets = PreciosDB[0].Pallets;
    const collectionRef = collection(db, "Manifiestos/Tarimas_precios/"+Manifiesto_Seleccionado.manifiesto+"/".replace(/ /g, ""));
    const docRef = doc(collectionRef, Manifiesto_Seleccionado.manifiesto);
    updateDoc(docRef, {Pallets});  
    console.log("update")
  }
  if(actualizar=="Primera"){
    const collectionRef = collection(db, "Manifiestos/Tarimas_precios/"+Manifiesto_Seleccionado.manifiesto+"/".replace(/ /g, ""));
    const docRef = doc(collectionRef, Manifiesto_Seleccionado.manifiesto);
    setDoc(docRef, {Pallets});  
    console.log("primera")
  }
  if(actualizar=="Segunda"){
    Pallets = PreciosDB[0].Pallets;
    const collectionRef = collection(db, "Manifiestos/Tarimas_precios/"+Manifiesto_Seleccionado.manifiesto+"/".replace(/ /g, ""));
    const docRef = doc(collectionRef, Manifiesto_Seleccionado.manifiesto);
    updateDoc(docRef, {Pallets});  
    console.log("Segunda")
  }
}

export default añadirprecio_pallets;
