import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router,} from 'react-router-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";


ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Router>
        <App />
      </Router>
    </MuiPickersUtilsProvider>  
  </React.StrictMode>,
  document.getElementById('root')
);

