import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllProducts(pagina) {
    

  //console.log(valor);
    const productos =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Produccion/"+pagina+"/Productos".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      productos.push(doc.data());
    });
    return productos;
    

}
