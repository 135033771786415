import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllpagos_proveedores() {
    const PagosDB =  [];
    console.log("Hoa");
    const collectionRef = collection(db, "Proveedores_pagos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PagosDB.push(doc.data());
    });
    return PagosDB;
    
}
