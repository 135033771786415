import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadirManifiesto(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Manifiestos/Manifiestos/Manifiestos/".replace(/ /g, ""));
  const docRef = doc(collectionRef, (infoProducto.fecha_actual3).toString());
  setDoc(docRef, infoProducto);  
}

export default añadirManifiesto;
