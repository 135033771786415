
import React from 'react';
import {Navigate} from 'react-router-dom'
import Login2 from '../Firebase/views/Login2';
import firebaseApp from '../Firebase/firebase/credenciales'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ErrorBoundary from "../Firebase/components/Salvavidas"
const auth = getAuth(firebaseApp);


function Login (){
    const [usuario, setUsuario] = React.useState(null);

    onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setUsuario(usuarioFirebase);
      } else {
        setUsuario(null);
      }
    });
    return(
    <div>
     <ErrorBoundary>
       {usuario ? <Navigate to="/Inventarios"/> : <Login2/>}
     </ErrorBoundary> 
    </div>
);
}

export default Login