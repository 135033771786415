import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_retirar_consumibles_tarima_esquinero(accion, usuario, auxiliar13, auxiliar12, date3, date2,
  Manifiesto_Seleccionado, linea_donde_se_uso_producto, esquinero_actual, tarima_actual, producto_terminado_seleccionado, orden_compra ) {
  console.log(accion, usuario, auxiliar13, auxiliar12, linea_donde_se_uso_producto);
  console.log(esquinero_actual);
  console.log(tarima_actual);
  console.log(producto_terminado_seleccionado);
  
  var auxiliar_consumibles = [];
  if(esquinero_actual == ""){
    auxiliar_consumibles = tarima_actual;
  }else{
    auxiliar_consumibles = esquinero_actual;
  }

console.log(auxiliar_consumibles)
  var persona= usuario.email;
  if(orden_compra == undefined){
    orden_compra = "";
  }else{persona= "Se genero desde la orden de compra con folio "+orden_compra}

  //Con esto evaluo si es el tiempo de la tarima o de los esquineros
  let date = "";
  if(date2 == ""){
    date = date3;
  }else{
    date = date2;
  }
  
  const cosecha= "NA";
  const retirado= "NA";
  //let date = new Date();
  let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, date.toString());
  const data = {
    accion,
    fecha: fecha_actual,
    fecha3: date,
    Hora: hora_actual,
    Autor: persona,
    Cantidad_retirada: auxiliar13,
    Cantidad_agregada: retirado,
    Producto_retirado: auxiliar12,
    Titulo: new Date().toString(),
    Cosecha: cosecha,
    Orden_folio: orden_compra,
    Manifiesto: Manifiesto_Seleccionado.manifiesto,
    Seccion: linea_donde_se_uso_producto,
    precio: auxiliar_consumibles.precio,
    moneda: auxiliar_consumibles.moneda,
    categoria: auxiliar_consumibles.categoria,
    lleva_iva: auxiliar_consumibles.lleva_iva,
    Cosecha_seleccionada_retirada: producto_terminado_seleccionado.categoria_pallet,
  };

  setDoc(docuRef, data)
  //setDoc(docuRef, data);
  
}
