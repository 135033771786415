
import React from 'react';
import Caurosel from '../Carousel/Carousel'
import Navbar from '../Navbars/Navbar'
import '../../index.css'


const Inicio = () =>{
    return(
       
        <div className="Contenedor_inicio">
            {/* TOP */} 
                <div className="Navbar">
                    <Navbar/>         
                </div>
                
                <div className="Carrusel">
                     <Caurosel/>
                </div>

            {/* Body */}
              <div className="Quienes_somos">
                    <h1>¿Quiénes somos?</h1>
                    <center className="Texto_justificado">
                                Una empresa familiar con años de experiencia en la agricultura, realizando el ciclo completo del 
                                producto; siembra, cosecha y empaque, de esta manera aseguramos la calidad e inocuidad de todos
                                nuestros productos. 
                    </center> 
                </div>

                <div className="Mision">
                     <h1>Misión</h1>
                    <center className="Texto_justificado">
                       Crear y ofrecer al mundo productos hortícolas de la más alta calidad para una comercialización optima,
                       aprovechando los recursos naturales de la región, perservando la ecología y salud de la misma. Con el personal
                       capacitado y tecnología de punta; elevando así el nivel de vida de las familias que dependen de esta empresa;
                       dando un ejemplo de trabajo y honradez para todas las personas que se relacionen con ella.
                    </center>
                </div>   

                <div className="Vision">
                    <h1>Visión</h1>
                    <center className="Texto_justificado">
                       Producir con eficiencia productos hortícolas con el nivel de calidad necesario para un mercado internacional
                       y tiendas de autoservicio con un aprovechamiento optimo del recurso humano y financiero, utilizando al máximo
                       nuestro equipo e instalaciones. 
                    </center>
                </div>  
            
               
            <br/>
            <br/>
            {/* Footer */}  
                <div className="Footer">
                    <footer >2022 Todos los derechos reservados.</footer>
                </div>
                
        </div>
        
    );
}

export default Inicio

{/* <a className="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                Link with href
                </a>
                <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                Button with data-bs-target
                </button>

                <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                    Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                    </div>
                    <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                        Dropdown button
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                    </div>
                </div>
                </div> */}

{/* <button data-tip data-for="Tooltip_contactos">Contactos</button>
                   
                    <ReactTooltip
                    id="Tooltip_contactos"
                    place="top"
                    type="dark"
                    effect="solid"
                    className='Tooltip_contactos'
                    backgroundColor="black"
                    borderColor='black'
                    arrowColor='black'
                    >
                        6444-11-11-11
                    </ReactTooltip> */}