import React from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import '../../index.css'

const Inventarios = () =>{
   
    return(
       <div id="Trabajo2">

           <div id="Trabajo2">
            <Navbartrabajo/>  
           </div>
           
            <div id="Trabajo" > 
                <h1 id="Titulo_inventarios">Bienvenidos a la base de datos, por favor elige una opción del menú para iniciar</h1>
            </div>
       </div>    
    )
}
//<CRUD/>
export default Inventarios;