import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllPrecios_pallets(Manifiesto_Seleccionado) {
    console.log(Manifiesto_Seleccionado.manifiesto);
    const PreciosDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Manifiestos/Tarimas_precios/"+Manifiesto_Seleccionado.manifiesto+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PreciosDB.push(doc.data());
    });
    return PreciosDB;
    

}
