import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_boton_bancos from "../../functions/Bancos/añadir_boton_bancos";

function ModalAñadir({
  ModalAñadir_boton,
  setModalAñadir_boton,
  actualizar_botones,
  Botonesdata,
}) {

  async function añadirProductoModal() {
    //obtener infor del formulario
    const nombre_banco = document.getElementById("nombre_banco").value;
    // enviar informacion a firebase
    const infoProducto = {nombre_banco};
    añadir_boton_bancos(infoProducto)
    // cerrar modal
    actualizar_botones();
    setModalAñadir_boton(false);
  }

  return (
    <Modal show={ModalAñadir_boton} onHide={() => setModalAñadir_boton(false)}>
      <Modal.Header>
        <Modal.Title>Añadir nuevo banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <Form.Control
              id="nombre_banco"
              placeholder="Agregar nombre del banco"
              type="text"
              className="mb-1"
            />  
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAñadir_boton(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
