import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getProductos_almacenes(categoria2, productocargo) {
    const ProductosDB =  [];
    var collectionRef = "";
     //Con esto lo añado en el almacen de campo
     if(productocargo=="Almacen campo"){
      console.log("Campo")
      collectionRef = collection(db, "Almacen_campo/"+categoria2+"/Productos/");
    }
    //Con esto lo añado en el almacen de empaque
    if(productocargo=="Almacen empaque"){
      console.log("empaque")
      collectionRef = collection(db, "Almacen_empaque/"+categoria2+"/Productos/");
    }
    
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      ProductosDB.push(doc.data());
    });
    return ProductosDB;
    

}


//"+productofolio+"
