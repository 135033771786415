import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import añadirProducto from "../../functions/Campo_secciones/añadirProducto";


function ModalAñadir({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
  pagina,
  start,
}) {
  function añadirProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("nombre").value;
    const cantidad_cultivos = document.getElementById("cantidad_cultivos").value;
    const producto1 = document.getElementById("producto1").value;
    const producto1_fecha = document.getElementById("producto1_fecha").value;
    const producto2 = document.getElementById("producto2").value;
    const producto2_fecha = document.getElementById("producto2_fecha").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, cantidad_cultivos, producto1, producto1_fecha, producto2, producto2_fecha  };
    añadirProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    actualizarEstadoProductos();
    setIsModalAñadir(false);
  }

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir tabla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Nombre"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="cantidad_cultivos"
              placeholder="Ingresa cantidad de cultivos en la tabla"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="producto1"
              placeholder="Ingresa producto en cultivo 1"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="producto1_fecha"
              placeholder="Ingresa fecha de inicio del cultivo 1"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="producto2"
              placeholder="Ingresa producto en cultivo 2"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="producto2_fecha"
              placeholder="Ingresa fecha de inicio del cultivo 2"
              type="text"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
