import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getClientes(auxiliar) {
  
  const clientesDB =  [];
  const collectionRef = collection(db, "Produccion_clientes_embarque".replace(/ /g, ""));

  //Con este filtro de los clientes a los nacionales
  if(auxiliar=="nacional"){
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    const valor = doc.data();
    if(valor.nacional_exportacion==="Nacional")
    {
      clientesDB.push(doc.data());
    }
  });}

  else{
  
 //Con este filtro de los clientes a los de exportacion
 if(auxiliar=="exportacion"){
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    const valor = doc.data();
    if(valor.nacional_exportacion==="Exportación")
    {
      clientesDB.push(doc.data());
    }
  });}

  }
    return clientesDB;
    
}
