import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarProducto(producto, usuario,pagina,start) {

  const collectionRef = collection(db, "Almacen_empaque/"+pagina+"/Productos".replace(/ /g, ""))
  const docuRef = doc(collectionRef, producto.nombre);
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
