import CRUD from '../Firebase/CRUDS/CRUD';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Listainventario from '../Listas/Listainventario';


//<Listaproduccion/> , va en la linea 14
const Almacen_campo= () =>{

    const [Start, setStart] = useState(false);
    const [valor, setvalor] = useState(null);
    const [continuar, setcontinuar] = useState(false);

    function operacion(){
        if(valor===null){
            setStart(false);
            setcontinuar(false);
        }else{
            setStart(true);
            setcontinuar(true);
        }
    }

    function Pagina_principal(){
        setStart(false);
        setcontinuar(false);
    }

       React.useEffect(() => {
        operacion();
       }, [valor]);

    return(
        <div id="Trabajo">  
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Listainventario 
                                setvalor={setvalor}
                                
                            />}
                

                    {continuar? <CRUD pagina={valor} start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}

export default Almacen_campo;
//-------------------------------------------------------------------------------------------------------------------------

/* import CRUD from '../Firebase/CRUDS/CRUD';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Listainventario from '../Listas/Listainventario';


//<Listaproduccion/> , va en la linea 14
const Almacen_campo= () =>{

    const [Start, setStart] = useState(false);

    const [Fertilizante, setFertilizante] = useState(false);
    const [Ferreteria2, setFerreteria] = useState(false);
    const [Semillas2, setSemillas] = useState(false);
    const [Rafia2, setRafia] = useState(false);

    function Pagina_principal(){
        setStart(false);
        setFertilizante(false);
        setFerreteria(false);
        setSemillas(false);
        setRafia(false);
    }

    function Fertilizantes(){
     setFertilizante(true); 
     setStart(true);
    }
    
    function Ferreteria(){
        setFerreteria(true);
        setStart(true);
    }
    
    function Semillas(){
        setSemillas(true);
        setStart(true);
    }

    function Rafia(){
        setRafia(true); 
        setStart(true);
       }

    return(
        <div id="Trabajo">  
                
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Listainventario 
                                Fertilizantes={()=>Fertilizantes()} 
                                Ferreteria={()=>Ferreteria()} 
                                Semillas={()=>Semillas()} 
                                Rafia={()=>Rafia()} 
                            />}
                

                
                    {Fertilizante ? <CRUD pagina="Fertilizantes y agroquimicos" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Ferreteria2 ? <CRUD pagina="Ferreteria" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Semillas2 ? <CRUD pagina="Semillas" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
               

                
                    {Rafia2 ? <CRUD pagina="Rafia" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}

export default Almacen_campo; */