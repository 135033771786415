import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(firebaseApp);

export default async function getAll_cuadrilleros() {
  const CuadrillerosDB = [];

  let collectionRef = collection(db, "Nominas/Cuadrillero/Nombres/".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    CuadrillerosDB.push(doc.data());
  });
  return CuadrillerosDB;
}
