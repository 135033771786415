import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, updateDoc } from "firebase/firestore";

function añadir_proveedor(Producto_a_editar, Nombre_de_boton, auxiliar) {
  console.log(Producto_a_editar.nombre)
  console.log(Nombre_de_boton.nombre_banco)
  console.log(auxiliar)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Bancos/"+(Nombre_de_boton.nombre_banco)+"/".replace(/ /g, ""))  
  const docRef = doc(collectionRef, (Producto_a_editar.nombre).toString());
  updateDoc(docRef, auxiliar);
}

export default añadir_proveedor;
