import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../../functions/Almacen_empaque/añadirProducto";

function ModalAñadir({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
  pagina,
  start,
}) {
  function añadirProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("nombre").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cantidad = document.getElementById("cantidad").value;
    const unidad = document.getElementById("unidad").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    añadirProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    actualizarEstadoProductos();
    setIsModalAñadir(false);
  }

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Nombre"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="precio"
              placeholder="precio"
              type="number"
              className="mb-1"
            />
            <Form.Control
              id="moneda"
              placeholder="moneda"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="cantidad"
              placeholder="cantidad"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="unidad"
              placeholder="Unidad de medida"
              type="text"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
