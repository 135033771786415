import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminar_venta(producto, Boton_seleccionado_costos) {
  const coleccionRef = collection(db, "Cultivo_en_proceso/"+Boton_seleccionado_costos+"/Ventas/");
  const docuRef = doc(coleccionRef, (producto.fecha).toString());
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
