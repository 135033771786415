import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";
import getCategorias from '../Firebase/functions/Productos/getCategorias';
import ModalAñadir_produccion from './Modales/ModalAñadir_produccion';
import getCategorias_botones_produccion from './funciones/getCategorias_botones_produccion';
import ModalEliminar_produccion from "./Modales/ModalEliminar_produccion";

const Lista_produccion = ({setvalor,}) =>{   
    const [Modal_categoria, setModal_categoria] = React.useState(false);
    const [Modal_eliminar, setModal_eliminar] = React.useState(false);
    const [Categoria, setCategoria] = React.useState([]);
    const [Botones_campo, setBotones_campo] = React.useState([]);
    
   async function Categorias(){
        await getCategorias().then((CategoriasDB) => {
            console.log(CategoriasDB) 
            setCategoria(CategoriasDB);
       });
       setModal_categoria(true);
     }

     async function eliminar(){
      setModal_eliminar(true);
    }

     async function botones(){
        await getCategorias_botones_produccion().then((Categorias_botonesDB) => {
            console.log(Categorias_botonesDB) 
            setBotones_campo(Categorias_botonesDB);
       });
     }

    async function Seleccionar_almacen(prop){
       console.log(prop);
       setvalor(prop);
     }
     
    
     React.useEffect(() => {
       botones();
      }, []);

    return(
      <div> 
        {Modal_categoria && (
        <ModalAñadir_produccion
          Modal_categoria={Modal_categoria}
          setModal_categoria={setModal_categoria}
          Categoria={Categoria}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

        {Modal_eliminar && (
        <ModalEliminar_produccion
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Categoria={Categoria}
          actualizar_botones={botones}
          Botones_campo={Botones_campo}
          className="modal-dialog modal-fullscreen-sm-down"
        />)}

      <div id="Botones_agregareliminar_categoria_almacen_campo">
         <Button id="Botones_01" onClick={()=>{Categorias()}}> Agregar categoría de producción</Button>
         <Button id="Botones_02" onClick={()=>{eliminar()}}> Eliminar categoría de producción</Button>
      </div>
            <div id="Lista_empaque">
                
                {Botones_campo.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" onClick={() => Seleccionar_almacen((datos.valor))} key={index}>{datos.valor}</button>
                ))}
           
            </div>
      </div>    
    )
}


export default Lista_produccion;



/* import React from 'react';
import '../../index.css'


const Lista_cultivo_en_proceso = (props) =>{   
    const {Lineas_produccion_1, Mantenimiento_2, Limpieza_3, Embarque_4,Consumible}=props;
    

    return(
        <div id="Trabajo">
            <br/>
            <br/>
            <br/>
            <br/>
                <div  id="Lista_empaque" className="list-group">
                    <button onClick={() => Lineas_produccion_1()}>Líneas de producción</button>
                    <br/>
                    <br/>
                    <button onClick={() => Mantenimiento_2()}>Mantenimiento</button>
                    <br/>
                    <br/>
                    <button onClick={() => Limpieza_3()}>Limpieza</button>
                    <br/>
                    <br/>
                    <button onClick={() => Embarque_4()}>Embarque</button>
                    <br/>
                    <br/>
                    <button onClick={() => Consumible()}>Consumibles</button>
                </div>    
        </div>
    )
}


export default Lista_cultivo_en_proceso */