import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function Traer_trabajadores_concentrado_cuadrillero(cuadrillero_seleccionado, año_seleccionado, semana_seleccionada) {
    console.log(cuadrillero_seleccionado, año_seleccionado, semana_seleccionada);
    const datoDB =  [];
    const collectionRef = collection(db, "Nominas/Pagos_semanales/"+año_seleccionado+"/"+semana_seleccionada+"/Trabajadores/");
    const q =  query(collectionRef, where("trabajador_cuadrillero", "==", cuadrillero_seleccionado));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        datoDB.push(doc.data());
      });
    console.log(datoDB)
    return datoDB;
} 





