import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getFolio_anterior() {
    

  //console.log(valor);
    const Folio_anteriorDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Ordenes_Folio_nuevo".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Folio_anteriorDB.push(doc.data());
    });
    return Folio_anteriorDB;
    

}


//"+productofolio+"
