import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs} from "firebase/firestore";
const db = getFirestore(firebaseApp);

    

export default async function getProveedores() {
    const ProveedoresDB =  [];
    console.log("Fiscales");
    const collectionRef = collection(db, "Proveedores/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      ProveedoresDB.push(doc.data());
    });
    return ProveedoresDB;
}