import React, {useState} from "react";
import { Modal, Stack, Form, Button, Table } from "react-bootstrap";
import getAlldata_consultar from "../../functions/Seguro_social/getAlldata_consultar";
import editar_consultar from "../../functions/Seguro_social/editar_consultar";
import eliminar_consulta from "../../functions/Seguro_social/eliminar_consulta";
import getAll_cuadrilleros from "../../functions/Seguro_social/getAll_cuadrilleros";
import Swal from "sweetalert2";

function Modal_consultar({
  isModalAñadir,
  setIsModalAñadir,
}) {
  
//Eliminar con sweetalert2
function eliminar_sweetalert(producto, Guardar_seleccion) {
  Swal.fire({
    title: '¿Esta seguro de eliminar '+ producto.nombre+'?',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      const auxiliar = Guardar_seleccion;
      eliminar_consulta(producto, auxiliar).then(
        (confirmacion) => {
          salir();
          Swal.fire(
            'Eliminado!',
            'Se ha sido eliminado.',
            'success'
          )
        }
      );
    }
  })
}


const [Cuadrilleros, setCuadrilleros] = React.useState();

async function traer_cuadrilleros(){
    await getAll_cuadrilleros().then((CuadrillerosDB) => {
        console.log(CuadrillerosDB);
        setCuadrilleros(CuadrillerosDB);
    });   
}


  const [datos_seleccionado, setdatos_seleccionado] = React.useState();
  const [Guardar_seleccion, setGuardar_seleccion] = React.useState();
  const [auxiliar_tabla, setauxiliar_tabla] = React.useState(false);
  const [auxiliar_tabla_trabajador, setauxiliar_tabla_trabajador] = React.useState(false);
  const [Editar_On, setEditar_On] = React.useState(false);
  const [Modal_On, setModal_On] = React.useState(false);
  const [Auxiliar_cuadrillero, setAuxiliar_cuadrillero] = React.useState(false);

  async function seleccion(){

    if((document.getElementById("lista").value)=="Cuadrillero"){
      console.log("cuadrillero")
      const seleccionado = "Cuadrillero";
      setGuardar_seleccion(seleccionado);
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        console.log(dataDB);
        //Con esto lo ordeno por fechas de forma descendiente
        dataDB.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
        setdatos_seleccionado(dataDB);
     });   
     setAuxiliar_cuadrillero(true);
     setauxiliar_tabla(true);
     setauxiliar_tabla_trabajador(false);
    }

    if((document.getElementById("lista").value)=="Trabajador"){
      console.log("Trabajador")
      const seleccionado = "Trabajador";
      setGuardar_seleccion(seleccionado);
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        console.log(dataDB);
        //Con esto lo ordeno por fechas de forma descendiente
        dataDB.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
        setdatos_seleccionado(dataDB);
     });   
     setAuxiliar_cuadrillero(false);
     setauxiliar_tabla(false);
     setauxiliar_tabla_trabajador(true);
    }

    if((document.getElementById("lista").value)=="Actividad"){
      console.log("Actividad")
      const seleccionado = "Actividad";
      setGuardar_seleccion(seleccionado);
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        console.log(dataDB);
        //Con esto lo ordeno por fechas de forma descendiente
        dataDB.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
        setdatos_seleccionado(dataDB);
     });   
     setAuxiliar_cuadrillero(false);
     setauxiliar_tabla(true);
     setauxiliar_tabla_trabajador(false);
    }

    if((document.getElementById("lista").value)=="Importe"){
      console.log("Importe")
      const seleccionado = "Importe";
      setGuardar_seleccion(seleccionado);
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        console.log(dataDB);
        //Con esto lo ordeno por fechas de forma descendiente
        dataDB.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
        setdatos_seleccionado(dataDB);
     });   
     setAuxiliar_cuadrillero(false);
     setauxiliar_tabla(true);
     setauxiliar_tabla_trabajador(false);
    }

    if((document.getElementById("lista").value)=="Cargo"){
      console.log("Cargo")
      const seleccionado = "Cargo";
      setGuardar_seleccion(seleccionado);
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        console.log(dataDB);
        //Con esto lo ordeno por fechas de forma descendiente
        dataDB.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
        setdatos_seleccionado(dataDB);
     });   
     setAuxiliar_cuadrillero(false);
     setauxiliar_tabla(true);
     setauxiliar_tabla_trabajador(false);
    }
  }

  const [Auxuliar_para_seleccion_a_editar, setAuxuliar_para_seleccion_a_editar] = React.useState(false);
  const [Producto_a_editar, setProducto_a_editar] = React.useState();

  function Editar_movimiento(producto){
    setProducto_a_editar(producto);
    
    if(Guardar_seleccion=="Cuadrillero"){
      setAuxuliar_para_seleccion_a_editar(true);
    }

    if(Guardar_seleccion=="Trabajador"){
      setAuxuliar_para_seleccion_a_editar(false);
    }

    if(Guardar_seleccion=="Actividad"){
      setAuxuliar_para_seleccion_a_editar(true);
    }

    if(Guardar_seleccion=="Importe"){
      setAuxuliar_para_seleccion_a_editar(true);
    }

    if(Guardar_seleccion=="Cargo"){
      setAuxuliar_para_seleccion_a_editar(true);
    }

    setEditar_On(true);
    setModal_On(false);

  }

  async function Personal_cuadrillero(producto){
    console.log(producto)
    const seleccionado = "Trabajador";
    setGuardar_seleccion(seleccionado);
    var auxiliar_cuadrillero_trabajadores = [];
    await getAlldata_consultar(seleccionado).then((dataDB) => {
      console.log(dataDB);
      dataDB.map((trabajadores)=>{
        if(trabajadores.trabajador_cuadrillero == producto.nombre){
          auxiliar_cuadrillero_trabajadores.push(trabajadores);
        }
      })
   });   
    console.log(auxiliar_cuadrillero_trabajadores);
    setauxiliar_tabla_trabajador(true);
    //Con esto lo ordeno por fechas de forma descendiente
    auxiliar_cuadrillero_trabajadores.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
    setdatos_seleccionado(auxiliar_cuadrillero_trabajadores);
    setauxiliar_tabla(false);
  }

  function salir(){
        setIsModalAñadir(false)
  }

  function auxiliar_modal(){
    setEditar_On(false);
    setModal_On(true);
  }

  function swal_exitoso(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se edito correctamente.',
      showConfirmButton: false,
      timer: 1500
    });
  }

  async function editarProductoModal() {
    //Este es para todos excepto trabajador ya que tiene mas datos
    if(Auxuliar_para_seleccion_a_editar==true){
      //obtener infor del formulario
      const fecha = Producto_a_editar.fecha;
      const nombre = document.getElementById("nombre").value;
      // enviar informacion a firebase
      const infoProducto = {nombre, fecha};
      const auxiliar = Guardar_seleccion;
      editar_consultar(infoProducto, auxiliar);
      setEditar_On(false);
      setModal_On(true);
      swal_exitoso();
    }
    //Este if es para editar trabajador
    if(Auxuliar_para_seleccion_a_editar==false){
      //obtener infor del formulario
      const fecha = Producto_a_editar.fecha;
      const fecha_alta = document.getElementById("fecha_alta").value;
      const nombre = document.getElementById("nombre_trabajador").value;
      const NSS_trabajador = document.getElementById("NSS_trabajador").value;
      const curp_trabajador = document.getElementById("curp_trabajador").value;
      const RFC_trabajador = document.getElementById("RFC_trabajador").value;
      const cuenta_trabajador = document.getElementById("cuenta_trabajador").value;
      const banco_trabajador = document.getElementById("banco_trabajador").value;
      const trabajador_fletero_de_cuadrillero = document.getElementById("trabajador_fletero_de_cuadrillero").value;
      const trabajador_cuadrillero = document.getElementById("trabajador_cuadrillero2").value;

      // enviar informacion a firebase
      const infoProducto = {nombre, fecha, NSS_trabajador, fecha_alta, curp_trabajador, RFC_trabajador, cuenta_trabajador, banco_trabajador, 
        trabajador_cuadrillero, trabajador_fletero_de_cuadrillero};
      const auxiliar = Guardar_seleccion;
      editar_consultar(infoProducto, auxiliar);
      setEditar_On(false);
      setModal_On(true);
      swal_exitoso();
    }
  }

  React.useEffect(() => {
    auxiliar_modal();
    traer_cuadrilleros();
  }, []);

  return (
    //Aqui empieza el modal editar-------------------------------------------------------------------------------------
    <div>
    {Editar_On? 
    <div>
    <Modal
      show={Editar_On}
    >
      <Modal.Header>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <Form>
        <div>
          {Auxuliar_para_seleccion_a_editar? 
            <div>
              <Stack>
                  <Form.Control
                    id="nombre"
                    placeholder="Ingresar nombre"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.nombre}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        nombre: e.target.value,
                      })
                    }
                  />
              </Stack>
            </div>
          :
            <div>
               <Stack>
               <h6>Nombre</h6>
                  <Form.Control
                    id="nombre_trabajador"
                    placeholder="Ingresar nombre"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.nombre}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        nombre: e.target.value,
                      })
                    }
                  />
                  <br/>
                  <h6>NSS</h6>
                  <Form.Control
                    id="NSS_trabajador"
                    placeholder="Ingresar NSS"
                    type="number"
                    className="mb-1"
                    value={Producto_a_editar?.NSS_trabajador}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        NSS_trabajador: e.target.value,
                      })
                    }
                  />
                  <br/>
                  <h6>CURP</h6>
                  <Form.Control
                    id="curp_trabajador"
                    placeholder="Ingresar CURP"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.curp_trabajador}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        curp_trabajador: e.target.value,
                      })
                    }
                  />
                  <br/>
                  <h6>RFC</h6>
                  <Form.Control
                    id="RFC_trabajador"
                    placeholder="Ingresar RFC"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.RFC_trabajador}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        RFC_trabajador: e.target.value,
                      })
                    }
                  />
                  <br/>
                  <h6>Fecha de alta</h6>
                  <Form.Control
                    id="fecha_alta"
                    placeholder="Ingresar fecha de alta"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.fecha_alta}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        fecha_alta: e.target.value,
                      })
                    }
                  />
                 <br/>
                  <h6>Cuenta</h6>
                  <Form.Control
                    id="cuenta_trabajador"
                    placeholder="Ingresar cuenta del trabajador"
                    type="text"
                    className="mb-1"
                    value={Producto_a_editar?.cuenta_trabajador}
                    onChange={(e) =>
                      setProducto_a_editar({
                        ...Producto_a_editar,
                        cuenta_trabajador: e.target.value,
                      })
                    }
                  />
                  <br/>
                 <h6>Elegir si el pago es a banco o efectivo</h6>
                 <select id="banco_trabajador" type="text" style={{ width: "30%"}}
                  >
                  <option>{Producto_a_editar?.banco_trabajador}</option>
                  <option>Bancomer</option>
                  <option>Santander</option>
                  <option>Efectivo</option>
                  <option>NA</option>
                  </select>
                  <br/>
                 <h6>Seleccionar cuadrillero</h6>
                 <select id="trabajador_cuadrillero2" type="text" style={{ width: "50%"}}>
                 <option>{Producto_a_editar?.trabajador_cuadrillero}</option>
                 {Cuadrilleros.map((datos,index)=>( 
                    <option key={index}>{datos.nombre}</option>
                 ))}
                 </select>

                  <br/>
                 <h6>¿Es fletero?</h6>
                 <select id="trabajador_fletero_de_cuadrillero" type="text" style={{ width: "10%"}}
                  >
                  <option>{Producto_a_editar?.trabajador_fletero_de_cuadrillero}</option>
                  <option>Si</option>
                  <option>No</option>
                  </select>
              </Stack>
            </div>
          }
        </div>

        </Form>
      </Modal.Body>

      <Modal.Footer>         
        <Button
          variant="secondary"
          onClick={() => {
            setEditar_On(false);
            setModal_On(true);
          }}
        >
          Cancelar
        </Button>
  
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
      </div>

      :
     //Aqui empieza el modal inicial-------------------------------------------------------------------------------------

      <div>
    <Modal id="Modal_Logs"className="container-fluid" show={Modal_On}>
      <Modal.Header>
        <Modal.Title>Consultar</Modal.Title>
        <Button  onClick={() => salir()}>
          Salir
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
              <h6>Elegir movimiento a consultar</h6>
              <select id="lista" type="text" style={{ width: "30%" }}
                onClick={() => {
                  seleccion()
                }}
              >
                <option>Seleccionar</option>
                <option>Cuadrillero</option>
                <option>Trabajador</option>
                <option>Actividad</option>
                <option>Importe</option>
                <option>Cargo</option>
              </select>
            <br/>

          </Stack>
        </Form>
       {auxiliar_tabla? 
        <Table id="Tabla_logs" className="container-fluid">
               <thead>
                 <tr>
                   <th>#</th>
                   <th>Nombre</th>
                   <th>ACCIONES</th>
                 </tr>
               </thead>
               <tbody>
                 {
                   datos_seleccionado.map((producto, index) => (
                     <tr key={index}>
                       <td>{index + 1}</td>
                       <td>{producto.nombre}</td>
                       <td>
                        {Auxiliar_cuadrillero? 
                             <Button
                             variant="success"
                             onClick={() => {
                              Personal_cuadrillero({ ...producto });
                             }}
                              >
                             Ver cuadrilla
                           </Button>
                          :
                          <></>
                          }
                          <Button
                            variant="dark"
                            onClick={() => {
                              Editar_movimiento({ ...producto });
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminar_sweetalert(producto, Guardar_seleccion)
                            }}
                          >
                            Eliminar
                          </Button>
                        </td>
                     </tr>
                   ))}
               </tbody>
         </Table>
          :
          <></>
        }         

        {auxiliar_tabla_trabajador? 
              <Table id="Tabla_logs" className="container-fluid">
              <thead>
                <tr>
                  <th>#</th>
                  <th>NSS</th>
                  <th>Nombre completo</th>
                  <th>CURP</th>
                  <th>RFC</th>
                  <th>BANCO</th>
                  <th>CUENTA</th>
                  <th>CUADRILLERO</th>
                  <th>FECHA DE ALTA</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                {
                  datos_seleccionado.map((producto, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{producto.NSS_trabajador}</td>
                      <td>{producto.nombre}</td>
                      <td>{producto.curp_trabajador}</td>
                      <td>{producto.RFC_trabajador}</td>
                      <td>{producto.banco_trabajador}</td>
                      <td>{producto.cuenta_trabajador}</td>
                      <td>{producto.trabajador_cuadrillero}</td>
                      <td>{producto.fecha_alta}</td>
                      <td>
                          <Button
                            variant="dark"
                            onClick={() => {
                              Editar_movimiento({ ...producto });
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminar_sweetalert(producto, Guardar_seleccion)
                            }}
                          >
                            Eliminar
                          </Button>
                        </td>
                    </tr>
                  ))}
              </tbody>
        </Table>
        :
        <></>
        }
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => salir()}>
          Salir
        </Button>
      </Modal.Footer>
    </Modal>
      </div>
    }


    </div>
  ); 
}

export default Modal_consultar;
