import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_productos_tablas from "../../functions/Bancos/añadir_productos_tablas";
import getProductos_tablas from "../../functions/Bancos/getProductos_tablas";
import getProductos_afectaciones_fiscales from "../../functions/Bancos/getProductos_afectaciones_fiscales";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";

function ModalAñadir_tablas({
  Modal_añadir_producto,
  setModal_añadir_producto,
  Tablasdata,
  actualizar_productos_tablas,
  usuario2,
  Nombre_de_boton,
  setTablasdata,
  setTotal,
  Producto_a_editar,
}) {

//Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

  async function actualizar2(){
    await getProductos_tablas(Nombre_de_boton).then((TablasDB) => {
          //Ordena de menor a mayor por fecha
          TablasDB.sort((a,b)=> (a.fecha2) - (b.fecha2));
          var auxiliar = 0;
          TablasDB.map((datos)=>{
                const str = auxiliar.toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                var nuevaStr4 = nuevaStr3.replace(",", "");
            if(nuevaStr4 != 0){
              if(datos.entrada_salida=="Entrada"){
                auxiliar = formatter.format(+nuevaStr4 + +datos.cantidad_total);
                datos['Saldo_actual'] = auxiliar;
                //console.log("entrada")
              }

              if(datos.entrada_salida=="Salida"){
                auxiliar = formatter.format(nuevaStr4 - datos.cantidad_total);
                datos['Saldo_actual'] = auxiliar;
                //console.log("salida")
              }
            }
            
            //Con este asigno el saldo iniciar y lo guardo en la variable auxiliar
            if(datos.producto=="Saldo inicial"){
              auxiliar = formatter.format(datos.cantidad_total);
              datos['Saldo_actual'] = auxiliar;
              //console.log("HOla")
            }
          })

          //Ordena de mayor a menor por fecha
          TablasDB.sort((a,b)=> (b.fecha2) - (a.fecha2));
        setTablasdata(TablasDB);
        //actualizar_datos_gastos(fechas_organizadas);
   });
  }


//Esto es para saber cuantas afectaciones tendra el concepto
  const [afectaciones_valor1, setafectaciones_valor1] = React.useState(false);
  const [afectaciones_valor2, setafectaciones_valor2] = React.useState(false);
  const [afectaciones_valor3, setafectaciones_valor3] = React.useState(false);
  const [afectaciones_valor4, setafectaciones_valor4] = React.useState(false);
  const [afectaciones_valor5, setafectaciones_valor5] = React.useState(false);

  function afectaciones_total(){
    const total = document.getElementById("cantidad_afectaciones").value;
   if(total==1){
    setafectaciones_valor1(true);
    setafectaciones_valor2(false);
    setafectaciones_valor3(false);
    setafectaciones_valor4(false);
    setafectaciones_valor5(false);
   }
   if(total==2){
    setafectaciones_valor1(true);
    setafectaciones_valor2(true);
    setafectaciones_valor3(false);
    setafectaciones_valor4(false);
    setafectaciones_valor5(false);
  }
  if(total==3){
    setafectaciones_valor1(true);
    setafectaciones_valor2(true);
    setafectaciones_valor3(true);
    setafectaciones_valor4(false);
    setafectaciones_valor5(false);
  }
  if(total==4){
    setafectaciones_valor1(true);
    setafectaciones_valor2(true);
    setafectaciones_valor3(true);
    setafectaciones_valor4(true);
    setafectaciones_valor5(false);
  }
  if(total==5){
    setafectaciones_valor1(true);
    setafectaciones_valor2(true);
    setafectaciones_valor3(true);
    setafectaciones_valor4(true);
    setafectaciones_valor5(true);
  }

  }

  async function actualizar_datos_gastos(TablasDB){
    var total_acumulado=0;
    console.log(TablasDB)
    TablasDB.map((dato)=>{
      //Con esto convierto el saldo a valor flotante ya que tiene un $
      const str = total_acumulado.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
        if(dato.entrada_salida=="Entrada"){
          total_acumulado = formatter.format(+nuevaStr4 + +dato.cantidad);
          console.log(nuevaStr4 + dato.cantidad)
        }
        if(dato.entrada_salida=="Salida"){
          total_acumulado = formatter.format(nuevaStr4 - dato.cantidad);
        }
    })
    //Se ocupa este timeout para que muestre el total
    await timeout(500);
    setTotal(total_acumulado);
    console.log(total_acumulado)
  
    //Guardar_total_gastos(total_acumulado,Nombre_de_boton)
  }

//Con esto se traen todas las afectaciones fiscales actuales
const [Afectaciones_fiscales_DB, setAfectaciones_fiscales_DB] = React.useState([]);
const [AuxiliarBancoDDLS, setAuxiliarBancoDDLS] = React.useState(false);

async function traer_afectaciones_fiscales() {
  console.log(Nombre_de_boton);
  await getProductos_afectaciones_fiscales().then((FiscalesDB) => {
    setAfectaciones_fiscales_DB(FiscalesDB);
    if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
      setAuxiliarBancoDDLS(true);
      console.log("Hola")
    }
   });
 }

//Traer productos del almacen campo
const [Cantidad_actual, setCantidad_actual] = React.useState(0);

  async function actualizar() {
    console.log(Nombre_de_boton)
    await getProductos_tablas(Nombre_de_boton).then((productos) => {
      setTablasdata(productos);   
      console.log(productos)
      //Esto es para saber cuantos productos tiene la tabla y darselo de nombre
      var valores_productos_concentrados = [];
      Tablasdata.map((dato)=>{
        valores_productos_concentrados.push(dato.nombre)
      })
      var valor_maximo = 0;
      if(valores_productos_concentrados.length==0){
        valor_maximo = 0;
      }else{
        valor_maximo = Math.max(...valores_productos_concentrados)
      }
      
      setCantidad_actual(valor_maximo+1);
      console.log(valores_productos_concentrados);
      console.log(valor_maximo+1);
     });
   }

  async function añadirProductoModal(usuario2, Nombre_de_boton) {
   //Esto sirve para la seleccion de la fecha
   let fecha = Producto_a_editar.fecha;
   let fecha2 = Producto_a_editar.fecha2;
   let fecha3 = Producto_a_editar.fecha3;

    const entrada_salida = Producto_a_editar.entrada_salida;
    const cantidad_total = Producto_a_editar.cantidad_total;
    var entrada = "";
    var salida = "";
    
    if(entrada_salida=="Entrada"){
      entrada = cantidad_total;
    }
    if(entrada_salida=="Salida"){
      salida = cantidad_total;
    }
    const usuario = usuario2.email;
    const nombre = Producto_a_editar.nombre;
    const banco = Nombre_de_boton.nombre_banco;
    const producto = Producto_a_editar.producto;
    const afectacion_si_no = false;
    
    //Agectaciones fiscales y sus cantidades
    var afectaciones_totales = document.getElementById("cantidad_afectaciones").value;
    var total_afectaciones = 0;

    var afectacion_fiscal_1 = "";
    if(afectaciones_totales>=1){afectacion_fiscal_1 = document.getElementById("afectacion_fiscal_1").value;}
    var afectacion_fiscal_2 = "";
    if(afectaciones_totales>=2){afectacion_fiscal_2 = document.getElementById("afectacion_fiscal_2").value;}
    var afectacion_fiscal_3 = "";
    if(afectaciones_totales>=3){afectacion_fiscal_3 = document.getElementById("afectacion_fiscal_3").value;}
    var afectacion_fiscal_4 = "";
    if(afectaciones_totales>=4){afectacion_fiscal_4 = document.getElementById("afectacion_fiscal_4").value;}
    var afectacion_fiscal_5 = "";
    if(afectaciones_totales>=5){afectacion_fiscal_5 = document.getElementById("afectacion_fiscal_5").value;}
    
    var cantidad_1 = "";
    if(afectaciones_totales>=1){
      cantidad_1 = document.getElementById("cantidad_1").value; 
      const str = total_afectaciones.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      total_afectaciones = formatter.format(+nuevaStr4 + +cantidad_1);
      //Calcular de dolar a pesos
      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        cantidad_1 = (document.getElementById("cantidad_1").value) * (document.getElementById("precio_dolar").value); 
      }
    }

    var cantidad_2 = "";
    if(afectaciones_totales>=2){
      cantidad_2 = document.getElementById("cantidad_2").value; 
      const str = total_afectaciones.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      total_afectaciones = formatter.format(+nuevaStr4 + +cantidad_2);
      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        cantidad_2 = (document.getElementById("cantidad_2").value) * (document.getElementById("precio_dolar").value); 
      }
    }

    var cantidad_3 = "";
    if(afectaciones_totales>=3){
      cantidad_3 = document.getElementById("cantidad_3").value; 
      const str = total_afectaciones.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      total_afectaciones = formatter.format(+nuevaStr4 + +cantidad_3);
      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        cantidad_3 = (document.getElementById("cantidad_3").value) * (document.getElementById("precio_dolar").value); 
      }
    }
    var cantidad_4 = "";
    if(afectaciones_totales>=4){
      cantidad_4 = document.getElementById("cantidad_4").value; 
      const str = total_afectaciones.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      total_afectaciones = formatter.format(+nuevaStr4 + +cantidad_4);
      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        cantidad_4 = (document.getElementById("cantidad_4").value) * (document.getElementById("precio_dolar").value); 
      }
    }

    var cantidad_5 = "";
    if(afectaciones_totales>=5){
      cantidad_5 = document.getElementById("cantidad_5").value; 
      const str = total_afectaciones.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      total_afectaciones = formatter.format(+nuevaStr4 + +cantidad_5);
      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        cantidad_5 = (document.getElementById("cantidad_5").value) * (document.getElementById("precio_dolar").value); 
      }
    }

    // enviar informacion a firebase
    const infoProducto = { fecha, fecha2, usuario, afectacion_fiscal_1, 
      afectacion_fiscal_2, afectacion_fiscal_3, afectacion_fiscal_4, afectacion_fiscal_5,
      cantidad_1, cantidad_2, cantidad_3, cantidad_4, cantidad_5,
       entrada_salida, nombre, producto, entrada, salida, banco, cantidad_total, fecha3, afectacion_si_no};


  const str = total_afectaciones.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  var nuevaStr4 = nuevaStr3.replace(",", "");

    if(nuevaStr4==cantidad_total){

      if(Nombre_de_boton.nombre_banco=="BANCOMER DLLS"){
        if((document.getElementById("precio_dolar").value) == ""){
          Swal.fire({
            icon: 'error',
            text: "El precio del dolar no se ha ingresado",
            footer: '¿Que hacer? <br/> Se deben agregar el precio del dolar actual.'
            
          })
          //Con este else verifico si es bancomer DLLS que la cantidad a ingresar del precio dolar no se deje en blanco
        }else{
          añadir_productos_tablas(infoProducto, Nombre_de_boton, Cantidad_actual );
          // cerrar modal
          await timeout(500);
          actualizar2()
          setModal_añadir_producto(false);
        }
        //Con este else verifico si esta activo el boton bancomer DLLS 
      }else{
        añadir_productos_tablas(infoProducto, Nombre_de_boton, Cantidad_actual );
        // cerrar modal
        await timeout(500);
        actualizar2()
        setModal_añadir_producto(false);
      }
    //Con este else verifico que la cantidad ingresada sea a misma que se consumio en las afectaciones  
    }else{
      Swal.fire({
        icon: 'error',
        text: "La cantidad total del concepto ingresada no es igual a la suma de la cantidad de las afectaciones",
        footer: '¿Que hacer? <br/> Se deben agregar valores de cantidades de las afectaciones tales que al sumarlas sean igual a la cantidad cantidad total del concepto ingresado.' + nuevaStr4 +' '+ cantidad_total
        
      })
    }
    
  }

  React.useEffect(() => {
    traer_afectaciones_fiscales();
    actualizar();
    console.log(Producto_a_editar)
  }, []); 

  return (
    <Modal show={Modal_añadir_producto} onHide={() => setModal_añadir_producto(false)}>
      <Modal.Header>
        <Modal.Title>Editar afectaciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
{/*           <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
          <br/> 
          <Form.Control
              style={{ fontSize: "90%" }}
              id="producto"
              placeholder="Ingresar nombre del concepto"
              type="text"
              className="mb-1"
            />
            <Form.Control
              style={{ fontSize: "90%" }}
              id="cantidad_total"
              placeholder="Ingresar cantidad total del concepto"
              type="number"
              className="mb-1"
            /> */}
            {AuxiliarBancoDDLS && (
              <Form.Control
              style={{ fontSize: "90%" }}
              id="precio_dolar"
              placeholder="Ingresar precio del dolar"
              type="number"
              className="mb-1"
              />
            )}
            <br/>  
{/*             <h6>Seleccionar si es entrada o salida</h6>
              <select id="entrada_salida" type="text">
                 <option >Entrada</option>
                 <option >Salida</option>
                </select>
            <br/> */}
            <h6>Ingresar cantidad de afectaciones</h6>
            <Form.Control
              style={{ fontSize: "90%", width: "15%" }}
              id="cantidad_afectaciones"
              placeholder=""
              type="number"
              className="mb-1"
              min="1"
              max="5"
              onChange={()=>{afectaciones_total()}}
            />
            {afectaciones_valor1 && (
              <div>
                  <h6>Seleccionar afectación fiscal</h6>
                  <select 
                    id="afectacion_fiscal_1"
                    type="text"
                    >
                      {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                          <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                      ))}
                    </select>
                  
                <Form.Control
                  id="cantidad_1"
                  placeholder="Ingresar la cantidad"
                  type="number"
                  className="mb-1"
                
                />
              </div>
            )}
             {afectaciones_valor2 && (
              <div>
                  <h6>Seleccionar afectación fiscal</h6>
                  <select 
                    id="afectacion_fiscal_2"
                    type="text"
                    >
                      {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                          <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                      ))}
                    </select>
                  
                <Form.Control
                  id="cantidad_2"
                  placeholder="Ingresar la cantidad"
                  type="number"
                  className="mb-1"
                  
                />
              </div>
            )}
             {afectaciones_valor3 && (
              <div>
                  <h6>Seleccionar afectación fiscal</h6>
                  <select 
                    id="afectacion_fiscal_3"
                    type="text"
                    >
                      {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                          <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                      ))}
                    </select>
                  
                <Form.Control
                  id="cantidad_3"
                  placeholder="Ingresar la cantidad"
                  type="number"
                  className="mb-1"
                 
                />
              </div>
            )}
             {afectaciones_valor4 && (
              <div>
                  <h6>Seleccionar afectación fiscal</h6>
                  <select 
                    id="afectacion_fiscal_4"
                    type="text"
                    >
                      {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                          <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                      ))}
                    </select>
                  
                <Form.Control
                  id="cantidad_4"
                  placeholder="Ingresar la cantidad"
                  type="number"
                  className="mb-1"
                  
                />
              </div>
            )}
             {afectaciones_valor5 && (
              <div>
                  <h6>Seleccionar afectación fiscal</h6>
                  <select 
                    id="afectacion_fiscal_5"
                    type="text"
                    >
                      {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                          <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                      ))}
                    </select>
                  
                <Form.Control
                  id="cantidad_5"
                  placeholder="Ingresar la cantidad"
                  type="number"
                  className="mb-1"
                  
                />
              </div>
            )}
         {/*  {array_auxiliar_afectacion.map((datos,index)=>( 
                      <div>
                          <h6>Seleccionar afectación fiscal</h6>
                          <select key={index+1}
                            id="afectacion_fiscal"
                            type="text"
                            >
                              {Afectaciones_fiscales_DB.map((datos2,index)=>( 
                                  <option key={index}>{datos2.nombre_afectacion_fiscal}</option>
                              ))}
                            </select>
                          
                        <Form.Control
                          id="cantidad"
                          placeholder="Ingresar la cantidad"
                          type="number"
                          className="mb-1"
                          key={index+2}
                        />
                      </div>
                  ))} */}

            
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_añadir_producto(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=> añadirProductoModal(usuario2, Nombre_de_boton)}>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_tablas;
