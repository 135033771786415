import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

async function editarCantidad_cajas({ 
  n_nuevaStr4,
  carton_seleccionado,
   }) {

  console.log(carton_seleccionado)
  console.log(n_nuevaStr4)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Almacen_empaque/Producto"+" "+"terminado/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, (carton_seleccionado).toString());
  await updateDoc(docRef, {
    cantidad: n_nuevaStr4
  })
}

export default editarCantidad_cajas;
