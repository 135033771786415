import React from "react";

import { Modal, Stack, Form, Button } from "react-bootstrap";
import getAllsecciones from "../../functions/Campo_secciones/getAllsecciones";
import editarProducto from "../../functions/Campo_secciones/editarProducto";

function ModalEditar({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
}) {

  const [cultivos1, setcultivos1] = React.useState(false);
  const [cultivos2, setcultivos2] = React.useState(false);

  async function actualizar() {
    await getAllsecciones().then((productos) => {
      console.log(productos)
      actualizarEstadoProductos(productos);    
     });
   }

  //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }


  function Cultivos_cantidad(){
    const cantidad=document.getElementById("cantidad_cultivos").value;
    {
      if(cantidad == 0){
        setcultivos1(false);
        setcultivos2(false);
      }
      if(cantidad == 1){
        setcultivos1(true);
        setcultivos2(false);
      }
      if(cantidad == 2){
        setcultivos1(true);
        setcultivos2(true);
      }
    }
  }


  async function editarProductoModal(productoEditar) {
    //obtener infor del formulario

    let producto1 = productoEditar.producto1;
    let producto2 = productoEditar.producto2;
    let producto1_fecha = productoEditar.producto1_fecha;
    let producto2_fecha = productoEditar.producto2_fecha;

    const nombre = productoEstado?.nombre
    const cantidad_cultivos = document.getElementById("cantidad_cultivos").value;
    {if(cantidad_cultivos == 0)
      {
    producto1 = "NA";
    producto2 = "NA";
    producto1_fecha = "NA";
    producto2_fecha = "NA";
      }}
    {if(cantidad_cultivos == 1)
      {
    producto1 = document.getElementById("producto1").value;
    producto1_fecha = document.getElementById("producto1_fecha").value;
      }}
    {if(cantidad_cultivos == 2)
      {
    producto1 = document.getElementById("producto1").value;
    producto1_fecha = document.getElementById("producto1_fecha").value;
    producto2 = document.getElementById("producto2").value;
    producto2_fecha = document.getElementById("producto2_fecha").value;
      }}
    
    
    // enviar informacion a firebase
    const infoProducto = { nombre, cantidad_cultivos, producto1, producto1_fecha, producto2, producto2_fecha  };
    editarProducto(infoProducto, usuario.email);
    // cerrar modal
    setProductoEditar(null);
    await timeout(500);
    actualizar();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar tabla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            {/* <Form.Control
              id="cantidad_cultivos"
              placeholder="Ingresa cantidad de cultivos en la tabla"
              type="text"
              className="mb-1"
              value={productoEstado?.cantidad_cultivos}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  cantidad_cultivos: e.target.value,
                })
              }
            /> */}

            <br/>
            <h6>Seleccionar cantidad de cultivos en la tabla</h6>
            <select id="cantidad_cultivos" type="text" onChange={(e) =>
                Cultivos_cantidad()
              }>
              <option>0</option>
              <option>1</option>
              <option>2</option>
            </select>
            <br/>

            {cultivos1 &&(
              <Form.Control
              id="producto1"
              placeholder="Ingresa producto en cultivo 1"
              type="text"
              className="mb-1"
              value={productoEstado?.producto1}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  producto1: e.target.value,
                })
              }
            />
            )}

            {cultivos1 &&(
              <Form.Control
              id="producto1_fecha"
              placeholder="Ingresa fecha de inicio del cultivo 1"
              type="text"
              className="mb-1"
              value={productoEstado?.producto1_fecha}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  producto1_fecha: e.target.value,
                })
              }
            />
              )}
            
           {cultivos2 && (
            <Form.Control
            id="producto2"
            placeholder="Ingresa producto en cultivo 2"
            type="text"
            className="mb-1"
            value={productoEstado?.producto2}
            onChange={(e) =>
              setProductoEstado({
                ...productoEstado,
                producto2: e.target.value,
              })
            }
          />
            )}

           {cultivos2 && (
           <Form.Control
           id="producto2_fecha"
           placeholder="Ingresa fecha de inicio del cultivo 2"
           type="text"
           className="mb-1"
           value={productoEstado?.producto2_fecha}
           onChange={(e) =>
             setProductoEstado({
               ...productoEstado,
               producto2_fecha: e.target.value,
             })
           }
         />
            )}
            
          </Stack>
        </Form>
        <Button variant="primary" onClick={()=>{
          editarProductoModal(productoEditar)}}>
          Editar
        </Button> 
      </Modal.Body>

      <Modal.Footer>         
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar;
