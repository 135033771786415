import React from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import getAllusuario from "../functions/Usuarios/getAllusuario";
//modales
import ModalAñadir from "../components/Usuarios/ModalAñadir";
import ModalEliminar from "../components/Usuarios/ModalEliminar";
import ModalEditar_usuarios from "../components/Usuarios/ModalEditar_usuarios";



function Home_crear_usuario({ usuario }) {
  
  const [Modal_Añadir, setModalAñadir] = React.useState(false);
  const [Modal_Eliminar, setModal_Eliminar] = React.useState(false);
  const [Modal_Editar, setModalEditar] = React.useState(false);

  const [UsuarioEditar, setUsuarioEditar] = React.useState([]);
  const [Usuarios, setUsuarios] = React.useState([]);

  //Traer todos los usuarios
  async function Traer_usuarios() {
    await getAllusuario().then((usuariosBD) => {
      console.log(usuariosBD);
      setUsuarios(usuariosBD);
     });
   }

   React.useEffect(() => {
    Traer_usuarios();
  }, []);

  return (
  <div>
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
      <ModalAñadir
        Modal_Añadir={Modal_Añadir}
        setModalAñadir={setModalAñadir}
        Traer_usuarios={Traer_usuarios}

      />
      {Modal_Eliminar &&
        <ModalEliminar
          Modal_Eliminar={Modal_Eliminar}
          setModal_Eliminar={setModal_Eliminar}

        />}

      {Modal_Editar &&
        <ModalEditar_usuarios
          Modal_Editar={Modal_Editar}
          setModalEditar={setModalEditar}
          UsuarioEditar={UsuarioEditar}
          Traer_usuarios={Traer_usuarios}
        />}  
        
                <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20  }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname}</p>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
                 <Button
                  style={{ fontSize: 40  }}
                    variant="dark"
                    onClick={() => {
                      setModalAñadir(true);
                    }}
                  >
                    Añadir usuario nuevo
                  </Button>

                  <br/>
                  <br/>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Nivel de acceso</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Usuarios &&
                      Usuarios.map((valor, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{valor.correo}</td>
                          <td>{valor.rol}</td>
                          <td>
                            <Button
                              variant="dark"
                              onClick={() => {
                                setUsuarioEditar({ ...valor });
                                setModalEditar(true);
                              }}
                            >
                              Editar
                            </Button>
                            {/* <Button
                              variant="danger"
                              onClick={() => {
                                eliminarProductoHome(producto, usuario.email, pagina, start).then(
                                  (confirmacion) => {
                                    actualizarEstadoProductos(pagina, start);
                                  }
                                );
                              }}
                            >
                              Eliminar
                            </Button>
                            <Button
                              variant="success"
                              onClick={() => {
                                Historial({ ...producto });
                              }}
                            >
                              Movimientos
                            </Button> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
      
    </Container>
    </div> 
  );
}

export default Home_crear_usuario;