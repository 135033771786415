import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllpagos(producto) {
    const PagosDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Proveedores_pagos/"+producto.nombre+"/Productos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PagosDB.push(doc.data());
    });
    return PagosDB;
    
}
