import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllordenes_compra() {
    const OrdenesDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Ordenes/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      OrdenesDB.push(doc.data());
    });
    return OrdenesDB;
    
}
