import React from "react";
import { Modal, Button, Table, Stack} from "react-bootstrap";
import "../../../../index.css"
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import getProductos_tablas from "../../functions/Bancos/getProductos_tablas";
import getProductos_afectaciones_fiscales from "../../functions/Bancos/getProductos_afectaciones_fiscales";
import getProductos_afectaciones from "../../functions/Bancos/getProductos_afectaciones";
import añadir_total_gastos from "../../functions/Bancos/añadir_total_gastos";
import Reiniciar_valores_afectaciones from "../../functions/Bancos/Reiniciar_valores_afectaciones";
import Swal from "sweetalert2";

function Modal_Afectaciones_concentrado({
  Modal_Concentrado,
  setModal_Concentrado,
  Botonesdata,
}) {

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

   //Eliminar con sweetalert2
  function eliminar_sweetalert(producto) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.Producto_retirado+'"',
      text: "Esta acción no se puede restablecer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado.',
          'success'
        )
        /* Traer_productos(producto, pagina, start).then(
          (confirmacion) => {
            Historial2();
          }
        ); */
      }
    })
  }

  //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

//-------------------------------------------------Tablas Botones------------------------------------------------------------------------------------
const [Tablasdata, setTablasdata] = React.useState([]);
const [Boton_seleccionado, setBoton_seleccionado] = React.useState([]);
const [acumulado_total, setacumulado_total] = React.useState("");
const [Afectaciones_por_boton, setAfectaciones_por_boton] = React.useState(false);

async function Seleccionar_afectacion(datos){
  setBoton_seleccionado(datos);
  var Nombre_de_boton = datos;
  await getProductos_afectaciones(Nombre_de_boton).then((AfectacionesDB) => {
      console.log(AfectacionesDB) 
    var productos_filtrados = [];
    var Total_acumulado_afectacion = 0;

      AfectacionesDB.map((valor)=>{
        const str = Total_acumulado_afectacion.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        var nuevaStr4 = nuevaStr3.replace(",", "");

        if(valor.afectacion_fiscal_1==Nombre_de_boton.nombre_afectacion_fiscal){
           productos_filtrados.push({fecha: valor.fecha, fecha2: valor.fecha2, concepto: valor.producto, banco: valor.banco, cantidad: valor.cantidad_1, entrada_salida: valor.entrada_salida})
           if(valor.entrada_salida=="Entrada"){
            Total_acumulado_afectacion = formatter.format(+nuevaStr4 + +valor.cantidad_1);
           }else{Total_acumulado_afectacion = formatter.format(nuevaStr4 - valor.cantidad_1);}
        }

        if(valor.afectacion_fiscal_2==Nombre_de_boton.nombre_afectacion_fiscal){
           productos_filtrados.push({fecha: valor.fecha, fecha2: valor.fecha2, concepto: valor.producto, banco: valor.banco, cantidad: valor.cantidad_2, entrada_salida: valor.entrada_salida})
           if(valor.entrada_salida=="Entrada"){
            Total_acumulado_afectacion = formatter.format(+nuevaStr4 + +valor.cantidad_2);
           }else{Total_acumulado_afectacion = formatter.format(nuevaStr4 - valor.cantidad_2);}
        }

        if(valor.afectacion_fiscal_3==Nombre_de_boton.nombre_afectacion_fiscal){
           productos_filtrados.push({fecha: valor.fecha, fecha2: valor.fecha2, concepto: valor.producto, banco: valor.banco, cantidad: valor.cantidad_3, entrada_salida: valor.entrada_salida})
          if(valor.entrada_salida=="Entrada"){
            Total_acumulado_afectacion = formatter.format(+nuevaStr4 + +valor.cantidad_3);
           }else{Total_acumulado_afectacion = formatter.format(nuevaStr4 - valor.cantidad_3);}
        }

        if(valor.afectacion_fiscal_4==Nombre_de_boton.nombre_afectacion_fiscal){
           productos_filtrados.push({fecha: valor.fecha, fecha2: valor.fecha2, concepto: valor.producto, banco: valor.banco, cantidad: valor.cantidad_4, entrada_salida: valor.entrada_salida})
          if(valor.entrada_salida=="Entrada"){
            Total_acumulado_afectacion = formatter.format(+nuevaStr4 + +valor.cantidad_4);
           }else{Total_acumulado_afectacion = formatter.format(nuevaStr4 - valor.cantidad_4);}
        }

        if(valor.afectacion_fiscal_5==Nombre_de_boton.nombre_afectacion_fiscal){
           productos_filtrados.push({fecha: valor.fecha, fecha2: valor.fecha2, concepto: valor.producto, banco: valor.banco, cantidad: valor.cantidad_5, entrada_salida: valor.entrada_salida})
          if(valor.entrada_salida=="Entrada"){
            Total_acumulado_afectacion = formatter.format(+nuevaStr4 + +valor.cantidad_5);
           }else{Total_acumulado_afectacion = formatter.format(nuevaStr4 - valor.cantidad_5);} 
        }
      })
      console.log(productos_filtrados);
      console.log(Total_acumulado_afectacion);
      //Se ordena de mayor a menor por fecha
      productos_filtrados.sort((a,b)=> (b.fecha2) - (a.fecha2));
      setacumulado_total(Total_acumulado_afectacion);
      setTablasdata(productos_filtrados);
      setAfectaciones_por_boton(true);
      /* window.scroll({
        top: 100,
       
      }) */

 });
}



//enviar
function Guardar_total_gastos(acumulado_total,Boton_seleccionado){
  var Nombre_de_boton = Boton_seleccionado;
  const dato = acumulado_total;
    var costo= {
      valor: dato,
      nombre_afectacion_fiscal: Nombre_de_boton.nombre_afectacion_fiscal,
      };
  añadir_total_gastos(acumulado_total,costo,Nombre_de_boton);
  traer_fiscales();
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'El total ha sido guardado correctamente.',
    showConfirmButton: false,
    timer: 1500
  })
}
//--------------------------------------------------------------------------------------------------------------------------------------------------
function Reiniciar_valores(){
  Fiscales_DB.map((valor)=>{
    Reiniciar_valores_afectaciones(valor);
  })
  traer_fiscales();
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Los valores se han reiniciado a 0 correctamente.',
    showConfirmButton: false,
    timer: 1500
  })
}

  const [Fiscales_DB, setFiscales_DB] = React.useState([]);

  async function traer_fiscales(){
    await getProductos_afectaciones_fiscales().then((FiscalesDB) => {
        console.log(FiscalesDB) 
        setFiscales_DB(FiscalesDB);
        //actualizar_productos_tablas(Botonesdata,FiscalesDB);
   });
  }

  function salir(){
    setModal_Concentrado(false)
  }

  React.useEffect(() => {
    traer_fiscales();
    setAfectaciones_por_boton(false);
  }, []);

  return (
   <div >
    {Afectaciones_por_boton?
      <Modal id="Modal_Logs" className="container-fluid" show={Afectaciones_por_boton} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Datos de la afectación {Boton_seleccionado.nombre_afectacion_fiscal}</Modal.Title>
        <hr />
        <Modal.Title style={{ fontSize: 14 }}>El total acumulado es {acumulado_total}</Modal.Title>
        <Modal.Title style={{ fontSize: 14 }}>
          <Button variant="success" onClick={() => {
                      Guardar_total_gastos(acumulado_total,Boton_seleccionado);
                    }}>
          Guardar
        </Button></Modal.Title>
        <hr />
        
        
        {/* {valor?
        null : 
        <ReactHTMLTableToExcel
        id="botonExportarExcel"
        className="btn btn-success"
        table="Tabla_logs"
        filename={Datos[0].Producto_retirado}
        sheet="Movimientos"
        buttonText="Descargar Excel"
        />} */}
        
        <Button variant="secondary" onClick={() => {
                      setAfectaciones_por_boton(false);
                    }}>
          Regresar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
        <thead>
             <tr >
             <th>Fecha</th>
             <th>Concepto</th>
             <th>Banco</th> 
             <th>Entrada o salida</th> 
             <th>Cantidad</th> 
                </tr>
            </thead>
        <tbody>
        {Tablasdata &&
            Tablasdata.map((datos, index) => (
              <tr key={index}>
                <td>{datos.fecha}</td>
                <td>{datos.concepto}</td>
                <td>{datos.banco}</td>
                <td>{datos.entrada_salida}</td>
                <td>${datos.cantidad}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      </Modal.Body>
    </Modal>
    :
    <Modal id="Modal_Logs" className="container-fluid" show={!Afectaciones_por_boton} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Historico de movimientos</Modal.Title>
        <hr />
        <Modal.Title style={{ fontSize: 14 }}>
          <Button variant="success" onClick={() => {
                      Reiniciar_valores();
                    }}>
          Reiniciar valores
        </Button></Modal.Title>
        <hr />
        {/* {valor?
        null : 
        <ReactHTMLTableToExcel
        id="botonExportarExcel"
        className="btn btn-success"
        table="Tabla_logs"
        filename={Datos[0].Producto_retirado}
        sheet="Movimientos"
        buttonText="Descargar Excel"
        />} */}
        
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
        <thead>
             <tr >
             <th>Nombre</th>
             <th>Total</th>  
                </tr>
            </thead>
        <tbody>
        {Fiscales_DB &&
            Fiscales_DB.map((valor_fiscal, index) => (
              <tr key={index}>
                <td>{valor_fiscal.nombre_afectacion_fiscal}</td>
                <td>{valor_fiscal.valor}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      </Modal.Body>

      {/* BOTONES */}
    <div> 
        
            <div id="Lista_empaque" style={{ background: "white" }}>        
                {Fiscales_DB.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" style={{ fontSize: 14, width: "60%", height: "60%" }} onClick={() => Seleccionar_afectacion((datos))} key={index}>{datos.nombre_afectacion_fiscal}</button>
                ))}
            </div>
      </div> 

    </Modal>

    }
    
  </div> 
  );
}

export default Modal_Afectaciones_concentrado;
