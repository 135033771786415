import { Modal, Stack, Form, Button } from "react-bootstrap";
import getAllproveedores from "../functions/Proveedores/getAllproveedores";
import añadirProducto from "../functions/añadirProducto";
import Swal from "sweetalert2";

function ModalAñadir_proveedores({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
}) {

  async function traer_proveedores() {
    await getAllproveedores().then((ProveedoresBD) => {   
    const nombre2 = document.getElementById("nombre").value;
    //la función trim() elimina los espacios al inicio y final del string
    const nombre = nombre2.trim();
    console.log(nombre);
    var auxiliar = false;
      ProveedoresBD.map((dato)=>{
        if(dato.nombre==nombre){
          Swal.fire({
            icon: 'error',
            text: "El nombre que se quiere asignar al nuevo proveedor ya existe.",
            footer: '¿Que hacer? <br/> Se debe elegir un nombre de proveedor que no exista ya en a base de datos, para comprobar cuales existen revisar la lista de proveedores.'
          })
          auxiliar = true;
        }
      })
      if(auxiliar){
        console.log("Error")
      }else{
        añadirProductoModal();
        console.log("Añadir")
      }
      
     });
   }



  function añadirProductoModal() {
    //obtener infor del formulario
    const nombre2 = document.getElementById("nombre").value;
    //la función trim() elimina los espacios al inicio y final del string
    const nombre = nombre2.trim();
    const telefono = document.getElementById("telefono").value;
    const direccion = document.getElementById("direccion").value;
    var Saldo_MXN = "$0";
    var Saldo_Dolares = "$0";
    // enviar informacion a firebase
    const infoProducto = { nombre, telefono, direccion, Saldo_MXN, Saldo_Dolares };
    añadirProducto(infoProducto, usuario.email);
    // cerrar modal
    actualizarEstadoProductos();
    setIsModalAñadir(false); 
  }

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir proveedor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Agregar nombre"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="telefono"
              placeholder="Agregar telefono o celular"
              type="text"
              className="mb-1"
            />
            <Form.Control
              id="direccion"
              placeholder="Agregar email"
              type="text"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={traer_proveedores}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_proveedores;