import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs} from "firebase/firestore";
const db = getFirestore(firebaseApp);

    

export default async function getProductos_tablas(Nombre_de_boton) {
    const TablasDB =  [];
    console.log(Nombre_de_boton.nombre_banco);
    const collectionRef = collection(db, "Bancos/Bancos/"+Nombre_de_boton.nombre_banco+"/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      TablasDB.push(doc.data());
    });
    return TablasDB;
}