import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto_orden from "../functions/añadirProducto_orden";
import filtrarDatosProducto_orden from "../functions/filtrarDatosProducto_orden";
import React from "react";
import getProductos from "../functions/getProductos";
import getProductos2 from "../functions/getProductos2";
import añadirProducto_gastos from "../functions/Ordenes_compra/añadirProducto_gastos";
import añadirProducto_almacen_campo from "../functions/Ordenes_compra/añadirProducto_almacen_campo";
import añadirProducto_almacen_empaque from "../functions/Ordenes_compra/añadirProducto_almacen_empaque";
import getProductos_tablas from "../functions/Ordenes_compra/getProductos_tablas";
import getProductos_orden from "../functions/getProductos_orden";
import añadirTotal_orden from "../functions/Ordenes_compra/añadirTotal_orden";
import getOrden_seleccionada from "../functions/Ordenes_compra/getOrden_seleccionada";
import escribirLog_agregar_producto_desde_ordenesCompra from "../functions/Cultivo_en_proceso/escribirLog_agregar_producto_desde_ordenesCompra";
import escribirLog_agregar_producto_empaque from "../functions/Produccion/escribirLog_agregar_producto_empaque";
import getProductos_almacenes from "../functions/Ordenes_compra/getProductos_almacenes";
import editarSaldo_proveedores from "../functions/Ordenes_compra/editarSaldo_proveedores";
import getProveedor from "../functions/Ordenes_compra/getProveedor";
import Swal from "sweetalert2";
import {DatePicker} from "@material-ui/pickers";
 
function ModalAñadir_ordenes({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario2,
  Enviarproducto,
  productofolio,
  productocargo,
  setProductos,
}) {

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

 async function actualizar2() {
    await getProductos_orden(productofolio).then((productos) => {
      setProductos(productos);   
      console.log(productos)   
    });
  }
  

  //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
     return new Promise(res => setTimeout(res, delay));
  }


  //Esto es para traer los productos actuales en las tablas de los gastos y sumarle 1
  const [Cantidad_actual, setCantidad_actual] = React.useState(0);
  async function actualizar() {
    console.log(productocargo)
    await getProductos_tablas(productocargo).then((TablasDB) => {  
      console.log(TablasDB);
      //Esto es para saber cuantos productos tiene la tabla y darselo de nombre
      var valores_productos_concentrados = [];
      TablasDB.map((dato)=>{
        valores_productos_concentrados.push(dato.nombre)
      })
      var valor_maximo = 0;
      if(valores_productos_concentrados.length==0){
        valor_maximo = 0;
      }else{
        valor_maximo = Math.max(...valores_productos_concentrados)
      }
      setCantidad_actual(valor_maximo+1);
      console.log(valor_maximo+1);
     });
   }


  //Traer productos de base de datos
  const [Datosproducto, setDatosproducto] = React.useState([]);
  const [Datos_persona, setDatos_persona] = React.useState([]);

  async function productos_basededatos(){
    await getProductos().then((productosDB) => {
     var productos_filtrados =[]; 
     console.log(Enviarproducto.moneda)
      productosDB.map((dato)=>{
        //Este filtra por moneda
        if(dato.moneda==Enviarproducto.moneda){
          productos_filtrados.push(dato);
        }
      })
      setDatosproducto(productos_filtrados);
      console.log(productos_filtrados)
     //Esto es para agregar el nombre del solicitante al agregarlo en el concentrado de personal 
      if(productocargo=="Personal"){
        setDatos_persona(Enviarproducto.solicitante);
      }else{setDatos_persona("NA")}

    });
  }

  //aqui se trea la informacion del producto seleccionado
  async function productos_basededatos2(valor){
    const nombreproducto = document.getElementById("producto").value;
    await getProductos2(nombreproducto).then((productosDB2) => {
      const precio2= productosDB2.map(cantidad=> cantidad.precio)
      const unidad2= productosDB2.map(cantidad=> cantidad.unidad)
      const categoria2= productosDB2.map(cantidad=> cantidad.categoria)
      const lleva_iva2= productosDB2.map(cantidad=> cantidad.lleva_iva)
      console.log(productosDB2);
      console.log(precio2,unidad2,categoria2);
      calcular_total_producto_agregado({precio2, unidad2, categoria2, lleva_iva2});
    });
  }

//Esto es para agregar el total del producto añadido al total de la orden de compra
async function calcular_total_producto_agregado({precio2, unidad2, categoria2, lleva_iva2}){
  console.log(productofolio);
  await getOrden_seleccionada(productofolio).then((OrdenesDB) => {
  var orden_seleccionada_datos = [];
        OrdenesDB.map((orden)=>{
          if(orden.nombre==productofolio){
            orden_seleccionada_datos.push(orden.total_orden);
          }
        }); //Fin map
  console.log(orden_seleccionada_datos);
  console.log((orden_seleccionada_datos[0]));
  
  //Este es el total que tenia la orden seleccionada antes de agregarle el producto 
  var total_orden_seleccionada_actual = orden_seleccionada_datos[0];
  const str = total_orden_seleccionada_actual.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  const nuevaStr4 = nuevaStr3.replace(",", "");

  const cantidad = document.getElementById("cantidad").value;
  var total_con_iva2 = 0;
  var iva2 = 0;
  if(lleva_iva2=="Si"){
    var auxiliar = parseFloat(precio2) * parseFloat(cantidad);
    iva2 = (auxiliar * 0.16).toFixed(2);
    var auxiliar10000 = formatter.format(+auxiliar + +iva2);
    const str2 = auxiliar10000.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const nuevaStr2 = str2.replace("$", "");
    const nuevaStr22 = nuevaStr2.replace(",", "");
    const nuevaStr33 = nuevaStr22.replace(",", "");
    const nuevaStr44 = nuevaStr33.replace(",", "");
    total_con_iva2 = nuevaStr44;
    //total_con_iva2 = parseFloat(auxiliar) + parseFloat(iva2);
    console.log("Lleva iva");
  }

  if(lleva_iva2=="No"){
    total_con_iva2 = parseFloat(precio2) * parseFloat(cantidad);
    console.log("No lleva iva");
  }

  if(lleva_iva2=="NA"){
    total_con_iva2 = parseFloat(precio2) * parseFloat(cantidad);
    console.log("El iva es NA"); 
  }
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'El producto ha sido agregado correctamente.',
    showConfirmButton: false,
    timer: 1500
  }).then(
    (confirmacion) => {
      añadirProductoModal({precio2, unidad2, categoria2, lleva_iva2, total_con_iva2, nuevaStr4,iva2})
    }
  );
  });
}
  //Contar numero de ordenes (Folio)
  const [productos2, setProductos2] = React.useState([]);

  function actualizarEstadoProductos2() {
    getProductos_orden().then((productos) => {
      setProductos2(productos);
    });
  }

  async function añadirProductoModal({precio2, unidad2, categoria2, lleva_iva2, total_con_iva2, nuevaStr4,iva2}) {
    //obtener infor del formulario
    let date = new Date();
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
    //obtener infor del formulario
    const fecha = fecha_actual;
    const fecha3 = date;
    const fechaOrden = Enviarproducto.fecha;
    //const proveedor = document.getElementById("proveedor").value;
    const producto = document.getElementById("producto").value;
    const cantidad = document.getElementById("cantidad").value;
    const precio = precio2[0];
    const unidad = unidad2[0];
    const subtotal= cantidad * precio;
    const categoria = categoria2[0];
    const usuario = usuario2.email;
    const nombre = Cantidad_actual;
    const total_con_iva = total_con_iva2;
    const lleva_iva = lleva_iva2[0];
    const iva = iva2;
    const agregado_desde_orden_compra = "Si";
    var persona = Datos_persona;
    console.log(Enviarproducto);
    var dato_concentrado_desde_orden = Enviarproducto.proveedor+" #"+productofolio;
    //var dato_concentrado_desde_orden = "Agregado desde la orden de compra con folio #"+productofolio;
    
    //const moneda = document.getElementById("moneda").value;
    // enviar informacion a firebase
    const infoProducto = {fecha3, cantidad, producto, iva, precio, unidad, subtotal, categoria, fecha, usuario, nombre, total_con_iva, lleva_iva, fechaOrden, agregado_desde_orden_compra, persona, dato_concentrado_desde_orden};
    
    //Esto sirve para escribir el total nuevo en la orden seleccionada
    var total_anterior_mas_nuevo = formatter.format(+nuevaStr4 + +total_con_iva);
    console.log(nuevaStr4, total_con_iva, total_anterior_mas_nuevo );
    var total_suma_nuevo_viejo = {
      total_orden: total_anterior_mas_nuevo,
      };

    console.log(total_suma_nuevo_viejo);
    añadirTotal_orden(total_suma_nuevo_viejo, productofolio);
    añadirProducto_orden(infoProducto, usuario2.email, productofolio);
    //se evalua que el cargo no sea almacen campo o empaque para poder añadir en gastos o si es se añade en el almacen seleccionado
    if((productocargo=="Almacen campo") || (productocargo=="Almacen empaque") ){
      const infoProducto2 = infoProducto;
      agregarProducto_almacenes(infoProducto2, categoria2, usuario2, producto, productocargo, productofolio);
      //Esto es para aumentar el valor del credito si es compra con credito al proveedor----------------------
      if(Enviarproducto.tipo_compra=="Credito"){
        if(Enviarproducto.moneda=="Pesos"){
          await getProveedor(Enviarproducto).then((proveedoresDB) => {
            console.log(proveedoresDB);
            if(proveedoresDB.Saldo_MXN==undefined){
              proveedoresDB.Saldo_MXN=0;
            }
            //Con esto convierto el saldo a valor flotante ya que tiene un $
            const str = proveedoresDB.Saldo_MXN.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var nuevaStr4 = nuevaStr3.replace(",", "");

            var Total_saldo = formatter.format(+total_con_iva + +nuevaStr4)
            
            var Total_a_editar= {
              Saldo_MXN: Total_saldo,
              };
            editarSaldo_proveedores(proveedoresDB, Total_a_editar)  
          });
        }

        if(Enviarproducto.moneda=="Dolares"){
          await getProveedor(Enviarproducto).then((proveedoresDB) => {
            console.log(proveedoresDB);
            console.log(proveedoresDB.Saldo_Dolares);
            if(proveedoresDB.Saldo_Dolares==undefined){
              proveedoresDB.Saldo_Dolares=0;
            }
            console.log(proveedoresDB.Saldo_Dolares);
            //Con esto convierto el saldo a valor flotante ya que tiene un $
            const str = proveedoresDB.Saldo_Dolares.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var nuevaStr4 = nuevaStr3.replace(",", "");
  
            var Total_saldo = formatter.format(+total_con_iva + +nuevaStr4)
            
            var Total_a_editar= {
              Saldo_Dolares: Total_saldo,
              };
            editarSaldo_proveedores(proveedoresDB, Total_a_editar)  
          });
        }
   }  
      //------------------------------------------------------------------------------------------------------
    }else{
    //Este es para cuando no es ninguno de los 2 de arriba
    añadirProducto_gastos(infoProducto, productocargo);

   //Este es para aumentar el total del producto agregado al proveedor saldo si es credito
console.log(Enviarproducto.tipo_compra);
   if(Enviarproducto.tipo_compra=="Credito"){
        if(Enviarproducto.moneda=="Pesos"){
          await getProveedor(Enviarproducto).then((proveedoresDB) => {
            console.log(proveedoresDB);
            if(proveedoresDB.Saldo_MXN==undefined){
              proveedoresDB.Saldo_MXN=0;
            }
            //Con esto convierto el saldo a valor flotante ya que tiene un $
            const str = proveedoresDB.Saldo_MXN.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var nuevaStr4 = nuevaStr3.replace(",", "");

            var Total_saldo = formatter.format(+total_con_iva + +nuevaStr4)
            
            var Total_a_editar= {
              Saldo_MXN: Total_saldo,
              };
            editarSaldo_proveedores(proveedoresDB, Total_a_editar)  
          });
        }

        if(Enviarproducto.moneda=="Dolares"){
          await getProveedor(Enviarproducto).then((proveedoresDB) => {
            console.log(proveedoresDB);
            console.log(proveedoresDB.Saldo_Dolares);
            if(proveedoresDB.Saldo_Dolares==undefined){
              proveedoresDB.Saldo_Dolares=0;
            }
            console.log(proveedoresDB.Saldo_Dolares);
            //Con esto convierto el saldo a valor flotante ya que tiene un $
            const str = proveedoresDB.Saldo_Dolares.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var nuevaStr4 = nuevaStr3.replace(",", "");
  
            var Total_saldo = formatter.format(+total_con_iva + +nuevaStr4)
            
            var Total_a_editar= {
              Saldo_Dolares: Total_saldo,
              };
            editarSaldo_proveedores(proveedoresDB, Total_a_editar)  
          });
        }
   }  
      console.log("Concentrado")
    }
    
    // cerrar modal
    await timeout(500);
    actualizar2();
    setIsModalAñadir(false); 
  }


  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());  

//Añadir al almacen de campo---------------------------------------------------------------------------------------------------------
 async function agregarProducto_almacenes(infoProducto2, categoria2, usuario2, producto, productocargo, productofolio) { 
  await getProductos_almacenes(categoria2, productocargo).then((ProductosDB) => {
   var producto_seleccionado_almacen = []; 
    ProductosDB.map((dato)=>{
      if(dato.nombre==producto){
        producto_seleccionado_almacen.push(dato);
      }
    })

    var orden_compra = productofolio;
    //Con esto lo añado en el almacen de campo
    if(productocargo=="Almacen campo"){
      const usuario = usuario2;
      const nombreproducto = producto;
      const valor_cantidad_ingresado = document.getElementById("cantidad").value;

      var auxiliar10001 = formatter.format(+(producto_seleccionado_almacen[0].cantidad) + +(valor_cantidad_ingresado))

      const str = auxiliar10001.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      const cantidad = nuevaStr4;
      //const cantidad = (parseInt(producto_seleccionado_almacen[0].cantidad) + parseInt(valor_cantidad_ingresado)).toString();
      // enviar informacion a firebase
      const infoProducto = {cantidad};
      añadirProducto_almacen_campo(infoProducto, infoProducto2, categoria2);
      var date = fechaSeleccionada;
      escribirLog_agregar_producto_desde_ordenesCompra("Agregar", usuario, valor_cantidad_ingresado, nombreproducto, date, orden_compra);
    }
    //Con esto lo añado en el almacen de empaque
    if(productocargo=="Almacen empaque"){
      const usuario = usuario2;
      const nombreproducto = producto;
      const valor_cantidad_ingresado = document.getElementById("cantidad").value;

      var auxiliar10001 = formatter.format(+(producto_seleccionado_almacen[0].cantidad) + +(valor_cantidad_ingresado));

      const str = auxiliar10001.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      const cantidad = nuevaStr4;

      //const cantidad = (parseInt(producto_seleccionado_almacen[0].cantidad) + parseInt(valor_cantidad_ingresado)).toString();
      // enviar informacion a firebase
      const infoProducto = {cantidad};
      añadirProducto_almacen_empaque(infoProducto, infoProducto2, categoria2);
      var Presentacion = "NA"
      var date = fechaSeleccionada;
      escribirLog_agregar_producto_empaque("Agregar", usuario, valor_cantidad_ingresado, nombreproducto, Presentacion, date, orden_compra);
    }

    

   });

      
}




 
  React.useEffect(() => {
    actualizarEstadoProductos2();
    productos_basededatos();
    actualizar();
    console.log(Enviarproducto);
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Añadir productos a orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>NOTA: Solo aparecen productos segun el tipo de moneda seleccionado al crear la orden ya sea en dolares o pesos.</h6>
            <h6>Seleccionar producto</h6>
            <select id="producto" type="text">
            {Datosproducto.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/> 
            <h6>Fecha final</h6>
                <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
            <br/> 

            <Form.Control
              id="cantidad"
              placeholder="Agregar cantidad"
              type="number"
              className="mb-1"
            />
{/*             {Boton_personal &&(
            <Form.Control
              id="persona"
              placeholder="Ingresar la persona que lo gasto"
              type="text"
              className="mb-1"
            />
          )} */}
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>{productos_basededatos2()}}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_ordenes;