import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_boton_gastos from "../../functions/Gastos/añadir_boton_gastos";
import getCategorias from "../../functions/Productos/getCategorias";

function ModalAñadir({
  ModalAñadir_boton,
  setModalAñadir_boton,
  actualizar_botones,
  Botonesdata,
}) {
  /* async function actualizar(pagina, start) {
    await getAlldata(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);   
       Traer_secciones(productos);
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  } */
const [Categorias_data, setCategorias_data] = React.useState([]);

async function Categorias() {
    await getCategorias().then((CategoriasDB) => {
      setCategorias_data(CategoriasDB);
    });
  }

  async function añadirProductoModal() {
    //obtener infor del formulario
    const valor = document.getElementById("categoria").value;
    // enviar informacion a firebase
    const infoProducto = {valor};
    añadir_boton_gastos(infoProducto);
    // cerrar modal
    actualizar_botones();
    setModalAñadir_boton(false);
  }

  React.useEffect(() => {
    Categorias();
  }, []); 

  return (
    <Modal show={ModalAñadir_boton} onHide={() => setModalAñadir_boton(false)}>
      <Modal.Header>
        <Modal.Title>Nueva categoria, 12 como maximo.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <br/>
            <h4>Seleccionar categoría</h4>
            <select id="categoria" type="text">
            {Categorias_data.map((datos,index)=>( 
                <option key={index}>{datos.categoria}</option>
            ))}
            </select>
            <br/>           
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAñadir_boton(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
