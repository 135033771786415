import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React, {useState} from 'react';

function añadirFolio(folio_editar) {
  console.log(folio_editar)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Ordenes_Folio_nuevo".replace(/ /g, ""))
  
      
  const docRef = doc(collectionRef, "Dato");
  setDoc(docRef, folio_editar);
  console.log(folio_editar)

  return(
        <div>
            
            
          </div>
        )
      
}

export default añadirFolio;
