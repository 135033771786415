import React from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import '../../index.css'
import '../Firebase/views/Login2'
//import  signOut  from '../Firebase/Cerrarsesion';
import Home from '../Firebase/views/Home'

//Esto va debajo del <Navbartrabajo/>
//<button onClick={signOut}>Cerrar seción</button>

const Trabajo = () =>{
    return(
        <div >
            
            <Home/>
            <div id="Trabajo">
               
            </div>
        </div>
    )
}



export default Trabajo