import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import React from "react";
import getAllproducts from "../../functions/Productos/getAllproducts";
import añadir_pallet_nuevo from "../../functions/Produccion/añadir_pallet_nuevo";
import getAllCosecha_pallets from "../../functions/Produccion/getAllCosecha_pallets";
import getAllTamaños_cajas from "../../functions/Produccion/getAllTamaños_cajas";
import getAllEtiquetas_cajas from "../../functions/Produccion/getAllEtiquetas_cajas";
import añadir_Tamaño_cajas from "../../functions/Produccion/añadir_Tamaño_cajas";
import añadir_Etiquetas_cajas from "../../functions/Produccion/añadir_Etiquetas_cajas";
import eliminarTamaño_cajas from "../../functions/Produccion/eliminarTamaño_cajas";
import eliminarEtiqueta_cajas from "../../functions/Produccion/eliminarEtiqueta_cajas";
import eliminarpallets_embarque from "../../functions/Produccion/eliminarpallets_embarque";
import Swal from "sweetalert2";

function ModalPallets({
  PALLETS,
  setPALLETS,
}) {

//Eliminar con sweetalert2 tamaño de las cajas
 function eliminar_sweetalert_tamaño(datos) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el tamaño?  "'+ datos.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El tamaño ha sido eliminado.',
        'success'
      )
      eliminarTamaño_cajas(datos).then(
        (confirmacion) => {
          tamaños_on();
        }
      );
    }
  })
}
//-------------------------------------------
//Eliminar con sweetalert2 tamaño de las cajas
function eliminar_sweetalert_etiqueta(datos) {
  Swal.fire({
    title: '¿Esta seguro de eliminar la etiqueta?  "'+ datos.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'La etiqueta ha sido eliminada.',
        'success'
      )
      eliminarEtiqueta_cajas(datos).then(
        (confirmacion) => {
          etiquetas_on();
        }
      );
    }
  })
}
//-------------------------------------------

//Eliminar con sweetalert2 pallets
function eliminar_sweetalert2(datos) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el pallet?  "'+ datos.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El pallet ha sido eliminado.',
        'success'
      )
      eliminarpallets_embarque(datos).then(
        (confirmacion) => {
          Traer_pallets();
        }
      );
    }
  })
}


  const [Pallets_actuales, setPallets_actuales] = React.useState([]);
  const [Cartones_actuales, setCartones_actuales] = React.useState([]);
  const [InicioPallets, setInicioPallets] = React.useState(false);
  const [Agregar_on, setAgregar_on] = React.useState(false);


  async function Traer_pallets(){
    //console.log(Manifiesto_Seleccionado)
    await getAllproducts().then((SeccionesDB) => {
      var pallets_filtrados = [];
      SeccionesDB.map((dato)=>{
        if(dato.categoria=="Producto terminado"){
          pallets_filtrados.push(dato);
        }
      })

      var cartones_filtrados = [];
      SeccionesDB.map((dato2)=>{
        if(dato2.categoria=="Carton"){
          cartones_filtrados.push(dato2);
        }
      })

      //console.log(pallets_filtrados)
      setCartones_actuales(cartones_filtrados)
      setPallets_actuales(pallets_filtrados);
      setInicioPallets(true);
      setAgregar_on(false);
      setTamañosON(false);
      setEtiquetasON(false);
    })
  }
  
//Tamaños de cajas-------------
const [Tamaños, setTamaños] = React.useState([]);
const [TamañosON, setTamañosON] = React.useState(false);

async function tamaños_on(auxiliar1000){
  await getAllTamaños_cajas().then((TamañosDB) => {
    setTamaños(TamañosDB);
    if(auxiliar1000 == true){

    }else{
      setInicioPallets(false);
      setAgregar_on(false);
      setTamañosON(true);
      setEtiquetasON(false);
    }

  })
}

function agregar_temaño_pallet(){
  const nombre_categoria2 = document.getElementById("tamaño_nombre").value;
  const nombre_categoria = nombre_categoria2.trim();
  var infoproducto = {
    nombre: nombre_categoria
      } 

  añadir_Tamaño_cajas(infoproducto);
  tamaños_on();
  Swal.fire(
    'Agregado!',
    'El tamaño ha sido agregado correctamente.',
    'success'
  )
}

function salir_tamaños(){
  setInicioPallets(true);
  setAgregar_on(false);
  setTamañosON(false);
  setEtiquetasON(false);
}
//-----------------------------

//Etiquetas de cajas-------------
const [Etiquetas, setEtiquetas] = React.useState([]);
const [EtiquetasON, setEtiquetasON] = React.useState(false);

async function etiquetas_on(auxiliar1000){
  await getAllEtiquetas_cajas().then((EtiquetasDB) => {
    setEtiquetas(EtiquetasDB);
    if(auxiliar1000==true){

    }else{
      setInicioPallets(false);
      setAgregar_on(false);
      setEtiquetasON(true);
      setTamañosON(false);
    }

  })
}

function agregar_etiqueta_pallet(){
  const nombre_categoria2 = document.getElementById("etiqueta_nombre").value;
  const nombre_categoria = nombre_categoria2.trim();
  var infoproducto = {
    nombre: nombre_categoria
      } 

  añadir_Etiquetas_cajas(infoproducto);
  etiquetas_on();
  Swal.fire(
    'Agregada!',
    'La etiqueta ha sido agregada correctamente.',
    'success'
  )
}

function salir_etiquetas(){
  setInicioPallets(true);
  setAgregar_on(false);
  setTamañosON(false);
  setEtiquetasON(false);
}
//-----------------------------



const [Cosechas_palletsON, setCosechas_palletsON] = React.useState([]);

async function agregar_pallet(){
  await getAllCosecha_pallets().then((Cosechas_palletsDB) => {
    setCosechas_palletsON(Cosechas_palletsDB);
    var auxiliar1000 = true;
    etiquetas_on(auxiliar1000);
    tamaños_on(auxiliar1000);
    setInicioPallets(false);
    setAgregar_on(true);
    setTamañosON(false);
    setEtiquetasON(false);
  })
}



function añadirProductoModal() {
    //obtener infor del formulario
    const nombre2 = document.getElementById("nombre").value;
    const nombre = nombre2.trim();
    const categoria_pallet = document.getElementById("categoria_pallet").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cajas_por_pallet = document.getElementById("Cajas_pallet").value;
    const kilos_por_cajas_por_pallet = document.getElementById("KG_por_caja").value;
    const carton_utilizado = document.getElementById("caja_presentacion").value;
    const linea_de_produccion = document.getElementById("linea_produccion").value;
    const tamaño = document.getElementById("tamaño").value;
    const etiqueta = document.getElementById("etiqueta").value;
    const tamaño_mas_etiqueta = tamaño+" "+etiqueta;
    var categoria = "Producto terminado";
    var cantidad = 0;
    var lleva_iva = "No";
    var proveedor = "NA";
    var unidad = "Pallet"
    // enviar informacion a firebase
    const infoProducto = { linea_de_produccion, carton_utilizado, nombre, categoria_pallet, precio, moneda, cajas_por_pallet, kilos_por_cajas_por_pallet, 
      categoria, cantidad, lleva_iva, proveedor, unidad, tamaño, etiqueta, tamaño_mas_etiqueta};
    añadir_pallet_nuevo(infoProducto);
    // cerrar modal
    Traer_pallets();
  }

  React.useEffect(() => {
    Traer_pallets();
  }, []);

  return (
  <div>
    <Modal id="Modal_Logs"  show={InicioPallets} onHide={() => null}>
      <Modal.Header>
        <Modal.Title>Pallets actuales</Modal.Title>
        <Button onClick={() => tamaños_on()}>
          Agregar tamaño de caja
        </Button>

        <Button onClick={() => etiquetas_on()}>
          Agregar etiqueta de caja
        </Button>

        <Button onClick={() => agregar_pallet()}>
          Agregar caja nueva
        </Button>

        <Button variant="secondary" onClick={() => setPALLETS(false)}>
          Salir
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
         <thead>
             <tr >
             <th>Nombre</th>
             <th>Moneda</th> 
             <th>Proveedor</th> 
             <th>Precio</th>
             <th>Cajas</th> 
             <th>KG por caja</th>  
             <th>Acciones</th> 
                </tr>
            </thead>
        <tbody>
        {Pallets_actuales &&
            Pallets_actuales.map((datos, index) => (
              <tr key={index}>
                <td>{datos.nombre}</td>
                <td>{datos.moneda}</td>
                <td>{datos.proveedor}</td>
                <td>{datos.precio}</td>
                <td>{datos.cajas_por_pallet}</td>
                <td>{datos.kilos_por_cajas_por_pallet}</td>
                <td>
                <Button variant="danger" onClick={() => eliminar_sweetalert2(datos)}>
                  Eliminar
                </Button>
                </td>
              </tr>
            ))}
        </tbody> 
      </Table>
      </Modal.Body>
    </Modal>
{/* ---------------------------------Agregar Pallet----------------------------------------------------- */}  
<Modal  show={Agregar_on} onHide={() => null}>
      <Modal.Header>
        <Modal.Title>Agregar caja nueva</Modal.Title>
        <Button variant="secondary" onClick={() => salir_tamaños()}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Ingresar nombre de la caja"
              type="text"
              className="mb-1"
            />
            
            <h6 style={{ paddingTop: "2%" }}>Seleccionar categoria</h6>
            <select id="categoria_pallet" type="text" >
            {Cosechas_palletsON.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            

            <h6 style={{ paddingTop: "2%" }}>Seleccionar tamaño</h6>
            <select id="tamaño" type="text" style={{ width: "30%" }} >
            {Tamaños.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>

            
            <h6 style={{ paddingTop: "2%" }}>Seleccionar etiqueta</h6>
            <select id="etiqueta" type="text" style={{ width: "50%" }}>
            {Etiquetas.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            
            <h6 style={{ paddingTop: "2%" }}>Seleccionar línea donde se producira esta caja</h6>
            <select id="linea_produccion" type="text" style={{ width: "40%" }}>
               <option >Tomate</option>
               <option >Calabaza</option>
               <option >Chile</option>
               <option >Tomatillo</option>
            </select>
           
           <h6 style={{ paddingTop: "2%"}}>Seleccionar el tipo de cartón que utiliza esta presentación</h6>
           <select id="caja_presentacion" type="text">
            {Cartones_actuales.map((datos,index)=>( 
                <option key={index+20}>{datos.nombre}</option>
            ))}
            </select>
            <Form.Control
              id="precio"
              placeholder="Ingresar precio mayor o igual a 1"
              type="text"
              className="mb-1"
              style={{ paddingTop: "2%" }}
            />
            <h6 style={{ paddingTop: "2%" }}>Seleccionar moneda</h6>
            <select id="moneda" type="text" > 
              <option >Pesos</option>
              <option >Dolares</option>
              <option >NA</option>
            </select>
            <Form.Control
              id="Cajas_pallet"
              placeholder="Ingresar las cajas que tiene el pallet"
              type="number"
              className="mb-1"
              style={{ paddingTop: "3%" }}
            />
            <Form.Control
              id="KG_por_caja"
              placeholder="Ingresar los KG por caja"
              type="number"
              className="mb-1"
              style={{ paddingTop: "2%" }}
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
         <Button variant="success" onClick={() => añadirProductoModal()}>
          Agregar pallet
        </Button>
      </Modal.Footer>
    </Modal>  
{/* ---------------------------Agregar tamaños de las cajas --------------------------------------------------- */} 
  <Modal id="Modal_Logs"  show={TamañosON} onHide={() => null}>
      <Modal.Header>
        <Modal.Title>Tamaños</Modal.Title>
        <Form.Control
              style={{ width: "40%"}}
              id="tamaño_nombre"
              placeholder="Ingresar nombre del tamaño nuevo"
              type="text"
              className="mb-1"
            />
        <Button style={{ width: "20%" }} onClick={() => agregar_temaño_pallet()}>
          Agregar
        </Button>
        <Button variant="secondary" onClick={() => salir_tamaños()}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
         <thead>
             <tr >
             <th>Nombre</th>
             <th>Acciones</th> 
                </tr>
            </thead>
        <tbody>
        {Tamaños &&
            Tamaños.map((datos, index) => (
              <tr key={index}>
                <td>{datos.nombre}</td>
                <td>
                <Button variant="danger" onClick={() => eliminar_sweetalert_tamaño(datos)}>
                  Eliminar
                </Button>
                </td>
              </tr>
            ))}
        </tbody> 
      </Table>
      </Modal.Body>
    </Modal>   

{/* ---------------------------Agregar etiquetas de las cajas --------------------------------------------------- */} 
  <Modal id="Modal_Logs"  show={EtiquetasON} onHide={() => null}>
      <Modal.Header>
        <Modal.Title>Etiquetas</Modal.Title>
        <Form.Control
              style={{ width: "40%"}}
              id="etiqueta_nombre"
              placeholder="Ingresar nombre de la etiqueta nueva"
              type="text"
              className="mb-1"
            />
        <Button style={{ width: "20%" }} onClick={() => agregar_etiqueta_pallet()}>
          Agregar
        </Button>
        <Button variant="secondary" onClick={() => salir_etiquetas()}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
         <thead>
             <tr >
             <th>Nombre</th>
             <th>Acciones</th> 
                </tr>
            </thead>
        <tbody>
        {Etiquetas &&
            Etiquetas.map((datos, index) => (
              <tr key={index}>
                <td>{datos.nombre}</td>
                <td>
                <Button variant="danger" onClick={() => eliminar_sweetalert_etiqueta(datos)}>
                  Eliminar
                </Button>
                </td>
              </tr>
            ))}
        </tbody> 
      </Table>
      </Modal.Body>
    </Modal>  
   </div>  
  );
}

export default ModalPallets;
