import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadirProducto(infoProducto, Presentacion) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Produccion/Lineas_produccion/"+Presentacion.replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.fecha2);
  setDoc(docRef, infoProducto);
  console.log(Presentacion)
      
}

export default añadirProducto;
