import React, {useState, useEffect} from 'react';
import "../../../index.css"
import Navbar from '../../Navbars/Navbar';
import ClipLoader from "react-spinners/ClipLoader";

const Jalapeño = () =>{
    const [Loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true);
        setTimeout(()=>{
          setLoading(false);
        },300)
      }, [])

    return(
        <div>
        {Loading?
        <div id="Loading_pages">
        <ClipLoader 
        color={'#36D7B7'} 
        Loading={Loading}  
        size={150} />
        </div>
      :
        <div >
            <Navbar/>
            <div id="Padre_tomate">
                <img id="Tomate_img" className="img-fluid" src="/Imagenes/Jalapeños.jpeg" width="100%" height="auto"></img>
                <div className="Hijo_jalapeño">
                   <div id="Hijo_tomate_texto">
                     <h2 >CHILE</h2>
                     <br/>
                     <center id="Descripcion">Totalmente sembrado, cosechado y empaquetado por nosotros, por lo que 
                         se esta manera se asegura la calidad e inocuidad de todos nuestros productos.
                     </center>
                     <br/>
                     <br/>
                     <h3 id="Presentaciones">Presentaciones:</h3>
                     <br/>
                     <br/>
                     <ul id="Presentaciones_tamaños">
                         <li> 1 Kg.</li>
                         <li> 5 Kg.</li>
                         <li>10 Kg.</li>
                     </ul>
                   </div>  
                </div>
            </div>
        </div> 
         }
    </div>  
    )
}



export default Jalapeño;

