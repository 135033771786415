import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarSaldo_proveedores(proveedoresDB, Total_a_editar) {
  console.log(proveedoresDB.nombre, Total_a_editar)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Proveedores/");
  const docRef = doc(collectionRef, proveedoresDB.nombre);
  updateDoc(docRef, Total_a_editar);
}

export default editarSaldo_proveedores;
