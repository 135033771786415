import React, {useState} from "react";
import { Modal, Stack, Form, Button, Table } from "react-bootstrap";

function Modal_actividades_cargos({
  isModalAñadir,
  setIsModalAñadir,
  dia_select_actividades,
  data_cargo_select,
  actividades_viernes_auxiliar,
  actividades_sabado_auxiliar,
  actividades_domingo_auxiliar,
  actividades_lunes_auxiliar,
  actividades_martes_auxiliar,
  actividades_miercoles_auxiliar,
  actividades_jueves_auxiliar,

}) {

    //Convertir a formato moneda
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

  const [tabla_filtrada, settabla_filtrada] = React.useState([]);

  function array_filtrado(){
    var auxiliar_de_dia_a_tratar = [];

      if(dia_select_actividades == "viernes"){
        auxiliar_de_dia_a_tratar = actividades_viernes_auxiliar;
      }
      if(dia_select_actividades == "sabado"){
        auxiliar_de_dia_a_tratar = actividades_sabado_auxiliar;
      }
      if(dia_select_actividades == "domingo"){
        auxiliar_de_dia_a_tratar = actividades_domingo_auxiliar;
      }
      if(dia_select_actividades == "lunes"){
        auxiliar_de_dia_a_tratar = actividades_lunes_auxiliar;
      }
      if(dia_select_actividades == "martes"){
        auxiliar_de_dia_a_tratar = actividades_martes_auxiliar;
      }
      if(dia_select_actividades == "miercoles"){
        auxiliar_de_dia_a_tratar = actividades_miercoles_auxiliar;
      }
      if(dia_select_actividades == "jueves"){
        auxiliar_de_dia_a_tratar = actividades_jueves_auxiliar;
      }


        var auxiliar_array = [];
        auxiliar_de_dia_a_tratar.map((dato)=>{
          if((dato.cargo).toUpperCase() == (data_cargo_select.nombre).toUpperCase()){
            auxiliar_array.push(dato)
          }
        })

        //Con esto filtro y junto si hay actividades repetidas para despues filtrarlas y sumarlas
        var auxiliar_juntar = [];
        var auxiliar_100 = true;
        auxiliar_array.map((valor, index)=>{
          auxiliar_100 = true
          if(index == 0){
            auxiliar_juntar.push({actividad: (valor.actividad).toUpperCase()});
          }else{
            auxiliar_juntar.map((dato)=>{
              if((valor.actividad).toUpperCase() == (dato.actividad).toUpperCase()){
                auxiliar_100 = false;
              }
            });
            if(auxiliar_100 == true){
              auxiliar_juntar.push({actividad: (valor.actividad).toUpperCase()});
            }
          }
        })

      //Aqui sumo las actividades ya sin repetir
      auxiliar_juntar.map((dato)=>{
        auxiliar_array.map((valor)=>{
            if((dato.actividad).toUpperCase() == (valor.actividad).toUpperCase()){
                if(dato.importe == undefined){
                  dato['importe'] = 0;
                }
                var auxiliar_919 = formatter.format(+valor.importe + +dato.importe);
                var n_str = (auxiliar_919).toString();
                var n_nuevaStr = n_str.replace("$", "");
                var n_nuevaStr2 = n_nuevaStr.replace(",", "");
                var n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                dato['importe'] = n_nuevaStr4;
            }
          });
      });
      settabla_filtrada(auxiliar_juntar);
    }

  

  
  React.useEffect(() => {
    array_filtrado();
  }, []);

  return (
   <div >
    <Modal id="Modal_Logs" className="container-fluid" show={isModalAñadir} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Actividades del día {dia_select_actividades} del cargo {data_cargo_select.nombre}</Modal.Title>
        <Button variant="secondary" onClick={() => {
                      setIsModalAñadir(false);
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            <th>Actividad</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            tabla_filtrada.map((producto, index) => (
              <tr key={index}>
                <td>{producto.actividad}</td>
                <td>{formatter.format(producto.importe)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      setIsModalAñadir(false);
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  );
}
export default Modal_actividades_cargos;
