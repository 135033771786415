import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_productos_tablas_sin_afectacion(infoProducto, Nombre_de_boton, Cantidad_actual ) {
  console.log(Cantidad_actual);
  console.log(Nombre_de_boton.nombre_banco);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Bancos/"+Nombre_de_boton.nombre_banco+"/");
  const docRef = doc(collectionRef, Cantidad_actual.toString());
  setDoc(docRef, infoProducto);  
}

export default añadir_productos_tablas_sin_afectacion;
