import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import escribirLog_productos_orden from "./escribirLog_productos_orden";
import React, {useState} from 'react';

function añadirProducto_orden(infoProducto, autor, productofolio) {
  //console.log(productofolio);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Ordenes_productos/"+productofolio+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.producto);
  setDoc(docRef, infoProducto);
  //console.log(window.location.pathname)
  //escribirLog_productos_orden("Creación", infoProducto, autor, productofolio);

      
}

export default añadirProducto_orden;
