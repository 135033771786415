import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_restar_cosecha(accion, usuario, cantidad_kilos_total_a_retirar,nombreproducto,Presentacion, date, cosecha_seleccionada, movimiento, cosecha_seleccionada_filtrada, productosDB2) {
  console.log(accion, usuario, cantidad_kilos_total_a_retirar, nombreproducto, cosecha_seleccionada_filtrada);
  console.log(productosDB2)
  var persona= usuario.email;

  const cosecha= "NA";
  const retirado= "NA";
  let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  let fecha_nombre = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, fecha_nombre);
  const data = {
    accion,
    fecha: fecha_actual,
    fecha3: date,
    Hora: hora_actual,
    Autor: persona,
    Seccion: Presentacion,
    Cantidad_retirada: cantidad_kilos_total_a_retirar,
    Cantidad_agregada: retirado,
    Producto_retirado: cosecha_seleccionada,
    Cosecha_seleccionada_retirada: cosecha_seleccionada,
    Titulo: fecha_nombre,
    Cosecha: cosecha,
    Movimiento: movimiento,
    precio: cosecha_seleccionada_filtrada[0].precio,
    lleva_iva: cosecha_seleccionada_filtrada[0].lleva_iva,
    moneda: cosecha_seleccionada_filtrada[0].moneda,
    categoria: cosecha_seleccionada_filtrada[0].categoria,
    tamaño: productosDB2[0].tamaño,
    etiqueta: productosDB2[0].etiqueta,
    tamaño_mas_etiqueta: productosDB2[0].tamaño_mas_etiqueta,
  };
  console.log(data)
  setDoc(docuRef, data)
  //setDoc(docuRef, data);
}
