import { Modal, Stack, Form, Button } from "react-bootstrap";
import eliminarProveedor from "../../functions/Proveedores/eliminarProveedor";
import Swal from "sweetalert2";

function ModalEliminar({
  Modal_Eliminar_proveedor,
  setModal_Eliminar_proveedor,
  Proveedores_todos,
}) {
 /*  async function actualizar(pagina, start) {
    await getAlldata(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);   
       Traer_secciones(productos);
     });
   } */

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

  //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, usuario) {
    var proveedor = document.getElementById("proveedor").value;
    Swal.fire({
      title: '¿Esta seguro de eliminar el proveedor?  "'+ proveedor+'"',
      text: "Esta acción no se puede restablecer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El proveedor ha sido eliminado.',
          'success'
        )
        Proveedores_todos.map((valor)=>{
          if((valor.nombre).replace(/ /g, "") == proveedor.replace(/ /g, "")){
            console.log(valor)
            proveedor = valor.nombre;
          }
        })

         eliminarProveedor(proveedor).then(
        (confirmacion) => {
          setModal_Eliminar_proveedor(false);
        }
      ); 
      }
    })
  }
  

  return (
    <Modal show={Modal_Eliminar_proveedor} onHide={() => setModal_Eliminar_proveedor(false)}>
      <Modal.Header>
        <Modal.Title>Añadir asegurado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Cargo</h6>
            <select id="proveedor" type="text">
            {Proveedores_todos.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_Eliminar_proveedor(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={eliminar_sweetalert}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEliminar;
