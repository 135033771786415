import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editar_pagos(infoProducto, auxiliar, fecha) {
  const db = getFirestore(firebaseApp);
  console.log("Nominas/"+auxiliar+"/Nombres/");
  const collectionRef = collection(db,"Nominas/"+auxiliar+"/Nombres/".replace(/ /g, ""));
  const docRef = doc(collectionRef, fecha);
  updateDoc(docRef, infoProducto);
}

export default editar_pagos;
