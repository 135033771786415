import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_productos_tablas(infoProducto, productos_cantidad, Nombre_de_boton) {
  console.log(productos_cantidad);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Gastos_productos_tablas/"+Nombre_de_boton.valor+"/Productos");
  const docRef = doc(collectionRef, productos_cantidad.toString());
  setDoc(docRef, infoProducto);      
}

export default añadir_productos_tablas;
