import React from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import "../../index.css"
import ModalInicio_ciclo from '../Firebase/components/Iniciofin_ciclo/ModalInicio_ciclo';

const Iniciofin_ciclo = () =>{

    const [Modal, setModal] = React.useState(false);
    //const [Data, setData] = React.useState(null);

    function AbrirModal(){
        setModal(true)
    }
    
    return(
        <div id="Trabajo">
            <Navbartrabajo/>
            <button id="inicio_ciclo" onClick={() => AbrirModal()} >Inicio Ciclo</button>
            <button id="fin_ciclo">Fin Ciclo</button>

              {Modal && (
                 <ModalInicio_ciclo
                 ModalCiclo={Modal}
                setModal={setModal}
                
                />
                )}
        </div>
    );
}

export default Iniciofin_ciclo;