import React from "react";
import { Modal, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import getAllproducts from "../../functions/Productos/getAllproducts";



function ModalTotal_concepto({
  setConcepto_totales,
  Producto_seleccionado,
  Tablasdata,
  actualizar_productos_tablas,
  usuario2,
  Nombre_de_boton,
  setTablasdata,
}) {

 //Convertir a formato moneda
 const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})


  const [Datos, setDatos] = React.useState([]);
  const [Graficas_on, setGraficas_on] = React.useState(false);

  async function Actualizar(){   
    await getAllproducts().then((SeccionesDB) => {
      //Esto es para sacar el total de cada producto por concepto
      SeccionesDB.map((producto_filtrado)=>{
       var total_concepto = 0;
            Tablasdata.map((productos_tabla)=>{
              //console.log(productos_tabla.producto, producto_filtrado.nombre)
              //Este filtra nomas por los productos que tiene agregados
              if(productos_tabla.producto==producto_filtrado.nombre){
                var auxiliar = (productos_tabla.total_con_iva).toFixed(2) 
                total_concepto = (parseFloat(total_concepto) + parseFloat(auxiliar))
              }
            })//Fin segundo map     
       producto_filtrado['total_por_concepto'] = total_concepto.toString();
      })//Fin primer map
  
      //Aqui filtro si estan en 0 para que no aparescan
      var productos_filtrados_por_estar_en_0 = []
      SeccionesDB.map((filtrado)=>{
        if(filtrado.total_por_concepto==0){
  
        }else{productos_filtrados_por_estar_en_0.push(filtrado)}
      })
      setDatos(productos_filtrados_por_estar_en_0);
     });
     
  }

  function salir(){
    setConcepto_totales(false)
  }

  React.useEffect(() => {
    Actualizar(Producto_seleccionado);
  }, []);

  return (
   <div >
    <Modal id="Modal_Logs"className="container-fluid" show={!Graficas_on} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Total por cada concepto</Modal.Title>

       <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            <th>#</th>
            <th>Producto retirado</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            Datos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                <td>{formatter.format(producto.total_por_concepto)}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>

{/* //------------------------------------------------------------------------- */}
<Modal id="Modal_Logs"className="container-fluid" show={Graficas_on} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Total por cada concepto</Modal.Title>

       <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      {/* <Container id="Grafica">
      <Bar
            data={state}
            options={{
              title:{
                display:true,
                responsive:true,
                text:'Average Rainfall per month',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          />
      </Container> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button> 
      </Modal.Footer>
    </Modal>


  </div> 
  );
}

export default ModalTotal_concepto;
