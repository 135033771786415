import firebaseApp from "../../Firebase/firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminar_almacen_produccion(infoProducto) {
  let collectionRef = collection(db, "Botones_categorias_produccion".replace(/ /g, ""));
  const docuRef = doc(collectionRef, infoProducto.valor);
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
