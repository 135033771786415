import { Modal, Stack, Form, Button, Table } from "react-bootstrap";


function ModalInicio_ciclo({
  ModalCiclo,
  setModal,
  Data,
  setData,
}) {
  console.log(ModalCiclo)
  function Modal_iniciar_ciclo() {
    //obtener infor del formulario
    /* const nombre = document.getElementById("nombre").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cantidad = document.getElementById("cantidad").value;
    const medida = document.getElementById("medida").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, medida };
    añadirProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setModal(false); */
  }

  return (
    <Modal id="Modal_Logs" show={ModalCiclo} onHide={() => setModal(false)}>
      <Modal.Header>
        <Modal.Title>Definición de tablas y sus secciones de cultivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>#</th>
            <th>Tabla o sección</th>
            <th>Cantidad de cultivos</th>
            <th>Producto del Cultivo 1</th>
            <th>Fecha de inicio cultivo 1</th>
            <th>Producto del Cultivo 2</th>
            <th>Fecha de inicio cultivo 2</th>
          </tr>
        </thead>
        <tbody>
              <tr >
                <td>Campo1</td>
                {/* <td>{producto.Autor}</td>
                <td>{producto.Producto_retirado}</td>
                <td>{producto.Cantidad_retirada}</td>
                <td>{producto.Seccion}</td>
                <td>{producto.fecha}</td> */}
                <td>
                </td>
              </tr>
        </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={Modal_iniciar_ciclo}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalInicio_ciclo;
