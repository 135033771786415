import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadirProducto_gastos(infoProducto, productocargo) {
  console.log(infoProducto.nombre);
  console.log(productocargo);
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Gastos_productos_tablas/"+productocargo+"/Productos/")
  const docRef = doc(collectionRef, (infoProducto.nombre).toString());
  setDoc(docRef, infoProducto);   
}

export default añadirProducto_gastos;
