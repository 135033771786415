import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../../functions/Produccion/Presentaciones/añadirProducto";
import añadirInforme_diario from "../../functions/Produccion/Presentaciones/añadirInforme_diario"
import getAllProducts from "../../functions/Produccion/Presentaciones/getAllProducts";
import getProductos from "../../functions/Produccion/getProductos";
import getProductos2 from "../../functions/Produccion/getProductos2";
import editarCantidad_articulo_retirado from "../../functions/Produccion/editarCantidad_articulo_retirado"
import escribirLog_agregar_producto_empaque2 from "../../functions/Produccion/escribirLog_agregar_producto_empaque2";
import escribirLog_restar_carton_empaque from "../../functions/Produccion/escribirLog_restar_carton_empaque";
import getProductos_cosecha from "../../functions/Produccion/Presentaciones/getProductos_cosecha";
import getProductos_carton from "../../functions/Produccion/Presentaciones/getProductos_carton";
import editarCantidad_cosecha from "../../functions/Produccion/Presentaciones/editarCantidad_cosecha";
import editarCantidad_carton from "../../functions/Produccion/Presentaciones/editarCantidad_carton";
import editarCantidad_cajas from "../../functions/Produccion/Presentaciones/editarCantidad_cajas";
import escribirLog_restar_cosecha from "../../functions/Produccion/Presentaciones/escribirLog_restar_cosecha";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";


function ModalAñadir_presentaciones({
  ModalAñadir_presentaciones_productos,
  setModalAñadir_presentaciones_productos,
  actualizarEstadoProductos,
  usuario,
  pagina,
  Presentacion,
  setProductosPresentaciones,
  ProductosPresentaciones,
}) {

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

  async function actualizar(Presentacion) {
    await getAllProducts(Presentacion).then((productos) => {
      console.log(productos)
      //Con esto lo ordeno por fechas de forma descendiente
      productos.sort((a,b)=>  (b.fecha3) - (a.fecha3));
      setProductosPresentaciones(productos);    
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }


  //Traer categorias de productos del almacen campo
  const [Datosproducto, setDatosproducto] = React.useState([]);

  async function productos_basededatos(){
    const nombrecategoria = "Producto terminado";
    await getProductos(nombrecategoria).then((productosDB) => {
      setDatosproducto(productosDB);
      retirar_producto_cosecha()
    });
  }

//Traer productos de cosechas del almacen
const [Datosproducto_cosecha, setDatosproducto_cosecha] = React.useState([]);

async function retirar_producto_cosecha(){
    await getProductos_cosecha().then((cosechaDB) => {
      setDatosproducto_cosecha(cosechaDB)
      retirar_producto_carton()
      //retirar_producto_cosecha2({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion, cosechaDB})
    });
  }

//Traer carton de cosechas del almacen
const [Datosproducto_carton, setDatosproducto_carton] = React.useState([]);

async function retirar_producto_carton(){
    await getProductos_carton().then((cartonDB) => {
      setDatosproducto_carton(cartonDB)
      //retirar_producto_cosecha2({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion, cosechaDB})
    });
  }




async function productos_basededatos2(Presentacion){
  //console.log(Presentacion);
  const nombreproducto = document.getElementById("producto").value;
  const nombrecategoria = "Producto terminado";
  await getProductos2({nombreproducto,nombrecategoria}).then((productosDB2) => {
    const precio2= productosDB2.map(dato=> dato.precio)
    const unidad2= productosDB2.map(dato=> dato.unidad)
    const cantidad2= productosDB2.map(dato=> dato.cantidad)
    const nombre2= productosDB2.map(dato=> dato.nombre)
    retirar_producto_cosecha2({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion})
    //añadirProductoModal({unidad2,valor_cantidad_ingresado});
  });
}


//---------------Hacer movimientos en el almacen de empaque---------------------------------------------
//Con esto resto la cantidad de kilos consumidos en el almacen > Cosecha
async function retirar_producto_cosecha2({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion}){
  const cosecha_seleccionada=document.getElementById("cosecha_seleccionada_a_consumir").value;
  var cosecha_seleccionada_filtrada = [];
  Datosproducto_cosecha.map((Productos_cosechas)=>{
      if(cosecha_seleccionada == Productos_cosechas.nombre){
        cosecha_seleccionada_filtrada.push(Productos_cosechas);
      }
    })
    
    const valor_cantidad_ingresado=document.getElementById("cantidad").value;
    //console.log(cosecha_seleccionada_filtrada[0].cantidad, valor_cantidad_ingresado)
   //Este if es para saber si la cantidad de kilos es mayor a 0 
   console.log(productosDB2)
    console.log(parseFloat(valor_cantidad_ingresado), parseFloat(productosDB2[0].kilos_por_cajas_por_pallet))
      var cantidad_kilos_total_a_retirar = parseFloat(valor_cantidad_ingresado) * parseFloat(productosDB2[0].kilos_por_cajas_por_pallet);

    if( parseFloat(cosecha_seleccionada_filtrada[0].cantidad) >=  parseFloat(cantidad_kilos_total_a_retirar) ){
      var Total_kilos_restantes = formatter.format( cosecha_seleccionada_filtrada[0].cantidad - cantidad_kilos_total_a_retirar)
      console.log(cosecha_seleccionada_filtrada[0].cantidad, cantidad_kilos_total_a_retirar)
      console.log(Total_kilos_restantes)
      var n_str = Total_kilos_restantes.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const n_nuevaStr = n_str.replace("$", "");
      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      var n_nuevaStr4_kilos = n_nuevaStr3.replace(",", "");
      //Este es el valor restado de kilos
      //console.log(n_nuevaStr4); 
  
      //Con esto se edita el valor actual en el almacen de cosecha
     /*   await editarCantidad_cosecha({
            n_nuevaStr4_kilos,
            cosecha_seleccionada,
            })  
          
            agregar_log_kilos({unidad2,valor_cantidad_ingresado,nombreproducto, Presentacion, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos})
            */
      retirar_producto({unidad2,valor_cantidad_ingresado, Presentacion, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, 
        nombreproducto, cantidad_kilos_total_a_retirar, cantidad2, nombre2, cosecha_seleccionada_filtrada});
    }else{
      Swal.fire({
        icon: 'error',
        text: "La cantidad solicitada de kilos de "+cosecha_seleccionada+" a retirar es mayor a la existente actualmente en almacen, la cantidad disponible actualmente es de "+cosecha_seleccionada_filtrada[0].cantidad+ " KG y usted quiere retirar "+valor_cantidad_ingresado+" cajas dando un total de "+cantidad_kilos_total_a_retirar+" KG. Cada caja seleccionada contiene "+productosDB2[0].kilos_por_cajas_por_pallet+" KG.",
        footer: '¿Que hacer? <br/> Se debe agregar cantidad adecuada de kilos que desea consumir en la sección de almacen de empaque en cosecha.'
      })
    }
   
}

async function agregar_log_kilos({unidad2,valor_cantidad_ingresado,nombreproducto, Presentacion, cosecha_seleccionada, productosDB2, 
  n_nuevaStr4_kilos, cantidad_kilos_total_a_retirar, movimiento, cosecha_seleccionada_filtrada}){
let date = fechaSeleccionada;
await escribirLog_restar_cosecha("Retiro", usuario, cantidad_kilos_total_a_retirar,nombreproducto, Presentacion, date, cosecha_seleccionada, movimiento, cosecha_seleccionada_filtrada, productosDB2);
   //retirar_producto({unidad2,valor_cantidad_ingresado, Presentacion, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, nombreproducto});
}

//-----------------------------------------------------------------------------------------------------------------------------
//Con esto resto la cantidad de cajas producidas en el almacen > Carton

async function retirar_producto({unidad2,valor_cantidad_ingresado, Presentacion, cosecha_seleccionada, productosDB2, 
  n_nuevaStr4_kilos, nombreproducto, cantidad_kilos_total_a_retirar, cantidad2, nombre2, cosecha_seleccionada_filtrada}){
    
  console.log(productosDB2[0].carton_utilizado)
  const carton_seleccionada = productosDB2[0].carton_utilizado;
  var carton_seleccionada_filtrada = [];
  Datosproducto_carton.map((Productos_carton)=>{
      if(carton_seleccionada == Productos_carton.nombre){
        carton_seleccionada_filtrada.push(Productos_carton);
      }
    })

   const cantidad_cajas_retiradas=document.getElementById("cantidad").value;
   //Este if es para saber si la cantidad de kilos es mayor a 0 
    if(parseFloat(carton_seleccionada_filtrada[0].cantidad) >= parseFloat(cantidad_cajas_retiradas)){
      var Total_kilos_restantes = formatter.format( carton_seleccionada_filtrada[0].cantidad - cantidad_cajas_retiradas)
  
      var n_str = Total_kilos_restantes.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const n_nuevaStr = n_str.replace("$", "");
      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      //Este es el valor restado de cajas
      //console.log(n_nuevaStr4); 
  
      //Con esto se edita el valor actual en el almacen de carton
      const carton_seleccionado = carton_seleccionada_filtrada[0].nombre;

        await funcion_axuliar2({unidad2,cantidad_cajas_retiradas, Presentacion, carton_seleccionado, carton_seleccionada_filtrada, 
          cantidad_kilos_total_a_retirar, nombreproducto, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, n_nuevaStr4, cantidad2, 
          nombre2, cosecha_seleccionada_filtrada});

       
    }else{
      Swal.fire({
        icon: 'error',
        text: "La cantidad solicitada de cajas de '"+carton_seleccionada_filtrada[0].nombre+"' a retirar es mayor a la existente actualmente en almacen, la cantidad disponible actualmente es de "+carton_seleccionada_filtrada[0].cantidad+ " y usted quiere retirar "+cantidad_cajas_retiradas+".",
        footer: '¿Que hacer? <br/> Se debe agregar cantidad adecuada de cajas que desea consumir en la sección de almacen de empaque en Carton.'
      })
    }
}



//Aqui resto las cajas y los kilos ya que de esta manera apenas asi podia ahcer que los logs se guardaran.

//Este es para restar las cajas
async function funcion_axuliar2({unidad2,cantidad_cajas_retiradas, Presentacion, carton_seleccionado, carton_seleccionada_filtrada, 
  cantidad_kilos_total_a_retirar, nombreproducto, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, n_nuevaStr4, cantidad2, nombre2, cosecha_seleccionada_filtrada}){
           var movimiento = 0;
              if(ProductosPresentaciones.length == 0){
                movimiento = 1;
              }else{
                var total_movimientos = [];
                ProductosPresentaciones.map((movimientos)=>{
                  total_movimientos.push(parseInt(movimientos.Movimiento));
                })
                var auxiliar599 = Math.max(...total_movimientos)
                movimiento = auxiliar599 + 1;
               }

  //Con esto cierro la ventana del modal desde un comienzo para que no pueda dar dos veces al añadir
  const producto_terminado_seleccionado=document.getElementById("producto").value;
  const valor_cantidad_ingresado=document.getElementById("cantidad").value;
  // cerrar modal
  setModalAñadir_presentaciones_productos(false);


  editarCantidad_carton({n_nuevaStr4, carton_seleccionado });
  let date = fechaSeleccionada;
  escribirLog_restar_carton_empaque("Retiro", usuario, cantidad_cajas_retiradas,carton_seleccionado, Presentacion, date, movimiento, carton_seleccionada_filtrada, productosDB2, cosecha_seleccionada);

 await timeout(1000);
 funcion_axuliar3({unidad2,cantidad_cajas_retiradas, Presentacion, carton_seleccionado, carton_seleccionada_filtrada, 
    cantidad_kilos_total_a_retirar, nombreproducto, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, n_nuevaStr4,
    valor_cantidad_ingresado, producto_terminado_seleccionado, movimiento, cosecha_seleccionada_filtrada})
}

//Este es para restar los KILOS
async function funcion_axuliar3({unidad2,cantidad_cajas_retiradas, Presentacion, carton_seleccionado, carton_seleccionada_filtrada, 
  cantidad_kilos_total_a_retirar, nombreproducto, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, n_nuevaStr4, cantidad2, nombre2,
  valor_cantidad_ingresado, producto_terminado_seleccionado, movimiento, cosecha_seleccionada_filtrada}){
  editarCantidad_cosecha({n_nuevaStr4_kilos, cosecha_seleccionada})
  agregar_log_kilos({unidad2,cantidad_kilos_total_a_retirar,nombreproducto, Presentacion, cosecha_seleccionada, productosDB2, n_nuevaStr4_kilos, movimiento, cosecha_seleccionada_filtrada})

  await timeout(1000);
  var carton_seleccionado2 = carton_seleccionado;
  retirar_producto3({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion, valor_cantidad_ingresado, 
    producto_terminado_seleccionado, cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento})
}





//-----------------------------------------------------------------------------------------------------------------------------
//Con esto sumo la cantidad de cajas producidas en el almacen > productos terminado
async function retirar_producto3({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, Presentacion, valor_cantidad_ingresado, producto_terminado_seleccionado,
   cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento}){
  console.log(Datosproducto)
  
  //const producto_terminado_seleccionado=document.getElementById("producto").value;
  var producto_terminado_seleccionada_filtrada = [];
  Datosproducto.map((Productos_terminado)=>{
      if(producto_terminado_seleccionado == Productos_terminado.nombre){
        producto_terminado_seleccionada_filtrada.push(Productos_terminado);
      }
    })
    console.log(producto_terminado_seleccionada_filtrada)
   //const valor_cantidad_ingresado=document.getElementById("cantidad").value;
      var Total_cajas_sumadas = formatter.format( +producto_terminado_seleccionada_filtrada[0].cantidad + +valor_cantidad_ingresado)
  
      var n_str = Total_cajas_sumadas.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const n_nuevaStr = n_str.replace("$", "");
      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      //Este es el valor restado de cajas
      console.log(n_nuevaStr4); 
  
      //Con esto se edita el valor actual en el almacen de carton
      const carton_seleccionado = producto_terminado_seleccionada_filtrada[0].nombre;
       await editarCantidad_cajas({
            n_nuevaStr4,
            carton_seleccionado,
            }) 
          
            agregar_log3({unidad2,valor_cantidad_ingresado, Presentacion, carton_seleccionado, 
              producto_terminado_seleccionada_filtrada, producto_terminado_seleccionado, cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento})
}

async function agregar_log3({unidad2,valor_cantidad_ingresado, Presentacion, carton_seleccionado, producto_terminado_seleccionada_filtrada, 
  producto_terminado_seleccionado, cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento}){
  console.log(Presentacion);
  var nombreproducto = carton_seleccionado;
  let date = fechaSeleccionada;
await escribirLog_agregar_producto_empaque2("Agregar", usuario, valor_cantidad_ingresado,nombreproducto, Presentacion, date, movimiento, producto_terminado_seleccionada_filtrada, cosecha_seleccionada);

  añadirProductoModal({unidad2,valor_cantidad_ingresado, Presentacion, producto_terminado_seleccionado, carton_seleccionado, 
    cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento, producto_terminado_seleccionada_filtrada});
}

//----------------------------FIM de los movimientos en los almacenes-------------------------------------------------------------------


//Fecha
const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

async function añadirProductoModal({unidad2, valor_cantidad_ingresado, Presentacion, producto_terminado_seleccionado, 
  carton_seleccionado, cosecha_seleccionada, cantidad_kilos_total_a_retirar, carton_seleccionado2, movimiento, producto_terminado_seleccionada_filtrada}) {
  console.log(Presentacion);
   //Este if es para validar que se ingrese un valor en el input de cantidad
    if(valor_cantidad_ingresado.length > 0){
          //obtener infor del formulario
          let date = fechaSeleccionada;
          let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear();
          const fecha = fecha_actual;
          const nombre = producto_terminado_seleccionado;
          const cantidad = valor_cantidad_ingresado;
          const unidad = unidad2.toString();
          const usuario2 = usuario.email;
          const fecha2 = new Date().toString();
          const fecha3 = date;
          const present = Presentacion;
          var tamaño = producto_terminado_seleccionada_filtrada[0].tamaño;
          var etiqueta = producto_terminado_seleccionada_filtrada[0].etiqueta;
          var tamaño_mas_etiqueta = producto_terminado_seleccionada_filtrada[0].tamaño_mas_etiqueta;

          var Movimiento = movimiento;
          var Total_KG_retirados = cantidad_kilos_total_a_retirar;
          var Total_Cartones_retirados = valor_cantidad_ingresado;
          var fecha_eliminar_cosecha = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
          var fecha_eliminar_carton = date.toString();
          var fecha_eliminar_caja = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + date.getSeconds();
          var Cosecha_seleccionada_retirada = cosecha_seleccionada;
          var carton_seleccionado_retirado = carton_seleccionado2;
          console.log(carton_seleccionado_retirado);
          //const sku = document.getElementById("sku").value;

          //Con esto cuento el numero de movimientos de la linea y le sumo 1
/*           var movimiento = 0;
          console.log(ProductosPresentaciones.length);
          console.log(ProductosPresentaciones);
          if(ProductosPresentaciones.length == 0){
            movimiento = 1;
          }else{
            var total_movimientos = [];
            ProductosPresentaciones.map((movimientos)=>{
              total_movimientos.push(parseInt(movimientos.movimiento));
            })
            var auxiliar599 = Math.max(...total_movimientos)
            movimiento = auxiliar599 + 1;
            console.log(total_movimientos);
            console.log(Math.max(...total_movimientos));
            console.log(movimiento);
          } */

          
          
          // enviar informacion a firebase
          const infoProducto = { Total_KG_retirados, Total_Cartones_retirados, Cosecha_seleccionada_retirada, 
            carton_seleccionado_retirado, fecha, nombre, cantidad, unidad, usuario2, fecha2, fecha3, present,
            fecha_eliminar_cosecha, fecha_eliminar_carton, fecha_eliminar_caja, Movimiento, tamaño, etiqueta, tamaño_mas_etiqueta};
          añadirProducto(infoProducto, Presentacion);
          añadirInforme_diario(infoProducto, Presentacion);

          await timeout(500);
          actualizar(Presentacion);
          Swal.fire(
            'Se agrego correctamente!.',
            '',
            'success'
          )
      }else{alert("Cantidad no ingresada")}

}

  React.useEffect(() => {
    productos_basededatos();
  }, []);

  return (
    <Modal show={ModalAñadir_presentaciones_productos} onHide={() => setModalAñadir_presentaciones_productos(false)}>
      <Modal.Header>
        <Modal.Title>Agregar cajas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar presentación de caja a producir</h6>
            {Datosproducto ? 
                <select 
                id="producto" 
                type="text"
                >
                  {Datosproducto.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>
            :
            null
            }  
            <br/>
            <Form.Control
              id="cantidad"
              placeholder="Cantidad de cajas producidas."
              type="number"
              className="mb-1"
            />
            <br/>
            <h6>Seleccionar cosecha a consumir</h6>
            {Datosproducto_cosecha ? 
                <select 
                id="cosecha_seleccionada_a_consumir" 
                type="text"
                >
                  {Datosproducto_cosecha.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>
            :
            null
            } 
{/*             <br/>
            <h6>Seleccionar tipo de cartón a consumir</h6>
            {Datosproducto_carton ? 
                <select 
                id="carton" 
                type="text"
                >
                  {Datosproducto_carton.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>
            :
            null
            } */} 
            <br/> 
            <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
           

            {/* <Form.Control
              id="sku"
              placeholder="sku"
              type="text"
              className="mb-1"
            /> */}
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAñadir_presentaciones_productos(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => productos_basededatos2(Presentacion)}>
          Agregar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_presentaciones;
