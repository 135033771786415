import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React from 'react';

function añadirCliente_Embarque(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Produccion_clientes_embarque/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);  
}

export default añadirCliente_Embarque;
