import React, {useRef} from "react";
import { useReactToPrint } from "react-to-print";
import QRcode from "react-qr-code";
import { Button } from "react-bootstrap";

const Prueba_QR = (ProductoEditar) =>{
  console.log(ProductoEditar);
  console.log(ProductoEditar.ProductoEditar.nombre);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
    onafterprint: () => alert('Print success')
  });

  return(
    <>
    <h5>Nota: Es necesario ajustar los margenes de impresión acorde a su impresora!!</h5>
      <div ref={componentRef} style={{width: '90%', height: window.center, padding: "5%" }}>
         
         <br/>
                   <QRcode 
                    size={256}
                    style={{width: '110%', height: window.center, maxWidth: "120%",  }}
                    value={ "Nombre: "+ProductoEditar.ProductoEditar.nombre+" \nDireccion: "+ProductoEditar.ProductoEditar.direccion+" \nProducto: "+ProductoEditar.ProductoEditar.producto+" "
            
                   }
                 /> 
      </div>
      <br/>
      <div style={{textAlign: "center" }}>
        <Button  variant="primary" onClick={handlePrint}>
          Imprimir
        </Button>
      </div>

    </>
  );
};

export default Prueba_QR;

/* value={ "Peso: 10 KGS \nDireccion: Pueblo Yaqui \nCorreo: horticolaluipack@luipack.com \nProducto: Tomate roma" */