import React from "react";
import Login2 from '../views/Login2'
import Home_Nominas from '../views/Home_Nominas'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD_Nominas({botones, pagina, start, Pagina_principal}){

    

    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
      <div>
      {usuario ? <Home_Nominas usuario={usuario} botones={botones} pagina={pagina} start={start} Pagina_principal={Pagina_principal}></Home_Nominas>: <Login2 />}
      </div>
    );
}




export default CRUD_Nominas;