import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../../functions/Cultivo_en_proceso/añadirProducto";
import getProductos from "../../functions/Cultivo_en_proceso/getProductos";
import getProductos2 from "../../functions/Cultivo_en_proceso/getProductos2";
import editarCantidad_articulo_retirado from "../../functions/Cultivo_en_proceso/editarCantidad_articulo_retirado";
import escribirLog_retirar_producto from "../../functions/Cultivo_en_proceso/escribirLog_retirar_producto";
import getAllsecciones from "../../functions/Campo_secciones/getAllsecciones";
import getAllProducts from "../../functions/Cultivo_en_proceso/getAllProducts";
import getCategorias_botones from "../../../Listas/funciones/getCategorias_botones";
import Swal from "sweetalert2";
import {DatePicker} from "@material-ui/pickers";


function ModalAñadir({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
  pagina,
  setProductos,
}) {

  async function actualizar(pagina) {
    const pagina2 = pagina;
    await getAllProducts(pagina2).then((productos) => {
      //console.log(productos)
       //Con esto lo ordeno por fechas de forma descendiente
       productos.sort((a,b)=>  (b.fecha3) - (a.fecha3));
       setProductos(productos);    
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

const [Dolar_o_pesos, setDolar_o_pesos] = React.useState(false);

//Con esto verifico si la moneda del producto seleccionado es dolar o peso
function Dolar_o_peso(){
  const nombre = document.getElementById("producto").value;
  Datosproducto.map((valor)=>{
    if(nombre==valor.nombre){
      console.log(valor);
      if(valor.moneda=="Dolares"){
        setDolar_o_pesos(true);
      }else{
        setDolar_o_pesos(false);
      }
    }
  })
}


//Traer las categorias de los botones del almacen de empaque
const [Botones_campo, setBotones_campo] = React.useState([]);
const [Pagina_gastos, setPagina_gastos] = React.useState(false);
async function botones(pagina){
  await getCategorias_botones().then((Categorias_botonesDB) => {
      console.log(Categorias_botonesDB) 
      setBotones_campo(Categorias_botonesDB);
      //Esto es para que no me aparesca la cosecha al momento de agregar algo
      if(pagina=="Gastos indirectos de producción"){
        setPagina_gastos(true);
      }
      
 });
}

  //Traer categorias de productos del almacen campo
  const [Datosproducto, setDatosproducto] = React.useState([]);

  async function productos_basededatos(){
    const nombrecategoria = document.getElementById("categoria").value;
    await getProductos(nombrecategoria).then((productosDB) => {
      console.log(productosDB);
      setDatosproducto(productosDB);
    });
  }

async function productos_basededatos2(){
  const nombreproducto = document.getElementById("producto").value;
  const nombrecategoria = document.getElementById("categoria").value;
  await getProductos2({nombreproducto,nombrecategoria}).then((productosDB2) => {
    const precio2= productosDB2.map(dato=> dato.precio)
    const unidad2= productosDB2.map(dato=> dato.unidad)
    const cantidad2= productosDB2.map(dato=> dato.cantidad)
    const nombre2= productosDB2.map(dato=> dato.nombre)
    const moneda2= productosDB2.map(dato=> dato.moneda)
    const categoria2= productosDB2.map(dato=> dato.categoria)
    retirar_producto({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, precio2, moneda2,categoria2})
  });
}


async function retirar_producto({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, precio2, moneda2,categoria2}){
  let cantidad_restante_en_almacen_campo = 0;
    const valor_cantidad_ingresado=document.getElementById("cantidad").value;
    if(valor_cantidad_ingresado <= parseFloat(cantidad2)){
     //cantidad_restante_en_almacen_campo = cantidad2 - valor_cantidad_ingresado;
     var auxiliar = formatter.format(cantidad2 - valor_cantidad_ingresado);
     const str = auxiliar.toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
     const nuevaStr = str.replace("$", "");
     const nuevaStr2 = nuevaStr.replace(",", "");
     const nuevaStr3 = nuevaStr2.replace(",", "");
     cantidad_restante_en_almacen_campo = nuevaStr3.replace(",", "");

    //Crear nuevo array del producto pero con la cantidad retirada restada  
    const producto_retirado= productosDB2.map(dato=> [{
      cantidad: cantidad_restante_en_almacen_campo,
      medida: dato.medida,
      moneda: dato.moneda,
      nombre: dato.nombre,
      precio: dato.precio,
      },]
      )

       
      const producto_retirado_final=producto_retirado[0];
      let nombrecategoria = document.getElementById("categoria").value;

    await editarCantidad_articulo_retirado({
          producto_retirado_final,
          nombrecategoria,
          nombre2,
          cantidad_restante_en_almacen_campo, 
          valor_cantidad_ingresado,
          pagina
          })
        
          agregar_log({unidad2,valor_cantidad_ingresado,nombreproducto, precio2, productosDB2, moneda2, categoria2})
          

    }else {
      //alert("La cantidad solicitada a retirar es menor a la existente actualmente en almacen o falto ingresar algun dato, la cantidad disponible en almacen del articulo que se desea retirar es "+cantidad2)
      Swal.fire({
        icon: 'error',
        text: "La cantidad solicitada a retirar es menor a la existente actualmente en almacen o falto ingresar algun dato, la cantidad disponible en almacen del articulo que se desea retirar es "+cantidad2,
        footer: '¿Que hacer? <br/> Se debe agregar cantidad adecuada al articulo o producto que desea consumir en la sección de almacen correspondiente'
      })
    }
}

async function agregar_log({unidad2,valor_cantidad_ingresado,nombreproducto, precio2, productosDB2, moneda2, categoria2}){
  let cosecha = "NA";
  console.log(Pagina_gastos)
          /* if(!Pagina_gastos){
            cosecha = document.getElementById("cosecha").value;
          }else{cosecha ="NA"; } */

  if(valor_cantidad_ingresado.length <= 0){
    console.log("yes");
    añadirProductoModal({unidad2,valor_cantidad_ingresado});
    }
    else{
      let date = new Date();
      let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear();

      //Esto sirve para la seleccion de la fecha
      let date2 =fechaSeleccionada;
      let fecha_actual3 = String(date2.getDate()).padStart(2, '0') + '-' + String(date2.getMonth() + 1).padStart(2, '0') + '-' + date2.getFullYear() ;
          
      await escribirLog_retirar_producto("Retiro", usuario, pagina,valor_cantidad_ingresado,nombreproducto, cosecha, fecha_actual, precio2, fecha_actual3, date2);
      añadirProductoModal({unidad2,valor_cantidad_ingresado, fecha_actual, precio2, productosDB2, moneda2, categoria2});
    }

}


//Fecha
const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());


async function añadirProductoModal({unidad2, valor_cantidad_ingresado, fecha_actual, precio2, productosDB2, moneda2, categoria2}) {
  //console.log(moneda2[0])
   //Este if es para validar que se ingrese un valor en el input de cantidad
    if(valor_cantidad_ingresado.length > 0){
          //obtener infor del formulario
          let date = new Date();
          let fecha_actual2 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
          

          //Esto sirve para la seleccion de la fecha
          let date2 =fechaSeleccionada;
          let fecha_actual = String(date2.getDate()).padStart(2, '0') + '-' + String(date2.getMonth() + 1).padStart(2, '0') + '-' + date2.getFullYear() ;

          let fecha_actual3 = date2;
          const fecha = fecha_actual;
          const fecha2 = fecha_actual2;
          const fecha3 = fecha_actual3;
          console.log(fecha3)
          const nombre = document.getElementById("producto").value;
          const cantidad = document.getElementById("cantidad").value;
          const unidad = unidad2.toString();
          const usuario2 = usuario.email;
          const seccion = pagina;
          const categoria = categoria2[0];
          var total = 0;
          if(moneda2[0]=="Dolares"){
            var tiene_valor_dolar = document.getElementById("precio_dolar").value;

            if(tiene_valor_dolar == ""){
              Swal.fire({
                icon: 'error',
                text: "El precio del dolar no se ingreso. ",
                footer: '¿Que hacer? <br/> Se debe agregar el precio del dolar actual ya que el producto es en dolares.'
              })
            }else{
              //Dolares
              var total_dolar = precio2 * tiene_valor_dolar;
              total = total_dolar * cantidad;
              console.log("Producto en dolares")
              console.log(total)
              var costo_directo_si_no = "";
              let cosecha = "NA";
              /* if(!Pagina_gastos){
                cosecha = document.getElementById("cosecha").value;
              }else{cosecha ="NA"; } */
              
              // enviar informacion a firebase
              const infoProducto = { fecha, fecha2, fecha3, nombre, cantidad, unidad, usuario2, cosecha, seccion, total, costo_directo_si_no, categoria };
              añadirProducto(infoProducto, usuario.email,pagina);
              // cerrar modal
              await timeout(500);
              actualizar(pagina);
              setIsModalAñadir(false);
            }
            
          }else{
          //Pesos
          console.log("Producto en pesos")
           total = precio2 * cantidad;
           console.log(total)
           var costo_directo_si_no = "";
           let cosecha = "NA";
           /* if(!Pagina_gastos){
             cosecha = document.getElementById("cosecha").value;
           }else{cosecha ="NA"; } */
           
           // enviar informacion a firebase
           const infoProducto = { fecha, fecha2, fecha3, nombre, cantidad, unidad, usuario2, cosecha, seccion, total, costo_directo_si_no, categoria };
           añadirProducto(infoProducto, usuario.email,pagina);
           // cerrar modal
           await timeout(500);
           actualizar(pagina);
           setIsModalAñadir(false);
          }
         
      }else{
        //alert("Cantidad no ingresada")
        Swal.fire({
          icon: 'error',
          text: "Cantidad o categoría no ingresado en el formulario. ",
          footer: '¿Que hacer? <br/> Se debe agregar cantidad y seleccionar una categoría adecuada al articulo o producto que desea consumir en la sección de almacen correspondiente'
        })
      }

}

  React.useEffect(() => {
    botones(pagina);
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Retirar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar categoria del producto a retirar</h6>
            <select
                    id="categoria" 
                    type="text" 
                    onClick={() => {
                      productos_basededatos();
                      /* Productos_secciones(); */
              }}>
              <option>Seleccionar</option>
              {Botones_campo.map((datos,index)=>( 
                      <option key={index}>{datos.valor}</option>
                  ))}

            </select>
            <br/>
            <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
           
            <br/>
            <h6>Seleccionar producto</h6>
            {Datosproducto ? 
                <select 
                id="producto" 
                type="text"
                onChange={() => {
                      Dolar_o_peso();
                      /* Productos_secciones(); */
              }}
                >
                  <option>Seleccionar</option>
                  {Datosproducto.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>
            :
            null
            }  
            <br/>

            <Form.Control
              id="cantidad"
              placeholder="Cantidad"
              type="number"
              className="mb-1"
            />
            <br/>
         {Dolar_o_pesos && (
            <div>
               <h6>Ingresar el precio del dolar ya que el producto es en Dolares</h6>
                <Form.Control
                  id="precio_dolar"
                  placeholder="Precio"
                  type="number"
                  className="mb-1"
                />
            </div>
         )}   
       
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={productos_basededatos2}>
          Retirar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
