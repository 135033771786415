import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getLugares_caja(Manifiesto_Seleccionado) {
    console.log(Manifiesto_Seleccionado.manifiesto);
    const Lugar_cajaDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+Manifiesto_Seleccionado.manifiesto+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Lugar_cajaDB.push(doc.data());
    });
    return Lugar_cajaDB;
    

}
