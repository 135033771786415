import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminar_consulta(producto, auxiliar) {
  console.log(producto.fecha)
  const coleccionRef = collection(db, "Nominas/"+auxiliar+"/Nombres/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.fecha);
  const eliminado = await deleteDoc(docuRef);
  return eliminado;
}
