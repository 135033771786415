import React from "react";
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home_almacen_empaque from '../views/Home_almacen_empaque'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD_almacen_empaque({pagina, start, Pagina_principal}){
  
    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
    <div>
      {usuario ? <Home_almacen_empaque usuario={usuario} pagina={pagina} start={start} Pagina_principal={Pagina_principal}></Home_almacen_empaque>: <Login2 />}
      
    </div>
    );
}




export default CRUD_almacen_empaque;