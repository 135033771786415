import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import React from "react";
import getAllordenes_compra from "../../functions/Proveedores/getAllordenes_compra";
import getProductos_orden from "../../functions/getProductos_orden";
import getAllpagos from "../../functions/Proveedores/getAllpagos";
import getAllpagos2 from "../../functions/Proveedores/getAllpagos2";
import añadir_pago from "../../functions/Proveedores/añadir_pago";
import eliminarPago from "../../functions/Proveedores/eliminarPago";
import editarSaldo_proveedores from "../../functions/Ordenes_compra/editarSaldo_proveedores";
import getProveedor from "../../functions/Ordenes_compra/getProveedor";
import getAllProducts from "../../functions/getAllProducts";
import getOrden_1_sola from "../../functions/Proveedores/getOrden_1_sola";
import editarSaldo_Dolares_proveedores from "../../functions/Proveedores/editarSaldo_Dolares_proveedores";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";


function ModalCartera_proveedores_Dolares({
  productoCartera_Dolares,
  setproductoCartera_Dolares,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar, 
  usuario,
  setProductos,
}) {
  
 //Convertir a formato moneda
 const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

      //Eliminar con sweetalert2
        function eliminar_sweetalert(producto) {
          Swal.fire({
            title: '¿Esta seguro de eliminar el pago? Este pago tiene la cantidad de $"'+ producto.cantidad_pago+'"',
            text: "Esta acción no se puede restablecer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar.'
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Eliminado!',
                'El pago ha sido eliminado.',
                'success'
              )


            //Con esto edito el total del saldo del proveedor
            var Enviarproducto = Orden_seleccionada;
            getProveedor(Enviarproducto).then((proveedoresDB) => {
            console.log(proveedoresDB)
            //Con esto convierto el saldo a valor flotante ya que tiene un $
            const n_str = proveedoresDB.Saldo_Dolares.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const n_nuevaStr = n_str.replace("$", "");
            const n_nuevaStr2 = n_nuevaStr.replace(",", "");
            const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
            
            console.log(producto.cantidad_pago, n_nuevaStr4);
            var Total_saldo = formatter.format(+n_nuevaStr4 + +producto.cantidad_pago)
            console.log(Total_saldo)
            var Total_a_editar= {
              Saldo_Dolares: Total_saldo,
              };

            var proveedoresDB = productoEditar;
            console.log(productoEditar);
            console.log(Total_saldo);
            editarSaldo_proveedores(proveedoresDB, Total_a_editar)
        });
        eliminarPago(producto).then(
          (confirmacion) => {
            pagos(producto);
          }
        );
      }
      })
      }
 

 //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
 function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

//--------Actualizo los productos al salir---------------------------------------------------------------------------
async function actualizar_productos() {
  await getAllProducts().then((productos) => {
    var productos_filtrados = [];
    productos.map((valor)=>{
      if((valor.Saldo_MXN == "$0") && (valor.Saldo_Dolares == "$0")){
      }else{
        productos_filtrados.push(valor)
      }
  
    })
    //console.log(productos_filtrados)
    setProductos(productos_filtrados);
    setproductoCartera_Dolares(false);
  });
}

//---------------------------------------------------------------------------------------------------------------------
//Filtrar por fecha------------------------------------------------------------------------------------

//Fecha
const [fechaSeleccionada_filtrado, setfechaSeleccionada_filtrado] = React.useState(new Date());
const [total_saldo_filtrado, settotal_saldo_filtrado] = React.useState(0);

function filtrar_ordenes_por_fecha(){
      //Esto sirve para la seleccion de la fecha
      let date =fechaSeleccionada_filtrado;
      //let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;   
      let fecha_actual = String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;   

      //con esto sumo todos los saldos de las ordenes filtradas
      var total_saldos = 0;
      let ordenes_filtradas = [];
      Ordenes_de_compra.map((valor)=>{
        //Con este slice le borro el dia a todas las fechas de las ordenes para asi filtrar solo por mes y año
        const Fecha_sin_dia = (valor.fecha).slice(3)
        if(Fecha_sin_dia==fecha_actual){
          ordenes_filtradas.push(valor)
          const str = total_saldos.toString();
          //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          const nuevaStr4 = nuevaStr3.replace(",", "");
  
          const str9 = valor.saldo;
          const nuevaStr9 = str9.replace("$", "");
          const nuevaStr29 = nuevaStr9.replace(",", "");
          const nuevaStr39 = nuevaStr29.replace(",", "");
          const nuevaStr49 = nuevaStr39.replace(",", "");
        
          total_saldos = formatter.format(+nuevaStr4 + +nuevaStr49);
        }
      })

      console.log(ordenes_filtradas);
      settotal_saldo_filtrado(total_saldos);
      setOrdenes_de_compra(ordenes_filtradas);
}

//Fin filtrado-----------------------------------------------------------------------------------------


//Traer ordenes de compra.
const [Ordenes_de_compra, setOrdenes_de_compra] = React.useState([]);

//Auxiliar abrir modal
const [Auxiliar_modalON, setAuxiliar_modalON] = React.useState(false);

async function Ordenes_de_compra_actuales(){
  await getAllordenes_compra().then((OrdenesDB) => {
    var Ordenes_filtradas_por_proveedor= [];
    OrdenesDB.map((dato)=>{
      if(dato.proveedor==productoEditar.nombre){
          if(dato.tipo_compra=="Credito"){
            if(dato.moneda=="Dolares"){
              dato['factura'] = dato.factura;
              Ordenes_filtradas_por_proveedor.push(dato)
            }
          }
      }
    })
    //console.log(Ordenes_filtradas_por_proveedor);
    actualizarEstadoProductos3(Ordenes_filtradas_por_proveedor);
    });
}


//Agregar el total de pagos a las ordenes de compra
async function actualizarEstadoProductos3(Ordenes_filtradas_por_proveedor) {
var saldo_total_suma = 0;
  Ordenes_filtradas_por_proveedor.map((ordenes)=>{
    var datostotal = 0;
    const productofolio = ordenes.nombre;
    //console.log(productofolio)
    getAllpagos2(productofolio).then((PagosDB2) => {
            //Este sirve para sacar el total de todos los productos de la orden 
           PagosDB2.map((dato)=> {
                //Con esto convierto el saldo a valor flotante ya que tiene un $
                const nstr = dato.cantidad_pago.toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const nnuevaStr = nstr.replace("$", "");
                const nnuevaStr2 = nnuevaStr.replace(",", "");
                const nnuevaStr3 = nnuevaStr2.replace(",", "");
                const nnuevaStr4 = nnuevaStr3.replace(",", "");

                var cantidad_pago_formatter = (nnuevaStr4);

                const Nnstr = datostotal.toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const NnnuevaStr = Nnstr.replace("$", "");
                const NnnuevaStr2 = NnnuevaStr.replace(",", "");
                const NnnuevaStr3 = NnnuevaStr2.replace(",", "");
                const NnnuevaStr4 = NnnuevaStr3.replace(",", "");

                var Auxiliar_datostotal = formatter.format(+NnnuevaStr4 + +cantidad_pago_formatter);

                //Este sirve para dejarlo como numero sin signos el total
                const Nnstr9 = Auxiliar_datostotal.toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const NnnuevaStr9 = Nnstr9.replace("$", "");
                const NnnuevaStr29 = NnnuevaStr9.replace(",", "");
                const NnnuevaStr39 = NnnuevaStr29.replace(",", "");
                const NnnuevaStr49 = NnnuevaStr39.replace(",", "");

                datostotal = NnnuevaStr49;
                //datostotal = parseFloat(datostotal) + parseFloat(dato.cantidad_pago);
              });
          //console.log(datostotal);
        //Con esto le agrego el total pagado a las ordenes
        ordenes['total_pagado'] = datostotal;


        //Con esto convierto el saldo a valor flotante ya que tiene un $
        const str = ordenes.total_orden;
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const nuevaStr4 = nuevaStr3.replace(",", "");

        var auxiliar_ordenes_total = (nuevaStr4);
        var auxiliar_datostotal = (datostotal);
        var saldo_total = formatter.format(auxiliar_ordenes_total - auxiliar_datostotal);
        //console.log(auxiliar_ordenes_total,auxiliar_datostotal, saldo_total)
        ordenes['saldo'] = saldo_total;

  //-------------------------Con esto sumo el total del saldo--------------------------
        //Con esto convierto el saldo a valor flotante ya que tiene un $
        const str99 = saldo_total.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr99 = str99.replace("$", "");
        const nuevaStr299 = nuevaStr99.replace(",", "");
        const nuevaStr399 = nuevaStr299.replace(",", "");
        const nuevaStr499 = nuevaStr399.replace(",", "");

        //Con esto convierto el saldo a valor flotante ya que tiene un $
        const str998 = saldo_total_suma.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr998 = str998.replace("$", "");
        const nuevaStr2998 = nuevaStr998.replace(",", "");
        const nuevaStr3998 = nuevaStr2998.replace(",", "");
        const nuevaStr4998 = nuevaStr3998.replace(",", "");

        //console.log(saldo_total , saldo_total_suma)
        saldo_total_suma = formatter.format(+nuevaStr4998 + +nuevaStr499);
        //console.log(saldo_total_suma)



    }); //Fin de getProductos_orden
   
  });
  //Sin este timeout no se carga correctamente el total y lo deja en undefined
  await timeout(1000);
  console.log(productoEditar.Saldo_Dolares)
  console.log(saldo_total_suma)
  //Con esto me fijo que sea igual el saldo que muestra al abrir proveedores que al sumado al abrir la cartera
  if(productoEditar.Saldo_Dolares==saldo_total_suma){
    console.log("OK")
  }else{
    console.log("No igual")
    editarSaldo_Dolares_proveedores(saldo_total_suma, productoEditar)
  }
  //Con esto lo ordeno por fechas de forma descendiente
  Ordenes_filtradas_por_proveedor.sort((a,b)=> (a.fecha3) - (b.fecha3));
  setOrdenes_de_compra(Ordenes_filtradas_por_proveedor);
  setAuxiliar_modalON(true);
  //console.log(Ordenes_filtradas_por_proveedor);
}

//PAGOS------------------------------------------------------------------------------------
//Traer clientes de embarque.
const [Productos_pagos, setProductos_pagos] = React.useState([]);

//Orden de compra seleccionado.
const [Orden_seleccionada, setOrden_seleccionada] = React.useState([]);


async function pagos(producto){
  setOrden_seleccionada(producto);
  console.log(producto)
  await getAllpagos(producto).then((PagosDB) => {
    var pagos_filtrados= [];
    PagosDB.map((valor)=>{
      if(producto.nombre==valor.nombre){
        pagos_filtrados.push(valor);
      }
    })
    setProductos_pagos(pagos_filtrados);
    console.log(pagos_filtrados);
    setAuxiliar_modalON(false);
    });
}

//Auxiliar2 abrir modal de pagos
const [Auxiliar_modal_pagos_ON, setAuxiliar_modal_pagos_ON] = React.useState(false);

//Fecha
const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

async function añadirProductoModal() {
  //Esto sirve para obtener el saldo de la orden y asi al agregar si no tiene nada en el saldo marque error  
  var valor_saldo_orden= 0;
    Ordenes_de_compra.map((valor_saldo)=>{
      if(valor_saldo.nombre==Orden_seleccionada.nombre){
        valor_saldo_orden = valor_saldo.saldo;
      }
    });

    console.log(valor_saldo_orden);

 const cantidad_pago = document.getElementById("cantidad_pago").value;
  
 //Con esto convierto el saldo a valor flotante ya que tiene un $
 const str = valor_saldo_orden.toString();
 console.log(str);
 //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
 const nuevaStr = str.replace("$", "");
 const nuevaStr2 = nuevaStr.replace(",", "");
 const nuevaStr3 = nuevaStr2.replace(",", "");
 const nuevaStr4 = nuevaStr3.replace(",", "");

 console.log(parseFloat(nuevaStr4));
 console.log(cantidad_pago, parseFloat(nuevaStr4))

 if(cantidad_pago > parseFloat(nuevaStr4)){
  Swal.fire({
    icon: 'error',
    text: "La cantidad ingresada de pago es menor que el saldo",
    footer: '¿Que hacer? <br/> Se debe agregar un valor igual o mayor al saldo de la orden de compra seleccionada, el saldo actual es de : ' + parseFloat(nuevaStr4)
  })
 }else{
      //obtener infor del formulario
    //Esto sirve para la seleccion de la fecha
    let date =fechaSeleccionada;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;   
    const fecha = fecha_actual;
    
    const nombre = Orden_seleccionada.nombre; 
    console.log(Orden_seleccionada)
    // enviar informacion a firebase
    const infoProducto = { fecha, cantidad_pago, nombre};
    añadir_pago(infoProducto);
      
       //Con esto edito el total del saldo del proveedor
       var Enviarproducto = Orden_seleccionada;
       await getProveedor(Enviarproducto).then((proveedoresDB) => {
             console.log(proveedoresDB)
             //Con esto convierto el saldo a valor flotante ya que tiene un $
             const n_str = proveedoresDB.Saldo_Dolares.toString();
             //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
             const n_nuevaStr = n_str.replace("$", "");
             const n_nuevaStr2 = n_nuevaStr.replace(",", "");
             const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
             var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
             
             console.log(cantidad_pago, n_nuevaStr4);
             var Total_saldo = formatter.format(n_nuevaStr4 - cantidad_pago)
             console.log(Total_saldo)
             var Total_a_editar= {
               Saldo_Dolares: Total_saldo,
               };
 
             var proveedoresDB = productoEditar;
             console.log(productoEditar);
             console.log(Total_saldo);
             editarSaldo_proveedores(proveedoresDB, Total_a_editar)
         });




    // cerrar modal
    setAuxiliar_modal_pagos_ON(false);
    setAuxiliar_modalON(true);
    Ordenes_de_compra_actuales();
    
    }
  }

  React.useEffect(() => {
    setAuxiliar_modalON(true);
    Ordenes_de_compra_actuales();
  }, []);

  return (
   <div>
    <Modal id="Modal_Logs" show={Auxiliar_modalON} onHide={() => {
    }}>
      <Modal.Header>
        <Modal.Title>Cartera en dolares del proveedor "{productoEditar.nombre}"</Modal.Title>
        <DatePicker 
          variant="inline"
          openTo="year"
          views={["year", "month"]}
          label="Filtrar por mes y año"
          helperText="Para salir solo dar click fuera."
          value={fechaSeleccionada_filtrado}
          onChange={setfechaSeleccionada_filtrado}/>
        <Button variant="primary" onClick={() => filtrar_ordenes_por_fecha()}>
        Filtrar
        </Button>
        <p>Total saldo = {total_saldo_filtrado}</p>
        <Button variant="secondary" onClick={() => actualizar_productos()}>
          Salir
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Orden de compra</th>
            <th>Factura</th>
           {/*  <th>Almacen</th> */}
            <th>Moneda</th>
            <th>Total deuda Dolares</th>
            <th>Total pagado</th>
            <th>saldo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Ordenes_de_compra.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.fecha}</td>
                <td>{producto.nombre}</td>
                <td>#{producto.factura}</td>
                {/* <td>{producto.almacen}</td> */}
                <td>{producto.moneda}</td>
                <td>{producto.total_orden}</td>
                <td>{formatter.format(producto.total_pagado)}</td>
                <td>{producto.saldo}</td>
                  <td>
                          <Button
                            variant="success"
                            onClick={() => {
                              pagos(producto);
                            }}
                          >
                            Pagos
                          </Button>
                          {/* <Button
                            variant="danger"
                            onClick={() => {
                              eliminarClientes_Embarque(producto);
                              Inicio();
                            }}
                          >
                            Eliminar
                          </Button> */}
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => actualizar_productos()}>
          Salir
        </Button>
      </Modal.Footer>
    </Modal>

 {/* --------------------------------------PAGOS-------------------------------------------------------------------- */}   
 <Modal id="Modal_Logs" show={!Auxiliar_modalON} onHide={() => {
    }}>
      <Modal.Header>
        <Modal.Title>Pagos</Modal.Title>
        <Button variant="primary" onClick={() => setAuxiliar_modal_pagos_ON(true)}>
          Añadir pago
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Cantidad pagada</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Productos_pagos.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.fecha}</td>
                <td>{formatter.format(producto.cantidad_pago)}</td>
                  <td>
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminar_sweetalert(producto)
                            }}
                          >
                            Eliminar
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setAuxiliar_modalON(true); Ordenes_de_compra_actuales();}}>
          Regresar
        </Button>
      </Modal.Footer>
    </Modal>

{/*----------------------------MODAL PARA AGREGAR------------------------------------------------------------ */}
    {Auxiliar_modal_pagos_ON && (
 <Modal show={Auxiliar_modal_pagos_ON} onHide={() => {
  }}>
    <Modal.Header>
      <Modal.Title>Nuevo pago</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Stack>
        <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
          <br/> 
        {/* <Form.Control
            id="fecha_de_pago"
            placeholder="Ingresar fecha del pago formato dd-mm-aa"
            type="text"
            className="mb-1"
          />   */}
        <Form.Control
            id="cantidad_pago"
            placeholder="Agregar cantidad a pagar"
            type="number"
            className="mb-1"
          />    
        </Stack>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setAuxiliar_modal_pagos_ON(false)}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={añadirProductoModal}>
        Añadir
      </Button>
    </Modal.Footer>
  </Modal>
    )}  
    </div> 

  );
  
}

export default ModalCartera_proveedores_Dolares;