import React from "react";

import { Modal, Stack, Form, Button } from "react-bootstrap";

import editarProducto from "../../functions/Cultivo_en_proceso/editarProducto";

function ModalEditar({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
}) {
  function editarProductoModal() {
    //obtener infor del formulario
    let date = new Date();
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
    const fecha = fecha_actual;
    const nombre = document.getElementById("producto").value;
    const cantidad = document.getElementById("cantidad").value;
    const unidad = document.getElementById("unidad").value;
    const usuario2 = document.getElementById("usuario2").value;
    const sku = document.getElementById("sku").value;
    // enviar informacion a firebase
    const infoProducto = { fecha, nombre, cantidad, unidad, usuario2, sku };
    editarProducto(infoProducto, usuario.email,pagina);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p id="Modal_movimiento">Producto actual (se puede editar nombre, cantidad, etc. cambiando el valor y presionando el botón de editar)</p> 
        <Form>
          <Stack>
            <Form.Control
              id="fecha"
              placeholder="Fecha"
              type="text"
              className="mb-1"
              value={productoEstado?.fecha}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  fecha: e.target.value,
                })
              }
            />
            <Form.Control
              id="producto"
              placeholder="Producto"
              type="text"
              className="mb-1"
              value={productoEstado?.nombre}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  nombre: e.target.value,
                })
              }
            />
            <Form.Control
              id="cantidad"
              placeholder="cantidad"
              type="number"
              className="mb-1"
              value={productoEstado?.cantidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  cantidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="unidad"
              placeholder="Ingresar unidad de medida del producto"
              type="text"
              className="mb-1"
              value={productoEstado?.unidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  unidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="usuario2"
              placeholder="sku"
              type="text"
              className="mb-1"
              value={productoEstado?.usuario2}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  usuario2: e.target.value,
                })
              }
            />
            <Form.Control
              id="sku"
              placeholder="sku"
              type="text"
              className="mb-1"
              value={productoEstado?.sku}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  sku: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 
      </Modal.Body>

      <Modal.Footer>         
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar;
