import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarManifiesto(producto) {
  const coleccionRef = collection(db, "Manifiestos/Manifiestos/Manifiestos/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, (producto.fecha_actual3).toString());
  const eliminado = await deleteDoc(docuRef);

  console.log(producto);
  const coleccionRef2 = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+(producto.fecha_actual3).toString()+"/".replace(/ /g, ""));
  const docuRef2 = doc(coleccionRef2, (producto.fecha_actual3).toString());
  await deleteDoc(docuRef2);

  const coleccionRef3 = collection(db, "Manifiestos/Tarimas_precios/"+(producto.fecha_actual3).toString()+"/".replace(/ /g, ""));
  const docuRef3 = doc(coleccionRef3, (producto.fecha_actual3).toString());
  await deleteDoc(docuRef3);

  return eliminado;
}
