import React from "react";
import { Modal, Stack, Form, Button, Table } from "react-bootstrap";
import getAlldata_consultar from "../../functions/Seguro_social/getAlldata_consultar";
import editar_pagos from "../../functions/Seguro_social/editar_pagos";
import get_un_solo_dato from "../../functions/Seguro_social/get_un_solo_dato";
import Swal from "sweetalert2";

function Modal_pago({
  setIsModalEditar,
  productoEditar,
  setProductoEditar,
  traer_trabajadores,
  settrabajadores_data,
  cuadrillero_seleccionado,
  trabajadores_data,
}) {

    //Convertir a formato moneda
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

  const [auxiliar_modal, setauxiliar_modal] = React.useState(false);
  const [dia_seleccionado, setdia_seleccionado] = React.useState();
  const [auxiliar_saber_actividad, setauxiliar_saber_actividad] = React.useState();
  const [auxiliar_saber_cargo, setauxiliar_saber_cargo] = React.useState();
  const [auxiliar_saber_actividad_2, setauxiliar_saber_actividad_2] = React.useState();
  const [auxiliar_saber_cargo_2, setauxiliar_saber_cargo_2] = React.useState();
//trabajadores_data son todos la lista de trabajadores que e trajeron del Home_Nominas.

  function Viernes_(){
   setauxiliar_saber_actividad(productoEditar.Viernes.actividad);
   setauxiliar_saber_cargo(productoEditar.Viernes.cargo);
   setauxiliar_saber_actividad_2(productoEditar.Viernes.actividad_2);
   setauxiliar_saber_cargo_2(productoEditar.Viernes.cargo_2);

   setdia_seleccionado("Viernes");
   setauxuliar_descuento(false);
   setauxiliar_modal(true);
  }

  function Sabado_(){
    setauxiliar_saber_actividad(productoEditar.Sabado.actividad);
    setauxiliar_saber_cargo(productoEditar.Sabado.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Sabado.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Sabado.cargo_2);

    setdia_seleccionado("Sabado");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  function Domingo_(){
    setauxiliar_saber_actividad(productoEditar.Domingo.actividad);
    setauxiliar_saber_cargo(productoEditar.Domingo.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Domingo.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Domingo.cargo_2);

    setdia_seleccionado("Domingo");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  function Lunes_(){
    setauxiliar_saber_actividad(productoEditar.Lunes.actividad);
    setauxiliar_saber_cargo(productoEditar.Lunes.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Lunes.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Lunes.cargo_2);

    setdia_seleccionado("Lunes");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  function Martes_(){
    setauxiliar_saber_actividad(productoEditar.Martes.actividad);
    setauxiliar_saber_cargo(productoEditar.Martes.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Martes.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Martes.cargo_2);

    setdia_seleccionado("Martes");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  function Miercoles_(){
    setauxiliar_saber_actividad(productoEditar.Miercoles.actividad);
    setauxiliar_saber_cargo(productoEditar.Miercoles.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Miercoles.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Miercoles.cargo_2);

    setdia_seleccionado("Miercoles");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  function Jueves_(){
    setauxiliar_saber_actividad(productoEditar.Jueves.actividad);
    setauxiliar_saber_cargo(productoEditar.Jueves.cargo);
    setauxiliar_saber_actividad_2(productoEditar.Jueves.actividad_2);
    setauxiliar_saber_cargo_2(productoEditar.Jueves.cargo_2);

    setdia_seleccionado("Jueves");
    setauxuliar_descuento(false);
    setauxiliar_modal(true);
  }

  const [auxuliar_descuento, setauxuliar_descuento] = React.useState(false);

  function Descuento_(){
    setdia_seleccionado("Descuento");
    setauxuliar_descuento(true);
    setauxiliar_modal(true);
  }



  const [actividades, setactividades] = React.useState([]);

  async function traer_actividad() {
    const seleccionado = "Actividad";
    await getAlldata_consultar(seleccionado).then((dataDB) => {
      console.log(dataDB) 
      setactividades(dataDB); 
     });
   }

   const [cargos, setcargos] = React.useState([]);

   async function traer_cargo() {
    const seleccionado = "Cargo";
    await getAlldata_consultar(seleccionado).then((dataDB2) => {
      console.log(dataDB2) 
      setcargos(dataDB2); 
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

/*  async function actualizar(){
    const seleccionado = "Trabajador";
    var aux_trabajadores = [];
   await getAlldata_consultar(seleccionado).then((dataDB) => {
      dataDB.map((datos)=>{
        if(datos.trabajador_cuadrillero == cuadrillero_seleccionado){
          aux_trabajadores.push(datos);
        }
      })
    settrabajadores_data(aux_trabajadores);
    });
  } */

  async function actualizar(){
    const auxiliar1 = productoEditar.fecha;
    const auxiliar2 = "Trabajador";
    var aux_trabajadores = [];
  await get_un_solo_dato(auxiliar1, auxiliar2).then((datoDB) => {
    console.log(datoDB);
    console.log(trabajadores_data);
        trabajadores_data.map((datos)=>{
          if(datos.nombre == datoDB[0].nombre){
            aux_trabajadores.push(datoDB[0]);
              }else{
                aux_trabajadores.push(datos);
              }
            }); 

     console.log(aux_trabajadores);
    });
    settrabajadores_data(aux_trabajadores);
  }

  async function editarProductoModal(dia_seleccionado) {
    //obtener infor del formulario
    const importe = document.getElementById("importe").value;
    var importe_2 = 0;
    var actividad = "";
    var cargo = "";
    var actividad_2 = "";
    var cargo_2 = "";
    if(auxuliar_descuento==true){
    }else{
      actividad = document.getElementById("actividad").value;
      cargo = document.getElementById("cargo").value;
      actividad_2 = document.getElementById("actividad_2").value;
      cargo_2 = document.getElementById("cargo_2").value;
      importe_2 = document.getElementById("importe_2").value;
    }
    const fecha = productoEditar.fecha;
    // enviar informacion a firebase
    const auxiliar = "Trabajador";
    var descuento = 0;
    var infoProducto ={[dia_seleccionado]:{importe, actividad, cargo, importe_2, actividad_2, cargo_2}};

    if(dia_seleccionado=="Descuento"){
      descuento = importe;
          //Aqui guardo todos los pagos y el descuento del trabajador a editar
          const pago_Viernes = productoEditar.Viernes.suma_importes;
          const pago_Sabado = productoEditar.Sabado.suma_importes;
          const pago_Domingo = productoEditar.Domingo.suma_importes;
          const pago_Lunes = productoEditar.Lunes.suma_importes;
          const pago_Martes = productoEditar.Martes.suma_importes;
          const pago_Miercoles = productoEditar.Miercoles.suma_importes;
          const pago_Jueves = productoEditar.Jueves.suma_importes;
          const pago_descuento = descuento;
    
          //aqui sumo todos los dias y resto el descuento del trabajador seleccionado
          const Total_con_formato = formatter.format(+pago_Viernes + +pago_Sabado + +pago_Domingo + +pago_Lunes + +pago_Martes + +pago_Miercoles + +pago_Jueves - pago_descuento);
          const n_str = (Total_con_formato).toString();
          //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
          const n_nuevaStr = n_str.replace("$", "");
          const n_nuevaStr2 = n_nuevaStr.replace(",", "");
          const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
          var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
          const total = n_nuevaStr4;

          infoProducto ={descuento, total} ;


    }else{
                //Aqui guardo todos los pagos y el descuento del trabajador a editar y obtengo el total
                var pago_Viernes = productoEditar.Viernes.importe;
                var pago_Sabado = productoEditar.Sabado.importe;
                var pago_Domingo = productoEditar.Domingo.importe;
                var pago_Lunes = productoEditar.Lunes.importe;
                var pago_Martes = productoEditar.Martes.importe;
                var pago_Miercoles = productoEditar.Miercoles.importe;
                var pago_Jueves = productoEditar.Jueves.importe;
                var pago_descuento = productoEditar.descuento;

                var pago_Viernes_2 = productoEditar.Viernes.importe_2;
                var pago_Sabado_2 = productoEditar.Sabado.importe_2;
                var pago_Domingo_2 = productoEditar.Domingo.importe_2;
                var pago_Lunes_2 = productoEditar.Lunes.importe_2;
                var pago_Martes_2 = productoEditar.Martes.importe_2;
                var pago_Miercoles_2 = productoEditar.Miercoles.importe_2;
                var pago_Jueves_2 = productoEditar.Jueves.importe_2;

                var suma_importes = 0;
                if(dia_seleccionado == "Viernes"){
                    const suma_con_formato = formatter.format(+importe + +importe_2);
                    const n_str = (suma_con_formato).toString();
                    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                    const n_nuevaStr = n_str.replace("$", "");
                    const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                    const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                    var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                    pago_Viernes = n_nuevaStr4;
                    suma_importes = pago_Viernes;
                }
                if(dia_seleccionado == "Sabado"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Sabado = n_nuevaStr4;
                  suma_importes = pago_Sabado;
                }
                if(dia_seleccionado == "Domingo"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Domingo = n_nuevaStr4;
                  suma_importes = pago_Domingo;
                }
                if(dia_seleccionado == "Lunes"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Lunes = n_nuevaStr4;
                  suma_importes = pago_Lunes;
                }
                if(dia_seleccionado == "Martes"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Martes = n_nuevaStr4;
                  suma_importes = pago_Martes;
                }
                if(dia_seleccionado == "Miercoles"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Miercoles = n_nuevaStr4;
                  suma_importes = pago_Miercoles;
                }
                if(dia_seleccionado == "Jueves"){
                  const suma_con_formato = formatter.format(+importe + +importe_2);
                  const n_str = (suma_con_formato).toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  pago_Jueves = n_nuevaStr4;
                  suma_importes = pago_Jueves;
                }

          
                //aqui sumo todos los dias y resto el descuento del trabajador seleccionado
                const Total_con_formato = formatter.format(+pago_Viernes + +pago_Sabado + +pago_Domingo + +pago_Lunes + +pago_Martes + +pago_Miercoles + +pago_Jueves + +pago_Viernes_2 + +pago_Sabado_2 + +pago_Domingo_2 + +pago_Lunes_2 + +pago_Martes_2 + +pago_Miercoles_2 + +pago_Jueves_2 - pago_descuento);
                console.log(Total_con_formato)
                console.log(pago_Viernes, pago_Sabado ,pago_Domingo ,pago_Lunes ,pago_Martes ,pago_Miercoles ,pago_Jueves ,pago_Viernes_2 ,pago_Sabado_2 ,pago_Domingo_2 ,pago_Lunes_2,pago_Martes_2,pago_Miercoles_2,pago_Jueves_2, pago_descuento)
                const n_str = (Total_con_formato).toString();
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                const n_nuevaStr = n_str.replace("$", "");
                const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                const total = n_nuevaStr4;
      infoProducto ={[dia_seleccionado]:{importe, actividad, cargo, importe_2, actividad_2, cargo_2, suma_importes}, total };
    }
    

    editar_pagos(infoProducto, auxiliar, fecha);
    // cerrar modal
    setProductoEditar(null);
    setIsModalEditar(false);  
    setauxuliar_descuento(false);
    await timeout(500);
    actualizar();

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se agrego correctamente.',
        showConfirmButton: false,
        timer: 1500
      });
  }

function sin_funcion_pero_necesario_para_los_input(){

}

  React.useEffect(() => {
    traer_actividad();
    traer_cargo();
    setauxiliar_modal(false);
  }, []);

  
  return (
   <div>
    {auxiliar_modal? 
     //Aqui empieza el modal de encuesta para pagar ----------------------------------------------------------------------------
      <div>
          <Modal show={auxiliar_modal}>        
            <Modal.Header>
            {auxuliar_descuento? 
            <Modal.Title>Agregar {dia_seleccionado}</Modal.Title>
            :
            <Modal.Title>Pago diario del día {dia_seleccionado}</Modal.Title>
            }

            </Modal.Header>
            <Modal.Body>  
                <Form>
                  <Stack>
    
                    {auxuliar_descuento?
                    <>
                      <Form.Control
                          id="importe"
                          placeholder="Ingresar importe"
                          type="number"
                          className="mb-1"
                        />
                    </>
                    :
                    <>
                      <Form.Control
                        id="importe"
                        placeholder="Ingresar importe para actividad 1"
                        type="number"
                        className="mb-1"
                        defaultValue={productoEditar?.[dia_seleccionado].importe}
                      />
                    <br/>
                     <h6>Seleccionar actividad </h6>
                      <select id="actividad" type="text">
                      <option key={"a"}>{auxiliar_saber_actividad}</option>
                      {actividades.map((datos,index)=>( 
                          <option key={index}>{datos.nombre}</option>
                      ))}
                      </select>
                    <br/>
                    <h6>Seleccionar cargo </h6>
                      <select id="cargo" type="text">
                      <option key={"p"}>{auxiliar_saber_cargo}</option>
                      {cargos.map((datos,index)=>( 
                          <option key={index}>{datos.nombre}</option>
                      ))}
                      </select>
                      <br/>
                        <Form.Control
                          id="importe_2"
                          placeholder="Ingresar importe para actividad 2"
                          type="number"
                          className="mb-1"
                          defaultValue={productoEditar?.[dia_seleccionado].importe_2}
                        />
                      <br/>
                      <h6>Seleccionar actividad 2</h6>
                      <select id="actividad_2" type="text">
                      <option key={"q"}>{auxiliar_saber_actividad_2}</option>
                      {actividades.map((datos,index)=>( 
                          <option key={index}>{datos.nombre}</option>
                      ))}
                      </select>
                    <br/>
                    <h6>Seleccionar cargo 2</h6>
                      <select id="cargo_2" type="text">
                      <option key={"r"}>{auxiliar_saber_cargo_2}</option>
                      {cargos.map((datos,index)=>( 
                          <option key={index}>{datos.nombre}</option>
                      ))}
                      </select>
                    </>
                    }

                  </Stack>
                </Form>
            </Modal.Body>

            <Modal.Footer>         
              <Button
                variant="secondary"
                onClick={() => {
                  setauxiliar_modal(false);
                }}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => {
                  editarProductoModal(dia_seleccionado);
                }}
              >
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
          </div>
          :
 //Aqui empieza el modal de inicio donde vienen los dias------------------------------------------------------------------------
        <div>
          <Modal id="Modal_Logs"className="container-fluid" show={!auxiliar_modal}>
            <Modal.Header>
              <Modal.Title>Pago diario</Modal.Title>
            </Modal.Header>
            <Modal.Body>  
            <Table>
                    <thead>
                      <tr>
                        <th>VIERNES</th>
                        <th>SABADO</th>
                        <th>DOMINGO</th>
                        <th>LUNES</th>
                        <th>MARTES</th>
                        <th>MIERCOLES</th>
                        <th>JUEVES</th>
                        <th>DESCUENTO</th>
                        {/* <th>TOTAL</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                          <tr key={"index"}>
                            {/* Viernes */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                Viernes_()}}>{"$"+productoEditar.Viernes.suma_importes}
                            </Button></td> 
                            {/* sabado */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  Sabado_()}}>{"$"+productoEditar.Sabado.suma_importes}
                            </Button></td> 
                              {/* Domingo */}
                              <td><Button
                                variant="light"
                                onClick={() => {
                                  Domingo_()}}>{"$"+productoEditar.Domingo.suma_importes}
                            </Button></td> 
                              {/* Lunes */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  Lunes_()}}>{"$"+productoEditar.Lunes.suma_importes}
                            </Button></td> 
                              {/* Martes */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  Martes_()}}>{"$"+productoEditar.Martes.suma_importes}
                            </Button></td> 
                              {/* Miercoles */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  Miercoles_()}}>{"$"+productoEditar.Miercoles.suma_importes}
                            </Button></td> 
                              {/* Jueves */}
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  Jueves_()}}>{"$"+productoEditar.Jueves.suma_importes}
                            </Button></td> 
                              {/* descuento */}
                              <td><Button
                                variant="light"
                                onClick={() => {
                                  Descuento_()}}>{"$"+productoEditar.descuento}
                            </Button></td> 
                          </tr>
                          }
                    </tbody>
              </Table>
            </Modal.Body>

            <Modal.Footer>         
              <Button
                variant="secondary"
                onClick={() => {
                  setIsModalEditar(false);
                  setProductoEditar(null);
                }}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    }
   </div> 
  );
}

export default Modal_pago;

