import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React from 'react';

function añadirProducto(infoProducto, autor, pagina,start) {
  console.log(infoProducto)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, window.location.pathname);
  if(start){ 
      collectionRef = collection(db, "Almacen_campo/"+pagina+"/Productos".replace(/ /g, ""))
      }else{ collectionRef = collection(db, window.location.pathname)}
      
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);
  

  return(
        <div>
            
            
          </div>
        )
      
}

export default añadirProducto;
