import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function añadirpago_cliente(infoProducto, Total_Pagos_manifiesto, eliminar_producto) {
  console.log(infoProducto, Total_Pagos_manifiesto, eliminar_producto)
  const db = getFirestore(firebaseApp);
  if(eliminar_producto==true){
  }else{
    const collectionRef = collection(db, "Manifiestos/Pagos/"+infoProducto.manifiesto+"/".replace(/ /g, ""));
    const docRef = doc(collectionRef, infoProducto.fecha);
    setDoc(docRef, infoProducto); 
  }
 
  var Total_Pagos_manifiesto = {Total_Pagos_manifiesto};
  console.log(Total_Pagos_manifiesto,infoProducto.manifiesto )
  const collectionRef2 = collection(db,"/Manifiestos/Manifiestos/Manifiestos/".replace(/ /g, ""));
  const docRef2 = doc(collectionRef2, infoProducto.manifiesto);
  updateDoc(docRef2, Total_Pagos_manifiesto);
}

export default añadirpago_cliente;
