import firebaseApp from "../../firebase/credenciales";
import React from "react";
import { getFirestore, collection, getDocs, document } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getProductos2({nombreproducto,nombrecategoria}) {
    
    const productosDB2 =  [];
    const collectionRef = collection(db, "Almacen_campo/"+nombrecategoria+"/Productos".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      const valor = doc.data();
      if(valor.nombre===nombreproducto)
      {
      productosDB2.push(doc.data());
      }
    });
    return productosDB2;
    

}

