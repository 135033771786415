import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarCodigo_QR(producto) {
  console.log(producto);
  const coleccionRef = collection(db, "Codigos_QR/");
  const docuRef = doc(coleccionRef, producto.nombre.toString());
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
