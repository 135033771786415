import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import Prueba_QR from "../../views/Prueba_QR";

function ModalImprimir_QR({
  imprimir_QR,
  setimprimir_QR,
  ProductoEditar,
}) {

  console.log(ProductoEditar);
  
  return (
    <Modal show={imprimir_QR} onHide={() => setimprimir_QR(false)}>
      <Modal.Body>
         <Prueba_QR ProductoEditar={ProductoEditar}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setimprimir_QR(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalImprimir_QR;
