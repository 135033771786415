import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React, {useState} from 'react';

function añadirProducto(productos_actual2, pagina2) {
  console.log(productos_actual2)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Almacen_campo/"+pagina2+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, productos_actual2.nombre);
  setDoc(docRef, productos_actual2);
      
}

export default añadirProducto;
