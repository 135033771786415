import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarManifiesto(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Manifiestos/Manifiestos/Manifiestos/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.fecha_actual3);
  updateDoc(docRef, infoProducto);
  //escribirLog("Edición", infoProducto, autor);
}

export default editarManifiesto;

