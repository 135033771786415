import React from "react";
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home_crear_usuario from '../views/Home_crear_usuario'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ErrorBoundary from "../components/Salvavidas"
const auth = getAuth(firebaseApp);



function CRUD_crear_usuario({pagina, start, Pagina_principal}){
  
    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
    <div>
      <ErrorBoundary>
       {usuario ? <Home_crear_usuario usuario={usuario} pagina={pagina} start={start} Pagina_principal={Pagina_principal}></Home_crear_usuario>: <Login2 />}
      </ErrorBoundary>
    </div>
    );
}




export default CRUD_crear_usuario;