import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getLogs_almacen_campo_costos() {
    const Logs_campo =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Logs_campo.push(doc.data());
    });
    return Logs_campo;
    

}
