import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React, {useState} from 'react';

function añadirCategoria(valor_categoria) {
  const db = getFirestore(firebaseApp);
  const categoria= valor_categoria;
  const infoProducto = {categoria};
  let collectionRef = collection(db, "Categorias_productos".replace(/ /g, ""));   
  const docRef = doc(collectionRef, valor_categoria);
  setDoc(docRef, infoProducto);
  //console.log(window.location.pathname)
  //escribirLog("Creación", infoProducto, autor);
   
}

export default añadirCategoria;
