import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editar_consultar(infoProducto, auxiliar) {
  const db = getFirestore(firebaseApp);
  console.log("Nominas/"+auxiliar+"/Nombres/");
  console.log(infoProducto);
  const auxiliar2 = infoProducto.fecha;
  const collectionRef = collection(db,"Nominas/"+auxiliar+"/Nombres/".replace(/ /g, ""));
  const docRef = doc(collectionRef, auxiliar2);
  updateDoc(docRef, infoProducto);
}

export default editar_consultar;
