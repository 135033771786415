import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs} from "firebase/firestore";
const db = getFirestore(firebaseApp);

    

export default async function getProductos_afectaciones_fiscales() {
    const FiscalesDB =  [];
    console.log("Fiscales");
    const collectionRef = collection(db, "Bancos/Afectaciones_fiscales/Productos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      FiscalesDB.push(doc.data());
    });
    return FiscalesDB;
}