import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React from 'react';

function añadirInforme_diario(infoProducto, Presentacion) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Produccion/Produccion_diario/Informe_diario".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.fecha2);
  setDoc(docRef, infoProducto);
  console.log(Presentacion)
      
}

export default añadirInforme_diario;
