import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarTamaño_cajas(datos) {
  const coleccionRef = collection(db, "Manifiestos/Tamaños_cajas/Productos/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, datos.nombre);
  const eliminado = await deleteDoc(docuRef);
  console.log("Hola")
  return eliminado;
}
