import React from 'react';
import CRUD_campo_secciones from '../Firebase/CRUDS/CRUD_campo_secciones';


const Campo_secciones = () =>{
    return(
        <div>
            <div >
              
            <CRUD_campo_secciones/>
            </div>
        </div>
    )
}

export default Campo_secciones;