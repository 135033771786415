import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_restar_carton_empaque(accion, usuario, cantidad_cajas_retiradas,carton_seleccionado,Presentacion, date, movimiento,
  carton_seleccionada_filtrada, productosDB2, cosecha_seleccionada, orden_compra ) {

  console.log(accion, usuario, cantidad_cajas_retiradas, carton_seleccionado, movimiento, carton_seleccionada_filtrada);
  var persona= usuario.email;
  if(orden_compra == undefined){
    orden_compra = "";
  }else{persona= "Se genero desde la orden de compra con folio "+orden_compra}

  const cosecha= "NA";
  const retirado= "NA";
  //let date = new Date();
  let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, date.toString());
  const data = {
    accion,
    fecha: fecha_actual,
    fecha3: date,
    Hora: hora_actual,
    Autor: persona,
    Seccion: Presentacion,
    Cantidad_retirada: cantidad_cajas_retiradas,
    Cantidad_agregada: retirado,
    Producto_retirado: carton_seleccionado,
    Titulo: date.toString(),
    Cosecha: cosecha,
    Orden_folio: orden_compra,
    Movimiento: movimiento,
    precio: carton_seleccionada_filtrada[0].precio,
    lleva_iva: carton_seleccionada_filtrada[0].lleva_iva,
    moneda: carton_seleccionada_filtrada[0].moneda,
    categoria: carton_seleccionada_filtrada[0].categoria,
    tamaño: productosDB2[0].tamaño,
    etiqueta: productosDB2[0].etiqueta,
    tamaño_mas_etiqueta: productosDB2[0].tamaño_mas_etiqueta,
    Cosecha_seleccionada_retirada: cosecha_seleccionada,
  };

  setDoc(docuRef, data)
  //setDoc(docuRef, data);
  
}
