import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import getClientes from "../../functions/Produccion/getClientes";
import getAllmanifiestos from "../../functions/Produccion/getAllmanifiestos";
import getAllPagos from "../../functions/Produccion/getAllPagos";
import añadirpago_cliente from "../../functions/Produccion/añadirpago_cliente";
import eliminarPagos_manifiestos from "../../functions/Produccion/eliminarPagos_manifiestos";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";

function ModalCartera_nacional({
  Cartera_nacional,
  setCartera_exportacion,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
  Manifiesto_Seleccionado,
}) {

  //Convertir a formato moneda
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

     //Eliminar con sweetalert2
 function eliminar_sweetalert(producto2) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el pago del día?  "'+ producto2.fecha+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El pago ha sido eliminado.',
        'success'
      )
      restar_eliminado(producto2);
     }
  })
}
  
function restar_eliminado(producto2){

  var Total_Pagos_manifiesto = 0;  
  console.log(Datos_pagos);
  Datos_pagos.map((Auxiliar)=>{
    const n_str = Total_Pagos_manifiesto.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const n_nuevaStr = n_str.replace("$", "");
    const n_nuevaStr2 = n_nuevaStr.replace(",", "");
    const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
    var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
    Total_Pagos_manifiesto = formatter.format(+n_nuevaStr4 + +Auxiliar.cantidad_pago);
    })

  const n_str = Total_Pagos_manifiesto.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const n_nuevaStr = n_str.replace("$", "");
  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
  console.log(n_nuevaStr4 ,  producto2.cantidad_pago)
  Total_Pagos_manifiesto = formatter.format(n_nuevaStr4 - producto2.cantidad_pago);
  console.log(Total_Pagos_manifiesto);
  var eliminar_producto = true;
  var infoProducto = {manifiesto: producto2.manifiesto};
  añadirpago_cliente(infoProducto, Total_Pagos_manifiesto, eliminar_producto);

  eliminarPagos_manifiestos(producto2).then(
    (confirmacion) => {
      var producto2 = manifiestoSelect;
      pagos(producto2);
    }
  );
}


  const [Datos_pagos, setDatos_pagos] = React.useState([]);
  const [Manifiesto_para_pago, setManifiesto_para_pago] = React.useState([]);
  const [manifiestoSelect, setmanifiestoSelect] = React.useState([]);

  async function pagos(producto2){
    setManifiesto_para_pago(producto2);
    await getAllPagos(producto2).then((PagosDB) => {
      setmanifiestoSelect(producto2);
      console.log(PagosDB);
      setDatos_pagos(PagosDB);
      setClientesOn(false);
      setManifiestosOn(false);
      setPagosOn(true);
      setPago_nuevoOn(false);
    })
  }
  
 const [Pago_nuevoOn, setPago_nuevoOn] = React.useState(false);  
  async function realizar_un_pago(){
      setClientesOn(false);
      setManifiestosOn(false);
      setPagosOn(false);
      setPago_nuevoOn(true);
  }

  //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  async function pagar_ok() {
    //Esto sirve para la seleccion de la fecha
    let date =fechaSeleccionada;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
 
   var fecha_existe = false;
   Datos_pagos.map((dato)=>{
      if(dato.fecha == fecha_actual){
        fecha_existe = true;
     
      }
    })

  if(fecha_existe == true){
    Swal.fire({
      icon: 'error',
      text: "La fecha seleccionada para el pago ya existe en otro pago por lo que no es posible realizar el pago.",
      footer: '¿Que hacer? <br/> Se debe seleccionar una fecha que no exista en ningun pago o en su defecto, eliminar el pago que tiene la fecha que se desea seleccionar.' 
    })
    regresar_a_manifiestos();
  }else{
    const cantidad_pago = document.getElementById("cantidad_pago").value;
    const n_str = manifiestoSelect.saldo.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const n_nuevaStr = n_str.replace("$", "");
    const n_nuevaStr2 = n_nuevaStr.replace(",", "");
    const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
    var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
    console.log(cantidad_pago, n_nuevaStr4  );
    if(parseFloat(cantidad_pago) > parseFloat(n_nuevaStr4)){
      Swal.fire({
        icon: 'error',
        text: "El pago que se desea realizar es mayor al saldo del manifiesto por lo que no se puede realizar dicho pago.",
        footer: '¿Que hacer? <br/> Se debe ingresar un pago igual o menor al saldo. El saldo actual es de ' +manifiestoSelect.saldo + ' y lo que quiere pagar es ' +formatter.format(cantidad_pago)
      })
    }else{

    //obtener infor del formulario
    const fecha = fecha_actual;
    const manifiesto = Manifiesto_para_pago.fecha_actual3;

    // enviar informacion a firebase
    const infoProducto = { fecha, cantidad_pago, manifiesto };

    var Total_Pagos_manifiesto = 0;  
    Datos_pagos.map((Auxiliar)=>{
      const n_str = Total_Pagos_manifiesto.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const n_nuevaStr = n_str.replace("$", "");
      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      Total_Pagos_manifiesto = formatter.format(+n_nuevaStr4 + +Auxiliar.cantidad_pago);
      })

    const n_str = Total_Pagos_manifiesto.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const n_nuevaStr = n_str.replace("$", "");
    const n_nuevaStr2 = n_nuevaStr.replace(",", "");
    const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
    var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
    console.log(n_nuevaStr4 , cantidad_pago)
    Total_Pagos_manifiesto = formatter.format(+n_nuevaStr4 + +cantidad_pago);
    añadirpago_cliente(infoProducto, Total_Pagos_manifiesto);
    // cerrar modal
    var producto2 = manifiestoSelect;
    //pagos(producto2)
    regresar_a_manifiestos();
      }//Condición de pago ingresado mayor que saldo
    }//Condicion de fecha existe
  }

  //console.log(Categorias_productos)
  function salir(){
    setCartera_exportacion(false)
  }

  //Cliente seleccionado
  const [Cliente_seleccionado, setCliente_seleccionado] = React.useState([]);
  //Manifiestos del Cliente seleccionado
  const [Cliente_seleccionado_Manifiestos, setCliente_seleccionado_Manifiestos] = React.useState([]);

 async function abrirManifiestos(producto){
  await getAllmanifiestos().then((ManifiestosDB) => {
    var Manifiesto_filtrados_por_cliente = [];
    ManifiestosDB.map((dato)=>{
      if(dato.cliente==producto.nombre){
        Manifiesto_filtrados_por_cliente.push(dato)
      }
    })
console.log(Manifiesto_filtrados_por_cliente);
    Manifiesto_filtrados_por_cliente.map((valor)=>{
      if(valor.Total_Pagos_manifiesto==undefined){
        valor['saldo']= formatter.format(valor.total_precios_manifiesto);
      }else{
        //Con esto convierto el saldo a valor flotante ya que tiene un $
        const n_str = (valor.Total_Pagos_manifiesto).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const n_nuevaStr = n_str.replace("$", "");
        const n_nuevaStr2 = n_nuevaStr.replace(",", "");
        const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
        var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
        valor['saldo']= formatter.format(valor.total_precios_manifiesto - n_nuevaStr4)
      }
      
    })
    setCliente_seleccionado_Manifiestos(Manifiesto_filtrados_por_cliente);
    setCliente_seleccionado(producto);
    setClientesOn(false);
    setManifiestosOn(true);
    setPagosOn(false);
    setPago_nuevoOn(false);
    });

  }

  function regresar_a_manifiestos(){
    var producto = Cliente_seleccionado;
    abrirManifiestos(producto)
    setClientesOn(false);
    setManifiestosOn(true);
    setPagosOn(false);
    setPago_nuevoOn(false);
  }

  const [Clientes, setClientes] = React.useState([]);

  async function Inicio(){
    var auxiliar = "exportacion";
    await getClientes(auxiliar).then((clientesDB) => {
      calcular_total_debe_pagado(clientesDB)
      });
    
  }

  const [ClientesOn, setClientesOn] = React.useState(false);
  const [ManifiestosOn, setManifiestosOn] = React.useState(false);
  const [PagosOn, setPagosOn] = React.useState(false);

  async function calcular_total_debe_pagado(clientesDB){
    await getAllmanifiestos().then((ManifiestosDB) => {
      var clientes_con_total_debe = 0;
      var clientes_con_total_pagado = 0;


            clientesDB.map((cliente)=>{
              //Se reinicia el valor a 0 para cada cliente si no le sumaria lo del cliente pasado
              clientes_con_total_debe = 0;
              clientes_con_total_pagado = 0;

                  ManifiestosDB.map((manifiesto)=>{
                        if(cliente.nombre==manifiesto.cliente){
                                //console.log(manifiesto)
                                //console.log(clientes_con_total_debe)
                                //console.log(manifiesto.total_precios_manifiesto);
                                clientes_con_total_debe= clientes_con_total_debe + parseFloat(manifiesto.total_precios_manifiesto);
                                //console.log(clientes_con_total_debe)
                                  if(manifiesto.Total_Pagos_manifiesto==undefined){
                                  }else{
                                      //Con esto convierto el saldo a valor flotante ya que tiene un $
                                      const n_str = (manifiesto.Total_Pagos_manifiesto).toString();
                                      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                                      const n_nuevaStr = n_str.replace("$", "");
                                      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                                      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                                      var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                                      clientes_con_total_pagado= clientes_con_total_pagado + parseFloat(n_nuevaStr4);
                                  }
                        }
                  }) //Fin segundo map

              cliente['debe'] = clientes_con_total_debe;
              cliente['pagado'] = clientes_con_total_pagado;
            }) //Fin primer map

      });
      setClientes(clientesDB);
      console.log(clientesDB);
      setClientesOn(true);
      setManifiestosOn(false);
      setPagosOn(false);
  }
  
  React.useEffect(() => {
    Inicio();
  }, []);

  return (
   <div >
{/* ---------------------------------------------Clientes nacionales-------------------------------------------------------------- */}

    <Modal id="Modal_Logs" show={ClientesOn} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Cartera exportación</Modal.Title>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
        

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Cliente exportación</th>
            <th>Debe</th>
            <th>Pagado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Clientes.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td>{formatter.format(producto.debe)}</td>
                <td>{formatter.format(producto.pagado)}</td>
                  <td>
                          {/* <Button 
                            variant="dark"
                            onClick={() => {
                              salir();
                            }}
                          >
                            Editar
                          </Button> */}
                          <Button
                            variant="success"
                            onClick={() => {
                              abrirManifiestos(producto);
                            }}
                          >
                            Manifiestos
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>
    
{/* ---------------------------------------------Manifiestos-------------------------------------------------------------- */}
    <Modal id="Modal_Logs" show={ManifiestosOn} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Todos los manifiestos del cliente {Cliente_seleccionado.nombre} </Modal.Title>
        <Button variant="secondary" onClick={() => {
                      Inicio();
                    }}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body> 
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Manifiesto</th>
            <th>Fecha</th>
            <th>Total manifiesto MXN</th>
            <th>Total pagado</th>
            <th>Saldo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Cliente_seleccionado_Manifiestos.map((producto2, index) => (    
              <tr key={index}>
                <td>{producto2.manifiesto}</td>
                <td>{producto2.fecha}</td>
                <td>{formatter.format(producto2.total_precios_manifiesto)}</td>
                <td>{producto2.Total_Pagos_manifiesto}</td>
                <td>{producto2.saldo}</td>
                  <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      pagos(producto2)
                    }}
                  >
                    Pagos
                  </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>

{/* ---------------------------------------------PAGOS-------------------------------------------------------------- */}
<Modal id="Modal_Logs" show={PagosOn} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Pagos </Modal.Title>
        <Button onClick={() => {
                      realizar_un_pago();
                    }}>
          Nuevo pago
        </Button>

        <Button variant="secondary" onClick={() => {
                      regresar_a_manifiestos();
                    }}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body> 
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Total pago MXN</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Datos_pagos.map((producto2, index) => (    
              <tr key={index}>
                <td>{producto2.fecha}</td>
                <td>{formatter.format(producto2.cantidad_pago)}</td>
                  <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto2)
                    }}
                  >
                    Eliminar
                  </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>  
    {/* ---------------------------------------------Pago Nuevo-------------------------------------------------------------- */}
<Modal show={Pago_nuevoOn} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Pagos </Modal.Title>
        <Button onClick={() => {
                      regresar_a_manifiestos();
                    }}>
          Regresar
        </Button>
      </Modal.Header>
      <Modal.Body> 
      <h6>Seleccionar fecha dando click</h6>
      <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
      <br/>
      <Form.Control
              id="cantidad_pago"
              placeholder="Ingresar la cantidad del pago"
              type="number"
              className="mb-1"
            />
      </Modal.Body>
       <Modal.Footer>
          <Button variant="success" onClick={() => {
                          pagar_ok();
                        }}>
              Agregar
          </Button>
       </Modal.Footer>
    </Modal> 
  </div> 
  );
}

export default ModalCartera_nacional;

//---------------------------------------------------------------------

/* import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import getClientes from "../../functions/Produccion/getClientes";
import añadirCliente_Embarque from "../../functions/Produccion/añadirCliente_Embarque";
import getAllmanifiestos from "../../functions/Produccion/getAllmanifiestos";
import editarManifiesto_pagado_si_no from "../../functions/Produccion/editarManifiesto_pagado_si_no";

function ModalCartera_exportacion({
  Cartera_nacional,
  setCartera_exportacion,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
}) {

  //Convertir a formato moneda
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

  //Cambiar el estado de pagado de no a si
  async function cambiar_a_si(producto2){
    var auxiliar_cambio = "Si"
    await editarManifiesto_pagado_si_no(producto2,auxiliar_cambio);
    console.log(producto2)
    actualizar(producto2);
  }

  //Cambiar el estado de pagado de no a si
  async function cambiar_a_no(producto2){
    var auxiliar_cambio = "No"
    await editarManifiesto_pagado_si_no(producto2,auxiliar_cambio);
    console.log(producto2)
    actualizar(producto2);
  }

const [Boton_si_noActivado, setBoton_si_noActivado] = React.useState(false);

  async function actualizar(producto2){
    await getAllmanifiestos().then((ManifiestosDB) => {
      var Manifiesto_filtrados_por_cliente = [];
      ManifiestosDB.map((dato)=>{
        if(dato.cliente==producto2.cliente){
          Manifiesto_filtrados_por_cliente.push(dato)
        }
      })
      setCliente_seleccionado_Manifiestos(Manifiesto_filtrados_por_cliente);
      setBoton_si_noActivado(true);
      console.log(Manifiesto_filtrados_por_cliente);
      });
  
    }

  //Modal para agregar categorias
  const [Agregar_categoriasON, setAgregar_categoriasON] = React.useState(false);

  //console.log(Categorias_productos)
  function salir(){
    setCartera_exportacion(false);
  }

  //Cliente seleccionado
  const [Cliente_seleccionado, setCliente_seleccionado] = React.useState([]);
  //Manifiestos del Cliente seleccionado
  const [Cliente_seleccionado_Manifiestos, setCliente_seleccionado_Manifiestos] = React.useState([]);

 async function abrirManifiestos(producto){
  await getAllmanifiestos().then((ManifiestosDB) => {
    var Manifiesto_filtrados_por_cliente = [];
    ManifiestosDB.map((dato)=>{
      if(dato.cliente==producto.nombre){
        Manifiesto_filtrados_por_cliente.push(dato)
      }
    })
    setCliente_seleccionado_Manifiestos(Manifiesto_filtrados_por_cliente);
    setCliente_seleccionado(producto);
    setInicio_modal(false);
    setAgregar_categoriasON(true);
    console.log(Manifiesto_filtrados_por_cliente);
    });

  }

  function regresar_categorias(){
    Inicio();
    setAgregar_categoriasON(false);
    setInicio_modal(true);
  }

  const [Inicio_modal, setInicio_modal] = React.useState(false);
  const [Clientes, setClientes] = React.useState([]);

  async function Inicio(){
    var auxiliar = "exportacion";
    await getClientes(auxiliar).then((clientesDB) => {
      calcular_total_debe_pagado(clientesDB)
      });
    
  }

  async function calcular_total_debe_pagado(clientesDB){
    await getAllmanifiestos().then((ManifiestosDB) => {
      var clientes_con_total_debe = 0;
      var clientes_con_total_pagado = 0;


            clientesDB.map((cliente)=>{
              //Se reinicia el valor a 0 para cada cliente si no le sumaria lo del cliente pasado
              clientes_con_total_debe = 0;
              clientes_con_total_pagado = 0;

                  ManifiestosDB.map((manifiesto)=>{
                        if(cliente.nombre==manifiesto.cliente){
                              if(manifiesto.pagado_si_no=="Si"){
                                clientes_con_total_pagado = clientes_con_total_pagado + parseFloat(manifiesto.total_flete);
                              }
                        
                              if(manifiesto.pagado_si_no=="No"){
                                clientes_con_total_debe= clientes_con_total_debe + parseFloat(manifiesto.total_flete);
                              }
                        }
                  }) //Fin segundo map

              cliente['debe'] = clientes_con_total_debe;
              cliente['pagado'] = clientes_con_total_pagado;
            }) //Fin primer map

      });
      setClientes(clientesDB);
      if(Boton_si_noActivado){
        setBoton_si_noActivado(false);
      }else{setInicio_modal(true);}
      console.log(Boton_si_noActivado);
      console.log(clientesDB);
  }

  function añadir_categoria(){
     //obtener infor del formulario
    //const proveedor = document.getElementById("proveedor").value;
    const nombre = document.getElementById("nombre_cliente").value;
    const nacional_exportacion = document.getElementById("nacional_exportacion").value;
    const ciudad = document.getElementById("ciudad").value;
    const direccion = document.getElementById("direccion").value;
    const rfc = document.getElementById("rfc").value;
    // enviar informacion a firebase
    const infoProducto = {nombre, nacional_exportacion, ciudad, direccion, rfc};
    añadirCliente_Embarque(infoProducto);
    // cerrar modal
    actualizarEstadoProductos();
    setCartera_exportacion(false);
    setInicio_modal(true);
  }
  
  React.useEffect(() => {
    Inicio();
  }, []);




  return (
   <div >
    <Modal id="Modal_Logs" show={Inicio_modal} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Control clientes de exportación</Modal.Title>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
        

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Cliente exportación</th>
            <th>Debe</th>
            <th>Pagado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Clientes.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td>{formatter.format(producto.debe)}</td>
                <td>{formatter.format(producto.pagado)}</td>
                  <td>
                          <Button
                            variant="success"
                            onClick={() => {
                              abrirManifiestos(producto);
                            }}
                          >
                            Manifiestos
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>
    
    <Modal id="Modal_Logs" show={Agregar_categoriasON} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Todos los manifiestos del cliente {Cliente_seleccionado.nombre} </Modal.Title>

      </Modal.Header>
      <Modal.Body> 
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Manifiesto</th>
            <th>Fecha</th>
            <th>Total flete MXN</th>
            <th>Pagado Si/No</th>
            <th>¿Ya se pagó?</th>
          </tr>
        </thead>
        <tbody>
        {Cliente_seleccionado_Manifiestos.map((producto2, index) => (    
              <tr key={index}>
                <td>{producto2.manifiesto}</td>
                <td>{producto2.fecha}</td>
                <td>{formatter.format(producto2.total_flete)}</td>
                <td>{producto2.pagado_si_no}</td>
                  <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      cambiar_a_si(producto2)
                    }}
                  >
                    Si
                  </Button>
                  </td>
                  <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      cambiar_a_no(producto2)
                    }}
                  >
                    No
                  </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      regresar_categorias();
                    }}>
          Regresar
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  );
}

export default ModalCartera_exportacion; */