import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getClientes_Embarque(pagina) {
    

  //console.log(valor);
    const clientesDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Produccion_clientes_embarque/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      clientesDB.push(doc.data());
    });
    return clientesDB;
    

}
