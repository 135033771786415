import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function Traer_trabajadores_concentrado_general(año_seleccionado, semana_seleccionada) {
    console.log(año_seleccionado, semana_seleccionada);
    const Trabajadores_concentradoDB =  [];
    const collectionRef = collection(db, "Nominas/Pagos_semanales/"+año_seleccionado+"/"+semana_seleccionada+"/Trabajadores/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Trabajadores_concentradoDB.push(doc.data());
    });
    console.log(Trabajadores_concentradoDB)
    return Trabajadores_concentradoDB;
} 





