import React from "react";
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home_Bancos from '../views/Home_Bancos'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD_Bancos(){

    

    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
    <Container fluid>
      {usuario ? <Home_Bancos usuario={usuario}></Home_Bancos>: <Login2 />}
    </Container>
    );
}




export default CRUD_Bancos;