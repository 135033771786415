import React from "react";
import Container from "react-bootstrap/Container"
import Login2 from '../views/Login2'
import Home from '../views/Home'
import firebaseApp from "../firebase/credenciales";
import { getAuth, onAuthStateChanged} from "firebase/auth";
const auth = getAuth(firebaseApp);



function CRUD({pagina, start, Pagina_principal}){
  
    const [usuario, setUsuario] = React.useState(null);

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });
    return(
    <div>
      {usuario ? <Home usuario={usuario} pagina={pagina} start={start} Pagina_principal={Pagina_principal}></Home>: <Login2 />}
      
    </div>
    );
}




export default CRUD;