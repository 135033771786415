import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, getDocs,  query, orderBy, limit } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllProducts(pagina2, start) {
  console.log(start)
  const productos = [];
  let collectionRef = collection(db, window.location.pathname);
  
  if(start){ 
      collectionRef = collection(db, "Almacen_campo/"+pagina2+"/Productos".replace(/ /g, ""))
      }else{ collectionRef = collection(db, window.location.pathname)}
  
  const snapshot = await getDocs(collectionRef);
  
  snapshot.forEach((doc) => {
    productos.push(doc.data());
  });
  return productos;
}
