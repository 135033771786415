import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_productos_tablas(infoProducto, Nombre_de_boton, Cantidad_actual ) {
  console.log(Cantidad_actual);
  console.log(Nombre_de_boton.nombre_banco);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Bancos/"+Nombre_de_boton.nombre_banco+"/");
  const docRef = doc(collectionRef, (infoProducto.nombre).toString());
  setDoc(docRef, infoProducto);  
  
  //Agregar a afectación--------------------------------------------------------------------------------------------------------------------
  if(infoProducto.afectacion_fiscal_1==""){
  }else{
    const db2 = getFirestore(firebaseApp);
    const collectionRef2 = collection(db2, "Bancos/Afectaciones_fiscales_productos/"+infoProducto.afectacion_fiscal_1+"/");
    const docRef2 = doc(collectionRef2, (infoProducto.fecha3).toString());
    setDoc(docRef2, infoProducto); 
  }

  if(infoProducto.afectacion_fiscal_2==""){
  }else{
    const db2 = getFirestore(firebaseApp);
    const collectionRef2 = collection(db2, "Bancos/Afectaciones_fiscales_productos/"+infoProducto.afectacion_fiscal_2+"/");
    const docRef2 = doc(collectionRef2, (infoProducto.fecha3).toString());
    setDoc(docRef2, infoProducto); 
  }

  if(infoProducto.afectacion_fiscal_3==""){
  }else{
    const db2 = getFirestore(firebaseApp);
    const collectionRef2 = collection(db2, "Bancos/Afectaciones_fiscales_productos/"+infoProducto.afectacion_fiscal_3+"/");
    const docRef2 = doc(collectionRef2, (infoProducto.fecha3).toString());
    setDoc(docRef2, infoProducto); 
  }

  if(infoProducto.afectacion_fiscal_4==""){
  }else{
    const db2 = getFirestore(firebaseApp);
    const collectionRef2 = collection(db2, "Bancos/Afectaciones_fiscales_productos/"+infoProducto.afectacion_fiscal_4+"/");
    const docRef2 = doc(collectionRef2, (infoProducto.fecha3).toString());
    setDoc(docRef2, infoProducto); 
  }

  if(infoProducto.afectacion_fiscal_5==""){
  }else{
    const db2 = getFirestore(firebaseApp);
    const collectionRef2 = collection(db2, "Bancos/Afectaciones_fiscales_productos/"+infoProducto.afectacion_fiscal_5+"/");
    const docRef2 = doc(collectionRef2, (infoProducto.fecha3).toString());
    setDoc(docRef2, infoProducto); 
  }
  

}

export default añadir_productos_tablas;
