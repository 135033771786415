import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_total_costos(costos_totales_final,costo) {
  console.log(costos_totales_final)
  console.log(costo);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Gastos".replace(/ /g, ""));
  const docRef = doc(collectionRef,"Costos producción campo");
  setDoc(docRef, costo);
}

export default añadir_total_costos;
