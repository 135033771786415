import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  
export default async function eliminarLog(producto, pagina, cantidad_actual) {
  console.log(producto);
  console.log(pagina);
  console.log(cantidad_actual);
  
  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""))
  const docuRef = doc(collectionRef, producto.Titulo);
  const eliminado = await deleteDoc(docuRef);
  console.log("Hola")

  //Se agreaga el if ya que si no marca error al eliminar un productos agregados desde el almacen de empaque
  if(producto.Cantidad_retirada=="NA"){}else{
      if(producto.fecha2 == null){
      }else{
        console.log("Hola")
        const collectionRef2 = collection(db, "Produccion/"+pagina+"/Productos".replace(/ /g, ""))
        const docuRef2 = doc(collectionRef2, producto.fecha2);
        console.log("Hola")
        await deleteDoc(docuRef2);}
      }  


  if(producto.embarque=="Embarque"){
  /* const collectionRef2 = collection(db, "Produccion/Embarque/Productos".replace(/ /g, ""))
  const docuRef2 = doc(collectionRef2, producto.fecha2);
  await deleteDoc(docuRef2); */

  console.log(producto.Manifiesto,producto.fecha2 )
  const collectionRef40 = collection(db, "Manifiestos/Tarimas/"+producto.Manifiesto+"/".replace(/ /g, ""))
  const docuRef40 = doc(collectionRef40, producto.fecha2);
  console.log("Hola")
  await deleteDoc(docuRef40);

  //-----------------------------------------------------------------------------
  var auxiliar = formatter.format(+cantidad_actual + +producto.Cantidad_retirada);
  const str = auxiliar.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  var cantidad_actualizada_embarque = nuevaStr3.replace(",", "");

  //var cantidad_actualizada_embarque = parseFloat(cantidad_actual) + parseFloat(producto.Cantidad_retirada)
  console.log(cantidad_actualizada_embarque);
  const collectionRef6 = collection(db, "Almacen_empaque/"+pagina+"/Productos/")
  const docuRef6 = doc(collectionRef6, producto.Producto_retirado);
  console.log("Hola")
  await updateDoc(docuRef6,{
      cantidad: cantidad_actualizada_embarque
  });
  }

  //------------
  //Esto es para restar la cantidad que se elimino del Logs en el almacen de empaque
  if(producto.Cantidad_agregada=="NA"){
    if(producto.embarque=="Embarque"){console.log("EMBARQUE")}else{
      console.log("1");
      
      var auxiliar = formatter.format(+cantidad_actual + +producto.Cantidad_retirada);
      const str = auxiliar.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var cantidad_actualizada2 = nuevaStr3.replace(",", "");

    //var cantidad_actualizada2 = parseFloat(cantidad_actual) + parseFloat(producto.Cantidad_retirada)
    console.log(cantidad_actualizada2);
    const collectionRef6 = collection(db, "Almacen_empaque/"+pagina+"/Productos/")
    const docuRef6 = doc(collectionRef6, producto.Producto_retirado);
    console.log("Hola")
    await updateDoc(docuRef6,{
      cantidad: cantidad_actualizada2
    });}
  }

    if(producto.Cantidad_retirada=="NA"){
      console.log("1");

      var auxiliar = formatter.format(cantidad_actual - producto.Cantidad_agregada);
      const str = auxiliar.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var cantidad_actualizada2 = nuevaStr3.replace(",", "");

      //var cantidad_actualizada2 = parseFloat(cantidad_actual) - parseFloat(producto.Cantidad_agregada)
      const collectionRef6 = collection(db, "Almacen_empaque/"+pagina+"/Productos/")
      const docuRef6 = doc(collectionRef6, producto.Producto_retirado);
      console.log("Hola")
      await updateDoc(docuRef6,{
        cantidad: cantidad_actualizada2
      });
      }
      
  //Esto es para eliminar en las lineas de producción
  if(pagina=="Producto terminado"){
    console.log("HOLA");
  const collectionRef3 = collection(db, "Produccion/Lineas_produccion/"+producto.Seccion.replace(/ /g, ""))
  const docuRef3 = doc(collectionRef3, producto.Titulo);
  console.log("Hola")
  await deleteDoc(docuRef3);

  const collectionRef5 = collection(db, "Produccion/Produccion_diario/Informe_diario/".replace(/ /g, ""))
  const docuRef5 = doc(collectionRef5, producto.Titulo);
  console.log("Hola")
  await deleteDoc(docuRef5);

  //Esto es para restar la cantidad que se elimino del Logs en el almacen de empaque
  if(producto.embarque=="Embarque"){console.log("ok");}else{
  console.log("1");

  var auxiliar = formatter.format(cantidad_actual - producto.Cantidad_agregada);
  const str = auxiliar.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  var cantidad_actualizada = nuevaStr3.replace(",", "");

  //var cantidad_actualizada = parseFloat(cantidad_actual) - parseFloat(producto.Cantidad_agregada)
  const collectionRef4 = collection(db, "Almacen_empaque/Producto terminado/Productos/")
  const docuRef4 = doc(collectionRef4, producto.Producto_retirado);
  console.log("Hola")
  await updateDoc(docuRef4,{
    cantidad: cantidad_actualizada
  });
}
  
  }else{
  var Costos = "";
  if(producto.Seccion=="Tomate"){
    Costos = "Costos_Tomate"
  }
  if(producto.Seccion=="Chile"){
    Costos = "Costos_Chile"
  }
  if(producto.Seccion=="Calabaza"){
    Costos = "Costos_Calabaza"
  }
  if(producto.Seccion=="Tomatillo"){
    Costos = "Costos_Tomatillo"
  }

  console.log(producto.fecha);
  console.log(producto.fecha2);
  console.log(Costos);
  
  //Se agreaga el if ya que si no marca error al eliminar un productos agregados desde el almacen de empaque
  if(producto.Cantidad_retirada=="NA"){}else{
    console.log("Hola")
    if(producto.fecha2 == null){
    }else{
  const collectionRef3 = collection(db, "Produccion/Lineas_produccion/"+Costos.replace(/ /g, ""))
  const docuRef3 = doc(collectionRef3, producto.fecha2);
  console.log("Hola")
  await deleteDoc(docuRef3);}
    }
  }

  return eliminado;
}
