import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_total_proveedores(costo) {
  console.log(costo);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Gastos/".replace(/ /g, ""));
  const docRef = doc(collectionRef,"Total credito proveedores en pesos");
  setDoc(docRef, costo);
}

export default añadir_total_proveedores;
