import React from "react";

import { Modal, Stack, Form, Button } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import editarProducto from "../functions/editarProducto";
import escribirLog_agregar_producto from "../functions/Cultivo_en_proceso/escribirLog_agregar_producto";

function ModalEditar({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
  setProductos,
}) {

  async function actualizar(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);    
     });
   }

     //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  
   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

//Guardar en log lo que se agrego al almacen
  async function agregar_log(){
    const nombreproducto = productoEditar.nombre;
    const valor_cantidad_ingresado = document.getElementById("agregar_retirar").value;
  await escribirLog_agregar_producto("Agregar", usuario, valor_cantidad_ingresado, nombreproducto);
  }


  async function editarProductoModal() {
    //obtener infor del formulario
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = document.getElementById("moneda").value;
    const cantidad = productoEditar.cantidad;
    const unidad = productoEditar.unidad;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    await timeout(500);
    actualizar(pagina,start);
    setIsModalEditar(false);
  }

  async function agregarProductoModal(e) { 
    //obtener infor del formulario
    console.log(productoEditar)
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = productoEditar.moneda;

        //Esto es para restar si se agrego desde el almacen
        var auxiliar = formatter.format(+productoEditar.cantidad + +(document.getElementById("agregar_retirar").value).toString());
        const str = auxiliar.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        var cantidad = nuevaStr3.replace(",", "");

    //const cantidad = ((+productoEditar.cantidad) + (+document.getElementById("agregar_retirar").value)).toString();
    console.log(cantidad)
    const unidad = productoEditar.unidad;
    
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    agregar_log();
    setProductoEditar(null);
    await timeout(500);
    actualizar(pagina,start);
    setIsModalEditar(false);
  }
  
  function retirarProductoModal() {
    //obtener infor del formulario
    const nombre = productoEditar.nombre;
    const precio = productoEditar.precio;
    const moneda = productoEditar.moneda;
    const cantidad = ((+productoEditar.cantidad) - (+document.getElementById("agregar_retirar").value)).toString();
    console.log(cantidad)
    const unidad = productoEditar.unidad;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }


  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      
       <p id="Modal_movimiento">Producto actual (se puede editar la moneda cambiando el valor y presionando el botón de editar)</p> 
            <hr/>
            <p>Seleccionar moneda</p>
            <select id="moneda" type="text">
              <option>Pesos</option>
              <option>Dolares</option>
            </select>
            <br/>
            <br/>
      
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 
      </Modal.Body>

      <Modal.Footer>  
        <br/>
      <p style={{marginRight: "32%"}}>Cantidad del producto que se desea agregar</p>  
        <Form.Control
        id="agregar_retirar"
        placeholder="ingresar cantidad a agregar"
        type="text"
        className="mb-1"
        />        
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
  
        <Button variant="primary" onClick={agregarProductoModal}>
          Agregar
        </Button>
        {/* <Button variant="primary" onClick={retirarProductoModal}>
          Retirar
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar;


/* import React from "react";

import { Modal, Stack, Form, Button } from "react-bootstrap";

import editarProducto from "../functions/editarProducto";

function ModalEditar({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  start,
}) {
  function editarProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("nombre").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cantidad = document.getElementById("cantidad").value;
    const unidad = document.getElementById("unidad").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }

  function agregarProductoModal(e) { 
    //obtener infor del formulario
    //console.log(productoEditar.nombre)
    const nombre = document.getElementById("nombre").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cantidad = ((+document.getElementById("cantidad").value) + (+document.getElementById("agregar_retirar").value));
    const unidad = document.getElementById("unidad").value;
    
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }
//window.location.reload(true);
  function retirarProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("nombre").value;
    const precio = document.getElementById("precio").value;
    const moneda = document.getElementById("moneda").value;
    const cantidad = ((+document.getElementById("cantidad").value) - (+document.getElementById("agregar_retirar").value));
    const unidad = document.getElementById("unidad").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, moneda, cantidad, unidad };
    editarProducto(infoProducto, usuario.email,pagina,start);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }


  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p id="Modal_movimiento">Producto actual (se puede editar nombre, cantidad, etc. cambiando el valor y presionando el botón de editar)</p> 
        <Form>
          <Stack>
            <Form.Control
              id="nombre"
              placeholder="Nombre"
              type="text"
              className="mb-1"
              value={productoEstado?.nombre}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  nombre: e.target.value,
                })
              }
            />
            <Form.Control
              id="precio"
              placeholder="precio"
              type="number"
              className="mb-1"
              value={productoEstado?.precio}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  precio: e.target.value,
                })
              }
            />
            <br/>
            <h6>Seleccionar moneda</h6>
            <select id="moneda" type="text">
              <option>Pesos</option>
              <option>Dolares</option>
            </select>
            <br/>
            <Form.Control
              id="cantidad"
              placeholder="cantidad"
              type="number"
              className="mb-1"
              value={productoEstado?.cantidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  cantidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="unidad"
              placeholder="Ingresar unidad de unidad del producto"
              type="text"
              className="mb-1"
              value={productoEstado?.unidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  unidad: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        {/* <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button>  */
      /* </Modal.Body>

      <Modal.Footer>  
      <p id="Modal_movimiento">Cantidad del producto que se desea retirar o agregar</p>  
        <Form.Control
        id="agregar_retirar"
        placeholder="ingresar cantidad a retirar o agregar"
        type="text"
        className="mb-1"
        />        
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
  
        <Button variant="primary" onClick={agregarProductoModal}>
          Agregar
        </Button>
        <Button variant="primary" onClick={retirarProductoModal}>
          Retirarr
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar; */ 

