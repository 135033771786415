import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import escribirLog from "../escribirLog";

function añadirProducto(infoProducto, autor,pagina) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Cultivo_en_proceso/"+pagina+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.sku);
  setDoc(docRef, infoProducto);
  escribirLog("Edición", infoProducto, autor);
}

export default añadirProducto;
