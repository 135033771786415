import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import escribirLog from "../escribirLog";

function añadirProducto(infoProducto, autor,pagina) {
  
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Campo_secciones/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);
  escribirLog("Edición", infoProducto, autor);
  
}

export default añadirProducto;
