import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import escribirLog from "../escribirLog";

function añadirProducto(infoProducto, autor,pagina,start) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Almacen_empaque/"+pagina+"/Productos".replace(/ /g, ""))  
  const docRef = doc(collectionRef, infoProducto.nombre);
  updateDoc(docRef, infoProducto);

  escribirLog("Edición", infoProducto, autor);
}

export default añadirProducto;
