import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getProductos_empaque(pagina) {
  const productos = [];
  console.log(pagina);
  let collectionRef = collection(db, "Almacen_empaque/"+pagina+"/Productos/")

  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    productos.push(doc.data());
  });
  return productos;
}
;