import React, {useState, useEffect} from 'react';
import { Routes, Route} from 'react-router-dom'
import Inicio from './Componentes//Paginas/Inicio'
import Trabajo from './Componentes/Paginas/Trabajo'
import Inventarios from './Componentes/Paginas/Inventarios'
import Produccion_empaque from './Componentes/Paginas/Produccion_empaque'
import Cultivo_en_proceso from './Componentes/Paginas/Cultivo_en_proceso'
import Login from './Componentes/Paginas/Login'
import Almacen_campo from './Componentes/Paginas/Almacen_campo'
import Almacen_empaque from './Componentes/Paginas/Almacen_empaque'
import Fertilizantesyagroquimicos from './Componentes/Paginas/Fertilizantesyagroquimicos' 
import Ordenes_compra from './Componentes/Paginas/Ordenes_compra'
import Productos from './Componentes/Paginas/Productos'
import Proveedores from './Componentes/Paginas/Proveedores'
import Ordenes from './Componentes/Paginas/Ordenes'
import Iniciofin_ciclo from './Componentes/Paginas/Iniciofin_ciclo'
import Campo_secciones from './Componentes/Paginas/Campo_secciones';
import Crear_usuario from './Componentes/Paginas/Crear_usuario';
import Nominas from './Componentes/Paginas/Nominas'
import Gastos from './Componentes/Paginas/Gastos';
import Bancos from "./Componentes/Paginas/Bancos"
import Codigos_QR from './Componentes/Paginas/Codigos_QR';
import './index.css'

//Importar productos para visualizar en pagina web
import Tomate from './Componentes/Paginas/Productos_pagina/Tomate';
import Calabaza from './Componentes/Paginas/Productos_pagina/Calabaza';
import Jalapeño from './Componentes/Paginas/Productos_pagina/Jalapeño';
import Trigo from './Componentes/Paginas/Productos_pagina/Trigo';

function App(){
  return(
    <div className="App" >
     <center>
          <div className="login">  
              <Routes>             
                <Route exact path="/" element={<Inicio/>} />
                <Route exact path="/Inicio" element={<Inicio/>} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Trabajo" element={<Trabajo/>} />
                <Route exact path="/Inventarios" element={<Inventarios />} id="titulo"/>
                <Route exact path="/Produccion" element={<Produccion_empaque/>} />
                <Route exact path="/Cultivo_en_proceso" element={<Cultivo_en_proceso/>} />
                <Route exact path="/Almacen_campo" element={<Almacen_campo/>} />
                <Route exact path="/Almacen_empaque" element={<Almacen_empaque/>} />
                <Route exact path="/Ordenes_compra" element={<Ordenes_compra/>} />
                <Route exact path="/Fertilizantesyagroquimicos" element={<Fertilizantesyagroquimicos/>} />
                <Route exact path="/Proveedores" element={<Proveedores/>} />
                <Route exact path="/Productos" element={<Productos/>} />
                <Route exact path="/Ordenes" element={<Ordenes/>} />
                <Route exact path="/Iniciofin_ciclo" element={<Iniciofin_ciclo/>} />  
                <Route exact path="/Campo_secciones" element={<Campo_secciones/>} />  
                <Route exact path="/Usuarios" element={<Crear_usuario/>} /> 
                <Route exact path="/Nominas" element={<Nominas/>} /> 
                <Route exact path="/Gastos" element={<Gastos/>} /> 
                <Route exact path="/Bancos" element={<Bancos/>} /> 
                <Route exact path="/Codigos_QR" element={<Codigos_QR/>} /> 

                {/* Paginas de productos en la web */}
                <Route exact path="/Tomate" element={<Tomate/>} />  
                <Route exact path="/Calabazas" element={<Calabaza/>} />  
                <Route exact path="/Jalapenos" element={<Jalapeño/>} />  
                <Route exact path="/Trigo" element={<Trigo/>} /> 
              </Routes>
          </div>  
      </center> 
    </div>
  );
}

export default App;
