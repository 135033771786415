import CRUD_almacen_empaque from '../Firebase/CRUDS/CRUD_almacen_empaque';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_almacen_empaque from '../Listas/Lista_almacen_empaque';

//<Listaproduccion/> , va en la linea 14
const Almacen_empaque= () =>{

    const [Start, setStart] = useState(false);
    const [valor, setvalor] = useState(null);
    const [continuar, setcontinuar] = useState(false);

    function operacion(){
        if(valor===null){
            setStart(false);
            setcontinuar(false);
        }else{
            setStart(true);
            setcontinuar(true);
        }
    }

    function Pagina_principal(){
        setStart(false);
        setcontinuar(false);
    }

       React.useEffect(() => {
        operacion();
       }, [valor]);

    return(
        <div id="Trabajo">  
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Lista_almacen_empaque 
                                setvalor={setvalor}
                                
                            />}
                

                    {continuar? <CRUD_almacen_empaque pagina={valor} start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}

export default Almacen_empaque;

/* import CRUD_almacen_empaque from '../Firebase/CRUDS/CRUD_almacen_empaque';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_almacen_empaque from '../Listas/Lista_almacen_empaque';

//<Listaproduccion/> , va en la linea 14
const Almacen_empaque= () =>{

    const [Start, setStart] = useState(false);

    const [Carton2, setCarton] = useState(false);
    const [Producto_final2, setProducto_final] = useState(false);
    const [Producto_inicial2, setProducto_inicial] = useState(false);
    const [Limpieza2, setLimpieza] = useState(false);
    const [Consumible2, setConsumible] = useState(false);
    
    //Este sirve para regresar a seleccionar cuando se presione regresar
    function Pagina_principal(){
        setStart(false);
        setCarton(false);
        setProducto_final(false);
        setProducto_inicial(false);
        setLimpieza(false);
        setConsumible(false);
    }


    function Carton(){
        setCarton(true);
        setStart(true);
    }
    
    function Producto_final(){
        setProducto_final(true);
        setStart(true);
    }

    function Producto_inicial(){
        setProducto_inicial(true);
        setStart(true);
    }

    function Limpieza(){
        setLimpieza(true);
        setStart(true);
    }

    function Consumible(){
        setConsumible(true);
        setStart(true);
    }

    return(
        <div id="Trabajo">  
                
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Lista_almacen_empaque                              
                                Carton={()=>Carton()} 
                                Producto_final={()=>Producto_final()} 
                                Producto_inicial={()=>Producto_inicial()} 
                                Limpieza={()=>Limpieza()} 
                                Consumible={()=>Consumible()} 
                            />}
                

                
                    {Carton2 ? <CRUD_almacen_empaque pagina="Carton" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}

                    {Producto_final2 ? <CRUD_almacen_empaque pagina="Producto terminado" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}

                    {Producto_inicial2 ? <CRUD_almacen_empaque pagina="Cosecha" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}

                    {Limpieza2 ? <CRUD_almacen_empaque pagina="Limpieza" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
                    {Consumible2 ? <CRUD_almacen_empaque pagina="Consumibles" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}

export default Almacen_empaque; */