import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllEtiquetas_cajas() {
    const EtiquetasDB =  [];
    console.log("Hola");
    const collectionRef = collection(db, "Manifiestos/Etiquetas_cajas/Productos/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      EtiquetasDB.push(doc.data());
    });
    return EtiquetasDB;
    

}
