import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React, {useState} from 'react';

function reset_semana_a_0(datos) {
  const db = getFirestore(firebaseApp);
  const valor = "activo_si_no";
  let collectionRef = collection(db, "Nominas/auxiliar_reset_semana/Nombres/".replace(/ /g, ""));   
  const docRef = doc(collectionRef, valor);
  setDoc(docRef, datos);   
}

export default reset_semana_a_0;
