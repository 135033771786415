import React, {useState} from "react";
import "../../../index.css"
import cerrarSesion from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import getProductos from "../functions/Almacen_campo/getProductos";
import eliminarProductoHome from "../functions/eliminarProductoHome";
import filtrarDatos from "../functions/filtrarDatos";
import Navbartrabajo from '../../Navbars/Navbartrabajo'
import getUsuarios from "../functions/Almacen_campo/getUsuarios";
import getLogs_almacen_campo from "../functions/getLogs_almacen_campo";
import Swal from "sweetalert2";
//modales
import ModalAñadir from "../components/ModalAñadir";
import ModalEditar from "../components/ModalEditar";
import ModalLogs from "../components/ModalLogs";
import añadirProducto from "../functions/Almacen_campo/añadirProducto";
import añadirProducto_update from "../functions/Almacen_campo/añadirProducto_update";

function Home({ usuario,pagina,start, Pagina_principal }) {
  

  //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, usuario, pagina, start) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.nombre+'" del almacen de empaque',
      text: "¡Esta acción no se puede restablecer!. Al eliminar un producto se eliminara la cantidad actual y su historial de movimientos ya no correspondera.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado, pero si aun existe en la categoria de "PRODUCTOS" volvera a incluirse en automatico al recargar la página.',
          'success'
        )
        eliminarProductoHome(producto, usuario.email, pagina, start).then(
          (confirmacion) => {
            actualizarEstadoProductos(pagina, start);
          }
        );
      }
    })
  }



  //Logs o historial
  const [isModalLogs, setIsModalLogs] = React.useState(false);
  const [Producto_seleccionado, setProducto_seleccionado] = React.useState([]);

  //Consultar movimientos de producto seleccionado y mmostrar en un modal
  async function Historial(producto){
    setProducto_seleccionado(producto);
    setIsModalLogs(true);
  }

  const [ADMIN_ON, setADMIN_ON] = React.useState(false);
  async function usuario_actual() {
    await getUsuarios().then((UsuariosDB) => {
        console.log(usuario.email);
        UsuariosDB.map((valor)=>{
          if(valor.correo==usuario.email){
            if(valor.rol=="Administrador"){
              setADMIN_ON(true);
            }
          }
        })
     });
   }


  //console.log("HOME")
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);

 //ver los productos de la categoria seleccionada en el almacen
 async function actualizarEstadoProductos5(pagina2, start) {
  await getAllProducts(pagina2, start).then((productos) => {
/*         console.log(pagina2)
        console.log(start)
        console.log(productos) */
        actualizarEstadoProductos2(pagina2, start, productos)    
   });
 }



  //Filtrar de productos segun categoria seleccionada
  async function actualizarEstadoProductos2(pagina2, start, productos) {
    await getProductos(pagina2, start).then((infoProducto) => {
      //Con esto se escriben todos los articulos en el almacen de campo
/*       console.log(infoProducto) */
      actualizarEstadoProductos3( {infoProducto, productos, pagina2, start})
     });
   }
// infoProductos= los que se trae de productos,  productos= los que estan actualmente en almacen de campo 
async function actualizarEstadoProductos3({ productos, infoProducto, pagina2, start}){
  const snapshot = infoProducto;
  //console.log(infoProducto)
  //console.log(productos);
  await snapshot.forEach((doc) => {
        const valor = doc;
        var auxiliar2 = false;
        var auxiliar3 = false;
        var auxiliar4 = [];
            productos.map((productos_actual)=>{
              //console.log(valor.nombre, productos_actual.nombre)
              if(valor.nombre==productos_actual.nombre){
                //console.log("Entro")
                auxiliar2 = true;
                if(valor.precio==productos_actual.precio){
                }else{
                  auxiliar3 = true;
                  auxiliar4 = productos_actual;
                }
                //añadirProducto_update( productos_actual , pagina2, start);
              }
            })
        if(auxiliar2==true){
          console.log(" NO Entro")
          if(auxiliar3==true){
            console.log("no son iguales los precios", valor.precio , auxiliar4.precio)
            añadirProducto_update( valor , pagina2, auxiliar4);
          }
        }else{
          console.log("Entro")
        }

       }
      );
      //console.log(infoProducto)
      actualizarEstadoProductos4( pagina2, productos, infoProducto, start,);
}

async function actualizarEstadoProductos4( pagina2, productos, infoProducto, start){

//ValorDatovacio, sirve por si no existe nada en el almacen, directamente pase a crear todos los productos.
const valorDatovacio = (productos == "");
var newArray = [];   
/* console.log(productos)
console.log(infoProducto)
console.log(valorDatovacio) */
{valorDatovacio?   
  Vacio(infoProducto, pagina2)
  
    :
     await productos.forEach((value,index)=>{
          if(index === 0){
              productos.slice(index, index+1);
              newArray = infoProducto.filter((item)=> item.nombre !== value.nombre)
            }else{
              productos.slice(index, index+1);
              newArray = newArray.filter((item)=> item.nombre !== value.nombre)
            }
        })
        console.log(newArray)
        newArray.map((datoset)=>{
          añadirProducto(datoset, pagina2)
          actualizarEstadoProductos(pagina2, start);

        })
        actualizarEstadoProductos(pagina2, start);
  }}


  async function actualizarEstadoProductos(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      //console.log(productos)
      console.log("Prueba")
       setProductos(productos);    
     });
   }

async function Vacio(infoProducto, pagina2){
  console.log(infoProducto)
  await infoProducto.map((datovacio)=>{
    añadirProducto(datovacio, pagina2)
  })
}

function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

async function alerta(){
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Has cerrado sesión correctamente',
    showConfirmButton: false,
    timer: 3000
  })
  await timeout(1500);
  cerrarSesion();
}

  React.useEffect(() => {
    actualizarEstadoProductos5(pagina, start);
    usuario_actual();
  }, []);

  return (
  <div id="Tablas_blanco">
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
    {isModalAñadir && (
      <ModalAñadir
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
        pagina={pagina}
        start={start}
      />
      )}
      {productoEditar && (
        <ModalEditar
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          pagina={pagina}
          start={start}
          setProductos={setProductos}
        />
      )}
      {isModalLogs && (
        <ModalLogs
          isModalLogs={isModalLogs}
          setIsModalLogs={setIsModalLogs}
          className="modal-dialog modal-fullscreen-sm-down"
          Producto_seleccionado={Producto_seleccionado}
          pagina={pagina}
          setProductos={setProductos}
          start={start}
        />
      )}
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname} </p>
        <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
        {/* <Button id="boton_regresar_ordenes" onClick={()=>{actualizarEstadoProductos(pagina, start)}}> refresh</Button> */}
        <Button onClick={alerta}>Cerrar sesión</Button>
      </Stack>
      {/* <hr />

      <Form onSubmit={busquedaFormHandler}>
        <Stack direction="horizontal">
          <Form.Group controlId="busqueda" className="w-75 m-3">
            <Form.Control type="text" placeholder="Buscar" />
          </Form.Group>
          <Button variant="dark" type="submit">
            Buscar
          </Button>
          <Button
            variant="light"
            onClick={() => {
              const input = document.getElementById("busqueda");
              input.value = "";
              actualizarEstadoProductos();
            }}
          >
            Resetear
          </Button>
        </Stack>
      </Form> */}

      <hr />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
          {ADMIN_ON && (
            <th>Precio</th>
          )}     
            <th>Moneda</th>
            <th>Cantidad</th>
            <th>Unidad de medida</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                {ADMIN_ON && (
                <td>${producto.precio}</td>
                )} 
                <td>{producto.moneda}</td>
                <td>{producto.cantidad}</td>
                <td>{producto.unidad}</td>
                <td>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setIsModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, usuario.email, pagina, start);
                    }}
                  >
                    Eliminar
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      Historial({ ...producto });
                    }}
                  >
                    Movimientos
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* <Button onClick={añadirProductoHome}> Añadir Producto</Button> */}
      <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
      <br/>
      <br/>
    </Container>
    </div> 
  );
}

export default Home;


//-------------------------------------------------------------------------------------------------------------------------

/* import React, {useState} from "react";
import "../../../index.css"
import cerrarSesion from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import getProductos from "../functions/Almacen_campo/getProductos";
import eliminarProductoHome from "../functions/eliminarProductoHome";
import filtrarDatos from "../functions/filtrarDatos";
import Navbartrabajo from '../../Navbars/Navbartrabajo'
import getLogs_almacen_campo from "../functions/getLogs_almacen_campo";
import Swal from "sweetalert2";
//modales
import ModalAñadir from "../components/ModalAñadir";
import ModalEditar from "../components/ModalEditar";
import ModalLogs from "../components/ModalLogs";
import añadirProducto from "../functions/Almacen_campo/añadirProducto";
import añadirProducto_update from "../functions/Almacen_campo/añadirProducto_update";

function Home({ usuario,pagina,start, Pagina_principal }) {
  

  //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, usuario, pagina, start) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.nombre+'" del almacen de empaque',
      text: "¡Esta acción no se puede restablecer!. Al eliminar un producto se eliminara la cantidad actual y su historial de movimientos ya no correspondera.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado, pero si aun existe en la categoria de "PRODUCTOS" volvera a incluirse en automatico al recargar la página.',
          'success'
        )
        eliminarProductoHome(producto, usuario.email, pagina, start).then(
          (confirmacion) => {
            actualizarEstadoProductos(pagina, start);
          }
        );
      }
    })
  }



  //Logs o historial
  const [isModalLogs, setIsModalLogs] = React.useState(false);
  const [Producto_seleccionado, setProducto_seleccionado] = React.useState([]);

  //Consultar movimientos de producto seleccionado y mmostrar en un modal
  async function Historial(producto){
    setProducto_seleccionado(producto);
    setIsModalLogs(true);
  }

  //console.log("HOME")
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);

  async function busquedaFormHandler(productos) {
    productos.preventDefault();
    const busqueda = productos.target.busqueda.value;
    const nvosDocus = await filtrarDatos(busqueda);
    setProductos(nvosDocus);
  }

 //ver los productos de la categoria seleccionada en el almacen
 async function actualizarEstadoProductos5(pagina2, start) {
  await getAllProducts(pagina2, start).then((productos) => {
        console.log(pagina2)
        console.log(start)
        console.log(productos)
        actualizarEstadoProductos2(pagina2, start, productos)    
   });
 }



  //Filtrar de productos segun categoria seleccionada
  async function actualizarEstadoProductos2(pagina2, start, productos) {
    await getProductos(pagina2, start).then((infoProducto) => {
      //Con esto se escriben todos los articulos en el almacen de campo
      console.log(infoProducto)
      actualizarEstadoProductos3( {infoProducto, productos, pagina2, start})
     });
   }
//productos = los que se trae de productos, infoProductos = los que estan actualmente en almacen de campo 
async function actualizarEstadoProductos3({ productos, infoProducto, pagina2, start}){
  const snapshot = infoProducto;
  console.log(infoProducto)
  await snapshot.forEach((doc) => {
        const valor = doc;
        productos.map((productos_actual)=>{
          if(valor.nombre===productos_actual.nombre){
            añadirProducto_update( productos_actual , pagina2, start);
          }
        })
       }
      );
      console.log(infoProducto)
      actualizarEstadoProductos4( pagina2, productos, infoProducto, start,);
}

async function actualizarEstadoProductos4( pagina2, productos, infoProducto, start){

//ValorDatovacio, sirve por si no existe nada en el almacen, directamente pase a crear todos los productos.
const valorDatovacio = (productos == "");
var newArray = [];   
console.log(productos)
console.log(infoProducto)
console.log(valorDatovacio)
{valorDatovacio?   
  Vacio(infoProducto, pagina2)
  
    :
     await productos.forEach((value,index)=>{
          if(index === 0){
              productos.slice(index, index+1);
              newArray = infoProducto.filter((item)=> item.nombre !== value.nombre)
            }else{
              productos.slice(index, index+1);
              newArray = newArray.filter((item)=> item.nombre !== value.nombre)
            }
        })
        console.log(newArray)
        newArray.map((datoset)=>{
          añadirProducto(datoset, pagina2)
          actualizarEstadoProductos(pagina2, start);

        })
        actualizarEstadoProductos(pagina2, start);
  }}


  async function actualizarEstadoProductos(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);    
     });
   }

async function Vacio(infoProducto, pagina2){
  console.log(infoProducto)
  await infoProducto.map((datovacio)=>{
    añadirProducto(datovacio, pagina2)
  })
}

function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

async function alerta(){
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Has cerrado sesión correctamente',
    showConfirmButton: false,
    timer: 3000
  })
  await timeout(1500);
  cerrarSesion();
}

  React.useEffect(() => {
    actualizarEstadoProductos5(pagina, start);
  }, []);

  return (
  <div id="Tablas_blanco">
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
    {isModalAñadir && (
      <ModalAñadir
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
        pagina={pagina}
        start={start}
      />
      )}
      {productoEditar && (
        <ModalEditar
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          pagina={pagina}
          start={start}
          setProductos={setProductos}
        />
      )}
      {isModalLogs && (
        <ModalLogs
          isModalLogs={isModalLogs}
          setIsModalLogs={setIsModalLogs}
          className="modal-dialog modal-fullscreen-sm-down"
          Producto_seleccionado={Producto_seleccionado}
          pagina={pagina}
          setProductos={setProductos}
          start={start}
        />
      )}
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname} </p>
        <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
        <Button onClick={alerta}>Cerrar sesión</Button>
      </Stack>

      <hr />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Moneda</th>
            <th>Cantidad</th>
            <th>Unidad de medida</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                <td>${producto.precio}</td>
                <td>{producto.moneda}</td>
                <td>{producto.cantidad}</td>
                <td>{producto.unidad}</td>
                <td>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setIsModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, usuario.email, pagina, start);
                    }}
                  >
                    Eliminar
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      Historial({ ...producto });
                    }}
                  >
                    Movimientos
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
      <br/>
      <br/>
    </Container>
    </div> 
  );
}

export default Home; */