import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarSaldo_Dolares_proveedores(saldo_total_suma, productoEditar) {
  console.log(productoEditar.nombre, saldo_total_suma)

  var dato_a_editar= {
    Saldo_Dolares: saldo_total_suma,
    };

  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Proveedores/");

  const docRef = doc(collectionRef, productoEditar.nombre);

  updateDoc(docRef, dato_a_editar);
}

export default editarSaldo_Dolares_proveedores;
