import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import getAlldata_consultar from "../../functions/Seguro_social/getAlldata_consultar";
import editar_pagos from "../../functions/Seguro_social/editar_pagos";
import Swal from "sweetalert2";

function Modalpagar_a_todos_al_mismo_tiempo({
  isModal_pagar_a_todos,
  setisModal_pagar_a_todos,
  trabajadores_data,
  settrabajadores_data,
  cuadrillero_seleccionado,
}) {

    //Convertir a formato moneda
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

  const [actividades, setactividades] = React.useState([]);

  async function traer_actividad() {
    const seleccionado = "Actividad";
    await getAlldata_consultar(seleccionado).then((dataDB) => {
      setactividades(dataDB); 
     });
   }

   const [cargos, setcargos] = React.useState([]);

   async function traer_cargo() {
    const seleccionado = "Cargo";
    await getAlldata_consultar(seleccionado).then((dataDB2) => {
      setcargos(dataDB2); 
     });
   }

   async function actualizar(){
    const seleccionado = "Trabajador";
    var aux_trabajadores = [];
   await getAlldata_consultar(seleccionado).then((dataDB) => {
    dataDB.map((datos)=>{
      if(datos.trabajador_cuadrillero == cuadrillero_seleccionado){
        aux_trabajadores.push(datos);
      }
    })

  //Con esto ordeno por nombre alfabetico
  aux_trabajadores.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0)); 
  /* setauxiliar_traba(aux_trabajadores); */
    });
  }

 //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
      return new Promise(res => setTimeout(res, delay));
    }


   async function pagar_trabajadores() {
    //obtener infor del formulario
    const dia = document.getElementById("dia").value;
    const importe = document.getElementById("importe").value;
    const actividad = document.getElementById("actividad").value;
    const cargo = document.getElementById("cargo").value;
    const suma_importes = importe;

    await trabajadores_data.map((dato)=>{
      var importe_2 = dato[dia].importe_2;
      var cargo_2 = dato[dia].cargo_2;
      var actividad_2 = dato[dia].actividad_2; 

      var Total_Viernes = dato.Viernes.importe;
      var Total_Sabado = dato.Sabado.importe;
      var Total_Domingo = dato.Domingo.importe;
      var Total_Lunes = dato.Lunes.importe;
      var Total_Martes = dato.Martes.importe;
      var Total_Miercoles = dato.Miercoles.importe;
      var Total_Jueves = dato.Jueves.importe;

      if(dia == "Viernes"){Total_Viernes = importe} 
      if(dia == "Sabado"){Total_Sabado = importe} 
      if(dia == "Domingo"){Total_Domingo = importe} 
      if(dia == "Lunes"){Total_Lunes =importe} 
      if(dia == "Martes"){Total_Martes = importe} 
      if(dia == "Miercoles"){Total_Miercoles = importe} 
      if(dia == "Jueves"){Total_Jueves = importe} 

      //aqui sumo todos los dias y resto el descuento del trabajador seleccionado
      const Total_con_formato = formatter.format(+Total_Viernes + +Total_Sabado + +Total_Domingo + +Total_Lunes + +Total_Martes + +Total_Miercoles + +Total_Jueves);
      const n_str = (Total_con_formato).toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const n_nuevaStr = n_str.replace("$", "");
      const n_nuevaStr2 = n_nuevaStr.replace(",", "");
      const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      const total = n_nuevaStr4;

      var infoProducto = {[dia]: {cargo, actividad, importe, importe_2, actividad_2, cargo_2, suma_importes}, total};
      var fecha = dato.fecha;
      var auxiliar = "Trabajador";
      editar_pagos(infoProducto, auxiliar, fecha);
    })

    // cerrar modal
    setisModal_pagar_a_todos(false);  
    await timeout(2000); 
        const seleccionado = "Trabajador";
        var aux_trabajadores = [];
          await getAlldata_consultar(seleccionado).then((dataDB) => {
            dataDB.map((datos)=>{
              if(datos.trabajador_cuadrillero == cuadrillero_seleccionado){
                aux_trabajadores.push(datos);
              }
            })

          //Con esto ordeno por nombre alfabetico
          aux_trabajadores.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0)); 
          settrabajadores_data(aux_trabajadores);
            });


      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se agrego correctamente.',
        showConfirmButton: false,
        timer: 1500
      });
  }

React.useEffect(() => {
  traer_actividad();
  traer_cargo();
  console.log(trabajadores_data)
}, []);

  return (
    <Modal show={isModal_pagar_a_todos} onHide={() => setisModal_pagar_a_todos(false)}>
      <Modal.Header>
        <Modal.Title >Agregar el primer cargo, actividad y pago a todos los trabajadores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar dia a pagar</h6>
            <select id="dia" style={{ marginRight: "70%"}}>
              <option >Viernes</option>
              <option >Sabado</option>
              <option >Domingo</option>
              <option >Lunes</option>
              <option >Martes</option>
              <option >Miercoles</option>
              <option >Jueves</option>
            </select>
            <br/>
            <h6>Ingresar importe a pagar </h6>
            <Form.Control
                id="importe"
                placeholder="Ingresar importe para actividad 1"
                type="number"
                className="mb-1"
              />
            <br/>
             <h6>Seleccionar actividad </h6>
              <select id="actividad" type="text">
              {actividades.map((datos,index)=>( 
                  <option key={index}>{datos.nombre}</option>
              ))}
              </select>
            <br/>
            <h6>Seleccionar cargo </h6>
              <select id="cargo" type="text">
              {cargos.map((datos,index)=>( 
                  <option key={index}>{datos.nombre}</option>
              ))}
              </select>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setisModal_pagar_a_todos(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => {pagar_trabajadores()}}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Modalpagar_a_todos_al_mismo_tiempo;
