import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(firebaseApp);

export default async function getLogs_almacen_empaque2(Producto_seleccionado) {
  console.log(Producto_seleccionado)
    const Logs =  [];
    let valor = [];
    const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      valor = doc.data();
        if(Producto_seleccionado.nombre == valor.Producto_retirado){
          Logs.push(doc.data());
        }
    });
    return Logs;
    

}


//"+productofolio+"
