import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc , getDocs, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default async function eliminarProductoHome(producto, pagina) {
  console.log(producto)
  console.log(pagina)
  const coleccionRef = collection(db, "Produccion/"+pagina+"/Productos".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.fecha2);
  var eliminado = await deleteDoc(docuRef);

  //Traer productos de la categoria seleccionada y sumarle lo eliminado
  const Auxuliar1 =  [];
  const collectionRef = collection(db, "Almacen_empaque/"+pagina+"/Productos/".replace(/ /g, ""));
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    Auxuliar1.push(doc.data());
  });

  Auxuliar1.map((dato)=>{
    if(producto.nombre == dato.nombre){
            //Elimino
            const coleccionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
            const docuRef = doc(coleccionRef, producto.fecha2);
            eliminado = deleteDoc(docuRef);

            //Sumo en almacen
            var Total_kilos_restantes = formatter.format(+dato.cantidad + +producto.cantidad)
            var n_str = Total_kilos_restantes.toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const n_nuevaStr = n_str.replace("$", "");
            const n_nuevaStr2 = n_nuevaStr.replace(",", "");
            const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4_kilos = n_nuevaStr3.replace(",", "");
            console.log(producto.cantidad, dato.cantidad, n_nuevaStr4_kilos)
            const collectionRef6 = collection(db, "Almacen_empaque/"+pagina+"/Productos/")
            const docuRef6 = doc(collectionRef6,producto.nombre);
             updateDoc(docuRef6,{
                cantidad: n_nuevaStr4_kilos
            });
    }
  })


  return eliminado;
}
