import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_bancos_afectaciones_fiscales from "../../functions/Bancos/añadir_bancos_afectaciones_fiscales";
import Swal from "sweetalert2";

function ModalAñadir_fiscales({
  ModalAñadir_boton_fiscales,
  setModalAñadir_boton_fiscales,
  actualizar_botones,
  Botonesdata,
}) {

  async function añadirProductoModal() {
    //obtener infor del formulario
    const nombre_afectacion_fiscal = document.getElementById("nombre_afectacion_fiscal").value;
    // enviar informacion a firebase
    const infoProducto = {nombre_afectacion_fiscal};
    añadir_bancos_afectaciones_fiscales(infoProducto)
    // cerrar modal
    actualizar_botones();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se agrego correctamente.',
      showConfirmButton: false,
      timer: 1500
    })
    setModalAñadir_boton_fiscales(false);
  }

  return (
    <Modal show={ModalAñadir_boton_fiscales} onHide={() => setModalAñadir_boton_fiscales(false)}>
      <Modal.Header>
        <Modal.Title>Añadir afectación fiscal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <Form.Control
              id="nombre_afectacion_fiscal"
              placeholder="Agregar nombre de la afectación fiscal"
              type="text"
              className="mb-1"
            />  
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAñadir_boton_fiscales(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_fiscales;
