import React from 'react';
import '../../index.css'
import CRUD_ordenes_principal from '../Firebase/CRUDS/CRUD_ordenes_principal';

const Ordenes = () =>{
    return(
        <div>
            <div >
            <div id="listas">  
               
            </div>  
            <CRUD_ordenes_principal/>
            </div>
        </div>
    )
}
//<CRUD/>
export default Ordenes;