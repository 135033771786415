import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_productos_tablas from "../../functions/Gastos/añadir_productos_tablas";
import getProductos from "../../functions/Gastos/getProductos";
import getProductos_tablas from "../../functions/Gastos/getProductos_tablas";

function ModalAñadir_tablas({
  Modal_añadir_producto,
  setModal_añadir_producto,
  Tablasdata,
  actualizar_productos_tablas,
  usuario2,
  Nombre_de_boton,
  setTablasdata,
}) {
  const [Cantidad_actual, setCantidad_actual] = React.useState(0);
  async function actualizar() {
    console.log(Nombre_de_boton)
    await getProductos_tablas(Nombre_de_boton).then((productos) => {
      setTablasdata(productos);   
      //Esto es para saber cuantos productos tiene la tabla y darselo de nombre
      var valores_productos_concentrados = [];
      Tablasdata.map((dato)=>{
        valores_productos_concentrados.push(dato.nombre)
      })
      var valor_maximo = 0;
      if(valores_productos_concentrados.length==0){
        valor_maximo = 0;
      }else{
        valor_maximo = Math.max(...valores_productos_concentrados)
      }
      
      setCantidad_actual(valor_maximo+1);
      console.log(valores_productos_concentrados);
      console.log(valor_maximo+1);
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

//Traer productos del almacen campo
const [Datosproducto, setDatosproducto] = React.useState([]);

async function productos_basededatos(){
  const nombrecategoria = "Producto terminado";
  await getProductos(nombrecategoria).then((productosDB) => {
    console.log(Nombre_de_boton.valor);
 var Productos_filtrados = [];   
    productosDB.map((dato)=>{
      if(dato.categoria==Nombre_de_boton.valor){
        Productos_filtrados.push(dato);
      }
    })  
    console.log(Productos_filtrados)
    setDatosproducto(Productos_filtrados);
  });
}

  async function añadirProductoModal(usuario2, Nombre_de_boton) {
    //Esto sirve para la seleccion de la fecha
    let date = new Date();
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;

    //obtener infor del formulario
    const fecha = fecha_actual;
    const fechaOrden = fecha_actual;
    const producto = document.getElementById("producto").value;
    //Obtener el precio del producto seleccionado
    var Productos_seleccionado = [];   
    Datosproducto.map((dato)=>{
      if(dato.nombre==producto){
        Productos_seleccionado.push(dato);
      }
    })  
    console.log(Nombre_de_boton);
    const cantidad = document.getElementById("cantidad").value;
    var persona = "NA";
    if(Boton_personal){
     persona = document.getElementById("persona").value;
    }
    
    const precio = Productos_seleccionado[0].precio;
    const total_con_iva = precio * cantidad;
    const productos_cantidad=Cantidad_actual;
    console.log(productos_cantidad);
    const usuario = usuario2.email;
    const nombre = productos_cantidad;
    const agregado_desde_orden_compra = "";
    // enviar informacion a firebase
    const infoProducto = { fecha, producto, cantidad, precio, total_con_iva, usuario, nombre, persona, fechaOrden, agregado_desde_orden_compra};
    añadir_productos_tablas(infoProducto, productos_cantidad, Nombre_de_boton );
    // cerrar modal
    await timeout(500);
    actualizar();
    actualizar_productos_tablas();
    setModal_añadir_producto(false);
  }


//TSi es el gasto personal aparecera un campo unico para este boton
const [Boton_personal, setBoton_personal] = React.useState(false);

  React.useEffect(() => {
    productos_basededatos();
    actualizar();
    if(Nombre_de_boton.valor=="Personal"){
      setBoton_personal(true)
    }
  }, []); 

  return (
    <Modal show={Modal_añadir_producto} onHide={() => setModal_añadir_producto(false)}>
      <Modal.Header>
        <Modal.Title>Añadir producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Seleccionar presentación</h6>
            {Datosproducto ? 
                <select 
                id="producto" 
                type="text"
                >
                  {Datosproducto.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>
            :
            null
            }  
            <br/>
            <Form.Control
              id="cantidad"
              placeholder="Ingresar la cantidad"
              type="number"
              className="mb-1"
            />
            <br/>
          {Boton_personal &&(
            <Form.Control
              id="persona"
              placeholder="Ingresar la persona que lo gasto"
              type="text"
              className="mb-1"
            />
          )}
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_añadir_producto(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=> añadirProductoModal(usuario2, Nombre_de_boton)}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_tablas;
