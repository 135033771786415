import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function añadirTotal_orden(total_suma_nuevo_viejo, productofolio) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Ordenes/".replace(/ /g, ""));
  const docRef = doc(collectionRef, productofolio);
  updateDoc(docRef, total_suma_nuevo_viejo);    
}
export default añadirTotal_orden;
