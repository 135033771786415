import React from 'react';
import CRUD_proveedores from '../Firebase/CRUDS/CRUD_proveedores';


const Proveedores = () =>{
    return(
        <div>
            <div >
             
            <div id="listas">  
               
            </div>  
            <CRUD_proveedores/>
            </div>
        </div>
    )
}

export default Proveedores;