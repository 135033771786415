import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function get_un_solo_dato(auxiliar1, auxiliar2) {
    console.log(auxiliar1, auxiliar2);
    const datoDB =  [];
    const collectionRef = collection(db, "Nominas/"+auxiliar2+"/Nombres/");
    const q =  query(collectionRef, where("fecha", "==", auxiliar1));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        datoDB.push(doc.data());
      });
    console.log(datoDB)
    return datoDB;
} 





