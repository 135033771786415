import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getProductos(productofolio) {
    

  //console.log(valor);
    const proveedoresDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Proveedores".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      proveedoresDB.push(doc.data());
    });
    return proveedoresDB;
    

}


//"+productofolio+"
