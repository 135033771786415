import CRUD_Produccion from '../Firebase/CRUDS/CRUD_Produccion';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_produccion from '../Listas/Lista_produccion';


//<Listaproduccion/> , va en la linea 14
const Produccion_empaque = () =>{
    const [Start, setStart] = useState(false);
    const [valor, setvalor] = useState(null);
    const [continuar, setcontinuar] = useState(false);

    function operacion(){
        if(valor===null){
            setStart(false);
            setcontinuar(false);
        }else{
            setStart(true);
            setcontinuar(true);
        }
    }

    function Pagina_principal(){
        setStart(false);
        setcontinuar(false);
    }

       React.useEffect(() => {
        operacion();
       }, [valor]);

    return(
        <div id="Trabajo">  
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Lista_produccion 
                                setvalor={setvalor}
                                
                            />}
                

                    {continuar? <CRUD_Produccion pagina={valor} start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}


export default Produccion_empaque;






/* import CRUD_Produccion from '../Firebase/CRUDS/CRUD_Produccion';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_produccion from '../Listas/Lista_produccion';
import Container from "react-bootstrap/Container"


//<Listaproduccion/> , va en la linea 14
const Produccion_empaque = () =>{

    const [Start, setStart] = useState(false);

    const [Lineas_produccion_1, setLineas_produccion_1] = useState(false);
    const [Mantenimiento_2, setMantenimiento_2] = useState(false);
    const [Limpieza_3, setLimpieza_3] = useState(false);
    const [Embarque_4, setEmbarque_4] = useState(false);
    const [Consumible2, setConsumible] = useState(false);

    //Este sirve para regresar a seleccionar cuando se presione regresar
    function Pagina_principal(){
        setStart(false);
        setLineas_produccion_1(false);
        setMantenimiento_2(false);
        setLimpieza_3(false);
        setEmbarque_4(false);
        setConsumible(false);
    }

    function Lineas_produccionn_1(){
        setLineas_produccion_1(true); 
     setStart(true);
    }
    
    function Mantenimientoo_2(){
        setMantenimiento_2(true);
        setStart(true);
    }
    
    function Limpiezaa_3(){
        setLimpieza_3(true);
        setStart(true);
    }

    function Embarquee_4(){
        setEmbarque_4(true); 
     setStart(true);
    }

    function Consumible(){
        setConsumible(true);
        setStart(true);
    }
    return(
        <div id="Trabajo">  
                
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Lista_produccion
                             Lineas_produccion_1={()=>Lineas_produccionn_1()} 
                             Mantenimiento_2={()=>Mantenimientoo_2()} 
                             Limpieza_3={()=>Limpiezaa_3()} 
                             Embarque_4={()=>Embarquee_4()} 
                             Consumible={()=>Consumible()} 
                            />}
                

                
                    {Lineas_produccion_1 ? <CRUD_Produccion pagina="Lineas_produccion" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                    {Mantenimiento_2 ? <CRUD_Produccion pagina="Mantenimiento" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                    {Limpieza_3 ? <CRUD_Produccion pagina="Limpieza" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                    {Embarque_4 ? <CRUD_Produccion pagina="Embarque" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                    {Consumible2 ? <CRUD_Produccion pagina="Consumibles" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

            </div>
    )
}

export default Produccion_empaque; */