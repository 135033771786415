import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarboton_gastos(producto) {
  const coleccionRef = collection(db, "Botones_gastos/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.valor);
  const eliminado = await deleteDoc(docuRef);
  return eliminado;
}
