import React, {useState} from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAlldata_consultar from "../functions/Seguro_social/getAlldata_consultar";
import eliminar from "../functions/Seguro_social/eliminar";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import reset_semana_a_0 from "../functions/Seguro_social/reset_semana_a_0";
import editar_consultar from "../functions/Seguro_social/editar_consultar";
import guardar_semana_pagos from "../functions/Seguro_social/guardar_semana_pagos";
import Traer_trabajadores_concentrado_cuadrillero from "../functions/Seguro_social/Traer_trabajadores_concentrado_cuadrillero";
import Traer_trabajadores_concentrado_general from "../functions/Seguro_social/Traer_trabajadores_concentrado_general";
import Swal from "sweetalert2";
//modales
import ModalAñadir_cuadrillero from "../components/Seguro_social/ModalAñadir_cuadrillero";
import Modal_pago from "../components/Seguro_social/Modal_pago";
import Modal_consultar from "../components/Seguro_social/Modal_consultar";
import ModalAñadir from "../components/Seguro_social/ModalAñadir";
import Modal_actividades_cargos from "../components/Seguro_social/Modal_actividades_cargos";
import Modalpagar_a_todos_al_mismo_tiempo from "../components/Seguro_social/Modalpagar_a_todos_al_mismo_tiempo"


  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

function Home_Nominas({ usuario, botones, pagina, start, Pagina_principal }) {

//Evaluar pagina actual--------------------------------------------------------------------------------------------------------------------------------------------

const [captura_diaria_ON, setcaptura_diaria_ON] = React.useState(false);
const [Concentrado_por_cuadrillero_ON, setConcentrado_por_cuadrillero_ON] = React.useState(false);
const [Concentrado_general_ON, setConcentrado_general_ON] = React.useState(false);

function evaluar_pagina(){
  console.log(pagina)
  if(pagina=="Captura_diaria"){
    setcaptura_diaria_ON(true);
    setConcentrado_por_cuadrillero_ON(false);
    setConcentrado_general_ON(false);
  }
  if(pagina=="Concentrado_por_cuadrillero"){
    setcaptura_diaria_ON(false);
    setConcentrado_por_cuadrillero_ON(true);
    setConcentrado_general_ON(false);
    
  }
  if(pagina=="Concentrado_general"){
    setcaptura_diaria_ON(false);
    setConcentrado_por_cuadrillero_ON(false);
    setConcentrado_general_ON(true);
  }
}
//Fin de evaluar pagina actual-------------------------------------------------------------------------------------------------------------------------------------

//Todo de aqui para adelante se utiliza para el boton de CAPTURA DIARIA--------------------------------------------------------------------------------------------
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir_cuadrillero, setIsModalAñadir_cuadrillero] = React.useState(false);
  const [isModalAñadir_consultar, setIsModalAñadir_consultar] = React.useState(false);
  const [isModal_pagar_a_todos, setisModal_pagar_a_todos] = React.useState(false);

  const [isModal_pago, setIsModal_pago] = React.useState(false);
  const [Modal_añadir_fecha_actual, setModal_añadir_fecha_actual] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);

  
const [cuadrilleros_data, setcuadrilleros_data] = React.useState([]);

async function traer_cuadrilleros(){
  const seleccionado = "Cuadrillero";
 await getAlldata_consultar(seleccionado).then((dataDB) => {
 //Con esto lo ordeno por fechas de forma descendiente
  setcuadrilleros_data(dataDB);
  console.log(dataDB)
  });
}

  function actualizarEstadoProductos() {
    const seleccionado = "Cuadrillero";
    getAlldata_consultar(seleccionado).then((productos) => {
   //Con esto lo ordeno por fechas de forma descendiente
    /* productos.sort((a,b)=>  (b.fecha2) - (a.fecha2)); */
    setProductos(productos);
    console.log(productos)
    //editar_asegurado_fecha(productos); 
    });
  }

  function añadir_cuadrillero() {
    setIsModalAñadir_cuadrillero(true);
  }

  function consultar() {
    setIsModalAñadir_consultar(true);
  }


  //Filtrar trabajadores por el cuadrillero seleccionado
  const [trabajadores_data, settrabajadores_data] = React.useState([]);
  const [cuadrillero_seleccionado, setcuadrillero_seleccionado] = React.useState();

 async function traer_trabajadores(){
    setcuadrillero_seleccionado(document.getElementById("trabajador_cuadrillero").value)
    const seleccionado = "Trabajador";
    var aux_trabajadores = [];
   await getAlldata_consultar(seleccionado).then((dataDB) => {
    dataDB.map((datos)=>{
      if(datos.trabajador_cuadrillero == document.getElementById("trabajador_cuadrillero").value){
        aux_trabajadores.push(datos);
      }
    })

  //Con esto ordeno por nombre alfabetico
  aux_trabajadores.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0)); 

  settrabajadores_data(aux_trabajadores);
    });
  }

//Con este evaluo si el auxiliar para ver si ya se reseteo esta semana esta en true o false, si esta en true ya se reseteo.
 async function evaluar_reset(){
    const seleccionado = "auxiliar_reset_semana";
   await getAlldata_consultar(seleccionado).then((dataDB4) => {
        //Esto sirve para obtener el dia actual-------------------------------------------
        let date = new Date();
        let fecha =  date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
        console.log(fecha)
        
        const fechaComoCadena= fecha;
        const dias = [
          'domingo',
          'lunes',
          'martes',
          'miercoles',
          'jueves',
          'viernes',
          'sabado',
        ]
        const numeroDia = new Date(fechaComoCadena).getDay();
        const nombreDia = dias[numeroDia];
        console.log("Nombre de dia de la semana: ", nombreDia);
        //--------------------------------------------------------------------------------

        
    //Con esto reseteo el auxiliar a false en caso de ser jueves y si es viernes reseteo todo a cero y pongo el auxiliar en true
      if(dataDB4[0].activo == true){
        console.log("No resetear");
        if(nombreDia == "sabado" || nombreDia == "domingo" || nombreDia == "lunes" || nombreDia == "martes" || nombreDia == "miercoles" || nombreDia == "jueves" ){
          console.log("Cambio auxiliar a false");
          const activo = false;
          const datos = {activo};
          reset_semana_a_0(datos)
        }
      }

      if(dataDB4[0].activo == false){
        console.log("Resetear dias de semana");
        if(nombreDia == "viernes"){
          console.log("Cambio auxiliar a true");
          //evaluar_semana();
        }
      }
    }); 

  }

  //Con este evaluo si es dia viernes y reseteo todos los valores de todos los trabajadores
 async function evaluar_semana(){
      const seleccionado = "Trabajador";
     await getAlldata_consultar(seleccionado).then((dataDB3) => {
        dataDB3.map((valor)=>{
          const importe = 0;
          const importe_2 = 0;
          const suma_importes = 0;
          const actividad = "";
          const cargo = "";
          const actividad_2 = "";
          const cargo_2 = "";
          const Viernes = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Sabado ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Domingo = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Lunes= {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Martes ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Miercoles = {importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const Jueves ={importe, actividad, cargo, importe_2, actividad_2, cargo_2,suma_importes};
          const descuento = 0;
          const total = 0;
          const fecha = valor.fecha;
          const auxiliar = "Trabajador";
          const infoProducto = {Viernes, Sabado, Domingo, Lunes, Martes, Miercoles, Jueves, descuento, total, fecha};
          editar_consultar(infoProducto, auxiliar)
        })    
      })
      //Con esto pongo el auxiliar de reset semana a true
      const activo = true;
      const datos = {activo};
      traer_trabajadores();
      reset_semana_a_0(datos)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se han borrado los pagos actuales de todos los trabajadores correctamente.',
        showConfirmButton: false,
        timer: 1500
      });
  }

 
 //Se guardan los pagos de todos los trabajadores actuales 
 async function guardar_pagos_semana(){
        setcuadrillero_seleccionado(document.getElementById("trabajador_cuadrillero").value)
        const seleccionado = "Trabajador";
        var aux_trabajadores = [];
      await getAlldata_consultar(seleccionado).then((dataDB) => {
        dataDB.map((datos)=>{
          if(datos.trabajador_cuadrillero == document.getElementById("trabajador_cuadrillero").value){
            aux_trabajadores.push(datos);
          }
        })
      settrabajadores_data(aux_trabajadores);
      console.log(aux_trabajadores);


      const auxiliar = "Pagos_semanales";
      const infoProducto = dataDB;
          
      guardar_semana_pagos( infoProducto, auxiliar, semana_actual_nominas, año_actual_nominas);

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se agrego correctamente.',
        showConfirmButton: false,
        timer: 1500
      });
  });
}

//Fin del boton CAPTURA DIARIA---------------------------------------------------------------------------------------------------------------------------

//Traer fecha de semana para las nominas-----------------------------------------------------------------------------------------------------------------
const [semana_actual_nominas, setsemana_actual_nominas] = React.useState("");
const [año_actual_nominas, setaño_actual_nominas] = React.useState("");
const [fecha_actual_nominas, setfecha_actual_nominas] = React.useState("");

async function fecha_nominas(){
  const seleccionado = "Fecha_para_nominas";
    getAlldata_consultar(seleccionado).then((dataDB) => {
    console.log(dataDB)
    setfecha_actual_nominas(dataDB[0].rango_fecha);
    setsemana_actual_nominas(dataDB[0].semana)
    setaño_actual_nominas(dataDB[0].año)
    console.log(dataDB[0].rango_fecha , dataDB[0].semana, dataDB[0].año)
  });
}
//Fin de fecha de semana para las nominas----------------------------------------------------------------------------------------------------------------

//Inicio concentrado por cuadrilleros--------------------------------------------------------------------------------------------------------------------
const [semanas_52, setsemanas_52] = React.useState();
const [años_para_seleccionar, setaños_para_seleccionar] = React.useState();

function crear_auxiliar_semanas_de_1_a_52(){
  const auxiliar = 52;
  const auxiliar_2 = [];
  for(let i = 0; i<auxiliar; i++){
    auxiliar_2.push(i);
  }
  setsemanas_52(auxiliar_2);

  //Crear selector de año
  const auxiliar_3 = [];
  var my_año = new Date();
  var año = my_año.getFullYear();
  for(let x = 2022; x<(año+1); x++){
    auxiliar_3.push(x);
  }
  setaños_para_seleccionar(auxiliar_3); 
  console.log(auxiliar_3);
}

const [trabajadores_concentrado, settrabajadores_concentrado] = React.useState([]);

function traer_trabajadores_de_cuadrilleros(){
  const cuadrillero_seleccionado = document.getElementById("cuadrillero_a_consultar").value;
  const año_seleccionado = document.getElementById("seleccionar_año").value;
  const semana_seleccionada = document.getElementById("semanas_del_año").value;
  Traer_trabajadores_concentrado_cuadrillero(cuadrillero_seleccionado, año_seleccionado, semana_seleccionada).then((dataDB) => {
  //Con este filtro los que no tienen ningun pago
  var quitar_los_que_tienen_0_pago = [];
  dataDB.map((dato)=>{
    if(dato.total == 0){
    }else{
      if(dato.curp_trabajador == undefined){
        dato["curp_trabajador"] = "";
      }
      if( dato.NSS_trabajador == undefined){
        dato["NSS_trabajador"] = "";
      }
      if( dato.RFC_trabajador == undefined){
        dato["RFC_trabajador"] = "";
      }

      if(dato.curp_trabajador == "" || dato.NSS_trabajador =="" || dato.RFC_trabajador == ""){
        dato["Falta_algun_dato"] = true;
      }
      
      quitar_los_que_tienen_0_pago.push(dato);
    }
  })  
  settrabajadores_concentrado(quitar_los_que_tienen_0_pago);
    if(dataDB == ""){
      Swal.fire({
        icon: 'error',
        text: "La semana seleccionada no contiene ningun registro de pagos.",
        footer: '¿Que hacer? <br/> Se debe probar consultar con otra fecha.'
      }) 
    }

  });
}
//Fin concentrado por cuadrilleros-----------------------------------------------------------------------------------------------------------------------

//Inicio concentrado general-----------------------------------------------------------------------------------------------------------------------------
const [concentrado_general, setconcentrado_general] = React.useState([]);
const [array_metodo_pago, setarray_metodo_pago] = React.useState([]);
const [array_cargos, setarray_cargos] = React.useState([]);

const [actividades_viernes_auxiliar, setactividades_viernes_auxiliar] = React.useState([]);
const [actividades_sabado_auxiliar, setactividades_sabado_auxiliar] = React.useState([]);
const [actividades_domingo_auxiliar, setactividades_domingo_auxiliar] = React.useState([]);
const [actividades_lunes_auxiliar, setactividades_lunes_auxiliar] = React.useState([]);
const [actividades_martes_auxiliar, setactividades_martes_auxiliar] = React.useState([]);
const [actividades_miercoles_auxiliar, setactividades_miercoles_auxiliar] = React.useState([]);
const [actividades_jueves_auxiliar, setactividades_jueves_auxiliar] = React.useState([]);

async function traer_datos_concentrado_general(){
  const año_seleccionado = document.getElementById("seleccionar_año").value;
  const semana_seleccionada = document.getElementById("semanas_del_año").value;
  const concentrado_seleccionado = document.getElementById("seleccionar_concentrado_general_a_consultar").value;


  if(concentrado_seleccionado == "Cuadrillero"){
    traer_cuadrilleros();

    var auxiliar_trabajadores = [];
    await Traer_trabajadores_concentrado_general(año_seleccionado, semana_seleccionada).then((Trabajadores_concentradoDB) => {
      auxiliar_trabajadores = Trabajadores_concentradoDB;
    }) 
  

  
    var agregar_fleteros = cuadrilleros_data;
    //Aqui se agrega el fletero al arreglo de cuadrilleros
    cuadrilleros_data.map((datos,index)=>{   
          auxiliar_trabajadores.map((valor,index)=>{ 
                    if(datos.nombre == valor.trabajador_cuadrillero){
                      if(valor.trabajador_fletero_de_cuadrillero == "Si"){
                        agregar_fleteros.push(valor);
                      }
                    }
                  })   
         }) 



    //Con esto calculo el total de cada cuadrillero y fletero de cada dia.
    var auxiliar_cuadrillero_totalizado = [];
    var auxiliar4 = [];
    var auxiliar_919 = 0;
    var n_str = 0;
    var n_nuevaStr = 0;
    var n_nuevaStr2 = 0;
    var n_nuevaStr3 = 0;
    var n_nuevaStr4 = 0;

    agregar_fleteros.map((datos,index)=>{ 
      //Con este agrego al cuadrillero al arreglo que se formara totalizado
        auxiliar4['nombre'] = (datos.nombre).toUpperCase();
        auxiliar4['viernes'] = 0;
        auxiliar4['sabado'] = 0;
        auxiliar4['domingo'] = 0;
        auxiliar4['lunes'] = 0;
        auxiliar4['martes'] = 0;
        auxiliar4['miercoles'] = 0;
        auxiliar4['jueves'] = 0;
        auxiliar4['total'] = 0;
        auxiliar4['adeudo'] = 0;
        auxiliar4['pagar'] = 0;
  
          auxiliar_trabajadores.map((valor,index)=>{ 
                    //Esto es para sumar lo del fletero de cada cuadrillero
                    if((valor.trabajador_fletero_de_cuadrillero == "Si") && (datos.nombre == valor.nombre)){
                        auxiliar_919 = formatter.format(+valor.Viernes.suma_importes + +auxiliar4.viernes);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['viernes'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Sabado.suma_importes + +auxiliar4.sabado);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['sabado'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Domingo.suma_importes + +auxiliar4.domingo);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['domingo'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Lunes.suma_importes + +auxiliar4.lunes);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['lunes'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Martes.suma_importes + +auxiliar4.martes);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['martes'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Miercoles.suma_importes + +auxiliar4.miercoles);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['miercoles'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+valor.Jueves.suma_importes + +auxiliar4.jueves);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['jueves'] = n_nuevaStr4;
    
                        auxiliar_919 = formatter.format(+auxiliar4.viernes + +auxiliar4.sabado + +auxiliar4.domingo + +auxiliar4.lunes + +auxiliar4.martes + +auxiliar4.miercoles + +auxiliar4.jueves);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['total'] = n_nuevaStr4;
    
                        auxiliar4['adeudo'] = 0;

                        auxiliar_919 = formatter.format(auxiliar4.total - auxiliar4.adeudo);
                        n_str = (auxiliar_919).toString();
                        n_nuevaStr = n_str.replace("$", "");
                        n_nuevaStr2 = n_nuevaStr.replace(",", "");
                        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                        auxiliar4['pagar'] = n_nuevaStr4;
                    }
                    
                    //Esto es para sumar todo lo de los cuadrilleros
                    if((valor.trabajador_fletero_de_cuadrillero == "No") && (datos.nombre == valor.trabajador_cuadrillero)){
                      auxiliar_919 = formatter.format(+valor.Viernes.suma_importes + +auxiliar4.viernes);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['viernes'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Sabado.suma_importes + +auxiliar4.sabado);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['sabado'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Domingo.suma_importes + +auxiliar4.domingo);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['domingo'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Lunes.suma_importes + +auxiliar4.lunes);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['lunes'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Martes.suma_importes + +auxiliar4.martes);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['martes'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Miercoles.suma_importes + +auxiliar4.miercoles);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['miercoles'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+valor.Jueves.suma_importes + +auxiliar4.jueves);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['jueves'] = n_nuevaStr4;
  
                      auxiliar_919 = formatter.format(+auxiliar4.viernes + +auxiliar4.sabado + +auxiliar4.domingo + +auxiliar4.lunes + +auxiliar4.martes + +auxiliar4.miercoles + +auxiliar4.jueves);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['total'] = n_nuevaStr4;
  
                      auxiliar4['adeudo'] = 0;

                      auxiliar_919 = formatter.format(auxiliar4.total - auxiliar4.adeudo);
                      n_str = (auxiliar_919).toString();
                      n_nuevaStr = n_str.replace("$", "");
                      n_nuevaStr2 = n_nuevaStr.replace(",", "");
                      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                      auxiliar4['pagar'] = n_nuevaStr4;
                    }
                  })   
  
          auxiliar_cuadrillero_totalizado.push(auxiliar4)
          auxiliar4 = [];
         }) 
  
    //Con este le agrego el ultimo array con el sumado de cada dia de cada fletero y cuadrillero
    var auxiliar_viernes = 0;
    var auxiliar_sabado = 0;
    var auxiliar_domingo = 0;
    var auxiliar_lunes = 0;
    var auxiliar_martes = 0;
    var auxiliar_miercoles = 0;
    var auxiliar_jueves = 0;
    var auxiliar_total = 0;
    var auxiliar_adeudo = 0;
    var auxiliar_pagar = 0;
  
    auxiliar_cuadrillero_totalizado.map((valor,index)=>{
      auxiliar_919 = formatter.format(+auxiliar_viernes + +valor.viernes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_viernes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_sabado + +valor.sabado);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_sabado = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_domingo + +valor.domingo);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_domingo = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_lunes + +valor.lunes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_lunes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_martes + +valor.martes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_martes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_miercoles + +valor.miercoles);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_miercoles = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_jueves + +valor.jueves);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_jueves = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_total + +valor.total);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_total = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_adeudo + +valor.adeudo);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_adeudo = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_pagar + +valor.pagar);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_pagar = n_nuevaStr4;
    })

    var array_sumador = {
      nombre: "TOTAL",
      viernes: auxiliar_viernes,
      sabado: auxiliar_sabado,
      domingo: auxiliar_domingo,
      lunes: auxiliar_lunes,
      martes: auxiliar_martes,
      miercoles: auxiliar_miercoles,
      jueves: auxiliar_jueves,
      total: auxiliar_total,
      adeudo: auxiliar_adeudo,
      pagar: auxiliar_pagar,
    }
    
     //Con esto lo ordeno por fechas de forma descendiente
    auxiliar_cuadrillero_totalizado.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
    auxiliar_cuadrillero_totalizado.push(array_sumador);
    setconcentrado_general(auxiliar_cuadrillero_totalizado);
  }//Fin if de cuadrillero
  
  if(concentrado_seleccionado == "Cargo"){
    const seleccionado = "Cargo";
    getAlldata_consultar(seleccionado).then((productos) => {
    setProductos(productos);
    
    Traer_trabajadores_concentrado_general(año_seleccionado, semana_seleccionada).then((Trabajadores_concentradoDB) => {
     var viernes_aux = 0;
     var sabado_aux = 0;
     var domingo_aux = 0;
     var lunes_aux = 0;
     var martes_aux = 0;
     var miercoles_aux = 0;
     var jueves_aux = 0;
     var auxiliar_919 = 0;
     var n_str = 0;
     var n_nuevaStr = 0;
     var n_nuevaStr2 = 0;
     var n_nuevaStr3 = 0;
     var n_nuevaStr4 = 0;

     var aux_actividades_viernes = [];
     var aux_actividades_sabado = [];
     var aux_actividades_domingo = [];
     var aux_actividades_lunes = [];
     var aux_actividades_martes = [];
     var aux_actividades_miercoles = [];
     var aux_actividades_jueves = [];

      productos.map((cargos_data)=>{
        viernes_aux = 0;
        sabado_aux = 0;
        domingo_aux = 0;
        lunes_aux = 0;
        martes_aux = 0;
        miercoles_aux = 0;
        jueves_aux = 0;

            Trabajadores_concentradoDB.map((trabajador, index)=>{
                if(trabajador.Viernes.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+viernes_aux + +trabajador.Viernes.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  viernes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Viernes.actividad,
                    importe : trabajador.Viernes.importe,
                    cargo : trabajador.Viernes.cargo,
                  }
                  aux_actividades_viernes.push(auxiliar_10);
                }

                if(trabajador.Viernes.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+viernes_aux + +trabajador.Viernes.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  viernes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Viernes.actividad_2,
                    importe : trabajador.Viernes.importe_2,
                    cargo : trabajador.Viernes.cargo_2,
                  }
                  aux_actividades_viernes.push(auxiliar_10);
                }

                if(trabajador.Sabado.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+sabado_aux + +trabajador.Sabado.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  sabado_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Sabado.actividad,
                    importe : trabajador.Sabado.importe,
                    cargo : trabajador.Sabado.cargo,
                  }
                  aux_actividades_sabado.push(auxiliar_10);
                }

                if(trabajador.Sabado.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+sabado_aux + +trabajador.Sabado.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  sabado_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Sabado.actividad_2,
                    importe : trabajador.Sabado.importe_2,
                    cargo : trabajador.Sabado.cargo_2,
                  }
                  aux_actividades_sabado.push(auxiliar_10);
                }

               if(trabajador.Domingo.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+domingo_aux + +trabajador.Domingo.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  domingo_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Domingo.actividad,
                    importe : trabajador.Domingo.importe,
                    cargo : trabajador.Domingo.cargo,
                  }
                  aux_actividades_domingo.push(auxiliar_10);
                }

                if(trabajador.Domingo.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+domingo_aux + +trabajador.Domingo.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  domingo_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Domingo.actividad_2,
                    importe : trabajador.Domingo.importe_2,
                    cargo : trabajador.Domingo.cargo_2,
                  }
                  aux_actividades_domingo.push(auxiliar_10);
                }

                if(trabajador.Lunes.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+lunes_aux + +trabajador.Lunes.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  lunes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Lunes.actividad,
                    importe : trabajador.Lunes.importe,
                    cargo : trabajador.Lunes.cargo,
                  }
                  aux_actividades_lunes.push(auxiliar_10);
                }

                if(trabajador.Lunes.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+lunes_aux + +trabajador.Lunes.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  lunes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Lunes.actividad_2,
                    importe : trabajador.Lunes.importe_2,
                    cargo : trabajador.Lunes.cargo_2,
                  }
                  aux_actividades_lunes.push(auxiliar_10);
                }

                if(trabajador.Martes.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+martes_aux + +trabajador.Martes.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  martes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Martes.actividad,
                    importe : trabajador.Martes.importe,
                    cargo : trabajador.Martes.cargo,
                  }
                  aux_actividades_martes.push(auxiliar_10);
                }

                if(trabajador.Martes.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+martes_aux + +trabajador.Martes.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  martes_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Martes.actividad_2,
                    importe : trabajador.Martes.importe_2,
                    cargo : trabajador.Martes.cargo_2,
                  }
                  aux_actividades_martes.push(auxiliar_10);
                }

                if(trabajador.Miercoles.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+miercoles_aux + +trabajador.Miercoles.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  miercoles_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Miercoles.actividad,
                    importe : trabajador.Miercoles.importe,
                    cargo : trabajador.Miercoles.cargo,
                  }
                  aux_actividades_miercoles.push(auxiliar_10);
                }

                if(trabajador.Miercoles.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+miercoles_aux + +trabajador.Miercoles.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  miercoles_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Miercoles.actividad_2,
                    importe : trabajador.Miercoles.importe_2,
                    cargo : trabajador.Miercoles.cargo_2,
                  }
                  aux_actividades_miercoles.push(auxiliar_10);
                }

                if(trabajador.Jueves.cargo == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+jueves_aux + +trabajador.Jueves.importe);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  jueves_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Jueves.actividad,
                    importe : trabajador.Jueves.importe,
                    cargo : trabajador.Jueves.cargo,
                  }
                  aux_actividades_jueves.push(auxiliar_10);
                }

                if(trabajador.Jueves.cargo_2 == cargos_data.nombre){
                  auxiliar_919 = formatter.format(+jueves_aux + +trabajador.Jueves.importe_2);
                  n_str = (auxiliar_919).toString();
                  n_nuevaStr = n_str.replace("$", "");
                  n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  n_nuevaStr4 = n_nuevaStr3.replace(",", "");
                  jueves_aux = n_nuevaStr4;
                  var auxiliar_10 = {
                    actividad : trabajador.Jueves.actividad_2,
                    importe : trabajador.Jueves.importe_2,
                    cargo : trabajador.Jueves.cargo_2,
                  }
                  aux_actividades_jueves.push(auxiliar_10);
                }
 

            });

        //Aqui le creo otra variable a cada cargo llamada total donde suma todos los dias de cada cargo de cada trabajador  
        auxiliar_919 = formatter.format(+viernes_aux + +sabado_aux + +domingo_aux + +lunes_aux + +martes_aux + +miercoles_aux + +jueves_aux);
        n_str = (auxiliar_919).toString();
        n_nuevaStr = n_str.replace("$", "");
        n_nuevaStr2 = n_nuevaStr.replace(",", "");
        n_nuevaStr3 = n_nuevaStr2.replace(",", "");
        n_nuevaStr4 = n_nuevaStr3.replace(",", "");
        cargos_data['total'] = n_nuevaStr4;

        cargos_data['viernes'] = viernes_aux;
        cargos_data['sabado'] = sabado_aux;
        cargos_data['domingo'] = domingo_aux;
        cargos_data['lunes'] = lunes_aux;
        cargos_data['martes'] = martes_aux;
        cargos_data['miercoles'] = miercoles_aux;
        cargos_data['jueves'] = jueves_aux;
        cargos_data['nombre'] = (cargos_data.nombre).toUpperCase();
        
      });
       //Con este le agrego el ultimo array con el sumado de cada dia de cada fletero y cuadrillero
    var auxiliar_viernes = 0;
    var auxiliar_sabado = 0;
    var auxiliar_domingo = 0;
    var auxiliar_lunes = 0;
    var auxiliar_martes = 0;
    var auxiliar_miercoles = 0;
    var auxiliar_jueves = 0;
    var auxiliar_total = 0;
  
    productos.map((valor,index)=>{
      auxiliar_919 = formatter.format(+auxiliar_viernes + +valor.viernes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_viernes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_sabado + +valor.sabado);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_sabado = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_domingo + +valor.domingo);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_domingo = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_lunes + +valor.lunes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_lunes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_martes + +valor.martes);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_martes = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_miercoles + +valor.miercoles);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_miercoles = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_jueves + +valor.jueves);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_jueves = n_nuevaStr4;

      auxiliar_919 = formatter.format(+auxiliar_total + +valor.total);
      n_str = (auxiliar_919).toString();
      n_nuevaStr = n_str.replace("$", "");
      n_nuevaStr2 = n_nuevaStr.replace(",", "");
      n_nuevaStr3 = n_nuevaStr2.replace(",", "");
      n_nuevaStr4 = n_nuevaStr3.replace(",", "");
      auxiliar_total = n_nuevaStr4;
    })

    var array_sumador = {
      nombre: "TOTAL",
      viernes: auxiliar_viernes,
      sabado: auxiliar_sabado,
      domingo: auxiliar_domingo,
      lunes: auxiliar_lunes,
      martes: auxiliar_martes,
      miercoles: auxiliar_miercoles,
      jueves: auxiliar_jueves,
      total: auxiliar_total,
    }

      //Con esto lo ordeno por fechas de forma descendiente
      productos.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));
      productos.push(array_sumador);
      console.log(productos)
      setarray_cargos(productos);


      //Guardar actividades y cargo de cada diva
      setactividades_viernes_auxiliar(aux_actividades_viernes);
      setactividades_sabado_auxiliar(aux_actividades_sabado);
      setactividades_domingo_auxiliar(aux_actividades_domingo);
      setactividades_lunes_auxiliar(aux_actividades_lunes);
      setactividades_martes_auxiliar(aux_actividades_martes);
      setactividades_miercoles_auxiliar(aux_actividades_miercoles);
      setactividades_jueves_auxiliar(aux_actividades_jueves);
    }) 

   });
  }//Fin if cargos

  if(concentrado_seleccionado == "Metodo de pago"){
    const metodo_de_pago_seleccionado = document.getElementById("seleccionar_banco_metodo_pago").value;

    await Traer_trabajadores_concentrado_general(año_seleccionado, semana_seleccionada).then((Trabajadores_concentradoDB) => {
    //Con esto calculo el total de cada cuadrillero y fletero de cada dia.
    var auxiliar_filtrados_por_banco = [];
        Trabajadores_concentradoDB.map((valor,index)=>{ 
                  if(valor.banco_trabajador == metodo_de_pago_seleccionado){
                    auxiliar_filtrados_por_banco.push(valor)
                  }
               });

      //Agregar array de totalizador
        var auxiliar_suma_totales = 0;
          auxiliar_filtrados_por_banco.map((valor,index)=>{ 
            var auxiliar_919 = formatter.format(+auxiliar_suma_totales + +valor.total);
            var n_str = (auxiliar_919).toString();
            var n_nuevaStr = n_str.replace("$", "");
            var n_nuevaStr2 = n_nuevaStr.replace(",", "");
            var n_nuevaStr3 = n_nuevaStr2.replace(",", "");
            var n_nuevaStr4 = n_nuevaStr3.replace(",", "");
            auxiliar_suma_totales = n_nuevaStr4;
        }) 

      var auxiliar_array_suma_total = {
          total: auxiliar_suma_totales,
          cuenta_trabajador: "TOTAL",
        };  

            
        //Con esto lo ordeno por fechas de forma descendiente
        auxiliar_filtrados_por_banco.sort((a,b)=> (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0));

        auxiliar_filtrados_por_banco.push(auxiliar_array_suma_total);
        setarray_metodo_pago(auxiliar_filtrados_por_banco);
    }) 
  }//Fin if de metodo de pago
}

//Ver actividades del cargo y dia seleccionado 
const [isModal_actividades_cargos, setisModal_actividades_cargos] = React.useState(false);
const [dia_select_actividades, setdia_select_actividades] = React.useState("");
const [data_cargo_select, setdata_cargo_select] = React.useState();

function ver_actividades_viernes(){
  setdia_select_actividades("viernes");
  setisModal_actividades_cargos(true);
}

function ver_actividades_sabado(){
  setdia_select_actividades("sabado");
  setisModal_actividades_cargos(true);
}

function ver_actividades_domingo(){
  setdia_select_actividades("domingo");
  setisModal_actividades_cargos(true);
}

function ver_actividades_lunes(){
  setdia_select_actividades("lunes");
  setisModal_actividades_cargos(true);
}

function ver_actividades_martes(){
  setdia_select_actividades("martes");
  setisModal_actividades_cargos(true);
}

function ver_actividades_miercoles(){
  setdia_select_actividades("miercoles");
  setisModal_actividades_cargos(true);
}

function ver_actividades_jueves(){
  setdia_select_actividades("jueves");
  setisModal_actividades_cargos(true);
}

const [concentrado_Cuadrillero_seleccionado, setconcentrado_Cuadrillero_seleccionado] = React.useState();
const [concentrado_Cargo_seleccionado, setconcentrado_Cargo_seleccionado] = React.useState();
const [concentrado_Metodo_seleccionado, setconcentrado_Metodo_seleccionado] = React.useState();

function seleccionar_consulta_auxiliar() {
  const concentrado_seleccionado = document.getElementById("seleccionar_concentrado_general_a_consultar").value;
  if(concentrado_seleccionado == "Cuadrillero"){
    setconcentrado_Cuadrillero_seleccionado(true);
    setconcentrado_Cargo_seleccionado(false);
    setconcentrado_Metodo_seleccionado(false);
  }

  if(concentrado_seleccionado == "Cargo"){
    setconcentrado_Cuadrillero_seleccionado(false);
    setconcentrado_Cargo_seleccionado(true);
    setconcentrado_Metodo_seleccionado(false);
  }

  if(concentrado_seleccionado == "Metodo de pago"){
    setconcentrado_Cuadrillero_seleccionado(false);
    setconcentrado_Cargo_seleccionado(false);
    setconcentrado_Metodo_seleccionado(true);
  }
}
//Fin concentrado general--------------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    /* boton captura diaria */
    traer_cuadrilleros();
    evaluar_reset();
    /* Fin boton captura diaria */

    /* Evaluar en que pagina estoy */
    evaluar_pagina();
    /* Fin evaluar pagina */

    /* Traer fecha para nominas */
    fecha_nominas();
    /* Fin traer fecha para nominas */

    /* Inicio concentrado por cuadrilleros */
    if(pagina=="Concentrado_por_cuadrillero" || pagina=="Concentrado_general" ){
      crear_auxiliar_semanas_de_1_a_52()
    }
    /* Fin concentrado por cuadrilleros */
  }, []);

  return (
    <div>
      {captura_diaria_ON && (
        <div>
        <div id="Tablas_blanco" >
        <Navbartrabajo/>
        <Container fluid id="Navbar_trabajo_home">
        {isModalAñadir_cuadrillero && (
          <ModalAñadir_cuadrillero
            isModalAñadir={isModalAñadir_cuadrillero}
            setIsModalAñadir={setIsModalAñadir_cuadrillero}
            actualizarEstadoProductos={actualizarEstadoProductos}
            usuario={usuario}
            setProductos= {setProductos}
          />
        )}
        {isModalAñadir_consultar && (
          <Modal_consultar
            isModalAñadir={isModalAñadir_consultar}
            setIsModalAñadir={setIsModalAñadir_consultar}
            actualizarEstadoProductos={actualizarEstadoProductos}
            usuario={usuario}
            setProductos= {setProductos}
          />
          )}
          {isModal_pago && (
            <Modal_pago
              isModalEditar={isModal_pago}
              setIsModalEditar={setIsModal_pago}
              actualizarEstadoProductos={actualizarEstadoProductos}
              productoEditar={productoEditar}
              setProductoEditar={setProductoEditar}
              usuario={usuario}
              settrabajadores_data= {settrabajadores_data}
              cuadrillero_seleccionado = {cuadrillero_seleccionado}
              trabajadores_data = {trabajadores_data}
            />
          )}
          {isModal_pagar_a_todos && (
            <Modalpagar_a_todos_al_mismo_tiempo
              isModal_pagar_a_todos={isModal_pagar_a_todos}
              setisModal_pagar_a_todos={setisModal_pagar_a_todos}
              actualizarEstadoProductos={actualizarEstadoProductos}
              usuario={usuario}
              trabajadores_data = {trabajadores_data}
              settrabajadores_data = {settrabajadores_data}
              cuadrillero_seleccionado = {cuadrillero_seleccionado}
            />
          )}

        {Modal_añadir_fecha_actual && (
            <ModalAñadir
              Modal_añadir_fecha_actual={Modal_añadir_fecha_actual}
              setModal_añadir_fecha_actual={setModal_añadir_fecha_actual}
              fecha_actual_nominas={fecha_actual_nominas}
              semana_actual_nominas={semana_actual_nominas}
              año_actual_nominas={año_actual_nominas}
            />
          )}

          <p style={{ fontSize: 20 }}>Actualmente estas en nominas en la sección de {pagina}</p>
          <Stack direction="horizontal" className="justify-content-between">
            <Button onClick={añadir_cuadrillero}>Añadir cuadrillero, importe, cargo, etc</Button>
            <Button onClick={consultar}>Consultar</Button>
            <Button id="reset_pagos_semana" onClick={()=>{evaluar_semana()}}> Borrar todos los pagos</Button>
            <Button id="guardar_semana" onClick={()=>{guardar_pagos_semana()}}> Guardar semana</Button>
            <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
          </Stack>

          <hr />

          <Stack direction="horizontal" className="justify-content-between">
              <h6 style={{ marginLeft: "0%"}}>Seleccionar cuadrillero </h6>
                 <select style={{ marginRight: "1%"}} id="trabajador_cuadrillero" type="text" onClick={() => { traer_trabajadores() }}>
                          <option key="1234">Seleccionar</option>
                          {cuadrilleros_data.map((datos,index)=>( 
                              <option key={index}>{datos.nombre}</option>
                          ))} 
                 </select>
               <Button style={{ marginRight: "0%"}} onClick={()=>{setisModal_pagar_a_todos(true)}}>Pagar a toda la cuadrilla</Button> 
              <h6 key="456" style={{ marginLeft: "0%"}} >Actualmente es la semana # {semana_actual_nominas} del {fecha_actual_nominas} del {año_actual_nominas}.</h6>
              <Button style={{ marginRight: "5%"}} onClick={()=>{setModal_añadir_fecha_actual(true)}}>Editar fecha</Button>          
           </Stack>
           <hr />
          {/* id="Tabla_logs" className="container-fluid" */}
          <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NSS</th>
                        <th>NOMBRE COMPLETO</th>
                        <th>VIERNES</th>
                        <th>SABADO</th>
                        <th>DOMINGO</th>
                        <th>LUNES</th>
                        <th>MARTES</th>
                        <th>MIERCOLES</th>
                        <th>JUEVES</th>
                        <th>DESCUENTO</th>
                        <th>TOTAL</th>
                        <th>BANCO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        trabajadores_data.map((producto, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{producto.NSS_trabajador}</td>
                            <td>{producto.nombre}</td>
                            <td>{formatter.format(producto.Viernes.suma_importes)}</td>
                            <td>{formatter.format(producto.Sabado.suma_importes)}</td>
                            <td>{formatter.format(producto.Domingo.suma_importes)}</td>
                            <td>{formatter.format(producto.Lunes.suma_importes)}</td>
                            <td>{formatter.format(producto.Martes.suma_importes)}</td>
                            <td>{formatter.format(producto.Miercoles.suma_importes)}</td>
                            <td>{formatter.format(producto.Jueves.suma_importes)}</td>
                            <td>{formatter.format(producto.descuento)}</td>
                            <td>{formatter.format(producto.total)}</td>
                            <td>{producto.banco_trabajador}</td>
                            <td>
                              <Button
                                variant="success"
                                onClick={() => {
                                  setProductoEditar({ ...producto });
                                  setIsModal_pago(true);
                                }}
                              >
                                Pago
                              </Button>
                            {/*    <Button
                                variant="danger"
                                onClick={() => {
                                  eliminar_sweetalert(producto, usuario);
                                }}
                              >
                                Eliminar
                              </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
              </Table>
          <br/>
          <br/>
        </Container>
        </div>
        </div>
      )}

      {Concentrado_por_cuadrillero_ON && (
        <div>
        <div id="Tablas_blanco" >
        <Navbartrabajo/>
        <Container fluid id="Navbar_trabajo_home">
        {isModalAñadir_cuadrillero && (
          <ModalAñadir_cuadrillero
            isModalAñadir={isModalAñadir_cuadrillero}
            setIsModalAñadir={setIsModalAñadir_cuadrillero}
            actualizarEstadoProductos={actualizarEstadoProductos}
            usuario={usuario}
            setProductos= {setProductos}
          />
        )}
        {isModalAñadir_consultar && (
          <Modal_consultar
            isModalAñadir={isModalAñadir_consultar}
            setIsModalAñadir={setIsModalAñadir_consultar}
            actualizarEstadoProductos={actualizarEstadoProductos}
            usuario={usuario}
            setProductos= {setProductos}
          />
          )}
          {isModal_pago && (
            <Modal_pago
              isModalEditar={isModal_pago}
              setIsModalEditar={setIsModal_pago}
              actualizarEstadoProductos={actualizarEstadoProductos}
              productoEditar={productoEditar}
              setProductoEditar={setProductoEditar}
              usuario={usuario}
              settrabajadores_data= {settrabajadores_data}
              cuadrillero_seleccionado = {cuadrillero_seleccionado}
              trabajadores_data = {trabajadores_data}
            />
          )}
          <Stack direction="horizontal" className="justify-content-between">
              <p style={{ fontSize: 20 }}>Actualmente estas en nominas en la sección de {pagina}</p>
              <Button style={{ marginRight: "10%"}} id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
          </Stack>
          <hr />
          <Stack direction="horizontal" className="justify-content-between">
                <h6 style={{ marginLeft: "0%"}}>Seleccionar cuadrillero </h6>
                   <select style={{ marginRight: "5%"}} id="cuadrillero_a_consultar" type="text" /* onClick={() => { traer_trabajadores() }} */>
                          <option key="1000">Seleccionar</option>
                          {cuadrilleros_data.map((datos,index)=>( 
                              <option key={index}>{datos.nombre}</option>
                          ))} 
                   </select>  

               <h6 key="678" style={{ marginLeft: "0%"}} >Seleccionar año.</h6>
                   <select style={{ marginRight: "5%", paddingRight: "0%"}} id="seleccionar_año" type="text" /* onClick={() => { traer_trabajadores() }} */>
                     <option key="2000">Seleccionar</option>
                          {años_para_seleccionar.map((datos,index)=>( 
                              <option key={index}>{datos}</option>
                          ))} 
                   </select>       

              <h6 key="679" style={{ marginLeft: "0%"}} >Seleccionar semana a consultar.</h6>
                   <select style={{ marginRight: "5%", paddingRight: "0%"}} id="semanas_del_año" type="text" /* onClick={() => { traer_trabajadores() }} */>
                     <option key="3000">Seleccionar</option>
                          {semanas_52.map((datos,index)=>( 
                              <option key={index}>{index+1}</option>
                          ))} 
                   </select>  

                   
              <Button style={{ marginRight: "2%"}} id="buscar_concentrado_cuadrillero" onClick={()=>{traer_trabajadores_de_cuadrilleros()}}>Consultar</Button>            
           </Stack>
          <hr />
          {/* id="Tabla_logs" className="container-fluid" */}
          <Table>
                    <thead>
                      <tr>
                        <th>NOMBRE TRABAJADOR</th>
                        <th>VIERNES</th>
                        <th>SABADO</th>
                        <th>DOMINGO</th>
                        <th>LUNES</th>
                        <th>MARTES</th>
                        <th>MIERCOLES</th>
                        <th>JUEVES</th>
                        <th>TOTAL</th>
                        <th>DESCUENTO</th>
                        <th>A PAGAR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        trabajadores_concentrado.map((producto, index) => (
                          <tr key={index}>
                            {producto.Falta_algun_dato ?
                              <td style={{ color: "red"}}>{producto.nombre}</td>
                              :
                              <td>{producto.nombre}</td>
                            }
                            <td>{formatter.format(producto.Viernes.suma_importes)}</td>
                            <td>{formatter.format(producto.Sabado.suma_importes)}</td>
                            <td>{formatter.format(producto.Domingo.suma_importes)}</td>
                            <td>{formatter.format(producto.Lunes.suma_importes)}</td>
                            <td>{formatter.format(producto.Martes.suma_importes)}</td>
                            <td>{formatter.format(producto.Miercoles.suma_importes)}</td>
                            <td>{formatter.format(producto.Jueves.suma_importes)}</td>
                            <td>{formatter.format(+(producto.total) + +(producto.descuento))}</td>
                            <td>{formatter.format(producto.descuento)}</td>
                            <td>{formatter.format(producto.total)}</td>
                          </tr>
                        ))}
                    </tbody>
              </Table>
          <br/>
          <br/>
        </Container>
        </div>
        </div>
      )}

      {Concentrado_general_ON && (
        <div>
        <div id="Tablas_blanco" >
        <Navbartrabajo/>
        <Container fluid id="Navbar_trabajo_home">
        {isModalAñadir_cuadrillero && (
          <ModalAñadir_cuadrillero
            isModalAñadir={isModalAñadir_cuadrillero}
            setIsModalAñadir={setIsModalAñadir_cuadrillero}
            actualizarEstadoProductos={actualizarEstadoProductos}
            usuario={usuario}
            setProductos= {setProductos}
          />
        )}

      {isModal_actividades_cargos && (
          <Modal_actividades_cargos
            isModalAñadir={isModal_actividades_cargos}
            setIsModalAñadir={setisModal_actividades_cargos}
            actualizarEstadoProductos={actualizarEstadoProductos}
            data_cargo_select={data_cargo_select}
            array_cargos= {array_cargos}
            dia_select_actividades = {dia_select_actividades}
            actividades_viernes_auxiliar = {actividades_viernes_auxiliar}
            actividades_sabado_auxiliar = {actividades_sabado_auxiliar}
            actividades_domingo_auxiliar = {actividades_domingo_auxiliar}
            actividades_lunes_auxiliar = {actividades_lunes_auxiliar}
            actividades_martes_auxiliar = {actividades_martes_auxiliar}
            actividades_miercoles_auxiliar = {actividades_miercoles_auxiliar}
            actividades_jueves_auxiliar = {actividades_jueves_auxiliar}
          />
        )}

          <Stack direction="horizontal" className="justify-content-between">
              <p style={{ fontSize: 20 }}>Actualmente estas en nominas en la sección de {pagina}</p>
              <Button style={{ marginRight: "10%"}} id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
          </Stack>
          <hr />
          <Stack direction="horizontal" className="justify-content-between">
                <h6 style={{ marginLeft: "0%"}}>Seleccionar consulta </h6>
                   <select style={{ marginRight: "5%"}} id="seleccionar_concentrado_general_a_consultar" type="text" onClick={()=>{seleccionar_consulta_auxiliar()}}>
                          <option key="10004">Seleccionar</option>
                          <option key="10001">Cuadrillero</option>
                          <option key="10002">Cargo</option>
                          <option key="10003">Metodo de pago</option>
                   </select> 


              {concentrado_Metodo_seleccionado && (  
                  <>
                   <h6 style={{ marginLeft: "0%"}}>Seleccionar consulta </h6>
                   <select style={{ marginRight: "5%"}} id="seleccionar_banco_metodo_pago" type="text" /* onClick={() => { traer_trabajadores() }} */>
                          <option key="10010">Bancomer</option>
                          <option key="10011">Santander</option>
                          <option key="10012">Efectivo</option>
                   </select> 
                   </>
     
                 )}

               <h6 key="999" style={{ marginLeft: "0%"}} >Seleccionar año.</h6>
                   <select style={{ marginRight: "5%", paddingRight: "0%"}} id="seleccionar_año" type="text" /* onClick={() => { traer_trabajadores() }} */>
                     <option key="2000">Seleccionar</option>
                          {años_para_seleccionar.map((datos,index)=>( 
                              <option key={index}>{datos}</option>
                          ))} 
                   </select>       

              <h6 key="555" style={{ marginLeft: "0%"}} >Seleccionar semana a consultar.</h6>
                   <select style={{ marginRight: "5%", paddingRight: "0%"}} id="semanas_del_año" type="text" /* onClick={() => { traer_trabajadores() }} */>
                     <option key="3000">Seleccionar</option>
                          {semanas_52.map((datos,index)=>( 
                              <option key={index}>{index+1}</option>
                          ))} 
                   </select>  

             <Button style={{ marginRight: "2%"}} id="buscar_concentrado_cuadrillero" onClick={()=>{traer_datos_concentrado_general()}}>Consultar</Button>  

{/*               {concentrado_Cuadrillero_seleccionado && ( 
                <Button style={{ marginRight: "2%"}} id="buscar_concentrado_cuadrillero" onClick={()=>{traer_datos_concentrado_general()}}>Consultar</Button>  
              )}     
              {concentrado_Cargo_seleccionado && ( 
                <Button style={{ marginRight: "2%"}} id="buscar_concentrado_cuadrillero" onClick={()=>{traer_datos_concentrado_general()}}>Consultar</Button>  
              )}     

              {concentrado_Metodo_seleccionado && ( 
                <Button style={{ marginRight: "2%"}} id="buscar_concentrado_metodo_pago" onClick={()=>{filtrar_trabajadores_por_banco()}}>Consultar</Button>  
              )}  */}    
          
           </Stack>
          <hr />
            {concentrado_Cuadrillero_seleccionado && (     
              <Table>
              <thead>
                <tr>
                  <th>NOMBRE CUADRILLERO</th>
                  <th>VIERNES</th>
                  <th>SABADO</th>
                  <th>DOMINGO</th>
                  <th>LUNES</th>
                  <th>MARTES</th>
                  <th>MIERCOLES</th>
                  <th>JUEVES</th>
                  <th>TOTAL</th>
                  <th>ADEUDO</th>
                  <th>A PAGAR</th>
                </tr>
              </thead>
              <tbody>
                 {
                  concentrado_general.map((producto, index) => (
                    <tr key={index}>
                      <td>{producto.nombre}</td>
                      <td>{formatter.format(producto.viernes)}</td>
                      <td>{formatter.format(producto.sabado)}</td>
                      <td>{formatter.format(producto.domingo)}</td>
                      <td>{formatter.format(producto.lunes)}</td>
                      <td>{formatter.format(producto.martes)}</td>
                      <td>{formatter.format(producto.miercoles)}</td>
                      <td>{formatter.format(producto.jueves)}</td>
                      <td>{formatter.format(+(producto.total) + +(producto.adeudo))}</td>
                      <td>{formatter.format(producto.adeudo)}</td>
                      <td>{formatter.format(producto.total)}</td>
                    </tr>
                  ))} 
              </tbody>
              </Table>
            )}

            {concentrado_Cargo_seleccionado && (     
              <Table>
              <thead>
                <tr>
                  <th>CARGO</th>
                  <th>VIERNES</th>
                  <th>SABADO</th>
                  <th>DOMINGO</th>
                  <th>LUNES</th>
                  <th>MARTES</th>
                  <th>MIERCOLES</th>
                  <th>JUEVES</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                 {
                  array_cargos.map((producto, index) => (
                    <tr key={index}>
                      <td>{producto.nombre}</td>
                           {/* Viernes */}
                      <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_viernes()
                                  }}>{formatter.format(producto.viernes)}
                            </Button></td> 
                      <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_sabado()}
                                  }>{formatter.format(producto.sabado)}
                            </Button></td> 
                            <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_domingo()
                                  }}>{formatter.format(producto.domingo)}
                            </Button></td> 
                      <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_lunes()}}
                                  >{formatter.format(producto.lunes)}
                            </Button></td> 

                            <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_martes()}
                                  }>{formatter.format(producto.martes)}
                            </Button></td> 
                      <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_miercoles
                                  ()}}>{formatter.format(producto.miercoles)}
                            </Button></td> 
                      <td><Button
                                variant="light"
                                onClick={() => {
                                  setdata_cargo_select({ ...producto });
                                  ver_actividades_jueves()}
                                  }>{formatter.format(producto.jueves)}
                            </Button></td> 

                      <td>{formatter.format(producto.total)}</td>
                    </tr>
                  ))} 
              </tbody>
              </Table>
            )}

            {concentrado_Metodo_seleccionado && (     
              <Table>
              <thead>
                <tr>
                  <th>CUENTA</th>
                  <th>IMPORTE</th>
                  <th>EMPLEADO</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                    {
                  array_metodo_pago.map((producto, index) => (
                    <tr key={index}>
                      <td>{producto.cuenta_trabajador}</td>
                      <td>{producto.total}</td>
                      <td>{producto.nombre}</td>
                      <td>{formatter.format(producto.total)}</td>
                    </tr>
                  ))} 
              </tbody>
              </Table>
            )}
            
  
          <br/>
          <br/>
        </Container>
        </div>
        </div>
      )}

    </div>
  
  );
}

export default Home_Nominas;
