import CRUD_Cultivo_en_proceso from '../Firebase/CRUDS/CRUD_Cultivo_en_proceso';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_cultivo_en_proceso from '../Listas/Lista_cultivo_en_proceso';


//<Listaproduccion/> , va en la linea 14
const Cultivo_en_proceso = () =>{
    const [Start, setStart] = useState(false);
    const [valor, setvalor] = useState(null);
    const [botones, setbotones] = useState([]);
    const [continuar, setcontinuar] = useState(false);

    const [Gastos_indi_produccion2, setGastos_indi_produccion2] = useState(false);
    const [Costos2, setCostos2] = useState(false);

    function operacion(){
        if(valor===null){
            setStart(false);
            setcontinuar(false);
        }else{
            setStart(true);
            setcontinuar(true);
        }
    }

    function Pagina_principal(){
        setStart(false);
        setcontinuar(false);
    }

    function Gastos_indi_produccion(){
        setvalor("Gastos indirectos de producción");
    }

    function Costos(Botones_campo){
        setbotones(Botones_campo);
        console.log(Botones_campo);
        setvalor("Costos");
    }

       React.useEffect(() => {
        operacion();
        
       }, [valor]);

    return(
        <div id="Trabajo">  
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                

                
                    {Start? null : 
                             <Lista_cultivo_en_proceso 
                                setvalor={setvalor}
                                Gastos_indi_produccion={Gastos_indi_produccion}
                                Costos={Costos}
                                setbotones={setbotones}
                                
                            />}
                

                    {continuar? <CRUD_Cultivo_en_proceso botones={botones} pagina={valor} start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}


export default Cultivo_en_proceso;


//---------------------------------------------------------------------------------------------------

/* import CRUD_Cultivo_en_proceso from '../Firebase/CRUDS/CRUD_Cultivo_en_proceso';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import Lista_cultivo_en_proceso from '../Listas/Lista_cultivo_en_proceso';
import Container from "react-bootstrap/Container"


//<Listaproduccion/> , va en la linea 14
const Cultivo_en_proceso = () =>{

    const [Start, setStart] = useState(false);

    const [Casa_sombra_1, setCasa_sombra_1] = useState(false);
    const [Casa_sombra_2, setCasa_sombra_2] = useState(false);
    const [Casa_sombra_3, setCasa_sombra_3] = useState(false);
    const [Casa_sombra_4, setCasa_sombra_4] = useState(false);
    const [Casa_sombra_5, setCasa_sombra_5] = useState(false);
    const [Casa_sombra_6, setCasa_sombra_6] = useState(false);
    const [Casa_sombra_7, setCasa_sombra_7] = useState(false);
    const [Casa_sombra_8, setCasa_sombra_8] = useState(false);

    const [T_9_Fausta, setT_9_Fausta] = useState(false);
    const [T_10_Fausta, setT_10_Fausta] = useState(false);
    const [T_11_Fausta, setT_11_Fausta] = useState(false);
    const [T_12_Fausta, setT_12_Fausta] = useState(false);
    const [T_13_Fausta, setT_13_Fausta] = useState(false);
    const [T_14_Fausta, setT_14_Fausta] = useState(false);
    const [T_15_Fausta, setT_15_Fausta] = useState(false);

    const [T_16_Woller, setT_16_Woller] = useState(false);
    const [T_17_Woller, setT_17_Woller] = useState(false);
    const [T_18_Woller, setT_18_Woller] = useState(false);
    const [T_19_Woller, setT_19_Woller] = useState(false);
    const [T_20_Woller, setT_20_Woller] = useState(false);
    const [T_21_Woller, setT_21_Woller] = useState(false);
    const [T_22_Woller, setT_22_Woller] = useState(false);

    const [T_1_Las_40, setT_1_Las_40] = useState(false);
    const [T_2_Las_40, setT_2_Las_40] = useState(false);
    const [T_3_Las_40, setT_3_Las_40] = useState(false);
    const [T_4_Las_40, setT_4_Las_40] = useState(false);
    const [T_5_Las_40, setT_5_Las_40] = useState(false);
    const [T_6_Las_70, setT_6_Las_70] = useState(false);

    const [T_7_Los_Pinos, setT_7_Los_Pinos] = useState(false);
    const [T_8_Los_Pinos, setT_8_Los_Pinos] = useState(false);
    const [T_9_Los_Pinos, setT_9_Los_Pinos] = useState(false);
    const [T_10_Los_Pinos, setT_10_Los_Pinos] = useState(false);
    const [T_11_Los_Pinos, setT_11_Los_Pinos] = useState(false);
    const [T_12_Los_Pinos, setT_12_Los_Pinos] = useState(false);

    const [T_1_Del_Eden, setT_1_Del_Eden] = useState(false);
    const [T_2_Del_Eden, setT_2_Del_Eden] = useState(false);
    const [T_3_Del_Eden, setT_3_Del_Eden] = useState(false);
    const [T_4_Del_Eden, setT_4_Del_Eden] = useState(false);
    const [T_5_Del_Eden, setT_5_Del_Eden] = useState(false);
    const [T_6_Del_Eden, setT_6_Del_Eden] = useState(false);
    const [T_7_Del_Eden, setT_7_Del_Eden] = useState(false);
    const [T_8_Del_Eden, setT_8_Del_Eden] = useState(false);


    const [Costos, setCostos] = useState(false);
    const [Gastos_indi_produccion, setGastos_indi_produccion] = useState(false);

    //Este sirve para regresar a seleccionar cuando se presione regresar
    function Pagina_principal(){
        setStart(false)
        setCasa_sombra_1(false);
        setCasa_sombra_2(false);
        setCasa_sombra_3(false);
        setCasa_sombra_4(false);
        setCasa_sombra_5(false);
        setCasa_sombra_6(false);
        setCasa_sombra_7(false);
        setCasa_sombra_8(false); 
        setT_9_Fausta(false); 
        setT_10_Fausta(false);
        setT_11_Fausta(false);
        setT_12_Fausta(false);
        setT_13_Fausta(false);
        setT_14_Fausta(false);
        setT_15_Fausta(false);
        setT_16_Woller(false);
        setT_17_Woller(false);
        setT_18_Woller(false);
        setT_19_Woller(false);
        setT_20_Woller(false);
        setT_21_Woller(false);
        setT_22_Woller(false);
        setT_1_Las_40(false);
        setT_2_Las_40(false);
        setT_3_Las_40(false);
        setT_4_Las_40(false);
        setT_5_Las_40(false);
        setT_6_Las_70(false);
        setT_7_Los_Pinos(false);
        setT_8_Los_Pinos(false);
        setT_9_Los_Pinos(false);
        setT_10_Los_Pinos(false);
        setT_11_Los_Pinos(false);
        setT_12_Los_Pinos(false);
        setT_1_Del_Eden(false);
        setT_2_Del_Eden(false);
        setT_3_Del_Eden(false);
        setT_4_Del_Eden(false);
        setT_5_Del_Eden(false);
        setT_6_Del_Eden(false);
        setT_7_Del_Eden(false);
        setT_8_Del_Eden(false);

        setCostos(false);
        setGastos_indi_produccion(false);
    }

    function Casa_sombraa_1(){
        setCasa_sombra_1(true); 
        setStart(true);
       }

    function Casa_sombraa_2(){
        setCasa_sombra_2(true); 
        setStart(true);
       }

    function Casa_sombraa_3(){
        setCasa_sombra_3(true);
        setStart(true);
       }

    function Casa_sombraa_4(){
        setCasa_sombra_4(true); 
        setStart(true);
       }

       function Casa_sombraa_5(){
        setCasa_sombra_5(true); 
        setStart(true);
       }

    function Casa_sombraa_6(){
        setCasa_sombra_6(true);
        setStart(true);
       }

    function Casa_sombraa_7(){
        setCasa_sombra_7(true);
        setStart(true);
       }

    function Casa_sombraa_8(){
        setCasa_sombra_8(true); 
        setStart(true);
       }

    function T_9_Faustaa(){
        setT_9_Fausta(true); 
        setStart(true);
       }  
       
    function T_10_Faustaa(){
        setT_10_Fausta(true); 
        setStart(true);
       }  
       
    function T_11_Faustaa(){
        setT_11_Fausta(true); 
        setStart(true);
       }     
       
    function T_12_Faustaa(){
        setT_12_Fausta(true); 
        setStart(true);
       }       
 
    function T_13_Faustaa(){
        setT_13_Fausta(true); 
        setStart(true);
       }  

    function T_14_Faustaa(){
        setT_14_Fausta(true); 
        setStart(true);
       }       
 
    function T_15_Faustaa(){
        setT_15_Fausta(true); 
        setStart(true);
       } 
       
    function T_16_Wollerr(){
        setT_16_Woller(true); 
        setStart(true);
       }
       
    function T_17_Wollerr(){
        setT_17_Woller(true); 
        setStart(true);
       } 
       
    function T_18_Wollerr(){
        setT_18_Woller(true); 
        setStart(true);
       }
       
    function T_19_Wollerr(){
        setT_19_Woller(true); 
        setStart(true);
       } 

    function T_20_Wollerr(){
        setT_20_Woller(true); 
        setStart(true);
       } 
       
    function T_21_Wollerr(){
        setT_21_Woller(true); 
        setStart(true);
       }
       
    function T_22_Wollerr(){
        setT_22_Woller(true); 
        setStart(true);
       } 
       
    function T_1_Lass_40(){
        setT_1_Las_40(true); 
        setStart(true);
       }  
       
    function T_2_Lass_40(){
        setT_2_Las_40(true); 
        setStart(true);
       } 
       
    function T_3_Lass_40(){
        setT_3_Las_40(true); 
        setStart(true);
       }  
       
    function T_4_Lass_40(){
        setT_4_Las_40(true); 
        setStart(true);
       }
       
    function T_5_Lass_40(){
        setT_5_Las_40(true); 
        setStart(true);
       }  
       
    function T_6_Lass_70(){
        setT_6_Las_70(true); 
        setStart(true);
       } 
    
    function T_7_Los_Pinoss(){
        setT_7_Los_Pinos(true); 
        setStart(true);
       } 

    function T_8_Los_Pinoss(){
        setT_8_Los_Pinos(true); 
        setStart(true);
       } 
       
    function T_9_Los_Pinoss(){
        setT_9_Los_Pinos(true); 
        setStart(true);
       }  
     
    function T_10_Los_Pinoss(){
        setT_10_Los_Pinos(true); 
        setStart(true);
       } 

    function T_11_Los_Pinoss(){
        setT_11_Los_Pinos(true); 
        setStart(true);
       } 
       
    function T_12_Los_Pinoss(){
        setT_12_Los_Pinos(true); 
        setStart(true);
       }     

    function T_1_Del_Edenn(){
        setT_1_Del_Eden(true); 
        setStart(true);
       } 

    function T_2_Del_Edenn(){
        setT_2_Del_Eden(true); 
        setStart(true);
       } 
       
    function T_3_Del_Edenn(){
        setT_3_Del_Eden(true); 
        setStart(true);
       } 
    function T_4_Del_Edenn(){
        setT_4_Del_Eden(true); 
        setStart(true);
       }  
       
    function T_5_Del_Edenn(){
        setT_5_Del_Eden(true); 
        setStart(true);
       } 
    function T_6_Del_Edenn(){
        setT_6_Del_Eden(true); 
        setStart(true);
       } 
       
    function T_7_Del_Edenn(){
        setT_7_Del_Eden(true); 
        setStart(true);
       } 
    function T_8_Del_Edenn(){
        setT_8_Del_Eden(true); 
        setStart(true);
       }  
       
       function Costoss(){
        setCostos(true); 
        setStart(true);
       }    
       
       function Gastos_indi_produccionn(){
        setGastos_indi_produccion(true); 
        setStart(true);
       }   
       
    return(
        <div id="Trabajo">  
                
                    {Start? null : 
                    <Navbartrabajo/>
                             }
                  

                
                    {Start? null : 
                             <Lista_cultivo_en_proceso

                                Casa_sombra_1={()=>Casa_sombraa_1()} 
                                Casa_sombra_2={()=>Casa_sombraa_2()} 
                                Casa_sombra_3={()=>Casa_sombraa_3()} 
                                Casa_sombra_4={()=>Casa_sombraa_4()} 
                                Casa_sombra_5={()=>Casa_sombraa_5()} 
                                Casa_sombra_6={()=>Casa_sombraa_6()} 
                                Casa_sombra_7={()=>Casa_sombraa_7()} 
                                Casa_sombra_8={()=>Casa_sombraa_8()} 
                                T_9_Fausta={()=>T_9_Faustaa()} 
                                T_10_Fausta={()=>T_10_Faustaa()} 
                                T_11_Fausta={()=>T_11_Faustaa()} 
                                T_12_Fausta={()=>T_12_Faustaa()} 
                                T_13_Fausta={()=>T_13_Faustaa()} 
                                T_14_Fausta={()=>T_14_Faustaa()} 
                                T_15_Fausta={()=>T_15_Faustaa()} 
                                T_16_Woller={()=>T_16_Wollerr()} 
                                T_17_Woller={()=>T_17_Wollerr()} 
                                T_18_Woller={()=>T_18_Wollerr()} 
                                T_19_Woller={()=>T_19_Wollerr()} 
                                T_20_Woller={()=>T_20_Wollerr()} 
                                T_21_Woller={()=>T_21_Wollerr()} 
                                T_22_Woller={()=>T_22_Wollerr()} 
                                T_1_Las_40={()=>T_1_Lass_40()} 
                                T_2_Las_40={()=>T_2_Lass_40()} 
                                T_3_Las_40={()=>T_3_Lass_40()} 
                                T_4_Las_40={()=>T_4_Lass_40()} 
                                T_5_Las_40={()=>T_5_Lass_40()} 
                                T_6_Las_70={()=>T_6_Lass_70()} 
                                T_7_Los_Pinos={()=>T_7_Los_Pinoss()} 
                                T_8_Los_Pinos={()=>T_8_Los_Pinoss()} 
                                T_9_Los_Pinos={()=>T_9_Los_Pinoss()} 
                                T_10_Los_Pinos={()=>T_10_Los_Pinoss()} 
                                T_11_Los_Pinos={()=>T_11_Los_Pinoss()} 
                                T_12_Los_Pinos={()=>T_12_Los_Pinoss()} 
                                T_1_Del_Eden={()=>T_1_Del_Edenn()} 
                                T_2_Del_Eden={()=>T_2_Del_Edenn()} 
                                T_3_Del_Eden={()=>T_3_Del_Edenn()} 
                                T_4_Del_Eden={()=>T_4_Del_Edenn()} 
                                T_5_Del_Eden={()=>T_5_Del_Edenn()} 
                                T_6_Del_Eden={()=>T_6_Del_Edenn()} 
                                T_7_Del_Eden={()=>T_7_Del_Edenn()} 
                                T_8_Del_Eden={()=>T_8_Del_Edenn()} 

                                Costos={()=>Costoss()} 
                                Gastos_indi_produccion={()=>Gastos_indi_produccionn()} 
                                
                            />}
                

                
                    {Casa_sombra_1 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 1" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_2 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 2" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_3 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 3" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_4 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 4" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_5 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 5" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_6 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 6" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_7 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 7" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {Casa_sombra_8 ? <CRUD_Cultivo_en_proceso pagina="Casa sombra 8" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_9_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-09" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_10_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-10" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_11_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-11" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_12_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-12" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_13_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-13" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_14_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-14" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_15_Fausta ? <CRUD_Cultivo_en_proceso pagina="Fausta T-15" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_16_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-16" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_17_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-17" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_18_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-18" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_19_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-19" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_20_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-20" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
                
                    {T_21_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-21" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_22_Woller ? <CRUD_Cultivo_en_proceso pagina="Woller T-22" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_1_Las_40 ? <CRUD_Cultivo_en_proceso pagina="Las 40 T-01" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_2_Las_40 ? <CRUD_Cultivo_en_proceso pagina="Las 40 T-02" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_3_Las_40 ? <CRUD_Cultivo_en_proceso pagina="Las 40 T-03" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_4_Las_40 ? <CRUD_Cultivo_en_proceso pagina="Las 40 T-04" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_5_Las_40 ? <CRUD_Cultivo_en_proceso pagina="Las 40 T-05" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_6_Las_70 ? <CRUD_Cultivo_en_proceso pagina="Las 70 T-06" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_7_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-07" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_8_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-08" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_9_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-09" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_10_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-10" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_11_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-11" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_12_Los_Pinos ? <CRUD_Cultivo_en_proceso pagina="Los Pinos T-12" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_1_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del_Eden T-01" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_2_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-02" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_3_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-03" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_4_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-04" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_5_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-05" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_6_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-06" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_7_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-07" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                

                
                    {T_8_Del_Eden ? <CRUD_Cultivo_en_proceso pagina="Del Eden T-08" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
                

                    {Costos ? <CRUD_Cultivo_en_proceso pagina="Costos" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                    {Gastos_indi_produccion ? <CRUD_Cultivo_en_proceso pagina="Gastos indirectos de produccion" start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
                    

            </div>
    )
}

export default Cultivo_en_proceso; */