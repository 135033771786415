// Importamos la función para inicializar la aplicación de Firebase
import { initializeApp } from "firebase/app";

// Añade aquí tus credenciales
const firebaseConfig = {
  apiKey: "AIzaSyA7ufP1pLe5q-2Plpe_kbMdRwgMVzAlAfk",
  authDomain: "horticola-react.firebaseapp.com",
  projectId: "horticola-react",
  storageBucket: "horticola-react.appspot.com",
  messagingSenderId: "452792060969",
  appId: "1:452792060969:web:01ca656c0c2a6b5012e038"
};

// Inicializamos la aplicación y la guardamos en firebaseApp
const firebaseApp = initializeApp(firebaseConfig);
// Exportamos firebaseApp para poder utilizarla en cualquier lugar de la aplicación
export default firebaseApp;
