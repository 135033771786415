import firebaseApp from "../firebase/credenciales";
import React from "react";
import { useDataContext } from "../../Contexts/DataContext";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getProductos_ordenPDF(valor) {
    

    //console.log(valor);
    const productos2 =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Ordenes_productos/"+valor+"/Productos".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      productos2.push(doc.data());
    });
    return productos2;
    

}


//"+productofolio+"
