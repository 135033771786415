import firebaseApp from "../firebase/credenciales";
import React from "react";
import { useDataContext } from "../../Contexts/DataContext";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getLogs_productos_orden(productofolio) {
    

  //console.log(valor);
    const Logs =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Ordenes_productos/"+productofolio+"/Logs".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Logs.push(doc.data());
    });
    return Logs;
    

}


//"+productofolio+"
