import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editarProducto from "../functions/editarProducto";
import getBotones_gastos from "../functions/Ordenes_compra/getBotones_gastos";

function ModalEditar_ordenes_principal({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,  
  usuario,
}) {

 //Traer todos los botones de gastos
 const [Gastos_Botones, setGastos_Botones] = React.useState([]);
 async function botones_gastos_traer(){
   await getBotones_gastos().then((Botones_GastosDB) => {
    setGastos_Botones(Botones_GastosDB);
     //console.log(proveedoresDB)
   });
 }


  function editarProductoModal() {
    //obtener infor del formulario
    const fecha = document.getElementById("fecha").value;
    const cargo = document.getElementById("cargo").value;
    const moneda = document.getElementById("moneda").value;
    const solicitante = document.getElementById("solicitante").value;
    const factura = document.getElementById("factura").value;
    const total_orden = document.getElementById("total_orden").value;

    
    /* const tipo_compra = document.getElementById("tipo_compra").value; */
    const nombre = productoEstado.nombre;
    // enviar informacion a firebase
    const infoProducto = { fecha, cargo, nombre , moneda, solicitante, factura, total_orden};
    //const infoProducto = { fecha, almacen, proveedor, moneda, subtotal, total, iva, sku };
    editarProducto(infoProducto, usuario.email);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });


  React.useEffect(() => {
    botones_gastos_traer();
  }, []);

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="fecha"
              placeholder="Agregar fecha"
              type="text"
              className="mb-1"
              value={productoEstado?.fecha}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  fecha: e.target.value,
                })
              }
            />
            <br/>
            <select id="cargo" type="text"
            value={productoEstado?.cargo}
            onChange={(e) =>
              setProductoEstado({
                ...productoEstado,
                cargo: e.target.value,
              })
            }>
              <option>{productoEstado.cargo}</option>
              <option>Almacen campo</option>
              <option>Almacen empaque</option>
              {Gastos_Botones.map((datos,index)=>( 
                <option key={index}>{datos.valor}</option>
               ))}
            </select>
            <br/>
            <select id="moneda" type="text"
            value={productoEstado?.moneda}
            onChange={(e) =>
              setProductoEstado({
                ...productoEstado,
                moneda: e.target.value,
              })
            }>
              <option>{productoEstado.moneda}</option>
              <option>Pesos</option>
              <option>Dolares</option>
            </select>
            <br/>
            <Form.Control
              id="solicitante"
              placeholder="Agregar solicitante"
              type="text"
              className="mb-1"
              value={productoEstado?.solicitante}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  solicitante: e.target.value,
                })
              }
            />
            <br/>
            {/* <select id="tipo_compra" type="text"
            value={productoEstado?.tipo_compra}
            onChange={(e) =>
              setProductoEstado({
                ...productoEstado,
                tipo_compra: e.target.value,
              })
            }>
             <option>{productoEstado.tipo_compra}</option>
             <option>Contado</option>
             <option>Credito</option>
            </select>
            <br/> */}

           <Form.Control
              id="total_orden"
              placeholder="Modificar el total de la orden"
              type="text"
              className="mb-1"
              value={productoEstado?.total_orden}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  total_orden: e.target.value,
                })
              }
            />
             <br/>
            <Form.Control
              id="factura"
              placeholder="Agregar factura"
              type="text"
              className="mb-1"
              value={productoEstado?.factura}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  factura: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        
      </Modal.Body>

      <Modal.Footer>       
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 

      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_ordenes_principal;

