import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllpagos2(productofolio) {
    const PagosDB2 =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Proveedores_pagos/"+productofolio+"/Productos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PagosDB2.push(doc.data());
    });
    return PagosDB2;
    
}
