import React, {useState} from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import eliminarProductoHome from "../functions/eliminarProductoHome";
import filtrarDatos from "../functions/filtrarDatos";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import añadir_total_proveedores from "../functions/Proveedores/añadir_total_proveedores";
import añadir_total_proveedores_dolares from "../functions/Proveedores/añadir_total_proveedores_dolares";
import getAllpagos_proveedores from "../functions/Proveedores/getAllpagos_proveedores";
import Swal from "sweetalert2";
//modales
import ModalAñadir_proveedores from "../components/ModalAñadir_proveedores";
import ModalEditar_proveedores from "../components/ModalEditar_proveedores";
import ModalCartera_proveedores from "../components/Proveedores/ModalCartera_proveedores";
import ModalCartera_proveedores_Dolares from "../components/Proveedores/ModalCartera_proveedores_Dolares";
import getAllordenes_compra from "../functions/Proveedores/getAllordenes_compra";
import editarSaldo_proveedores from "../functions/Ordenes_compra/editarSaldo_proveedores";
import ModalEliminar from "../components/Proveedores/ModalEliminar";


function Home_proveedores({ usuario }) {

 //Convertir a formato moneda
 const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

  //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, usuario) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el proveedor?  "'+ producto.nombre+'"',
      text: "Esta acción no se puede restablecer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El proveedor ha sido eliminado.',
          'success'
        )
       eliminarProductoHome(producto, usuario.email).then(
        (confirmacion) => {
          actualizarEstadoProductos();
        }
      );
      }
    })
  }

  //Cartera proveedores
  const [productoCartera, setproductoCartera] = React.useState(false);
  const [productoCartera_Dolares, setproductoCartera_Dolares] = React.useState(false);



  //console.log("HOME")
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);
  const [Modal_Eliminar_proveedor, setModal_Eliminar_proveedor] = React.useState(false);

  const [Total_MXN_final, setTotal_MXN_final] = React.useState("");
  const [Total_Dolares_final, setTotal_Dolares_final] = React.useState("");
  const [Proveedores_todos, setProveedores_todos] = React.useState([]);
  //const [Proveedores_todosON, setProveedores_todosON] = React.useState(false);

  async function actualizarEstadoProductos() {
    await getAllProducts().then((productos) => {
      //Con esto sumo el total de dolares y pesos de los proveedores
      console.log("HOLA")
      var total_MXN = 0;
      var total_DOLARES = 0;
      productos.map((datos)=>{
        if(datos.Saldo_Dolares==undefined){
          datos.Saldo_MXN = 0;
        }
        const n_str = datos.Saldo_MXN.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const n_nuevaStr = n_str.replace("$", "");
        const n_nuevaStr2 = n_nuevaStr.replace(",", "");
        const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
        var n_nuevaStr4 = n_nuevaStr3.replace(",", "");

        const AuxiliarMXN_n_str = total_MXN.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const AuxiliarMXN_n_nuevaStr = AuxiliarMXN_n_str.replace("$", "");
        const AuxiliarMXN_n_nuevaStr2 = AuxiliarMXN_n_nuevaStr.replace(",", "");
        const AuxiliarMXN_n_nuevaStr3 = AuxiliarMXN_n_nuevaStr2.replace(",", "");
        var AuxiliarMXN_n_nuevaStr4 = AuxiliarMXN_n_nuevaStr3.replace(",", "");

        //console.log(AuxiliarMXN_n_nuevaStr4, n_nuevaStr4);
        total_MXN = formatter.format(+AuxiliarMXN_n_nuevaStr4 + +n_nuevaStr4)
        //console.log(total_MXN);
        //DOLARES
        if(datos.Saldo_Dolares==undefined){
          datos.Saldo_Dolares = 0;
        }
        const Dolares_n_str = datos.Saldo_Dolares.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const Dolares_n_nuevaStr = Dolares_n_str.replace("$", "");
        const Dolares_n_nuevaStr2 = Dolares_n_nuevaStr.replace(",", "");
        const Dolares_n_nuevaStr3 = Dolares_n_nuevaStr2.replace(",", "");
        var Dolares_n_nuevaStr4 = Dolares_n_nuevaStr3.replace(",", "");

        const AuxiliarDolares_n_str = total_DOLARES.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const AuxiliarDolares_n_nuevaStr = AuxiliarDolares_n_str.replace("$", "");
        const AuxiliarDolares_n_nuevaStr2 = AuxiliarDolares_n_nuevaStr.replace(",", "");
        const AuxiliarDolares_n_nuevaStr3 = AuxiliarDolares_n_nuevaStr2.replace(",", "");
        var AuxiliarDolares_n_nuevaStr4 = AuxiliarDolares_n_nuevaStr3.replace(",", "");

        //console.log(AuxiliarDolares_n_nuevaStr4, Dolares_n_nuevaStr4);
        total_DOLARES = formatter.format(+AuxiliarDolares_n_nuevaStr4 + +Dolares_n_nuevaStr4)
      })
      setTotal_MXN_final(total_MXN);
      setTotal_Dolares_final(total_DOLARES);
      //actualizarEstadoProductos5(productos)
      //console.log(total_MXN,total_DOLARES )
      setProveedores_todos(productos);
      //console.log(productos)
      //setProductos(productos)
      filtrar_proveedores(productos);
    });
    
  }

async function filtrar_proveedores(productos){
var productos_filtrados = [];
  productos.map((valor)=>{
    if((valor.Saldo_MXN == "$0") && (valor.Saldo_Dolares == "$0")){
    }else{
      productos_filtrados.push(valor)
    }

  })
  //console.log(productos_filtrados)
  setProductos(productos_filtrados);
/*   await getAllpagos_proveedores().then((PagosDB) => {
       console.log(PagosDB)
      //Esto me sirve para quitar los proveedores que no tengan nada en saldo MXN ni dolares
      var productos_filtrados = [];
      productos.map((valor)=>{
        const n_str = valor.Saldo_Dolares.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const n_nuevaStr = n_str.replace("$", "");
        const n_nuevaStr2 = n_nuevaStr.replace(",", "");
        const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
        var n_nuevaStr4 = n_nuevaStr3.replace(",", "");

        const ln_str = valor.Saldo_MXN.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const ln_nuevaStr = ln_str.replace("$", "");
        const ln_nuevaStr2 = ln_nuevaStr.replace(",", "");
        const ln_nuevaStr3 = ln_nuevaStr2.replace(",", "");
        var ln_nuevaStr4 = ln_nuevaStr3.replace(",", "");
        if((n_nuevaStr4 == 0) && (ln_nuevaStr4 == 0)){
          console.log("HOLA")
        }else{productos_filtrados.push(valor)}
      })//Fin primer map
    console.log(productos_filtrados);
    setProductos(productos_filtrados);
  }) */
}



  //------------------------------------------------------------------------------------
  //Esto se creo para cargar el valor de total de la orden a todos los proveedores solo una vez
  async function actualizarEstadoProductos5(productos) {
    await getAllordenes_compra().then((OrdenesDB) => {

    productos.map((proveedores)=>{
        var suma = 0;
        OrdenesDB.map((ordenes)=>{
            if(proveedores.nombre==ordenes.proveedor){
              if(ordenes.moneda=="Dolares"){
                if(ordenes.tipo_compra=="Credito"){
                  const n_str = ordenes.total_orden.toString();
                  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                  const n_nuevaStr = n_str.replace("$", "");
                  const n_nuevaStr2 = n_nuevaStr.replace(",", "");
                  const n_nuevaStr3 = n_nuevaStr2.replace(",", "");
                  var n_nuevaStr4 = n_nuevaStr3.replace(",", "");

                    suma = parseFloat(n_nuevaStr4) + parseFloat(suma);
                }
              }
            }
          })


        console.log(suma)
        var Total_a_editar= {
          Saldo_Dolares: formatter.format(suma),
          };

        var proveedoresDB = proveedores;
        console.log(proveedores);
        editarSaldo_proveedores(proveedoresDB, Total_a_editar)

      })

    });
  }
  //------------------------------------------------------------------------------------

  /* const [total_proveedores_MXN, settotal_proveedores_MXN] = React.useState(0);
  const [total_proveedores_Dolares, settotal_proveedores_Dolares] = React.useState(0);

  async function Ordenes_de_compra_actuales(productos){
    await getAllordenes_compra().then((OrdenesDB) => {
      //Este es para sumar el total de cada proveedor  
      var total_ordenes_todos_proveedores_MXN = 0;
      var total_ordenes_todos_proveedores_Dolares = 0;
      //Este es para filtrar de todas las ordenes que sean de credito
      var Ordenes_filtradas_por_credito_MXN= [];
      var Ordenes_filtradas_por_credito_Dolares= [];
      OrdenesDB.map((dato)=>{
              if(dato.tipo_compra=="Credito"){
                //Esto es para sumar todo los proveedores
                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                //console.log(dato.total_orden)
                const str = (dato.total_orden);
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                const nuevaStr4 = nuevaStr3.replace(",", "");
                if(dato.moneda=="Pesos"){
                  Ordenes_filtradas_por_credito_MXN.push(dato)
                  total_ordenes_todos_proveedores_MXN = parseFloat(total_ordenes_todos_proveedores_MXN) + parseFloat(nuevaStr4);
                }

                if(dato.moneda=="Dolares"){
                  Ordenes_filtradas_por_credito_Dolares.push(dato)
                  total_ordenes_todos_proveedores_Dolares = parseFloat(total_ordenes_todos_proveedores_Dolares) + parseFloat(nuevaStr4);
                }
                
            }
      })
      settotal_proveedores_MXN(total_ordenes_todos_proveedores_MXN);
      settotal_proveedores_Dolares(total_ordenes_todos_proveedores_Dolares);

  //Aqui se saca la suma del total en pesos de cada proveedor 
        productos.map((proveedor)=>{
                var total_deuda_proveedor_MXN = 0;
                     Ordenes_filtradas_por_credito_MXN.map((valor_orden)=>{
                              if(proveedor.nombre==valor_orden.proveedor){
                                //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                                //console.log(valor_orden.total_orden)
                                const str = (valor_orden.total_orden);
                                const nuevaStr = str.replace("$", "");
                                const nuevaStr2 = nuevaStr.replace(",", "");
                                const nuevaStr3 = nuevaStr2.replace(",", "");
                                const nuevaStr4 = nuevaStr3.replace(",", "");
                                //console.log(parseFloat(total_deuda_proveedor_MXN),  parseFloat(nuevaStr4));
                                total_deuda_proveedor_MXN = parseFloat(total_deuda_proveedor_MXN) + parseFloat(nuevaStr4);
                              }
                        }) //Fin segundo map
                  proveedor['total_saldo_MXN'] = formatter.format(total_deuda_proveedor_MXN);
        }) //Fin primer map

  //Aqui se saca la suma del total en Dolares de cada proveedor 
        productos.map((proveedor)=>{
          var total_deuda_proveedor_Dolares = 0;
                 Ordenes_filtradas_por_credito_Dolares.map((valor_orden)=>{
                        if(proveedor.nombre==valor_orden.proveedor){
                          //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                          //console.log(valor_orden.total_orden)
                          const str = (valor_orden.total_orden);
                          const nuevaStr = str.replace("$", "");
                          const nuevaStr2 = nuevaStr.replace(",", "");
                          const nuevaStr3 = nuevaStr2.replace(",", "");
                          const nuevaStr4 = nuevaStr3.replace(",", "");
                          //console.log(parseFloat(total_deuda_proveedor_Dolares),  parseFloat(nuevaStr4));
                          total_deuda_proveedor_Dolares = parseFloat(total_deuda_proveedor_Dolares) + parseFloat(nuevaStr4);
                        }
                  }) //Fin segundo map
            proveedor['total_saldo_Dolares'] = formatter.format(total_deuda_proveedor_Dolares);
  }) //Fin primer map
  console.log("HOLA")
      console.log(productos);
      setProductos(productos);
      });
  }
 */

  function añadirProductoHome() {
    setIsModalAñadir(true);
  }


//Enviar costos a la pagina de gastos
function Guardar_total_proveedores_MXN(Total_MXN_final){
        const AuxiliarDolares_n_str = Total_MXN_final.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const AuxiliarDolares_n_nuevaStr = AuxiliarDolares_n_str.replace("$", "");
        const AuxiliarDolares_n_nuevaStr2 = AuxiliarDolares_n_nuevaStr.replace(",", "");
        const AuxiliarDolares_n_nuevaStr3 = AuxiliarDolares_n_nuevaStr2.replace(",", "");
        var AuxiliarDolares_n_nuevaStr4 = AuxiliarDolares_n_nuevaStr3.replace(",", "");
        console.log(Total_MXN_final,AuxiliarDolares_n_nuevaStr4 )
  const dato = (AuxiliarDolares_n_nuevaStr4);
    var costo= {
      valor: dato,
      nombre: "Total credito proveedores en pesos",
      };
 añadir_total_proveedores(costo);
 Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Se agrego correctamente a concentrado.',
  showConfirmButton: false,
  timer: 1500
})
}

//Enviar costos a la pagina de gastos
function Guardar_total_proveedores_dolares(Total_Dolares_final){
  const AuxiliarDolares_n_str = Total_Dolares_final.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const AuxiliarDolares_n_nuevaStr = AuxiliarDolares_n_str.replace("$", "");
  const AuxiliarDolares_n_nuevaStr2 = AuxiliarDolares_n_nuevaStr.replace(",", "");
  const AuxiliarDolares_n_nuevaStr3 = AuxiliarDolares_n_nuevaStr2.replace(",", "");
  var AuxiliarDolares_n_nuevaStr4 = AuxiliarDolares_n_nuevaStr3.replace(",", "");

  const dato = (AuxiliarDolares_n_nuevaStr4);
    var costo= {
      valor: dato,
      nombre: "Total credito proveedores en dolares",
      };
      añadir_total_proveedores_dolares(costo);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se agrego correctamente a concentrado.',
        showConfirmButton: false,
        timer: 1500
      })
}

  React.useEffect(() => {
    actualizarEstadoProductos();
  }, []);

  return (
  <div>
    
    <Navbartrabajo/>


    <Container fluid id="Navbar_trabajo_home">
    {isModalAñadir && (
      <ModalAñadir_proveedores
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
      />
    )}
      {productoEditar && (
        <ModalEditar_proveedores
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          setProductos={setProductos}
        />
      )}
      {productoCartera && (
        <ModalCartera_proveedores
          productoCartera={productoCartera}
          setproductoCartera={setproductoCartera}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          setProductos={setProductos}
        />
      )}
      {productoCartera_Dolares && (
        <ModalCartera_proveedores_Dolares
          productoCartera_Dolares={productoCartera_Dolares}
          setproductoCartera_Dolares={setproductoCartera_Dolares}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          setProductos={setProductos}
        />
      )}
      {Modal_Eliminar_proveedor && (
        <ModalEliminar
          Modal_Eliminar_proveedor={Modal_Eliminar_proveedor}
          setModal_Eliminar_proveedor={setModal_Eliminar_proveedor}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          Proveedores_todos={Proveedores_todos}
        />
      )}

      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Actualmente estas en {window.location.pathname} </p>
        <Button onClick={() => {setProductos(Proveedores_todos);}}> Todos los proveedores</Button>
        <Button onClick={añadirProductoHome}> Añadir proveedor</Button>
        <Button onClick={() => {setModal_Eliminar_proveedor(true);}}> Eliminar proveedor</Button>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
      <hr />
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 16, marginLeft: "20%"  }}>Total crédito {Total_MXN_final} MXN</p>
        <Button style={{ marginRight: "10%",fontSize: 14,marginTop: "0%" }} onClick={() => {Guardar_total_proveedores_MXN(Total_MXN_final);}}>Guardar MXN</Button>   
       
        <p style={{ fontSize: 16, marginLeft: "0%"  }}>Total crédito {Total_Dolares_final} Dolares</p>
        <Button style={{ marginRight: "10%",fontSize: 14,marginTop: "0%" }} onClick={() => {Guardar_total_proveedores_dolares(Total_Dolares_final);}}>Guardar dolares</Button>     
      </Stack>

      <hr />
      <Table>
        <thead>
          <tr>
          <th>#</th>
            <th>Nombre</th>
            <th>Teléfono o celular</th>
            <th>Email</th>
            <th>Total crédito MXN</th>
            <th>Total crédito Dolares</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                <td>{producto.telefono}</td>
                <td>{producto.direccion}</td>
                <td>{producto.Saldo_MXN}</td>
                <td>{producto.Saldo_Dolares}</td>
                <td>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setIsModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  {/* <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, usuario);
                    }}
                  >
                    Eliminar
                  </Button> */}
                  <Button
                    variant="success"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setproductoCartera(true);
                    }}
                  >
                    Cartera pesos
                  </Button>
                  <Button
                    variant="warning"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setproductoCartera_Dolares(true);
                    }}
                  >
                    Cartera Dolares
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button onClick={añadirProductoHome}> Añadir proveedor</Button>
      <br/>
      <br/>
    </Container>
    </div> 
  );
}

export default Home_proveedores;
