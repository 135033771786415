import React from "react";
import { Container, Stack, Table, Button} from "react-bootstrap";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import getCostos from "../functions/Gastos/getCostos";
import Reiniciar_valores_gastos from "../functions/Gastos/Reiniciar_valores_gastos";
import getbotones_gastos from "../functions/Gastos/getbotones_gastos";
//Tablas
import getProductos_tablas from "../functions/Gastos/getProductos_tablas";
import ModalAñadir_tablas from "../components/Gastos/ModalAñadir_tablas";
import ModalEliminar_tablas from "../components/Gastos/ModalEliminar_tablas";
import añadir_total_gastos from "../functions/Gastos/añadir_total_gastos";
import eliminarProductoGastos from "../functions/Gastos/eliminarProductoGastos";
//Modales
import ModalAñadir from "../components/Gastos/ModalAñadir";
import ModalEliminar from "../components/Gastos/ModalEliminar";
import ModalTotal_concepto from "../components/Gastos/ModalTotal_concepto";


function Home_Gastos({ usuario }) {
  
//Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Aqui esta original sin quitar el signo $
/* const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
}) */


//Botones----------------------------------------------------------
const [ModalAñadir_boton, setModalAñadir_boton] = React.useState(false);
const [Modal_eliminar, setModal_eliminar] = React.useState(false);
const [Botonesdata, setBotonesdata] = React.useState([]);

async function botones(){
  await getbotones_gastos().then((BotonesDB) => {
      //console.log(BotonesDB) 
      setBotonesdata(BotonesDB);
 });
}

function agregar_botones(){
  setModalAñadir_boton(true);
}

async function eliminar(){
 setModal_eliminar(true);
}

//Tablas de los botones
const [Tabla_botones_On, setTabla_botones_On] = React.useState(false);
const [Modal_añadir_producto, setModal_añadir_producto] = React.useState(false);
const [Modal_eliminar_producto, setModal_eliminar_producto] = React.useState(false);
const [Tablasdata, setTablasdata] = React.useState([]);
const [Nombre_de_boton, setNombre_de_boton] = React.useState([]);
const [Nombre_de_boton_personal, setNombre_de_boton_personal] = React.useState(false);
const [Concepto_totales, setConcepto_totales] = React.useState(false);
const [Cantidad_de_productos, setCantidad_de_productos] = React.useState(0);
const [Total, setTotal] = React.useState(0);

async function actualizar_productos_tablas(datos){
  var Nombre_de_boton = datos;
  await getProductos_tablas(Nombre_de_boton).then((TablasDB) => {
      //console.log(TablasDB) 
      setTablasdata(TablasDB);
      //Este setTotal es para que al entrar a la tabla siempre inicie en 0
      setTotal(0);
 });
}

function Seleccionar_gastos(datos){
  actualizar_productos_tablas(datos);
  setNombre_de_boton(datos);
  setTabla_botones_On(true);
  if(datos.valor=="Personal"){
    setNombre_de_boton_personal(true);
  }else{setNombre_de_boton_personal(false)}
}

function agregar_producto(){
  setModal_añadir_producto(true);
}

function actualizar_datos_gastos(Nombre_de_boton){
  var total_acumulado=0;
  Tablasdata.map((dato)=>{
    const str = total_acumulado.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const nuevaStr = str.replace("$", "");
    const nuevaStr2 = nuevaStr.replace(",", "");
    const nuevaStr3 = nuevaStr2.replace(",", "");
    var nuevaStr4 = nuevaStr3.replace(",", "");
    //console.log(nuevaStr4, dato.total_con_iva)
    total_acumulado = formatter.format(+nuevaStr4 + +dato.total_con_iva);
    //total_acumulado = total_acumulado + dato.total_con_iva;
  })
  setTotal(total_acumulado);
  Guardar_total_gastos(total_acumulado,Nombre_de_boton)
}

function Guardar_total_gastos(total_acumulado,Nombre_de_boton){
  const str = total_acumulado.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  var nuevaStr4 = nuevaStr3.replace(",", "");

  const dato = nuevaStr4;
  console.log(dato);
    var costo= {
      valor: dato,
      nombre: Nombre_de_boton.valor,
      };
  añadir_total_gastos(total_acumulado,costo,Nombre_de_boton);
  actualizarEstadoProductos();
}

//total por concepto
function totales_por_concepto(){
  setConcepto_totales(true);
}
//-----------------------------------------------------------------
//Resultado
const [Resultado_total, setResultado_total] = React.useState(0);

function resultado(Productos){
  console.log(Productos);
var productos_a_sumar = 0;  
  Productos.map((dato)=>{
    var string = dato.nombre;
    var palabra_busqueda = "Gasto";
    var palabra_busqueda2 = "Costo";
    var busqueda = string.indexOf(palabra_busqueda);
    var busqueda2 = string.indexOf(palabra_busqueda2);

    if((busqueda >= 0) || (busqueda2 >= 0)){
      console.log("Se encontro la palabra en posición" + busqueda)
      const str = productos_a_sumar.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      console.log(nuevaStr4, dato.valor)
      productos_a_sumar = formatter.format(+nuevaStr4 + +dato.valor);
    }
  })
  console.log(productos_a_sumar)


  var productos_a_sumar_venta = 0;  
  Productos.map((dato)=>{
    var string = dato.nombre;
    var palabra_busqueda = "Venta";
    var busqueda = string.indexOf(palabra_busqueda);

    if(busqueda >= 0){
      console.log("Se encontro la palabra en posición" + busqueda)
      const str = productos_a_sumar_venta.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
      console.log(nuevaStr4, dato.valor)
      productos_a_sumar_venta = formatter.format(+nuevaStr4 + +dato.valor);
    }
  })
  console.log(productos_a_sumar_venta)

      const venta_str = productos_a_sumar_venta.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const venta_nuevaStr = venta_str.replace("$", "");
      const venta_nuevaStr2 = venta_nuevaStr.replace(",", "");
      const venta_nuevaStr3 = venta_nuevaStr2.replace(",", "");
      var venta_nuevaStr4 = venta_nuevaStr3.replace(",", "");
      
      const costoygastos_str = productos_a_sumar.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
      const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
      const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
      var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");
      

  var total = formatter.format(venta_nuevaStr4 - costoygastos_nuevaStr4);
  console.log(total)
  setResultado_total(total);
}


//-----------------------------------------------------------------


//Busqueda producto por nombre
const [Productos, setProductos] = React.useState([]);

async function actualizarEstadoProductos() {
    await getCostos().then((costosygastos) => {
      setProductos(costosygastos);
      console.log(costosygastos)
    });
  }

 function Reiniciar_valores(){
    Productos.map((valor)=>{
      Reiniciar_valores_gastos(valor);
    })
    actualizarEstadoProductos();
  }


//--------Buscar producto------------------------------------------------------------
//Busqueda producto por nombre del producto seleccionado
const [BusquedaON, setBusquedaON] = React.useState(false);
const [Busqueda_datos, setBusqueda_datos] = React.useState([]);
const [Producto_filtrados, setProducto_filtrados] = React.useState([]);
const [Total_Producto_filtrados, setTotal_Producto_filtrados] = React.useState([]);

function filtrar_productos(){
//El primer if evita que se repita el filtrado al elegir un producto
if(Busqueda_datos.length==0){
      var auxiliar_100 = []; 
      Tablasdata.map((valor)=>{
        var auxiliar_102 = valor.producto;
        var auxiliar_103= false;

                  if(auxiliar_100.length==0){
                    var auxiliar_101 = valor.producto;
                    console.log(valor.producto);
                    auxiliar_100.push(auxiliar_101);
                  }

                  if(auxiliar_100.length>0){
                        auxiliar_100.map((dato)=>{
                          if(valor.producto == dato){
                            auxiliar_103 = true;
                          }
                        })
                  }

            if(auxiliar_103==false){
              auxiliar_100.push(auxiliar_102);
            }

      })
      console.log(auxiliar_100)
      setBusqueda_datos(auxiliar_100); 
}
 
}

function aceptar_busqueda(){
  const nombre_afectacion_fiscal = document.getElementById("productos_lista").value;  
  var datos_filtrados = [];  
    Tablasdata.map((valor)=>{
      if(valor.producto == nombre_afectacion_fiscal){
        datos_filtrados.push(valor);
      }
    })

  var auxiliar_suma_total = 0;  
    datos_filtrados.map((dato)=>{
      const costoygastos_str = auxiliar_suma_total.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
      const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
      const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
      var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");
  
      auxiliar_suma_total = formatter.format(+costoygastos_nuevaStr4 + +dato.total_con_iva);
    })
  
  setTotal_Producto_filtrados(auxiliar_suma_total);
  //Filtrar por fecha
  //Logs.sort((a,b)=>  (b.fecha3) - (a.fecha3));
  setProducto_filtrados(datos_filtrados);
  setBusquedaON(true);
}

function reset_busqueda(){
  setBusquedaON(false);
}

function regresar_concentrado_principal(){
  setBusqueda_datos([]);
  setTotal_Producto_filtrados(0);
  setTabla_botones_On(false)
}

//Con este filtro por busqueda escrita
/* const [Busqueda, setBusqueda] = React.useState("");
const [BusquedaON, setBusquedaON] = React.useState(false);
const [Busqueda_datos, setBusqueda_datos] = React.useState([]);
const [Total_producto_filtrado, setTotal_producto_filtrado] = React.useState(0);


function handleChange(e){
  setBusqueda(e.target.value);
  filtrar(e.target.value);
}

const filtrar=(terminoBusqueda)=>{
  //console.log(terminoBusqueda.toLowerCase())
  var auxiliar = Tablasdata;
  var auxiliar_suma_total = 0;
  var resultadoBusqueda=auxiliar.filter((elemento)=>{

    if((elemento.producto.toLowerCase()).includes(terminoBusqueda.toLowerCase()))
    {
      return elemento;
    }
  })
  //console.log(resultadoBusqueda);
  resultadoBusqueda.map((dato)=>{
    const costoygastos_str = auxiliar_suma_total.toString();
    //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
    const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
    const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
    var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");

    auxiliar_suma_total = formatter.format(+costoygastos_nuevaStr4 + +dato.total_con_iva);
  })

  setTotal_producto_filtrado(auxiliar_suma_total)
  setBusqueda_datos(resultadoBusqueda);
  if(Tablasdata==""){
   setBusquedaON(false);
  }
   setBusquedaON(true);
} */


//------------------------------------------------------------------------------------



  React.useEffect(() => {
    actualizarEstadoProductos();
    botones();
  }, []); 

  return (
  <div>
    {Tabla_botones_On?
    <div>
      <Navbartrabajo/>
      <Container fluid id="Navbar_trabajo_home">
          <br/>
          <Stack direction="horizontal" className="justify-content-between">
            <p style={{ fontSize: 20 }}>Actualmente estas en /Concentrado "{Nombre_de_boton.valor}"</p>
            <Button style={{ marginRight: "0%" }} onClick={() => {regresar_concentrado_principal()}}>Regresar</Button>
            <Button id="" onClick={()=>{totales_por_concepto()}}> Total por concepto</Button>
            <Button id="" onClick={()=>{agregar_producto()}}> Agregar producto</Button>
          </Stack>
          <hr />
          <Stack direction="horizontal" className="justify-content-between">
              <p style={{ fontSize: 16, marginLeft: "20%"  }}> Gastos totales {(Total)} MXN</p>
              <Button style={{ marginRight: "30%" }} onClick={() => {actualizar_datos_gastos(Nombre_de_boton)}}>Guardar</Button>        
          </Stack>
          <hr />
          <Stack direction="horizontal" className="justify-content-between">
              <p style={{ fontSize: 16, marginLeft: "1%", color: "red"  }}> NOTA: Si el producto fue agregado desde una orden de compra no se podra eliminar desde aqui, solamente se puede eliminar desde la orden de compra donde fue agregado</p>       
          </Stack>
          <hr />

{/* Con este busco por selección */}
          <Stack direction="horizontal" className="justify-content-between">
           <>
            <select 
            id="productos_lista"
            style={{ width: "35%" , marginLeft: "10%" }}
            type="text"
            onClick={() => {
              filtrar_productos()
                    }} 
             >
              <option>Seleccionar</option>
            {Busqueda_datos.map((datos,index)=>( 
                <option key={index}>{datos}</option>
            ))}
            </select>
            <Button style={{ fontSize: 16, marginRight: "2%", marginLeft: "1%" }} onClick={()=>{aceptar_busqueda()}}> Buscar</Button>
            <Button variant= "secondary" style={{ fontSize: 16, marginRight: "1%", marginLeft: "1%" }} onClick={()=>{reset_busqueda()}}> Reset</Button>
            <p style={{ fontSize: 14, marginRight: "5%", marginLeft: "0%" }}> Total del producto {(Total_Producto_filtrados)} MXN</p>
            </> 
          </Stack>

          {/* Con esto busco por nombre escrito */}
{/*           <Stack direction="horizontal" className="justify-content-between">
              <input
              value={Busqueda}
              placeholder="Busqueda por nombre del producto"
              onChange={handleChange}
              style={{ width: "35%" , marginLeft: "10%" }}
              id="solicitante"
              />
              <p style={{ fontSize: 14, marginRight: "25%", marginLeft: "5%" }}> Total del producto {(Total_producto_filtrado)} MXN</p>
              </Stack> */}
          <hr />

              {Modal_añadir_producto && (
              <ModalAñadir_tablas
                Modal_añadir_producto={Modal_añadir_producto}
                setModal_añadir_producto={setModal_añadir_producto}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario2={usuario}
                Nombre_de_boton={Nombre_de_boton}
                setTablasdata={setTablasdata}
                className="modal-dialog modal-fullscreen-sm-down"
              />)}
               
            {Modal_eliminar_producto && (
            <ModalEliminar_tablas
                Modal_eliminar_producto={Modal_eliminar_producto}
                setModal_eliminar_producto={setModal_eliminar_producto}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario={usuario}
                /* Botones_campo={Botones_campo} */
                className="modal-dialog modal-fullscreen-sm-down"
             />)}

             {Concepto_totales && (
            <ModalTotal_concepto
                Concepto_totales={Concepto_totales}
                setConcepto_totales={setConcepto_totales}
                Tablasdata={Tablasdata}
                actualizar_productos_tablas={actualizar_productos_tablas}
                usuario2={usuario}
                Nombre_de_boton={Nombre_de_boton}
                setTablasdata={setTablasdata}
                className="modal-dialog modal-fullscreen-sm-down"
             />)}

          <Table style={{ fontSize: "90%" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Total MXN</th>
                <th>Usuario</th>
                {Nombre_de_boton_personal && (
                <th>Persona que lo gasto</th>
                )}
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
             {BusquedaON? 
             /* Con este muestra los productos filtrados */ 
             <>
              {Producto_filtrados &&
                Producto_filtrados.map((producto, index) => ( 
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{producto.fechaOrden}</td>
                    <td>{producto.producto}</td>
                    <td style={{ textAlign: "right" }}>{producto.cantidad}</td>
                    <td style={{ textAlign: "right" }}>{producto.precio}</td>
                    <td style={{ textAlign: "right" }}>{formatter.format(producto.total_con_iva)}</td>
                    <td>{producto.usuario}</td>
                    {Nombre_de_boton_personal && (
                    <td>{producto.persona}</td>
                    )}
                    <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                      {producto.agregado_desde_orden_compra? 
                      producto.dato_concentrado_desde_orden
                      :       
                            <Button
                            variant="danger"
                            onClick={() => {
                              eliminarProductoGastos(producto,Nombre_de_boton).then(
                                (confirmacion) => {
                                  actualizar_productos_tablas(Nombre_de_boton);
                                }
                              );
                            }}
                          >
                            Eliminar
                        </Button>
                      }
                      
                     </td>
                  </tr>  
              ))}
             </>
             :
          /* Con este muestra todos los profuctos */   
             <>
              {Tablasdata &&
                Tablasdata.map((producto, index) => ( 
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{producto.fechaOrden}</td>
                    <td>{producto.producto}</td>
                    <td style={{ textAlign: "right" }}>{producto.cantidad}</td>
                    <td style={{ textAlign: "right" }}>{producto.precio}</td>
                    <td style={{ textAlign: "right" }}>{formatter.format(producto.total_con_iva)}</td>
                    <td>{producto.usuario}</td>
                    {Nombre_de_boton_personal && (
                    <td>{producto.persona}</td>
                    )}
                    <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                      {producto.agregado_desde_orden_compra? 
                      producto.dato_concentrado_desde_orden
                      :       
                            <Button
                            variant="danger"
                            onClick={() => {
                              eliminarProductoGastos(producto,Nombre_de_boton).then(
                                (confirmacion) => {
                                  actualizar_productos_tablas(Nombre_de_boton);
                                }
                              );
                            }}
                          >
                            Eliminar
                        </Button>
                      }
                      
                     </td>
                  </tr>  
              ))}
             </>
             } 
              
            </tbody>
          </Table>
        </Container> 
    </div>  
    :
    <div>
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en /Concentrado </p>
        <Button style={{ marginRight: "20%" }} onClick={() => {Reiniciar_valores()}}>Reiniciar valores</Button>
        <Button id="" onClick={()=>{agregar_botones()}}> Agregar botón</Button>
        <Button id="" onClick={()=>{eliminar()}}> Eliminar botón</Button>            
      </Stack>
      <hr />
          <Stack direction="horizontal" className="justify-content-between">
             <p style={{ fontSize: 20 }}>El resultado es {Resultado_total}</p>
             <input id="solicitante" placeholder="Agregar precio del dolar actual" type="number" style={{ width: "35%" }}/>
            <Button style={{ fontSize: 16 }} onClick={()=>{resultado(Productos)}}>Resultado  </Button>
          </Stack>
      <hr />
      <Table style={{ width: "70%" }}>
        <thead>
          <tr>
            <th>Nombre</th>
            <th style={{ textAlign: "right" }}>Total MXN</th>
          </tr>
        </thead>
        <tbody>
          {Productos &&
            Productos.map((producto, index) => (
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td style={{ textAlign: "right" }}>{formatter.format(producto.valor)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
{/* BOTONES */}
    <div> 
        {ModalAñadir_boton && (
        <ModalAñadir
          ModalAñadir_boton={ModalAñadir_boton}
          setModalAñadir_boton={setModalAñadir_boton}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

        {Modal_eliminar && (
        <ModalEliminar
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Botonesdata={Botonesdata}
          actualizar_botones={botones}
          /* Botones_campo={Botones_campo} */
          className="modal-dialog modal-fullscreen-sm-down"
        />)}
        
            <div id="Lista_empaque" style={{ background: "white" }}>        
                {Botonesdata.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" onClick={() => Seleccionar_gastos((datos))} key={index}>{datos.valor}</button>
                ))}
            </div>
      </div> 

    </Container>
    </div> 
    }
  </div>  
 
  );
}

export default Home_Gastos;
