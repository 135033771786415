import React from 'react';
import '../../index.css'
import CRUD_Codigos_QR from '../Firebase/CRUDS/CRUD_Codigos_QR';

const Codigos_QR = () =>{
    return(
        <div>
            <div >
            <div id="listas">  
               
            </div>  
            <CRUD_Codigos_QR/>
            </div>
        </div>
    )
}
//<CRUD/>
export default Codigos_QR;