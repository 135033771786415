import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import React from 'react';

function añadirProducto(infoProducto, autor, pagina) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Produccion/"+pagina+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.fecha2);
  setDoc(docRef, infoProducto);
  //console.log(window.location.pathname)
  //escribirLog("Creación", infoProducto, autor, pagina);
  console.log(infoProducto.zona);
  if(infoProducto.zona == "Embarque"){}else{
  const collectionRef2 = collection(db, "Produccion/Lineas_produccion/Costos_"+infoProducto.linea.replace(/ /g, ""));
  const docRef2 = doc(collectionRef2, infoProducto.fecha2);
  setDoc(docRef2, infoProducto);
  }
      
}

export default añadirProducto;
