import firebaseApp from "../firebase/credenciales";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
const db = getFirestore();

async function filtrarDatos(stringBusqueda) {
  const docusFiltrado = [];

  const collecionRef = collection(db, window.location.pathname);
  const queryTitulo = query(
    collecionRef,
    where("nombre", "==", stringBusqueda)
  );
  const querySku = query(collecionRef, where("proveedor", "==", stringBusqueda));
  const querycampo = query(collecionRef, where("cargo", "==", stringBusqueda));
  const querycampo2 = query(collecionRef, where("tipo_compra", "==", stringBusqueda));
  const querycampo3 = query(collecionRef, where("moneda", "==", stringBusqueda));
  const querycampo4 = query(collecionRef, where("fecha", "==", stringBusqueda));
  const querycampo5 = query(collecionRef, where("solicitante", "==", stringBusqueda));

  const arraySnapshots = await Promise.all([
    getDocs(queryTitulo),
    getDocs(querySku),
    getDocs(querycampo),
    getDocs(querycampo2),
    getDocs(querycampo3),
    getDocs(querycampo4),
    getDocs(querycampo5),
  ]);

  arraySnapshots.forEach((snapshot) => {
    snapshot.forEach((doc) => {
      docusFiltrado.push(doc.data());
    });
  });
  return docusFiltrado;
}

export default filtrarDatos;
