import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllCosecha() {
    console.log("Hola");
    const CosechaDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_empaque/Cosecha/Productos/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      CosechaDB.push(doc.data());
    });
    return CosechaDB;
    

}
