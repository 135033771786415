import General from '../Formularios/General';
import Listaproduccion from '../Listas/Lista_almacen_empaque';
import CRUD_crear_usuario from '../Firebase/CRUDS/CRUD_crear_usuario';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';



//<Listaproduccion/> , va en la linea 14
const Crear_usuario= () =>{
    return(
        <div>
            <div >
            <div id="listas">  
               
            </div>  
            <CRUD_crear_usuario/>
            </div>
        </div>
    )

}

export default Crear_usuario;