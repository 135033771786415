import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function Reiniciar_valores_afectaciones(valor) {
  console.log(valor)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Bancos/Afectaciones_fiscales/Productos/".replace(/ /g, ""))
  const docRef = doc(collectionRef, valor.nombre_afectacion_fiscal);
  updateDoc(docRef, {
    valor : 0
  });
  
}

export default Reiniciar_valores_afectaciones;
