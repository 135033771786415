import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import getAllproducts from "../functions/Productos/getAllproducts";
import editarProducto from "../functions/editarProducto";
import getProveedores from "../functions/Productos/getProveedores"
import getCategorias from "../functions/Productos/getCategorias";

function ModalEditar_productos({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,  
  usuario,
  setProductos,
  Traer_secciones,
}) {

  async function actualizar() {
    await getAllproducts().then((productos) => {
      console.log(productos)
       setProductos(productos);    
       Traer_secciones(productos);  
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

  //Traer proveedores de base de datos
const [Datosproveedores, setDatosproducto] = React.useState([]);
async function productos_basededatos(){
  await getProveedores().then((proveedoresDB) => {
    setDatosproducto(proveedoresDB);
    //console.log(proveedoresDB)
  });
}

//Traer categorias de base de datos
const [Datoscategorias, setDatoscategorias] = React.useState([]);
async function categorias_basededatos(){
  await getCategorias().then((categoriasDB) => {
    setDatoscategorias(categoriasDB);
    //console.log(proveedoresDB)
  });
}

  async function editarProductoModal() {
    //obtener infor del formulario
    //const nombre = document.getElementById("nombre").value;
    const nombre = productoEstado.nombre;
    const precio = document.getElementById("precio").value;
    const unidad = document.getElementById("unidad").value;
    const proveedor = document.getElementById("proveedor").value;
    const categoria = document.getElementById("categoria").value;
    const moneda = document.getElementById("moneda").value;
    const lleva_iva = document.getElementById("lleva_iva").value;
    //const sku = document.getElementById("sku").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, precio, unidad, proveedor, categoria, moneda, lleva_iva};
    editarProducto(infoProducto, usuario.email);
    // cerrar modal
    setProductoEditar(null);
    await timeout(500);
    actualizar();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          {/* <h6>Nombre</h6>
            <Form.Control
              id="nombre"
              placeholder="Agregar nombre"
              type="text"
              className="mb-1"
              value={productoEstado?.nombre}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  nombre: e.target.value,
                })
              }
            />
            <br/> */}
            <h6>Agregar precio</h6>
            <Form.Control
              id="precio"
              placeholder="Agregar precio"
              type="text"
              className="mb-1"
              value={productoEstado?.precio}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  precio: e.target.value,
                })
              }
            />
            <br/>
            <h6>Seleccionar unidad de medida</h6>
            <select 
            id="unidad" 
            type="text" 
            value={productoEstado?.unidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  unidad: e.target.value,
                })
              }> 
              <option >{productoEstado.unidad}</option>
              <option >Bolsa</option>
              <option >Caja</option>
              <option >Galon</option>
              <option >Frasco</option>
              <option >KG</option>
              <option >Litro</option>
              <option >Millares</option>
              <option >M^2</option>
              <option >M^3</option>             
              <option >Pieza</option>
              <option >Pallet</option>
              <option >Rollo</option>
              <option >Saco</option>
              <option >Sobre</option>
              <option >Tonelada</option>
            </select>
          
            <br/>
            <h6>Seleccionar categoria</h6>
            <select 
            id="categoria" 
            type="text" 
            onClick={() => categorias_basededatos()}
            value={productoEstado?.categoria}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  categoria: e.target.value,
                })
              }
            >
            <option >{productoEstado.categoria}</option>
            {Datoscategorias.map((datos,index)=>( 
                <option key={index}>{datos.categoria}</option>
            ))}
            </select> 
            <br/>
            
            <h6>Seleccionar moneda</h6>
            <select 
            id="moneda" 
            type="text" 
            value={productoEstado?.moneda}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  moneda: e.target.value,
                })
              }> 
              <option >{productoEstado.moneda}</option>
              <option >Pesos</option>
              <option >Dolares</option>
            </select>

            <br/>
            
            <h6>¿Lleva iva?</h6>
            <select 
            id="lleva_iva" 
            type="text" 
            value={productoEstado?.lleva_iva}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  lleva_iva: e.target.value,
                })
              }> 
              <option >{productoEstado.lleva_iva}</option>
              <option >No</option>
              <option >Si</option>
              <option >NA</option>
            </select>

            <br/>

            <h6>Seleccionar proveedor</h6>
            <select 
            id="proveedor" 
            type="text" 
            onClick={() => productos_basededatos()}
            value={productoEstado?.proveedor}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  proveedor: e.target.value,
                })
              }>
            <option>{productoEstado.proveedor}</option>
            {Datosproveedores.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>
            
          </Stack>
        </Form>
        
      </Modal.Body>

      <Modal.Footer>       
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 

      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_productos;