import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllusuario() {
  const usuariosBD = [];
  let collectionRef = collection(db, "Usuarios/".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  
  snapshot.forEach((doc) => {
    usuariosBD.push(doc.data());
  });

  return usuariosBD;
}
