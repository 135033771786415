import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getProductos_gastos() {
    
    const GastosDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      GastosDB.push(doc.data());
    });
    console.log(GastosDB);
    return GastosDB;
    

}
