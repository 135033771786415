import React from 'react';
import CRUD_Bancos from '../Firebase/CRUDS/CRUD_Bancos';


const Bancos = () =>{
    return(
        <div>
            <div >
             
            <div id="listas">  
               
            </div>  
            <CRUD_Bancos/>
            </div>
        </div>
    )
}

export default Bancos;