import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarTotal_manifiesto(total_precios_manifiesto, Manifiesto_Seleccionado) {
  console.log(total_precios_manifiesto)
  console.log(Manifiesto_Seleccionado)
  var Total_manifiesto = {total_precios_manifiesto};
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"/Manifiestos/Manifiestos/Manifiestos/".replace(/ /g, ""));
  const docRef = doc(collectionRef, (Manifiesto_Seleccionado.fecha_actual3).toString());
  updateDoc(docRef, Total_manifiesto);
}

export default editarTotal_manifiesto;
