import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminar_presentaciones(datoeliminado, articulo) {
  console.log(datoeliminado)
  console.log(articulo)
  const coleccionRef = collection(db, "Produccion/Lineas_produccion/"+articulo);
  const docuRef = doc(coleccionRef, datoeliminado.fecha2);
  const eliminado = await deleteDoc(docuRef);

  const coleccionRef2 = collection(db, "Produccion/Produccion_diario/Informe_diario");
  const docuRef2 = doc(coleccionRef2, datoeliminado.fecha2);
  await deleteDoc(docuRef2);

  const coleccionRef3 = collection(db, "Almacen_empaque/LOGS/LOGS");
  const docuRef3 = doc(coleccionRef3, datoeliminado.fecha2);
  await deleteDoc(docuRef3);

  return eliminado;
}
