import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function añadirProducto_almacen_campo(infoProducto, infoProducto2, categoria2) {
  console.log(infoProducto.nombre);
  console.log(categoria2);
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Almacen_campo/"+categoria2+"/Productos/")
  const docRef = doc(collectionRef, infoProducto2.producto);
  updateDoc(docRef, infoProducto);  
}

export default añadirProducto_almacen_campo;
