import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, getDocs, query, where,} from "firebase/firestore";

const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getLogs_almacen_campo(producto, Datofinciclo) {
      console.log(producto.nombre)
      const producto2 = (producto.nombre).toString(); 
      const Logs =  [];
      const collectionRef = collection(db, "Almacen_campo/LOGS/LOGS/");
      const q =  query(collectionRef, where("Producto_retirado", "==", producto2));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        Logs.push(doc.data());
        });
      console.log(Logs)

    return Logs;
    
    

}



/* import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getLogs_almacen_campo(producto, Datofinciclo) {
    console.log(Datofinciclo)
    const producto2 = producto.nombre; 
    const Logs =  [];
    let valor = [];
    var prueba = []
    const collectionRef = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      valor = doc.data();
      prueba.push(valor)
      if(Datofinciclo===1){
        if(valor.Seccion == producto2){
          Logs.push(doc.data());
        }
      }else{
        if(valor.Producto_retirado == producto2){
            Logs.push(doc.data());
          }
           }

        });
      console.log(prueba);
    return Logs;
    

} */
