import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_usuario(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Usuarios/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.uid);
  setDoc(docRef, infoProducto);
}

export default añadir_usuario;
