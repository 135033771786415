import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import eliminarboton_gastos from "../../functions/Gastos/eliminarboton_gastos";


function ModalEliminar({
  Modal_eliminar,
  setModal_eliminar,
  actualizar_botones,
  Botonesdata,
}) {


  function eliminarProductoModal() {
    //obtener infor del formulario
    const valor = document.getElementById("categoria").value;
    // enviar informacion a firebase
    const infoProducto = {valor};
    eliminarboton_gastos(infoProducto);
    // cerrar modal
    actualizar_botones();
    setModal_eliminar(false); 
  }
 
  return (
    <Modal show={Modal_eliminar} onHide={() => setModal_eliminar(false)}>
      <Modal.Header>
      <Modal.Title>Eliminar botón de almacen campo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Seleccionar botón a eliminar</h6>
            <select id="categoria" type="text">
            {Botonesdata.map((datos,index)=>( 
                <option key={index}>{datos.valor}</option>
            ))}
            </select>
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_eliminar(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>eliminarProductoModal()}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEliminar;