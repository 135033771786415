import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarFiscal(infoProducto) {
  console.log(infoProducto);
  const coleccionRef = collection(db, "Bancos/Afectaciones_fiscales/Productos/");
  const docuRef = doc(coleccionRef, infoProducto.valor);
  const eliminado = await deleteDoc(docuRef);
  return eliminado;
}
