import React, {useState} from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import getAllproducts from "../functions/Productos/getAllproducts";
import getCategorias from "../functions/Productos/getCategorias";
import eliminarProducto from "../functions/eliminarProducto";
import filtrarDatos from "../functions/filtrarDatos";
import Navbartrabajo from '../../Navbars/Navbartrabajo';
import getUsuarios from "../functions/Almacen_campo/getUsuarios";
import Swal from "sweetalert2";
//modales
import ModalAñadir_productos from "../components/ModalAñadir_productos";
import ModalEditar_productos from "../components/ModalEditar_productos";
import ModalCategorias from "../components/Productos/ModalCategorias";


function Home_productos({ usuario }) {

  function eliminar_sweetalert(producto, usuario) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.nombre+'" ',
      text: "¡Esta acción no se puede restablecer!.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado.',
          'success'
        )
        eliminarProducto(producto, usuario.email).then(
          (confirmacion) => {
            actualizarEstadoProductos();
          }
        );
      }
    })
  }


  const [ADMIN_ON, setADMIN_ON] = React.useState(false);
  async function usuario_actual() {
    await getUsuarios().then((UsuariosDB) => {
        console.log(usuario.email);
        UsuariosDB.map((valor)=>{
          if(valor.correo==usuario.email){
            if(valor.rol=="Administrador"){
              setADMIN_ON(true);
            }
          }
        })
     });
   }

  //console.log("HOME")
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);

  //Categorias
  const [CategoriasON, setCategoriasON] = React.useState(false);
  const [Categorias_productos, setCategorias_productos] = React.useState([]);
  
//Busqueda producto por nombre
const [Secciones, setSecciones] = React.useState("");

async function Traer_secciones(){
  await getAllproducts().then((SeccionesDB) => {
    setSecciones(SeccionesDB)
    //console.log(SeccionesDB)
  });
}

const [Busqueda, setBusqueda] = React.useState("");

function handleChange(e){
  setBusqueda(e.target.value);
  filtrar(e.target.value);
}

const filtrar=(terminoBusqueda)=>{
  //console.log(terminoBusqueda.toLowerCase())
  var resultadoBusqueda=Secciones.filter((elemento)=>{

    if((elemento.nombre.toLowerCase()).includes(terminoBusqueda.toLowerCase()))
    {
      return elemento;
    }
  })
  setProductos(resultadoBusqueda);
}

//------------------------------------------

  async function Categorias(){
   await getCategorias().then((CategoriasDB) => {
      setCategorias_productos(CategoriasDB);
      setCategoriasON(true);
      console.log("Prueba")
      //console.log(CategoriasDB)
    });
  }


  function actualizarEstadoProductos() {
    getAllProducts().then((productos) => {
      setProductos(productos);
      console.log("Prueba")
      //console.log(productos)
    });
  }

  function añadirProductoHome() {
    setIsModalAñadir(true);
  }

  React.useEffect(() => {
    actualizarEstadoProductos();
    Traer_secciones();
    usuario_actual();
  }, []);

  /* React.useEffect(() => {
    actualizarEstadoProductos();
  }, [productoEditar, isModalEditar,  isModalAñadir ]); */

  return (
  <div>
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
    {isModalAñadir && (
      <ModalAñadir_productos
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
        setProductos= {setProductos}
        Traer_secciones = {Traer_secciones}
      />
    )}
      {productoEditar && (
        <ModalEditar_productos
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          setProductos= {setProductos}
          Traer_secciones = {Traer_secciones}
        />
      )}

      {CategoriasON && (
        <ModalCategorias
          CategoriasON={CategoriasON}
          setCategoriasON={setCategoriasON}
          Categorias_productos={Categorias_productos}
          actualizarEstadoProductos={Categorias}
        />
      )}
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname} </p>
        <Button
          onClick={() => {
            Categorias();
          }}
          >Categorias</Button>
        <Button onClick={añadirProductoHome}> Añadir producto</Button>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
      <hr />
      
      <input
      value={Busqueda}
      placeholder="Busqueda por nombre"
      onChange={handleChange}
      className="w-75 m-3"
      />
      <hr />
      <Table>
        <thead>
          <tr>
          <th>#</th>
            <th>Nombre</th>
            {ADMIN_ON && (
            <th>Precio</th>
          )} 
            <th>Unidad de medida</th>
            <th>Moneda</th>
            <th>¿iva?</th>
            <th>Categoria</th>
            <th>Proveedor</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                {ADMIN_ON && (
                <td>${producto.precio}</td>
                )} 
                <td>{producto.unidad}</td>
                <td>{producto.moneda}</td>
                <td>{producto.lleva_iva}</td>
                <td>{producto.categoria}</td>
                <td>{producto.proveedor}</td>
                <td>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setIsModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, usuario)
                    }}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button onClick={añadirProductoHome}> Añadir producto</Button>
      <br/>
      <br/>
    </Container>
    </div> 
  );
}

export default Home_productos;
