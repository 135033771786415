import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import editarProducto from "../functions/editarProducto";

function ModalEditar_proveedores({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,  
  usuario,
  setProductos,
}) {

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
 function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

  async function actualizar() {
    await getAllProducts().then((productos) => {
      //Ordenes_de_compra_actuales(productos);
      setProductos(productos);
      console.log(productos)
      console.log("Hola")
      setIsModalEditar(false);
    });
  }

 async function editarProductoModal() {
    //obtener infor del formulario
    const nombre = productoEstado.nombre;
    const telefono = document.getElementById("telefono").value;
    const direccion = document.getElementById("direccion").value;
    // enviar informacion a firebase
    const infoProducto = { nombre, telefono, direccion };
    editarProducto(infoProducto, usuario.email);
    // cerrar modal
    setProductoEditar(null);
    await timeout(500);
    actualizar();
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <Form.Control
              id="telefono"
              placeholder="Agregar telefono"
              type="text"
              className="mb-1"
              value={productoEstado?.telefono}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  telefono: e.target.value,
                })
              }
            />
            <Form.Control
              id="direccion"
              placeholder="Agregar email"
              type="text"
              className="mb-1"
              value={productoEstado?.direccion}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  direccion: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        
      </Modal.Body>

      <Modal.Footer>       
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 

      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_proveedores;
