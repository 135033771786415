import React from "react";
import signOut from "../functions/cerrarSesion";
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/getAllProducts";
import Navbartrabajo from '../../Navbars/Navbartrabajo'
import getAllsecciones from "../functions/Campo_secciones/getAllsecciones";
//modales
import ModalAñadir from "../components/Campo_secciones/ModalAñadir";
import ModalEditar from "../components/Campo_secciones/ModalEditar";
import ModalFinciclo from "../components/Campo_secciones/ModalFinciclo";


function Home_campo_secciones({ usuario }) {

  //console.log("HOME")
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);


//Busqueda de una tabla o seccion por nombre
  const [Secciones, setSecciones] = React.useState("");

  async function Traer_secciones(){
    await getAllsecciones().then((SeccionesDB) => {
      setSecciones(SeccionesDB)
      console.log(productos)
      //console.log(productos)
    });
  }

  const [Busqueda, setBusqueda] = React.useState("");

  function handleChange(e){
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    //console.log(terminoBusqueda.toLowerCase())
    var resultadoBusqueda=Secciones.filter((elemento)=>{

      if((elemento.nombre.toLowerCase()).includes(terminoBusqueda.toLowerCase()))
      {
        return elemento;
      }
    })
    setProductos(resultadoBusqueda);
  }

//------------------------------------------

  function actualizarEstadoProductos() {
    getAllProducts().then((productos) => {
      setProductos(productos);
    });
  }

  const [Fin, setFin] = React.useState(false);

  React.useEffect(() => {
    actualizarEstadoProductos();
    Traer_secciones();
  }, []);

  return (
  <div>
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
      <ModalAñadir
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
      />
      {productoEditar && (
        <ModalEditar
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
        />
      )}

    {Fin && (
        <ModalFinciclo
          ModalFin={Fin}
          setFin={setFin}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
        />
      )}
      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20  }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname} </p>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
      <hr />
      
      <input
      value={Busqueda}
      placeholder="Busqueda por tabla"
      onChange={handleChange}
      className="w-75 m-3"
      />

      <hr />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Tabla o sección</th>
            <th>Cantidad de cultivos</th>
            <th>Producto del Cultivo 1</th>
            <th>Fecha de inicio cultivo 1</th>
            <th>Producto del Cultivo 2</th>
            <th>Fecha de inicio cultivo 2</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.nombre}</td>
                <td>{producto.cantidad_cultivos}</td>
                <td>{producto.producto1}</td>
                <td>{producto.producto1_fecha}</td>
                <td>{producto.producto2}</td>
                <td>{producto.producto2_fecha}</td>
                <td>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setIsModalEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setProductoEditar({ ...producto });
                      setFin(true);
                    }}
                  >
                    Fin ciclo
                  </Button>
                  {/* <Button
                    variant="danger"
                    onClick={() => {
                      eliminarTabla(producto, usuario.email).then(
                        (confirmacion) => {
                          actualizarEstadoProductos();
                        }
                      );
                    }}
                  >
                    Eliminar
                  </Button> */}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* <Button onClick={añadirProductoHome}> Añadir tabla</Button> */}
      <br/>
      <br/>
    </Container>
    </div> 
  );
}

export default Home_campo_secciones;
