import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllPagos(Manifiesto_Seleccionado) {
  console.log(Manifiesto_Seleccionado.manifiesto)

    const PagosDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Manifiestos/Pagos/"+(Manifiesto_Seleccionado.fecha_actual3).toString()+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      PagosDB.push(doc.data());
    });
    return PagosDB;
    

}
