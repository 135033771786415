import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarCodigo(infoProducto) {
  const db = getFirestore(firebaseApp);
  console.log(infoProducto)
  let collectionRef = collection(db, "Codigos_QR/".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.nombre);
  updateDoc(docRef, infoProducto);
}

export default editarCodigo;
