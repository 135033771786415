import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_productos_orden(accion, producto, autor,productofolio) {
  const collectionRef = collection(db, "Ordenes_productos/"+productofolio+"/Logs".replace(/ /g, ""));
  const docuRef = doc(collectionRef, new Date().toString());
  const data = {
    accion,
    fecha: new Date(),
    producto,
    autor,
  };
  setDoc(docuRef, data);
}
