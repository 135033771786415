import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(firebaseApp);

export default async function getLogs_almacen_empaque() {
    const Logs =  [];
    const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Logs.push(doc.data());
    });
    return Logs;
    

}


//"+productofolio+"
