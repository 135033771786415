import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import escribirLog from "../escribirLog";
import React, {useState} from 'react';

function añadirProducto(infoProducto, autor, pagina,start) {
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, window.location.pathname);
  if(start){ 
      collectionRef = collection(db, "Almacen_empaque/"+pagina+"/Productos".replace(/ /g, ""))
      }else{ collectionRef = collection(db, window.location.pathname)}
      
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);
  //console.log(window.location.pathname)
  escribirLog("Creación", infoProducto, autor);

  return(
        <div>
            
            
          </div>
        )
      
}

export default añadirProducto;
