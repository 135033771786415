import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_total_gastos(total_acumulado,costo,Nombre_de_boton) {
  console.log(total_acumulado)
  console.log(costo);
  console.log(Nombre_de_boton.nombre_afectacion_fiscal);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Afectaciones_fiscales/Productos/".replace(/ /g, ""));
  const docRef = doc(collectionRef, Nombre_de_boton.nombre_afectacion_fiscal);
  setDoc(docRef, costo);     
}

export default añadir_total_gastos;
