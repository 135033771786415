import React from "react";
import { Modal, Stack, Form, Button, Table, Container} from "react-bootstrap";
import getProduccion_diaria from "../../functions/Produccion/Presentaciones/getProduccion_diaria";
import getProduccion_costos from "../../functions/Produccion/Presentaciones/getProduccion_costos";
import getDetalles_costos from "../../functions/Produccion/Presentaciones/getDetalles_costos";
import getLogs_almacen_empaque from "../../functions/Almacen_empaque/getLogs_almacen_empaque";
import añadirInforme_diario_lineas from "../../functions/Produccion/Presentaciones/añadirInforme_diario_lineas";
import getAllCosecha_pallets from "../../functions/Produccion/getAllCosecha_pallets";
//Costos
import añadir_total_costos from "../../functions/Produccion/Presentaciones/añadir_total_costos";
import {DatePicker} from "@material-ui/pickers";
import "../../../../index.css";
import {Bar} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ModalProduccion_semanal({
  ModalSemanal,
  setModalSemanal,
  actualizarEstadoProductos,
  usuario,
  pagina,
  Presentacion,
  setProductosPresentaciones,
}) {

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

  const [TablaModalPrincipal, setTablaModalPrincipal] = React.useState(false);

  const [Datos, setDatos] = React.useState([]);
  //Rango de fechas seleccionado
  const [Datos_filtrados_Tomate, setDatos_filtrados_Tomate] = React.useState([]);
  const [Datos_filtrados_Chile, setDatos_filtrados_Chile] = React.useState([]);
  const [Datos_filtrados_Calabaza, setDatos_filtrados_Calabaza] = React.useState([]);
  const [Datos_filtrados_Tomatillo, setDatos_filtrados_Tomatillo] = React.useState([]);
  //Graficar semanal
  const [Datos_filtrados_semanal_Tomate, setDatos_filtrados_semanal_Tomate] = React.useState([]);
  const [Datos_filtrados_semanal_Chile, setDatos_filtrados_semanal_Chile] = React.useState([]);
  const [Datos_filtrados_semanal_Calabaza, setDatos_filtrados_semanal_Calabaza] = React.useState([]);
  const [Datos_filtrados_semanal_Tomatillo, setDatos_filtrados_semanal_Tomatillo] = React.useState([]);

  //DEL TOTAL DE DIAS SELECCIONADO
  const [Pallets_Total_Tomate, setPallets_Total_Tomate] = React.useState(0);
  const [Cajas_Total_Tomate, setCajas_Total_Tomate] = React.useState(0);
  const [Pallets_Total_Chile, setPallets_Total_Chile] = React.useState(0);
  const [Cajas_Total_Chile, setCajas_Total_Chile] = React.useState(0);
  const [Pallets_Total_Calabaza, setPallets_Total_Calabaza] = React.useState(0);
  const [Cajas_Total_Calabaza, setCajas_Total_Calabaza] = React.useState(0);
  const [Pallets_Total_Tomatillo, setPallets_Total_Tomatillo] = React.useState(0);
  const [Cajas_Total_Tomatillo, setCajas_Total_Tomatillo] = React.useState(0);

  //SEMANAL A PARTIR DEL PRIMER DIA SELECCIONADO
  const [Pallets_Total_semanal_Tomate, setPallets_Total_semanal_Tomate] = React.useState(0);
  const [Cajas_Total_semanal_Tomate, setCajas_Total_semanal_Tomate] = React.useState(0);
  const [Pallets_Total_semanal_Chile, setPallets_Total_semanal_Chile] = React.useState(0);
  const [Cajas_Total_semanal_Chile, setCajas_Total_semanal_Chile] = React.useState(0);
  const [Pallets_Total_semanal_Calabaza, setPallets_Total_semanal_Calabaza] = React.useState(0);
  const [Cajas_Total_semanal_Calabaza, setCajas_Total_semanal_Calabaza] = React.useState(0);
  const [Pallets_Total_semanal_Tomatillo, setPallets_Total_semanal_Tomatillo] = React.useState(0);
  const [Cajas_Total_semanal_Tomatillo, setCajas_Total_semanal_Tomatillo] = React.useState(0);
  
  const [ModalDetalles, setModalDetalles] = React.useState(false);
  const [filtropresentacion, setfiltropresentacion] = React.useState([]);

function salir_detalles_produccion(){
  setDetalles_produccion_seleccionada(false);
  setModalDetalles(false);
  setModalDetalles(false);
  setDetalles_por_tamaño_ON(false);
}

//Produccion diaria grafia
const state = {
  /* labels: ['Tomate', 'Chile', 'Calabaza',
           'Tomatillo'], */
  labels: ['Viernes', 'Sabado', 'Domingo','Lunes', 'Martes', 'Miercoles',
           'Jueves',],
  datasets: [
    {
      label: 'Tomate (Cajas)',
      backgroundColor: [
      '#22B419',
      ],
      hoverBackgroundColor: [
        '#187412',
        ],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      //data: [Pallets_Total_semanal_Tomate[0],Pallets_Total_semanal_Tomate[1],Pallets_Total_semanal_Tomate[2],Pallets_Total_semanal_Tomate[3],Pallets_Total_semanal_Tomate[4],Pallets_Total_semanal_Tomate[5],Pallets_Total_semanal_Tomate[6]]
      data: [Pallets_Total_semanal_Tomate[5],Pallets_Total_semanal_Tomate[6],Pallets_Total_semanal_Tomate[0],Pallets_Total_semanal_Tomate[1],Pallets_Total_semanal_Tomate[2],Pallets_Total_semanal_Tomate[3],Pallets_Total_semanal_Tomate[4]]
    },
    {
      label: 'Chile (Cajas)',
      backgroundColor: [
      '#F33007',
          ],
      hoverBackgroundColor: [
        '#7B1E0A',
        ],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      //data: [Pallets_Total_semanal_Chile[0],Pallets_Total_semanal_Chile[1],Pallets_Total_semanal_Chile[2],Pallets_Total_semanal_Chile[3],Pallets_Total_semanal_Chile[4],Pallets_Total_semanal_Chile[5],Pallets_Total_semanal_Chile[6]]
      data: [Pallets_Total_semanal_Chile[5],Pallets_Total_semanal_Chile[6],Pallets_Total_semanal_Chile[0],Pallets_Total_semanal_Chile[1],Pallets_Total_semanal_Chile[2],Pallets_Total_semanal_Chile[3],Pallets_Total_semanal_Chile[4]]
    },
    {
      label: 'Calabaza (Cajas)',
      backgroundColor: [
        '#E6F378',
          ],
      hoverBackgroundColor: [
        '#98A71D',
        ],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      //data: [Pallets_Total_semanal_Calabaza[0],Pallets_Total_semanal_Calabaza[1],Pallets_Total_semanal_Calabaza[2],Pallets_Total_semanal_Calabaza[3],Pallets_Total_semanal_Calabaza[4],Pallets_Total_semanal_Calabaza[5],Pallets_Total_semanal_Calabaza[6]]
      data: [Pallets_Total_semanal_Calabaza[5],Pallets_Total_semanal_Calabaza[6],Pallets_Total_semanal_Calabaza[0],Pallets_Total_semanal_Calabaza[1],Pallets_Total_semanal_Calabaza[2],Pallets_Total_semanal_Calabaza[3],Pallets_Total_semanal_Calabaza[4]]
    },
    {
      label: 'Tomatillo (Cajas)',
      backgroundColor: [
        '#F4AA64',
          ],
      hoverBackgroundColor: [
        '#975312',
        ],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      //data: [Pallets_Total_semanal_Tomatillo[0],Pallets_Total_semanal_Tomatillo[1],Pallets_Total_semanal_Tomatillo[2],Pallets_Total_semanal_Tomatillo[3],Pallets_Total_semanal_Tomatillo[4],Pallets_Total_semanal_Tomatillo[5],Pallets_Total_semanal_Tomatillo[6]]
      data: [Pallets_Total_semanal_Tomatillo[5],Pallets_Total_semanal_Tomatillo[6],Pallets_Total_semanal_Tomatillo[0],Pallets_Total_semanal_Tomatillo[1],Pallets_Total_semanal_Tomatillo[2],Pallets_Total_semanal_Tomatillo[3],Pallets_Total_semanal_Tomatillo[4]]
    }
  ]
}
//-------------------------------------------------------------------------------------

  async function actualizar(Presentacion) {
    await getProduccion_diaria(Presentacion).then((productos) => {
      console.log(productos)
      setDatos(productos);  
     });
   }

   //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());
  const [fechaSeleccionada3, setfechaSeleccionada3] = React.useState(new Date());
  const [Auxiliar_boton_dolar, setAuxiliar_boton_dolar] = React.useState(false);
  const [fechaSeleccionada_intervalo, setfechaSeleccionada_intervalo] = React.useState([]);

  function filtrado(Datos){
      //Esto es para aparecer el input y boton del dolar
      setAuxiliar_boton_dolar(true);
      //Esto sirve para filtrar los dias a buscar en los logs
      const dia_inicio = fechaSeleccionada;
      const dia_final = fechaSeleccionada3;
      var timeStart = fechaSeleccionada;
      var timeEnd = fechaSeleccionada3;
      var diferencia_dias = 0;
      if (timeEnd > timeStart)
      {
          var diff = timeEnd.getTime() - timeStart.getTime();
          console.log(diff);
          diferencia_dias = Math.round(diff / (1000 * 60 * 60 * 24));
      }
      else if (timeEnd != null && timeEnd < timeStart) {
          alert("La fecha final de la promoción debe ser mayor a la fecha inicial");
      }

      const dias_evaluar = new Date(dia_inicio);
      let dato = 0;
      let dia_filtrado = 0;
      let Dias_a_buscar = [];
      for(var i=-1; i<diferencia_dias; i++){
        if(i==-1){
          dias_evaluar.setDate(dias_evaluar.getDate());
          dato =dias_evaluar;
          dia_filtrado = String(dato.getDate()).padStart(2, '0') + '-' + String(dato.getMonth() + 1).padStart(2, '0') + '-' + dato.getFullYear() ;
        }else{
          dias_evaluar.setDate(dias_evaluar.getDate() + 1);
          dato =dias_evaluar;
          dia_filtrado = String(dato.getDate()).padStart(2, '0') + '-' + String(dato.getMonth() + 1).padStart(2, '0') + '-' + dato.getFullYear() ;
        }
        Dias_a_buscar.push(dia_filtrado);
      }
 //Aqui termina el filtro de los dias ys e almacena en Dias_a_buscar

//Sirve para filtrar todos los valores en el log por el rango de dias seleccionado y los guarda en la presentacion correspondiente
    var Informediario_Tomate= [];
    var Informediario_Chile= [];
    var Informediario_Calabaza= [];
    var Informediario_Tomatillo= [];

    //Se ocupan las fechas a buscar para sacar los costos de produccion
    console.log(Dias_a_buscar)
    setfechaSeleccionada_intervalo(Dias_a_buscar);

    Dias_a_buscar.map((primer_dia)=>{
    Datos.map((data)=>{
      if(data.fecha==primer_dia){
        if(data.present=="Tomate"){
          Informediario_Tomate.push(data);
        }
      }

      if(data.fecha==primer_dia){
        if(data.present=="Chile"){
          Informediario_Chile.push(data);
        }
      }

      if(data.fecha==primer_dia){
        if(data.present=="Calabaza"){
          Informediario_Calabaza.push(data);
        }
      }

      if(data.fecha==primer_dia){
        if(data.present=="Tomatillo"){
          Informediario_Tomatillo.push(data);
        }
      }
    })
  })

  setDatos_filtrados_Tomate(Informediario_Tomate);
  setDatos_filtrados_Chile(Informediario_Chile);
  setDatos_filtrados_Calabaza(Informediario_Calabaza);
  setDatos_filtrados_Tomatillo(Informediario_Tomatillo);

    //Esto sirve para sacar el total de pallets y cajas en el dia generados del total del rango seleccionado
    //Tomate

    console.log(Informediario_Chile);
    var datostotal_Tomate_Pallet= [];
    Informediario_Tomate.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Tomate_Pallet.push(valor.cantidad)}})
    let subtotal_Tomate_Pallet=0;
    for(let i of datostotal_Tomate_Pallet) subtotal_Tomate_Pallet+=parseInt(i);
    setPallets_Total_Tomate(subtotal_Tomate_Pallet);

    var datostotal_Tomate_Caja= [];
    Informediario_Tomate.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomate_Caja.push(valor.cantidad)}})
    let subtotal_Tomate_Caja=0;
    for(let i of datostotal_Tomate_Caja) subtotal_Tomate_Caja+=parseInt(i);
    setCajas_Total_Tomate(subtotal_Tomate_Caja);

    //Chile
    const datostotal_Chile_Pallet=[];
    Informediario_Chile.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Chile_Pallet.push(valor.cantidad)}})
    let subtotal_Chile_Pallet=0;
    for(let i of datostotal_Chile_Pallet) subtotal_Chile_Pallet+=parseInt(i);
    setPallets_Total_Chile(subtotal_Chile_Pallet);

    var datostotal_Chile_Caja= [];
    Informediario_Chile.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Chile_Caja.push(valor.cantidad)}})
    let subtotal_Chile_Caja=0;
    for(let i of datostotal_Chile_Caja) subtotal_Chile_Caja+=parseInt(i);
    setCajas_Total_Chile(subtotal_Chile_Caja);

    //Calabaza
    const datostotal_Calabaza_Pallet=[];
    Informediario_Calabaza.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Calabaza_Pallet.push(valor.cantidad)}})
    let subtotal_Calabaza_Pallet=0;
    for(let i of datostotal_Calabaza_Pallet) subtotal_Calabaza_Pallet+=parseInt(i);
    setPallets_Total_Calabaza(subtotal_Calabaza_Pallet);

    var datostotal_Calabaza_Caja= [];
    Informediario_Calabaza.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Calabaza_Caja.push(valor.cantidad)}})
    let subtotal_Calabaza_Caja=0;
    for(let i of datostotal_Calabaza_Caja) subtotal_Calabaza_Caja+=parseInt(i);
    setCajas_Total_Calabaza(subtotal_Calabaza_Caja);

    //Tomatillo
    const datostotal_Tomatillo_Pallet=[];
    Informediario_Tomatillo.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Tomatillo_Pallet.push(valor.cantidad)}})
    let subtotal_Tomatillo_Pallet=0;
    for(let i of datostotal_Tomatillo_Pallet) subtotal_Tomatillo_Pallet+=parseInt(i);
    setPallets_Total_Tomatillo(subtotal_Tomatillo_Pallet);

    var datostotal_Tomatillo_Caja= [];
    Informediario_Tomatillo.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomatillo_Caja.push(valor.cantidad)}})
    let subtotal_Tomatillo_Caja=0;
    for(let i of datostotal_Tomatillo_Caja) subtotal_Tomatillo_Caja+=parseInt(i);
    setCajas_Total_Tomatillo(subtotal_Tomatillo_Caja);

//Sirve para filtrar todos los valores en el log por el rango de los 7 dias como semana empezando por 
//lunes el cual deberia ser el primer dia seleccionado apartir de ahi se seleccionan los 6 dias seiguientes
//y los guarda en la presentacion correspondiente, todo esto para sacar los datos de la grafica semanal

//Esto sirve para filtrar del rango de fechas seleccionado, solamente 7
let dias_buscar_semanal = [];
console.log(Dias_a_buscar);
for(var i=0; i<7; i++){
  var valor=Dias_a_buscar[i];
  console.log(valor);
  dias_buscar_semanal.push(valor);
}

var Informesemanal_Tomate= [];
var Informesemanal_Chile= [];
var Informesemanal_Calabaza= [];
var Informesemanal_Tomatillo= [];

dias_buscar_semanal.map((primer_dia)=>{
Datos.map((data)=>{
  if(data.fecha==primer_dia){
    if(data.present=="Tomate"){
      Informesemanal_Tomate.push(data);
    }
  }

  if(data.fecha==primer_dia){
    if(data.present=="Chile"){
      Informesemanal_Chile.push(data);
    }
  }

  if(data.fecha==primer_dia){
    if(data.present=="Calabaza"){
      Informesemanal_Calabaza.push(data);
    }
  }

  if(data.fecha==primer_dia){
    if(data.present=="Tomatillo"){
      Informesemanal_Tomatillo.push(data);
    }
  }
})
})
    setDatos_filtrados_semanal_Tomate(Informesemanal_Tomate);
    setDatos_filtrados_semanal_Chile(Informesemanal_Chile);
    setDatos_filtrados_semanal_Calabaza(Informesemanal_Calabaza);
    setDatos_filtrados_semanal_Tomatillo(Informesemanal_Tomatillo);
  /* console.log(Informediario_Tomate);
  console.log(Informediario_Chile);
  console.log(Informediario_Calabaza);
  console.log(Informediario_Tomatillo); */

    //Esto sirve para sacar el total de pallets y cajas en el dia generados SEMANAL a partir del primer dia seleccionado
    //Tomate
    var datostotal_Tomate_semanal_Pallet= [];
    let subtotal_Tomate_semanal_Pallet_Lunes=0;
    let subtotal_Tomate_semanal_Pallet_Martes=0;
    let subtotal_Tomate_semanal_Pallet_Miercoles=0;
    let subtotal_Tomate_semanal_Pallet_Jueves=0;
    let subtotal_Tomate_semanal_Pallet_Viernes=0;
    let subtotal_Tomate_semanal_Pallet_Sabado=0;
    let subtotal_Tomate_semanal_Pallet_Domingo=0;
    for(var i=0; i<7; i++){
    datostotal_Tomate_semanal_Pallet=[];
    Informesemanal_Tomate.map((valor)=>{ if(valor.unidad=="Pallet"){if(valor.fecha==dias_buscar_semanal[i]){datostotal_Tomate_semanal_Pallet.push(valor.cantidad)}
    else{datostotal_Tomate_semanal_Pallet.push(0)}}})
    if(i==0){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Lunes = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==1){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Martes = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==2){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Miercoles = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==3){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Jueves = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==4){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Viernes = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==5){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Sabado = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==6){
      let subtotal_Tomate_semanal_Pallet=0;
      for(let j of datostotal_Tomate_semanal_Pallet) subtotal_Tomate_semanal_Pallet+=parseInt(j);
      subtotal_Tomate_semanal_Pallet_Domingo = subtotal_Tomate_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
   
  }
  var datostotal_Tomate_semanal= [];
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Lunes);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Martes);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Miercoles);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Jueves);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Viernes);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Sabado);
  datostotal_Tomate_semanal.push(subtotal_Tomate_semanal_Pallet_Domingo);
  setPallets_Total_semanal_Tomate(datostotal_Tomate_semanal);

    var datostotal_Tomate_semanal_Caja= [];
    Informesemanal_Tomate.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomate_semanal_Caja.push(valor.cantidad)}})
    let subtotal_Tomate_semanal_Caja=0;
    for(let i of datostotal_Tomate_semanal_Caja) subtotal_Tomate_semanal_Caja+=parseInt(i);
    setCajas_Total_semanal_Tomate(subtotal_Tomate_semanal_Caja);



    //Chile
    var datostotal_Chile_semanal_Pallet= [];
    let subtotal_Chile_semanal_Pallet_Lunes=0;
    let subtotal_Chile_semanal_Pallet_Martes=0;
    let subtotal_Chile_semanal_Pallet_Miercoles=0;
    let subtotal_Chile_semanal_Pallet_Jueves=0;
    let subtotal_Chile_semanal_Pallet_Viernes=0;
    let subtotal_Chile_semanal_Pallet_Sabado=0;
    let subtotal_Chile_semanal_Pallet_Domingo=0;
    for(var i=0; i<7; i++){
    datostotal_Chile_semanal_Pallet=[];
    Informesemanal_Chile.map((valor)=>{ if(valor.unidad=="Pallet"){if(valor.fecha==dias_buscar_semanal[i]){datostotal_Chile_semanal_Pallet.push(valor.cantidad)}
    else{datostotal_Chile_semanal_Pallet.push(0)}}})
    if(i==0){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Lunes = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==1){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Martes = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==2){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Miercoles = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==3){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Jueves = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==4){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Viernes = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==5){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Sabado = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==6){
      let subtotal_Chile_semanal_Pallet=0;
      for(let j of datostotal_Chile_semanal_Pallet) subtotal_Chile_semanal_Pallet+=parseInt(j);
      subtotal_Chile_semanal_Pallet_Domingo = subtotal_Chile_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
   
  }

  var datostotal_Chile_semanal= [];
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Lunes);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Martes);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Miercoles);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Jueves);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Viernes);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Sabado);
  datostotal_Chile_semanal.push(subtotal_Chile_semanal_Pallet_Domingo);
  setPallets_Total_semanal_Chile(datostotal_Chile_semanal);


    var datostotal_Chile_semanal_Caja= [];
    Informesemanal_Chile.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Chile_semanal_Caja.push(valor.cantidad)}})
    let subtotal_Chile_semanal_Caja=0;
    for(let i of datostotal_Chile_semanal_Caja) subtotal_Chile_semanal_Caja+=parseInt(i);
    setCajas_Total_semanal_Chile(subtotal_Chile_semanal_Caja);

    //Calabaza
    var datostotal_Calabaza_semanal_Pallet= [];
    let subtotal_Calabaza_semanal_Pallet_Lunes=0;
    let subtotal_Calabaza_semanal_Pallet_Martes=0;
    let subtotal_Calabaza_semanal_Pallet_Miercoles=0;
    let subtotal_Calabaza_semanal_Pallet_Jueves=0;
    let subtotal_Calabaza_semanal_Pallet_Viernes=0;
    let subtotal_Calabaza_semanal_Pallet_Sabado=0;
    let subtotal_Calabaza_semanal_Pallet_Domingo=0;
    for(var i=0; i<7; i++){
    datostotal_Calabaza_semanal_Pallet=[];
    Informesemanal_Calabaza.map((valor)=>{ if(valor.unidad=="Pallet"){if(valor.fecha==dias_buscar_semanal[i]){datostotal_Calabaza_semanal_Pallet.push(valor.cantidad)}
    else{datostotal_Calabaza_semanal_Pallet.push(0)}}})
    if(i==0){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Lunes = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==1){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Martes = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==2){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Miercoles = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==3){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Jueves = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==4){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Viernes = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==5){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Sabado = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==6){
      let subtotal_Calabaza_semanal_Pallet=0;
      for(let j of datostotal_Calabaza_semanal_Pallet) subtotal_Calabaza_semanal_Pallet+=parseInt(j);
      subtotal_Calabaza_semanal_Pallet_Domingo = subtotal_Calabaza_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
  
  }
  
  var datostotal_Calabaza_semanal= [];
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Lunes);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Martes);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Miercoles);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Jueves);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Viernes);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Sabado);
  datostotal_Calabaza_semanal.push(subtotal_Calabaza_semanal_Pallet_Domingo);
  setPallets_Total_semanal_Calabaza(datostotal_Calabaza_semanal);

    var datostotal_Calabaza_semanal_Caja= [];
    Informesemanal_Calabaza.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Calabaza_semanal_Caja.push(valor.cantidad)}})
    let subtotal_Calabaza_semanal_Caja=0;
    for(let i of datostotal_Calabaza_semanal_Caja) subtotal_Calabaza_semanal_Caja+=parseInt(i);
    setCajas_Total_semanal_Calabaza(subtotal_Calabaza_semanal_Caja);

    //Tomatillo
    var datostotal_Tomatillo_semanal_Pallet= [];
    let subtotal_Tomatillo_semanal_Pallet_Lunes=0;
    let subtotal_Tomatillo_semanal_Pallet_Martes=0;
    let subtotal_Tomatillo_semanal_Pallet_Miercoles=0;
    let subtotal_Tomatillo_semanal_Pallet_Jueves=0;
    let subtotal_Tomatillo_semanal_Pallet_Viernes=0;
    let subtotal_Tomatillo_semanal_Pallet_Sabado=0;
    let subtotal_Tomatillo_semanal_Pallet_Domingo=0;
    for(var i=0; i<7; i++){
    datostotal_Tomatillo_semanal_Pallet=[];
    Informesemanal_Tomatillo.map((valor)=>{ if(valor.unidad=="Pallet"){if(valor.fecha==dias_buscar_semanal[i]){datostotal_Tomatillo_semanal_Pallet.push(valor.cantidad)}
    else{datostotal_Tomatillo_semanal_Pallet.push(0)}}})
    if(i==0){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Lunes = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==1){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Martes = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==2){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Miercoles = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==3){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Jueves = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==4){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Viernes = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==5){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Sabado = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
    if(i==6){
      let subtotal_Tomatillo_semanal_Pallet=0;
      for(let j of datostotal_Tomatillo_semanal_Pallet) subtotal_Tomatillo_semanal_Pallet+=parseInt(j);
      subtotal_Tomatillo_semanal_Pallet_Domingo = subtotal_Tomatillo_semanal_Pallet
       //setPallets_Total_semanal_Tomate(subtotal_Tomate_semanal_Pallet);
    }
  }
  
  var datostotal_Tomatillo_semanal= [];
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Lunes);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Martes);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Miercoles);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Jueves);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Viernes);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Sabado);
  datostotal_Tomatillo_semanal.push(subtotal_Tomatillo_semanal_Pallet_Domingo);
  setPallets_Total_semanal_Tomatillo(datostotal_Tomatillo_semanal);

    var datostotal_Tomatillo_semanal_Caja= [];
    Informesemanal_Tomatillo.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomatillo_semanal_Caja.push(valor.cantidad)}})
    let subtotal_Tomatillo_semanal_Caja=0;
    for(let i of datostotal_Tomatillo_semanal_Caja) subtotal_Tomatillo_semanal_Caja+=parseInt(i);
    setCajas_Total_semanal_Tomatillo(subtotal_Tomatillo_semanal_Caja);
  


//Esto sirve para sumar el costo total de cada línea
var Presentacion = "";
Presentacion = "Tomate";
costos(Presentacion,Dias_a_buscar);

Presentacion = "Calabaza";
costos(Presentacion,Dias_a_buscar);

Presentacion = "Chile";
costos(Presentacion,Dias_a_buscar);

Presentacion = "Tomatillo";
costos(Presentacion,Dias_a_buscar);


/* console.log(Datos_Costos_Tomate_Pesos)
console.log(Datos_Costos_Chile_Pesos)
console.log(Datos_Costos_Calabaza_Pesos)
console.log(Datos_Costos_Tomatillo_Pesos) */

    setTablaModalPrincipal(true);
    setDetalles_por_tamaño_ON(false);
  } 


  //Pesos productos
  const [Datos_Costos_Tomate_Pesos, setDatos_Costos_Tomate_Pesos] = React.useState([]);
  const [Datos_Costos_Chile_Pesos, setDatos_Costos_Chile_Pesos] = React.useState([]);
  const [Datos_Costos_Calabaza_Pesos, setDatos_Costos_Calabaza_Pesos] = React.useState([]);
  const [Datos_Costos_Tomatillo_Pesos, setDatos_Costos_Tomatillo_Pesos] = React.useState([]);
  //Dolares productos
  const [Datos_Costos_Tomate_Dolares, setDatos_Costos_Tomate_Dolares] = React.useState([]);
  const [Datos_Costos_Chile_Dolares, setDatos_Costos_Chile_Dolares] = React.useState([]);
  const [Datos_Costos_Calabaza_Dolares, setDatos_Costos_Calabaza_Dolares] = React.useState([]);
  const [Datos_Costos_Tomatillo_Dolares, setDatos_Costos_Tomatillo_Dolares] = React.useState([]);

  //Pesos dinero individual
  const [Valor_Costos_Tomate_Pesos, setValor_Costos_Tomate_Pesos] = React.useState(0);
  const [Valor_Costos_Chile_Pesos, setValor_Costos_Chile_Pesos] = React.useState(0);
  const [Valor_Costos_Calabaza_Pesos, setValor_Costos_Calabaza_Pesos] = React.useState(0);
  const [Valor_Costos_Tomatillo_Pesos, setValor_Costos_Tomatillo_Pesos] = React.useState(0);
  //Dolares dinero individual
  const [Valor_Costos_Tomate_Dolares, setValor_Costos_Tomate_Dolares] = React.useState(0);
  const [Valor_Costos_Chile_Dolares, setValor_Costos_Chile_Dolares] = React.useState(0);
  const [Valor_Costos_Calabaza_Dolares, setValor_Costos_Calabaza_Dolares] = React.useState(0);
  const [Valor_Costos_Tomatillo_Dolares, setValor_Costos_Tomatillo_Dolares] = React.useState(0);
  //Total pesos + dolares
  const [Valor_Costos_Tomate_PesosyDoalres, setValor_Costos_Tomate_PesosyDoalres] = React.useState(0);
  const [Valor_Costos_Chile_PesosyDoalres, setValor_Costos_Chile_PesosyDoalres] = React.useState(0);
  const [Valor_Costos_Calabaza_PesosyDoalres, setValor_Costos_Calabaza_PesosyDoalres] = React.useState(0);
  const [Valor_Costos_Tomatillo_PesosyDoalres, setValor_Costos_Tomatillo_PesosyDoalres] = React.useState(0);

  //Costo total de las 4 lineas en MXN
  const [COSTOS_TOTAL_4_LINEAS_JUNTAS, setCOSTOS_TOTAL_4_LINEAS_JUNTAS] = React.useState(0);



//Sirve para filtrar todos los productos en sus respectivas lineas y por pesos o dolares
async function costos(Presentacion, Dias_a_buscar){
var Tomate_Pesos = [];
var Calabaza_Pesos = [];
var Chile_Pesos = [];
var Tomatillo_Pesos = [];

var Tomate_Dolares = [];
var Calabaza_Dolares = [];
var Chile_Dolares = [];
var Tomatillo_Dolares = [];

console.log(Logs_almacen_empaque);
/*     if(Presentacion == "Tomate"){
      if(valor.moneda=="Pesos"){
        Tomate_Pesos.push(valor);
      }else{
        Tomate_Dolares.push(valor);
      }
    } */

  //Con estos filtro los movimientos de cada presentacion
  if(Presentacion == "Tomate"){
    var logs_filtados = [];
    Dias_a_buscar.map((primer_dia)=>{
      Logs_almacen_empaque.map((data)=>{
        //Aqui filtro por las fechas seleccionadas los LOGS 
        if(data.fecha==primer_dia){
          //console.log("Hola")
          //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
            if((data.accion == "Retiro") || (data.accion == "retiro")){
              //console.log("Hola")
              //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                if(data.embarque == "Embarque"){
                  //console.log("Hola")
  
                }else{
                  //console.log("Hola")
                  //Aqui filtro por los movimientos por la zona donde se consumierón
                    if(data.Seccion == "Tomate"){
                      logs_filtados.push(data)
                    }
                }
             
            }
        }
      })
    })

    console.log(logs_filtados);

    logs_filtados.map((auxiliar500)=>{
      if(auxiliar500.moneda=="Pesos"){
        Tomate_Pesos.push(auxiliar500);
      }else{
        Tomate_Dolares.push(auxiliar500);
      }
    })
  }

    if(Presentacion == "Calabaza"){
      var logs_filtados = [];
      Dias_a_buscar.map((primer_dia)=>{
        Logs_almacen_empaque.map((data)=>{
          //Aqui filtro por las fechas seleccionadas los LOGS 
          if(data.fecha==primer_dia){
            //console.log("Hola")
            //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
              if((data.accion == "Retiro") || (data.accion == "retiro")){
                //console.log("Hola")
                //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                  if(data.embarque == "Embarque"){
                    //console.log("Hola")
    
                  }else{
                    //console.log("Hola")
                    //Aqui filtro por los movimientos por la zona donde se consumierón
                      if(data.Seccion == "Calabaza"){
                        logs_filtados.push(data)
                      }
                  }
               
              }
          }
        })
      })

      console.log(logs_filtados);

      logs_filtados.map((auxiliar500)=>{
        if(auxiliar500.moneda=="Pesos"){
          Calabaza_Pesos.push(auxiliar500);
        }else{
          Calabaza_Dolares.push(auxiliar500);
        }
      })


/*       Dias_a_buscar.map((primer_dia)=>{
        Logs_almacen_empaque.map((data)=>{
          //Aqui filtro por las fechas seleccionadas los LOGS 
          if(data.fecha==primer_dia){
            //console.log("Hola")
            //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
              if((data.accion == "Retiro") || (data.accion == "retiro")){
                //console.log("Hola")
                //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                  if(data.embarque == "Embarque"){
                    //console.log("Hola")
  
                  }else{
                    //console.log("Hola")
                    //Aqui filtro por los movimientos por la zona donde se consumierón
                      if(data.Seccion == "Calabaza"){
                        if(valor.moneda=="Pesos"){
                          Calabaza_Pesos.push(data);
                        }else{
                          Calabaza_Dolares.push(data);
                        }
                      }
                  }
               
              }
          }
        })
      }) */
    }

    if(Presentacion == "Chile"){
      var logs_filtados = [];
      Dias_a_buscar.map((primer_dia)=>{
        Logs_almacen_empaque.map((data)=>{
          //Aqui filtro por las fechas seleccionadas los LOGS 
          if(data.fecha==primer_dia){
            //console.log("Hola")
            //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
              if((data.accion == "Retiro") || (data.accion == "retiro")){
                //console.log("Hola")
                //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                  if(data.embarque == "Embarque"){
                    //console.log("Hola")
    
                  }else{
                    //console.log("Hola")
                    //Aqui filtro por los movimientos por la zona donde se consumierón
                      if(data.Seccion == "Chile"){
                        logs_filtados.push(data)
                      }
                  }
               
              }
          }
        })
      })

      console.log(logs_filtados);

      logs_filtados.map((auxiliar500)=>{
        if(auxiliar500.moneda=="Pesos"){
          Chile_Pesos.push(auxiliar500);
        }else{
          Chile_Dolares.push(auxiliar500);
        }
      })
    }

      if(Presentacion == "Tomatillo"){
      var logs_filtados = [];
      Dias_a_buscar.map((primer_dia)=>{
        Logs_almacen_empaque.map((data)=>{
          //Aqui filtro por las fechas seleccionadas los LOGS 
          if(data.fecha==primer_dia){
            //console.log("Hola")
            //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
              if((data.accion == "Retiro") || (data.accion == "retiro")){
                //console.log("Hola")
                //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                  if(data.embarque == "Embarque"){
                    //console.log("Hola")
    
                  }else{
                    //console.log("Hola")
                    //Aqui filtro por los movimientos por la zona donde se consumierón
                      if(data.Seccion == "Tomatillo"){
                        logs_filtados.push(data)
                      }
                  }
               
              }
          }
        })
      })

      console.log(logs_filtados);

      logs_filtados.map((auxiliar500)=>{
        if(auxiliar500.moneda=="Pesos"){
          Tomatillo_Pesos.push(auxiliar500);
        }else{
          Tomatillo_Dolares.push(auxiliar500);
        }
      })
    }


  if(Presentacion == "Tomate"){
  setDatos_Costos_Tomate_Pesos(Tomate_Pesos);
  setDatos_Costos_Tomate_Dolares(Tomate_Dolares);
  console.log(Tomate_Pesos)
  console.log(Tomate_Dolares)
  }
  if(Presentacion == "Calabaza"){
  setDatos_Costos_Calabaza_Pesos(Calabaza_Pesos);
  setDatos_Costos_Calabaza_Dolares(Calabaza_Dolares);
  console.log(Calabaza_Pesos)
  console.log(Calabaza_Dolares)
  }
  if(Presentacion == "Chile"){
  setDatos_Costos_Chile_Pesos(Chile_Pesos);
  setDatos_Costos_Chile_Dolares(Chile_Dolares);
  console.log(Chile_Pesos)
  console.log(Chile_Dolares)
  }

  if(Presentacion == "Tomatillo"){
  setDatos_Costos_Tomatillo_Pesos(Tomatillo_Pesos);
  setDatos_Costos_Tomatillo_Dolares(Tomatillo_Dolares);
  console.log(Tomatillo_Pesos)
  console.log(Tomatillo_Dolares)
  }
}

const [Precio_dolar_ingresado, setPrecio_dolar_ingresado] = React.useState(0);

function Valor_dolar(){
  const precio_dolar_actual = document.getElementById("precio_dolar").value;
  setPrecio_dolar_ingresado(precio_dolar_actual);
  //Tomate total en pesos, dolares y doles + pesos.
  const datostotal_Tomate_pesos= Datos_Costos_Tomate_Pesos.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Pesos_Tomate=0;
  for(let i of datostotal_Tomate_pesos) subtotal_Pesos_Tomate+=i;
  setValor_Costos_Tomate_Pesos(subtotal_Pesos_Tomate);

  const datostotal_Tomate_Dolares= Datos_Costos_Tomate_Dolares.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Dolares_Tomate=0;
  for(let i of datostotal_Tomate_Dolares) subtotal_Dolares_Tomate+=i;
  setValor_Costos_Tomate_Dolares(subtotal_Dolares_Tomate);
  const dolarapeso_Tomate = precio_dolar_actual*subtotal_Dolares_Tomate;
  setValor_Costos_Tomate_PesosyDoalres(subtotal_Pesos_Tomate+dolarapeso_Tomate);
  var total_costos_Tomate_MXN=subtotal_Pesos_Tomate+dolarapeso_Tomate;

  //Calabaza total en pesos, dolares y doles + pesos.
  const datostotal_Calabaza_pesos= Datos_Costos_Calabaza_Pesos.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Pesos_Calabaza=0;
  for(let i of datostotal_Calabaza_pesos) subtotal_Pesos_Calabaza+=i;
  setValor_Costos_Calabaza_Pesos(subtotal_Pesos_Calabaza);

  const datostotal_Calabaza_Dolares= Datos_Costos_Calabaza_Dolares.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Dolares_Calabaza=0;
  for(let i of datostotal_Calabaza_Dolares) subtotal_Dolares_Calabaza+=i;
  setValor_Costos_Calabaza_Dolares(subtotal_Dolares_Calabaza);
  const dolarapeso_Calabaza = precio_dolar_actual*subtotal_Dolares_Calabaza;
  setValor_Costos_Calabaza_PesosyDoalres(subtotal_Pesos_Calabaza+dolarapeso_Calabaza);
  var total_costos_Calabaza_MXN=subtotal_Pesos_Calabaza+dolarapeso_Calabaza;

  //Chile total en pesos, dolares y doles + pesos.
  const datostotal_Chile_pesos= Datos_Costos_Chile_Pesos.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Pesos_Chile=0;
  for(let i of datostotal_Chile_pesos) subtotal_Pesos_Chile+=i;
  setValor_Costos_Chile_Pesos(subtotal_Pesos_Chile);

  const datostotal_Chile_Dolares= Datos_Costos_Chile_Dolares.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Dolares_Chile=0;
  for(let i of datostotal_Chile_Dolares) subtotal_Dolares_Chile+=i;
  setValor_Costos_Chile_Dolares(subtotal_Dolares_Chile);
  const dolarapeso_Chile = precio_dolar_actual*subtotal_Dolares_Chile;
  setValor_Costos_Chile_PesosyDoalres(subtotal_Pesos_Chile+dolarapeso_Chile);
  var total_costos_Chile_MXN=subtotal_Pesos_Chile+dolarapeso_Chile;

  //Tomatillo total en pesos, dolares y doles + pesos.
  const datostotal_Tomatillo_pesos= Datos_Costos_Tomatillo_Pesos.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Pesos_Tomatillo=0;
  for(let i of datostotal_Tomatillo_pesos) subtotal_Pesos_Tomatillo+=i;
  setValor_Costos_Tomatillo_Pesos(subtotal_Pesos_Tomatillo);

  const datostotal_Tomatillo_Dolares= Datos_Costos_Tomatillo_Dolares.map(cantidad=> (cantidad.Cantidad_retirada)*(cantidad.precio))
  var subtotal_Dolares_Tomatillo=0;
  for(let i of datostotal_Tomatillo_Dolares) subtotal_Dolares_Tomatillo+=i;
  setValor_Costos_Tomatillo_Dolares(subtotal_Dolares_Tomatillo);
  const dolarapeso_Tomatillo = precio_dolar_actual*subtotal_Dolares_Tomatillo;
  setValor_Costos_Tomatillo_PesosyDoalres(subtotal_Pesos_Tomatillo+dolarapeso_Tomatillo);
  var total_costos_Tomatillo_MXN=subtotal_Pesos_Tomatillo+dolarapeso_Tomatillo;

  var TOTAL_COSTOS_4_Lineas=total_costos_Tomate_MXN+total_costos_Calabaza_MXN+total_costos_Chile_MXN+total_costos_Tomatillo_MXN;
  console.log(total_costos_Tomate_MXN, total_costos_Calabaza_MXN, total_costos_Chile_MXN, total_costos_Tomatillo_MXN)
  setCOSTOS_TOTAL_4_LINEAS_JUNTAS(TOTAL_COSTOS_4_Lineas);
}



 async function detalles_presentacion(producto, Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo ){
    var Detalles_data= [];
    const presentacion_data= producto;
    console.log(producto);
    //console.log(Datos);
    console.log(Datos_filtrados_Tomate);
    if(producto == "Tomate"){
        var categorias = [];
        var auxiliar = false;

        Datos_filtrados_Tomate.map((dato)=>{
          console.log("HOla")
          auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
              categorias.map((dato_categoria)=>{
                if(dato.nombre==dato_categoria.nombre){
                  console.log(categorias);
                  console.log(dato.cantidad,dato_categoria.cantidad)
                  categorias.map(valor=>{
                    if(valor.nombre==dato_categoria.nombre){
                      valor['cantidad'] = parseInt(dato.cantidad) + parseInt(dato_categoria.cantidad);
                    }
                    auxiliar = true; 
                  })
                }
              })//Map de las categorias o segundo map 
           if(auxiliar){
           }else{categorias.push(dato)}   
        })//Map de los productos a sumar o primer map
        Detalles_data = categorias;
    }
  
    if(producto == "Chile"){
      var categorias = [];
      var auxiliar = false;

      Datos_filtrados_Chile.map((dato)=>{
        console.log("HOla")
        auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
            categorias.map((dato_categoria)=>{
              if(dato.nombre==dato_categoria.nombre){
                console.log(categorias);
                console.log(dato.cantidad,dato_categoria.cantidad)
                categorias.map(valor=>{
                  if(valor.nombre==dato_categoria.nombre){
                    valor['cantidad'] = parseInt(dato.cantidad) + parseInt(dato_categoria.cantidad);
                  }
                  auxiliar = true; 
                })
              }
            })//Map de las categorias o segundo map 
         if(auxiliar){
         }else{categorias.push(dato)}   
      })//Map de los productos a sumar o primer map
      Detalles_data = categorias;
  }
  if(producto == "Calabaza"){
    var categorias = [];
    var auxiliar = false;

    Datos_filtrados_Calabaza.map((dato)=>{
      console.log("HOla")
      auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
          categorias.map((dato_categoria)=>{
            if(dato.nombre==dato_categoria.nombre){
              console.log(categorias);
              console.log(dato.cantidad,dato_categoria.cantidad)
              categorias.map(valor=>{
                if(valor.nombre==dato_categoria.nombre){
                  valor['cantidad'] = parseInt(dato.cantidad) + parseInt(dato_categoria.cantidad);
                }
                auxiliar = true; 
              })
            }
          })//Map de las categorias o segundo map 
       if(auxiliar){
       }else{categorias.push(dato)}   
    })//Map de los productos a sumar o primer map
    Detalles_data = categorias;
}
if(producto == "Tomatillo"){
  var categorias = [];
  var auxiliar = false;

  Datos_filtrados_Tomatillo.map((dato)=>{
    console.log("HOla")
    auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
        categorias.map((dato_categoria)=>{
          if(dato.nombre==dato_categoria.nombre){
            console.log(categorias);
            console.log(dato.cantidad,dato_categoria.cantidad)
            categorias.map(valor=>{
              if(valor.nombre==dato_categoria.nombre){
                valor['cantidad'] = parseInt(dato.cantidad) + parseInt(dato_categoria.cantidad);
              }
              auxiliar = true; 
            })
          }
        })//Map de las categorias o segundo map 
     if(auxiliar){
     }else{categorias.push(dato)}   
  })//Map de los productos a sumar o primer map
  Detalles_data = categorias;
}

    setDetalles_costos_on(false);
    setfiltropresentacion(Detalles_data);
    setModalDetalles(true);
    setDetalles_por_tamaño_ON(false);
    console.log(Detalles_data);
  }

  //----- COSTOS-------------------------------------------------------------
  const [Detalles_costos_on, setDetalles_costos_on] = React.useState(false);
  const [Data_Detalles_costos_on, setData_Detalles_costos_on] = React.useState([]);
  const [Costos_produccion_ON, setCostos_produccion_ON] = React.useState(false);
  
function Seleccionar_produccion_costos(prop){
  console.log(prop)
  setCosecha_seleccionada(prop)

var Logs_filtrados_por_linea = [];
  fechaSeleccionada_intervalo.map((primer_dia)=>{
    Logs_almacen_empaque.map((data)=>{
      //Aqui filtro por las fechas seleccionadas los LOGS 
      if(data.fecha==primer_dia){
        //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
          if((data.accion == "Retiro") || (data.accion == "retiro")){
                //Aqui filtro por los movimientos por la zona donde se consumierón
                  if(data.Seccion == Linea_seleccionada){
                    //Aqui filtro por el boton seleccionado o cosecha
                    if(data.Cosecha_seleccionada_retirada == prop){
                      //Con este ignoro los movimientos de consumo de cosecha ya que no se ocupan aqui
                      if(data.categoria == "Cosecha"){
                      }else{
                        Logs_filtrados_por_linea.push(data)
                      }
                      
                    }
                  }
          }
      }
    })
  })

console.log(Logs_filtrados_por_linea);

//Con esto creo el array el cual se mostrara en los detalles iniciandolos en 0
var costos_fechas = [];
for(var i=0; i<(fechaSeleccionada_intervalo.length); i++){
  var auxiliar212 = {
    Fecha: fechaSeleccionada_intervalo[i],
    carton: 0,
    bolsas: 0,
    tarimas: 0,
    esquineros: 0,
    mano_de_obra: 0,
    subtotal: 0,
    total: 0,
    }
    costos_fechas.push(auxiliar212);
}

costos_fechas.map((dato_fecha)=>{
    Logs_filtrados_por_linea.map((valor)=>{
      if(dato_fecha.Fecha == valor.fecha){
       
        //Con esto calculo el subtotal
        if(valor.moneda == "Pesos"){
          var auxiliar501 = (valor.precio) * (valor.Cantidad_retirada);
          var auxiliar111 = formatter.format(+(dato_fecha.subtotal) + +(auxiliar501));
          const str = (auxiliar111).toString();
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          const auxiliar11 = nuevaStr3.replace(",", "");
          dato_fecha["subtotal"] = auxiliar11;

           //Suma el carton
        if(valor.categoria == "Carton"){
          var auxiliar111 = formatter.format(+(dato_fecha.carton) + +(auxiliar501));
          const str = (auxiliar111).toString();
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          const auxiliar11 = nuevaStr3.replace(",", "");
          dato_fecha["carton"] = auxiliar11
        }
        //Suma la tarima, los esquineros y las bolsas
        if(valor.categoria == "Consumibles"){
          //Si el nombre incluye la palabra bolsa suma en bolsas
          console.log(valor.Producto_retirado.toLowerCase());
          if((valor.Producto_retirado.toLowerCase()).includes("bolsa".toLowerCase()))
            {
              var auxiliar111 = formatter.format(+(dato_fecha.bolsas) + +(auxiliar501));
              const str = (auxiliar111).toString();
              const nuevaStr = str.replace("$", "");
              const nuevaStr2 = nuevaStr.replace(",", "");
              const nuevaStr3 = nuevaStr2.replace(",", "");
              const auxiliar11 = nuevaStr3.replace(",", "");
              dato_fecha["bolsas"] = auxiliar11;
            }

          //Si el nombre incluye la palabra bolsa suma en tarimas
          if((valor.Producto_retirado.toLowerCase()).includes("tarima".toLowerCase()))
            {
              var auxiliar111 = formatter.format(+(dato_fecha.tarimas) + +(auxiliar501));
              const str = (auxiliar111).toString();
              const nuevaStr = str.replace("$", "");
              const nuevaStr2 = nuevaStr.replace(",", "");
              const nuevaStr3 = nuevaStr2.replace(",", "");
              const auxiliar11 = nuevaStr3.replace(",", "");
              dato_fecha["tarimas"] = auxiliar11;
            }
          
          //Si el nombre incluye la palabra bolsa suma en esquineros
          if((valor.Producto_retirado.toLowerCase()).includes("esquinero".toLowerCase()))
            {
              var auxiliar111 = formatter.format(+(dato_fecha.esquineros) + +(auxiliar501));
              const str = (auxiliar111).toString();
              const nuevaStr = str.replace("$", "");
              const nuevaStr2 = nuevaStr.replace(",", "");
              const nuevaStr3 = nuevaStr2.replace(",", "");
              const auxiliar11 = nuevaStr3.replace(",", "");
              dato_fecha["esquineros"] = auxiliar11;
            }

        }
        //Suma la mano de obra
        if(valor.categoria == "Mano de obra"){
          var auxiliar111 = formatter.format(+(dato_fecha.mano_de_obra) + +(auxiliar501));
          const str = (auxiliar111).toString();
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          const auxiliar11 = nuevaStr3.replace(",", "");
          dato_fecha["mano_de_obra"] = auxiliar11;
        }



        }else{
          //Este suma en dolares
          var auxiliar501 = (valor.precio) * (valor.Cantidad_retirada) * (Precio_dolar_ingresado);
          var auxiliar111 = formatter.format(+(dato_fecha.subtotal) + +(auxiliar501));
          const str = (auxiliar111).toString();
          const nuevaStr = str.replace("$", "");
          const nuevaStr2 = nuevaStr.replace(",", "");
          const nuevaStr3 = nuevaStr2.replace(",", "");
          const auxiliar11 = nuevaStr3.replace(",", "");
          dato_fecha["subtotal"] = auxiliar11;


           //Suma el carton
           if(valor.categoria == "Carton"){
            var auxiliar111 = formatter.format(+(dato_fecha.carton) + +(auxiliar501));
            const str = (auxiliar111).toString();
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            const auxiliar11 = nuevaStr3.replace(",", "");
            dato_fecha["carton"] = auxiliar11
          }
          //Suma la tarima, los esquineros y las bolsas
          if(valor.categoria == "Consumibles"){
            //Si el nombre incluye la palabra bolsa suma en bolsas
            console.log(valor.Producto_retirado.toLowerCase());
            if((valor.Producto_retirado.toLowerCase()).includes("bolsa".toLowerCase()))
              {
                var auxiliar111 = formatter.format(+(dato_fecha.bolsas) + +(auxiliar501));
                const str = (auxiliar111).toString();
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                const auxiliar11 = nuevaStr3.replace(",", "");
                dato_fecha["bolsas"] = auxiliar11;
              }
  
            //Si el nombre incluye la palabra bolsa suma en tarimas
            if((valor.Producto_retirado.toLowerCase()).includes("tarima".toLowerCase()))
              {
                var auxiliar111 = formatter.format(+(dato_fecha.tarimas) + +(auxiliar501));
                const str = (auxiliar111).toString();
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                const auxiliar11 = nuevaStr3.replace(",", "");
                dato_fecha["tarimas"] = auxiliar11;
              }
            
            //Si el nombre incluye la palabra bolsa suma en esquineros
            if((valor.Producto_retirado.toLowerCase()).includes("esquinero".toLowerCase()))
              {
                var auxiliar111 = formatter.format(+(dato_fecha.esquineros) + +(auxiliar501));
                const str = (auxiliar111).toString();
                const nuevaStr = str.replace("$", "");
                const nuevaStr2 = nuevaStr.replace(",", "");
                const nuevaStr3 = nuevaStr2.replace(",", "");
                const auxiliar11 = nuevaStr3.replace(",", "");
                dato_fecha["esquineros"] = auxiliar11;
              }
  
          }
          //Suma la mano de obra
          if(valor.categoria == "Mano de obra"){
            var auxiliar111 = formatter.format(+(dato_fecha.mano_de_obra) + +(auxiliar501));
            const str = (auxiliar111).toString();
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            const auxiliar11 = nuevaStr3.replace(",", "");
            dato_fecha["mano_de_obra"] = auxiliar11;
          }

        }
      }
    })
})

//---Con esto le agrego el acumulado al final---------------------------------------------

var Auxiliar_carton = 0;
var Auxiliar_bolsas = 0;
var Auxiliar_tarimas = 0;
var Auxiliar_esquineros = 0;
var Auxiliar_mano_de_obra = 0;
var Auxiliar_subtotal = 0;
var Auxiliar_total = 0;

costos_fechas.map((auxiliar250)=>{
  if(auxiliar250.carton == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_carton) + +(auxiliar250.carton));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_carton = auxiliar5101;
  }
  if(auxiliar250.bolsas == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_bolsas) + +(auxiliar250.bolsas));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_bolsas = auxiliar5101;
  }
  if(auxiliar250.tarimas == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_tarimas) + +(auxiliar250.tarimas));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_tarimas = auxiliar5101;
  }
  if(auxiliar250.esquineros == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_esquineros) + +(auxiliar250.esquineros));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_esquineros = auxiliar5101;
  }
  if(auxiliar250.mano_de_obra == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_mano_de_obra) + +(auxiliar250.mano_de_obra));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_mano_de_obra = auxiliar5101;
  }
  if(auxiliar250.subtotal == 0){}else{
    var auxiliar1010 = formatter.format(+(Auxiliar_total) + +(auxiliar250.subtotal));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_total = auxiliar5101;
  }
})

var auxiliar212 = {
  Fecha: "ACUMULADO",
  carton: Auxiliar_carton,
  bolsas: Auxiliar_bolsas,
  tarimas: Auxiliar_tarimas,
  esquineros: Auxiliar_esquineros,
  mano_de_obra: Auxiliar_mano_de_obra,
  subtotal: Auxiliar_subtotal,
  total: Auxiliar_total,
  }

  costos_fechas.push(auxiliar212);
//-------------------------------------------------------------------------------------------

/*     var categorias = [];
    var auxiliar = false;
    var auxiliar2 = [];
   //Aqui filtro por las fechas seleccionadas los LOGS 
    fechaSeleccionada_intervalo.map((primer_dia)=>{
      Logs_almacen_empaque.map((data)=>{
        //Aqui filtro por las fechas seleccionadas los LOGS 
        if(data.fecha==primer_dia){
          //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
            if((data.accion == "Retiro") || (data.accion == "retiro")){
              //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                if(data.embarque == "Embarque"){

                }else{
                  //Aqui filtro por los movimientos por la zona donde se consumierón
                    if(data.Seccion == "Calabaza"){
                      auxiliar2.push(data)
                    }
                }
             
            }
        }
      })
    auxiliar2.map((dato)=>{
      //Este if es para agregarle el precio a los que tienen el precio con la p mayuscula
      if(dato.precio == undefined){
            dato['precio'] = dato.Precio;       
      }
      auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
          categorias.map((dato_categoria)=>{
            if(dato.Producto_retirado==dato_categoria.Producto_retirado){
              categorias.map(valor=>{
                if(valor.Producto_retirado==dato_categoria.Producto_retirado){
                  valor['Cantidad_retirada'] = parseInt(dato.Cantidad_retirada) + parseInt(dato_categoria.Cantidad_retirada);
                  
                }
                auxiliar = true; 
              })
            }
          })//Map de las categorias o segundo map 
       if(auxiliar){
       }else{categorias.push(dato)}   
    })//Map de los productos a sumar o primer map

   });
 */


  console.log(costos_fechas);
  setData_Detalles_costos_on(costos_fechas);
  setDetalles_costos_on(true);
  setCostos_produccion_ON(false);

}


const [Linea_seleccionada, setLinea_seleccionada] = React.useState("");

async function detalles_costos(linea){

await getAllCosecha_pallets().then((Cosechas_palletsDB) => {
 //Con esto filtro las cosechas, si alguna cosecha incluye el nombre de linea se seleccionara si no se ignora 
  var cosechas_filtradas= [];
    Cosechas_palletsDB.map((dato)=>{
      if((dato.nombre.toLowerCase()).includes(linea.toLowerCase()))
      {
        cosechas_filtradas.push(dato);
      }
    })

   setCosechas(cosechas_filtradas);
  })

  setLinea_seleccionada(linea)
  setDetalles_costos_on(false);
  setCostos_produccion_ON(true);
  setModalDetalles(true);
  setDetalles_por_tamaño_ON(false);



  if(linea == "Tomate"){
    var categorias = [];
    var auxiliar = false;
    var auxiliar2 = [];
   //Aqui filtro por las fechas seleccionadas los LOGS 
    fechaSeleccionada_intervalo.map((primer_dia)=>{
      Logs_almacen_empaque.map((data)=>{
        //Aqui filtro por las fechas seleccionadas los LOGS 
        if(data.fecha==primer_dia){
          //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
            if((data.accion == "Retiro") || (data.accion == "retiro")){
              //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                if(data.embarque == "Embarque"){

                }else{
                  //Aqui filtro por los movimientos por la zona donde se consumierón
                    if(data.Seccion == "Tomate"){
                      auxiliar2.push(data)
                    }
                }
             
            }
        }
      })
    auxiliar2.map((dato)=>{
      //Este if es para agregarle el precio a los que tienen el precio con la p mayuscula
      if(dato.precio == undefined){
            dato['precio'] = dato.Precio;       
      }
      auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
          categorias.map((dato_categoria)=>{
            if(dato.Producto_retirado==dato_categoria.Producto_retirado){
              categorias.map(valor=>{
                if(valor.Producto_retirado==dato_categoria.Producto_retirado){
                  valor['Cantidad_retirada'] = parseInt(dato.Cantidad_retirada) + parseInt(dato_categoria.Cantidad_retirada);
                  
                }
                auxiliar = true; 
              })
            }
          })//Map de las categorias o segundo map 
       if(auxiliar){
       }else{categorias.push(dato)}   
    })//Map de los productos a sumar o primer map

   });
   console.log(categorias);
   setData_Detalles_costos_on(categorias);
   setDetalles_costos_on(false);
   setCostos_produccion_ON(true);
   setModalDetalles(true);
   setDetalles_por_tamaño_ON(false);
  }

  if(linea == "Chile"){
    var categorias = [];
    var auxiliar = false;
    var auxiliar2 = [];
   //Aqui filtro por las fechas seleccionadas los LOGS 
    fechaSeleccionada_intervalo.map((primer_dia)=>{
      Logs_almacen_empaque.map((data)=>{
        //Aqui filtro por las fechas seleccionadas los LOGS 
        if(data.fecha==primer_dia){
          //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
            if((data.accion == "Retiro") || (data.accion == "retiro")){
              //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                if(data.embarque == "Embarque"){

                }else{
                  //Aqui filtro por los movimientos por la zona donde se consumierón
                    if(data.Seccion == "Chile"){
                      auxiliar2.push(data)
                    }
                }
             
            }
        }
      })
    auxiliar2.map((dato)=>{
      //Este if es para agregarle el precio a los que tienen el precio con la p mayuscula
      if(dato.precio == undefined){
            dato['precio'] = dato.Precio;       
      }
      auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
          categorias.map((dato_categoria)=>{
            if(dato.Producto_retirado==dato_categoria.Producto_retirado){
              categorias.map(valor=>{
                if(valor.Producto_retirado==dato_categoria.Producto_retirado){
                  valor['Cantidad_retirada'] = parseInt(dato.Cantidad_retirada) + parseInt(dato_categoria.Cantidad_retirada);
                  
                }
                auxiliar = true; 
              })
            }
          })//Map de las categorias o segundo map 
       if(auxiliar){
       }else{categorias.push(dato)}   
    })//Map de los productos a sumar o primer map

   });
   console.log(categorias);
   setData_Detalles_costos_on(categorias);
   setDetalles_costos_on(false);
   setCostos_produccion_ON(true);
   setModalDetalles(true);
   setDetalles_por_tamaño_ON(false);

  }

  if(linea == "Tomatillo"){
    var categorias = [];
    var auxiliar = false;
    var auxiliar2 = [];
   //Aqui filtro por las fechas seleccionadas los LOGS 
    fechaSeleccionada_intervalo.map((primer_dia)=>{
      Logs_almacen_empaque.map((data)=>{
        //Aqui filtro por las fechas seleccionadas los LOGS 
        if(data.fecha==primer_dia){
          //Aqui filtro por los movimientos que sean de RETIRO y elimina los que son de AGREGAR
            if((data.accion == "Retiro") || (data.accion == "retiro")){
              //Aqui filtro por los movimientos que sean de embarque ya que los retiros de productos terminados del almacen no cuentan.
                if(data.embarque == "Embarque"){

                }else{
                  //Aqui filtro por los movimientos por la zona donde se consumierón
                    if(data.Seccion == "Tomatillo"){
                      auxiliar2.push(data)
                    }
                }
             
            }
        }
      })
    auxiliar2.map((dato)=>{
      //Este if es para agregarle el precio a los que tienen el precio con la p mayuscula
      if(dato.precio == undefined){
            dato['precio'] = dato.Precio;       
      }
      auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
          categorias.map((dato_categoria)=>{
            if(dato.Producto_retirado==dato_categoria.Producto_retirado){
              categorias.map(valor=>{
                if(valor.Producto_retirado==dato_categoria.Producto_retirado){
                  valor['Cantidad_retirada'] = parseInt(dato.Cantidad_retirada) + parseInt(dato_categoria.Cantidad_retirada);
                  
                }
                auxiliar = true; 
              })
            }
          })//Map de las categorias o segundo map 
       if(auxiliar){
       }else{categorias.push(dato)}   
    })//Map de los productos a sumar o primer map

   });
   console.log(categorias);
   setData_Detalles_costos_on(categorias);
   setDetalles_costos_on(false);
   setCostos_produccion_ON(true);
   setModalDetalles(true);
   setDetalles_por_tamaño_ON(false);

  }
  
  
  
}

//Enviar costos a la pagina de gastos
function Guardar_total_castos(COSTOS_TOTAL_4_LINEAS_JUNTAS){
  const dato = parseFloat(COSTOS_TOTAL_4_LINEAS_JUNTAS);
    var costo= {
      valor: dato,
      nombre: "Costos de producción empaque",
      };
  añadir_total_costos(COSTOS_TOTAL_4_LINEAS_JUNTAS,costo);
}

//-------Fin costos----------------------------------------------------------------
const [Logs_almacen_empaque, setLogs_almacen_empaque] = React.useState([]);

async function Traer_Logs(){
  await getLogs_almacen_empaque().then((Logs) => {
    console.log("Logs")
    setLogs_almacen_empaque(Logs);
  })
}

//DETALLES PRODUCCION---------------------------------------------------------------------------------------------------
const [Detalles_produccion_ON, setDetalles_produccion_ON] = React.useState(false);
const [Cosechas, setCosechas] = React.useState([]);
const [Detalles_produccion_seleccionada, setDetalles_produccion_seleccionada] = React.useState(false);
const [Cosecha_seleccionada, setCosecha_seleccionada] = React.useState([]);
const [Productos_filtrados_por_linea, setProductos_filtrados_por_linea] = React.useState([]);
const [Detalles_Produccion_final, setDetalles_Produccion_final] = React.useState([]);
const [Detalles_por_tamaño_ON, setDetalles_por_tamaño_ON] = React.useState(false);
const [Detalles_por_tamaño_Data, setDetalles_por_tamaño_Data] = React.useState([]);

function Seleccionar_produccion(prop){
  console.log(prop);
  //Con este filtro por la cosecha seleccionada
  var Filtrados_por_cosecha = [];
  Productos_filtrados_por_linea.map((valor)=>{
    if(valor.Cosecha_seleccionada_retirada == prop){
      Filtrados_por_cosecha.push(valor)
    }
  })

  console.log(Filtrados_por_cosecha)
//-----------------------------------------------


//saber cuantas etiquetas diferentes existen
/* var Productos_filtrados_por_etiquetas = [];
  var etiquetas = [];
  var auxiliar = false;

  Filtrados_por_cosecha.map((dato)=>{
    auxiliar = false; //Se vuelve a mandar a false para que inicia asi en el otro producto sino, siempre sera true.
        etiquetas.map((dato_etiquetas)=>{
          if(dato.tamaño_mas_etiqueta==dato_etiquetas.tamaño_mas_etiqueta){
            etiquetas.map((valor)=>{
              auxiliar = true; 
            })
          }
        })//Map de las categorias o segundo map 
     if(auxiliar){
     }else{etiquetas.push(dato)}   
  })//Map de los productos a sumar o primer map
  Productos_filtrados_por_etiquetas = etiquetas;

console.log(Productos_filtrados_por_etiquetas); */

//-------------------------------------
//Genero un arreglo con la cantidad de dias seleccionados y con todos lso valores en 0
var arreglo_de_cantidad_de_dias = [];
for(var i=0; i<(fechaSeleccionada_intervalo.length); i++){
  var auxiliar212 = {
    Fecha: fechaSeleccionada_intervalo[i],
    KG_CAMPO: 0,
    X1_376: 0,
    X1_DCAESAR: 0,
    X1_FULGOR: 0,
    X2_376: 0,
    X2_FULGOR: 0,
    X2_DCAESAR: 0,
    X2_JULIUS: 0,
    X3_FULGOR: 0,
    X3_DCAESAR: 0,
    X3_JULIUS: 0,
    X4_FULGOR: 0,
    X4_IND: 0,
    X4_GENERICO: 0,
    Total_cajas: 0,
    Total_KG: 0,
    Rendimiento: 0,
    }
    arreglo_de_cantidad_de_dias.push(auxiliar212);
}
console.log(arreglo_de_cantidad_de_dias);
//--------------------------------------------------
//Con esto sumo el total de cajas por cada etiqueta
var total_cajas_por_todas_etiquetas = 0;
var total_KG_por_todas_etiquetas = 0;
arreglo_de_cantidad_de_dias.map((dato_arreglo)=>{
  //Inicio en 0 el total por cada dia
  total_cajas_por_todas_etiquetas = 0;
  total_KG_por_todas_etiquetas = 0;

  Filtrados_por_cosecha.map((dato2)=>{
    //Aqui filtro por fechas para sumar
    if(dato_arreglo.Fecha == dato2.fecha){
      if(dato2.tamaño_mas_etiqueta == "1X 376"){
        dato_arreglo['X1_376'] = parseInt(dato_arreglo.X1_376) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
        var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "1X DCAESAR"){
        dato_arreglo['X1_DCAESAR'] = parseInt(dato_arreglo.X1_DCAESAR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "1X FULGOR"){
        dato_arreglo['X1_FULGOR'] = parseInt(dato_arreglo.X1_FULGOR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "2X 376"){
        dato_arreglo['X2_376'] = parseInt(dato_arreglo.X2_376) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "2X FULGOR"){
        dato_arreglo['X2_FULGOR'] = parseInt(dato_arreglo.X2_FULGOR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "2X DCAESAR"){
        dato_arreglo['X2_DCAESAR'] = parseInt(dato_arreglo.X2_DCAESAR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "2X JULIUS"){
        dato_arreglo['X2_JULIUS'] = parseInt(dato_arreglo.X2_JULIUS) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "3X FULGOR"){
        dato_arreglo['X3_FULGOR'] = parseInt(dato_arreglo.X3_FULGOR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "3X DCAESAR"){
        dato_arreglo['X3_DCAESAR'] = parseInt(dato_arreglo.X3_DCAESAR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "3X JULIUS"){
        dato_arreglo['X3_JULIUS'] = parseInt(dato_arreglo.X3_JULIUS) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "4X FULGOR"){
        dato_arreglo['X4_FULGOR'] = parseInt(dato_arreglo.X4_FULGOR) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "4X IND"){
        dato_arreglo['X4_IND'] = parseInt(dato_arreglo.X4_IND) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
      if(dato2.tamaño_mas_etiqueta == "4X GENERICO"){
        dato_arreglo['X4_GENERICO'] = parseInt(dato_arreglo.X4_GENERICO) + parseInt(dato2.cantidad);
        total_cajas_por_todas_etiquetas = parseInt(total_cajas_por_todas_etiquetas) + parseInt(dato2.cantidad);
                var auxiliar111 = formatter.format(+(total_KG_por_todas_etiquetas) + +(dato2.Total_KG_retirados));
        const str = (auxiliar111).toString();
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        const auxiliar11 = nuevaStr3.replace(",", "");
        total_KG_por_todas_etiquetas = auxiliar11;
      }
    }

  })//Fin segundo map
  //Con esto sumo todas las cajas por dias.
  dato_arreglo['total_cajas_por_todas_etiquetas'] =  total_cajas_por_todas_etiquetas;
  dato_arreglo['total_KG_por_todas_etiquetas'] =  total_KG_por_todas_etiquetas;
})//Fin primer map



//-----Con este le agrego los KG agregados por cada dia de la fecha

arreglo_de_cantidad_de_dias.map((valorKG)=>{
  Logs_almacen_empaque.map((datoLog)=>{
    if(valorKG.Fecha == datoLog.fecha){
      if(datoLog.accion == "Agregar"){
        if(datoLog.Producto_retirado == prop){
          valorKG['KG_CAMPO'] = datoLog.Cantidad_agregada;
          //Con esto saco el rendimiento en %
          var auxuliar500 = (valorKG.total_KG_por_todas_etiquetas)/(datoLog.Cantidad_agregada);
          valorKG['Rendimiento'] = Number.parseFloat(auxuliar500 * 100).toFixed(2)+"%";
        }
      }
    }
  })
})
//-----------------------------------------------------------------

//---Con esto le agrego el acumulado al final---------------------------------------------

var Auxiliar_KG_CAMPO = 0;
var Auxiliar_X1_376 = 0;
var Auxiliar_X1_DCAESAR = 0;
var Auxiliar_X1_FULGOR = 0;
var Auxiliar_X2_376 = 0;
var Auxiliar_X2_FULGOR = 0;
var Auxiliar_X2_DCAESAR = 0;
var Auxiliar_X2_JULIUS = 0;
var Auxiliar_X3_FULGOR = 0;
var Auxiliar_X3_DCAESAR = 0;
var Auxiliar_X3_JULIUS = 0;
var Auxiliar_X4_FULGOR = 0;
var Auxiliar_X4_IND = 0;
var Auxiliar_X4_GENERICO = 0;
var Auxiliar_Total_cajas = 0;
var Auxiliar_Total_KG = 0;
var Auxiliar_Rendimiento = 0;

arreglo_de_cantidad_de_dias.map((auxiliar250)=>{
  if(auxiliar250.KG_CAMPO == ""){ 
  }else{
    var auxiliar1010 = formatter.format(+(Auxiliar_KG_CAMPO) + +(auxiliar250.KG_CAMPO));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_KG_CAMPO = auxiliar5101;
  }
  if(auxiliar250.X1_376 == ""){}else{Auxiliar_X1_376 = parseInt(Auxiliar_X1_376) + parseInt(auxiliar250.X1_376)}
  if(auxiliar250.X1_DCAESAR == ""){}else{Auxiliar_X1_DCAESAR = parseInt(Auxiliar_X1_DCAESAR) + parseInt(auxiliar250.X1_DCAESAR)}
  if(auxiliar250.X1_FULGOR == ""){}else{Auxiliar_X1_FULGOR = parseInt(Auxiliar_X1_FULGOR) + parseInt(auxiliar250.X1_FULGOR)}
  if(auxiliar250.X2_376 == ""){}else{Auxiliar_X2_376 = parseInt(Auxiliar_X2_376) + parseInt(auxiliar250.X2_376)}
  if(auxiliar250.X2_FULGOR == ""){}else{Auxiliar_X2_FULGOR = parseInt(Auxiliar_X2_FULGOR) + parseInt(auxiliar250.X2_FULGOR)}
  if(auxiliar250.X2_DCAESAR == ""){}else{Auxiliar_X2_DCAESAR = parseInt(Auxiliar_X2_DCAESAR) + parseInt(auxiliar250.X2_DCAESAR)}
  if(auxiliar250.X2_JULIUS == ""){}else{Auxiliar_X2_JULIUS = parseInt(Auxiliar_X2_JULIUS) + parseInt(auxiliar250.X2_JULIUS);}
  if(auxiliar250.X3_FULGOR == ""){}else{Auxiliar_X3_FULGOR = parseInt(Auxiliar_X3_FULGOR) + parseInt(auxiliar250.X3_FULGOR)}
  if(auxiliar250.X3_DCAESAR == ""){}else{Auxiliar_X3_DCAESAR = parseInt(Auxiliar_X3_DCAESAR) + parseInt(auxiliar250.X3_DCAESAR)}
  if(auxiliar250.X3_JULIUS == ""){}else{Auxiliar_X3_JULIUS = parseInt(Auxiliar_X3_JULIUS) + parseInt(auxiliar250.X3_JULIUS)}
  if(auxiliar250.X4_FULGOR == ""){}else{Auxiliar_X4_FULGOR = parseInt(Auxiliar_X4_FULGOR) + parseInt(auxiliar250.X4_FULGOR)}
  if(auxiliar250.X4_IND == ""){}else{Auxiliar_X4_IND = parseInt(Auxiliar_X4_IND) + parseInt(auxiliar250.X4_IND)}
  if(auxiliar250.X4_GENERICO == ""){}else{Auxiliar_X4_GENERICO = parseInt(Auxiliar_X4_GENERICO) + parseInt(auxiliar250.X4_GENERICO)}
  if(auxiliar250.total_cajas_por_todas_etiquetas == ""){}else{Auxiliar_Total_cajas = parseInt(Auxiliar_Total_cajas) + parseInt(auxiliar250.total_cajas_por_todas_etiquetas)}
  if(auxiliar250.total_KG_por_todas_etiquetas == ""){
  }else{
    var auxiliar1010 = formatter.format(+(Auxiliar_Total_KG) + +(auxiliar250.total_KG_por_todas_etiquetas));
    var str = (auxiliar1010).toString();
    var nuevaStr = str.replace("$", "");
    var nuevaStr2 = nuevaStr.replace(",", "");
    var nuevaStr3 = nuevaStr2.replace(",", "");
    var auxiliar5101 = nuevaStr3.replace(",", "");
    Auxiliar_Total_KG = auxiliar5101;
  }
})

var auxiliar212 = {
  Fecha: "ACUMULADO",
  KG_CAMPO: Auxiliar_KG_CAMPO,
  X1_376: Auxiliar_X1_376,
  X1_DCAESAR: Auxiliar_X1_DCAESAR,
  X1_FULGOR: Auxiliar_X1_FULGOR,
  X2_376: Auxiliar_X2_376,
  X2_FULGOR: Auxiliar_X2_FULGOR,
  X2_DCAESAR: Auxiliar_X2_DCAESAR,
  X2_JULIUS: Auxiliar_X2_JULIUS,
  X3_FULGOR: Auxiliar_X3_FULGOR,
  X3_DCAESAR: Auxiliar_X3_DCAESAR,
  X3_JULIUS: Auxiliar_X3_JULIUS,
  X4_FULGOR: Auxiliar_X4_FULGOR,
  X4_IND: Auxiliar_X4_IND,
  X4_GENERICO: Auxiliar_X4_GENERICO,
  total_cajas_por_todas_etiquetas: Auxiliar_Total_cajas,
  total_KG_por_todas_etiquetas: Auxiliar_Total_KG,
  Rendimiento: Auxiliar_Rendimiento,
  }

  arreglo_de_cantidad_de_dias.push(auxiliar212);
//-------------------------------------------------------------------------------------------
//--Con este le agrego el rendimiento, se agrega aparte ya que primero tengo que tener el total de todo antes de sacar la cuenta
arreglo_de_cantidad_de_dias.map((auxiliar777)=>{
  if(auxiliar777.Fecha == "ACUMULADO"){
    var auxiliar2000 = (auxiliar777.total_KG_por_todas_etiquetas)/(auxiliar777.KG_CAMPO)
    auxiliar777['Rendimiento'] = Number.parseFloat(auxiliar2000 * 100).toFixed(2) + "%";
    
  }
})
//------------------------------------------------------------------------------------------------------------------------------

//--Con esto borro todos los 0 y les asigno un espacio para que no aparezcan en el resumen
arreglo_de_cantidad_de_dias.map((dato10)=>{
  if(dato10.KG_CAMPO == 0){ dato10['KG_CAMPO'] = "";}
  if(dato10.X1_376 == 0){ dato10['X1_376'] = "";}
  if(dato10.X1_DCAESAR == 0){ dato10['X1_DCAESAR'] = "";}
  if(dato10.X1_FULGOR == 0){ dato10['X1_FULGOR'] = "";}
  if(dato10.X2_376 == 0){ dato10['X2_376'] = "";}
  if(dato10.X2_FULGOR == 0){ dato10['X2_FULGOR'] = "";}
  if(dato10.X2_DCAESAR == 0){ dato10['X2_DCAESAR'] = "";}
  if(dato10.X2_JULIUS == 0){ dato10['X2_JULIUS'] = "";}
  if(dato10.X3_FULGOR == 0){ dato10['X3_FULGOR'] = "";}
  if(dato10.X3_DCAESAR == 0){ dato10['X3_DCAESAR'] = "";}
  if(dato10.X3_JULIUS == 0){ dato10['X3_JULIUS'] = "";}
  if(dato10.X4_FULGOR == 0){ dato10['X4_FULGOR'] = "";}
  if(dato10.X4_IND == 0){ dato10['X4_IND'] = "";}
  if(dato10.X4_GENERICO == 0){ dato10['X4_GENERICO'] = "";}
  if(dato10.total_cajas_por_todas_etiquetas == 0){ dato10['total_cajas_por_todas_etiquetas'] = "";}
  if(dato10.total_KG_por_todas_etiquetas == 0){ dato10['total_KG_por_todas_etiquetas'] = "";}
  if(dato10.Rendimiento == 0){ dato10['Rendimiento'] = "";}
})
//----------------------------------------------------------------------------------------
//Filtrar por tamaños los productos para hacer el detallado por tamaño--------------------

//Con esto creo el array el cual se mostrara en los detalles iniciandolos en 0
var total_tamaños_fechas = [];
for(var i=0; i<(fechaSeleccionada_intervalo.length); i++){
  var auxiliar212 = {
    Fecha: fechaSeleccionada_intervalo[i],
    X1: 0,
    X2: 0,
    X3: 0,
    X4: 0,
    }
    total_tamaños_fechas.push(auxiliar212);
}

console.log(Filtrados_por_cosecha);
total_tamaños_fechas.map((dato_fecha)=>{
  Filtrados_por_cosecha.map((tamaño)=>{
    if(dato_fecha.Fecha == tamaño.fecha){
      if(tamaño.tamaño == "1X"){
        dato_fecha["X1"] = parseInt(dato_fecha.X1) + parseInt(tamaño.cantidad)
      }
      if(tamaño.tamaño == "2X"){
        dato_fecha["X2"] = parseInt(dato_fecha.X2) + parseInt(tamaño.cantidad)
      }
      if(tamaño.tamaño == "3X"){
        dato_fecha["X3"] = parseInt(dato_fecha.X3) + parseInt(tamaño.cantidad)
      }
      if(tamaño.tamaño == "4X"){
        dato_fecha["X4"] = parseInt(dato_fecha.X4) + parseInt(tamaño.cantidad)
      }
    }
  })
})

//Con esto le agrego la cantidad de cajas diarias que se sacaron ya previamiente, a cada fecha del nuevo arreglo

total_tamaños_fechas.map((auxiliar1111)=>{
  arreglo_de_cantidad_de_dias.map((auxiliar22)=>{
    if(auxiliar1111.Fecha == auxiliar22.Fecha){
      if(auxiliar22.total_cajas_por_todas_etiquetas == ""){
        auxiliar1111["Total_cajas"] = 0;
        auxiliar1111['X1_porciento'] = "0.00%";
        auxiliar1111['X2_porciento'] = "0.00%";
        auxiliar1111['X3_porciento'] = "0.00%";
        auxiliar1111['X4_porciento'] = "0.00%";
      }else{
        auxiliar1111["Total_cajas"] = auxiliar22.total_cajas_por_todas_etiquetas;
        
        var obtener_porcentaje_1X = (auxiliar1111.X1) * 100
        auxiliar1111['X1_porciento'] = Number.parseFloat(obtener_porcentaje_1X / auxiliar22.total_cajas_por_todas_etiquetas).toFixed(2) + "%";

        var obtener_porcentaje_2X = (auxiliar1111.X2) * 100
        auxiliar1111['X2_porciento'] = Number.parseFloat(obtener_porcentaje_2X / auxiliar22.total_cajas_por_todas_etiquetas).toFixed(2) + "%";
        
        var obtener_porcentaje_3X = (auxiliar1111.X3) * 100
        auxiliar1111['X3_porciento'] = Number.parseFloat(obtener_porcentaje_3X / auxiliar22.total_cajas_por_todas_etiquetas).toFixed(2) + "%";

        var obtener_porcentaje_4X = (auxiliar1111.X4) * 100
        auxiliar1111['X4_porciento'] = Number.parseFloat(obtener_porcentaje_4X / auxiliar22.total_cajas_por_todas_etiquetas).toFixed(2) + "%";
      }
    }
  })
})


setDetalles_por_tamaño_Data(total_tamaños_fechas)
console.log(total_tamaños_fechas);
//----------------------------------------------------------------------------------------





console.log(arreglo_de_cantidad_de_dias);
setDetalles_Produccion_final(arreglo_de_cantidad_de_dias);


  setCosecha_seleccionada(prop);
  setDetalles_produccion_ON(false);
  setDetalles_produccion_seleccionada(true);
  setDetalles_por_tamaño_ON(false)
}

async function Detaller_produccionMODAL(producto, Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo){

  if(producto == "Tomate"){
    setProductos_filtrados_por_linea(Datos_filtrados_Tomate);
  }
  if(producto == "Chile"){
    setProductos_filtrados_por_linea(Datos_filtrados_Chile);
  }

  if(producto == "Calabaza"){
    setProductos_filtrados_por_linea(Datos_filtrados_Calabaza);
  }

  if(producto == "Tomatillo"){
    setProductos_filtrados_por_linea(Datos_filtrados_Tomatillo);
  }

  setDetalles_costos_on(false);
  setModalDetalles(true);
  
  await getAllCosecha_pallets().then((Cosechas_palletsDB) => {
    console.log(Datos_filtrados_Calabaza)
    var cosechas_filtradas= [];
    Cosechas_palletsDB.map((dato)=>{
      if((dato.nombre.toLowerCase()).includes(producto.toLowerCase()))
      {
        cosechas_filtradas.push(dato);
      }
    })

    setCosechas(cosechas_filtradas)
    setDetalles_produccion_ON(true);
  })
  
}

function Detalles_por_tamaño(){
  setDetalles_produccion_seleccionada(false);
  setDetalles_produccion_ON(false);
  setDetalles_por_tamaño_ON(true);
  
}

//FIN DETALLES PRODUCCION----------------------------------------------------------------------

  React.useEffect(() => {
    actualizar();
    Traer_Logs();
  }, []);

  return (
  <div> 
    <Modal id="Modal_Logs" className="container-fluid" show={!ModalDetalles} onHide={() =>{}}>
      <Modal.Header>
        <Modal.Title>Seleccionar fechas dando click en la fecha y despues pulsar botón de "Aceptar"</Modal.Title>
        <Button variant="secondary" onClick={() => setModalSemanal(false)}>
          Salir
        </Button>
        {Auxiliar_boton_dolar&&(
          <div>
          <Button style={{ marginRight:"5px" }} onClick={() => Valor_dolar()}>
            Precio Dolar
          </Button>
          <input placeholder="" id="precio_dolar"/>
        </div>
        )}
        
        <hr/>
      </Modal.Header>
      <Modal.Body>
     {/*  <h6>Seleccionar fechas dando click en la fecha y despues pulsar ok.</h6> */}
      <Stack direction="horizontal" className="justify-content-between">
        {/* <Form.Control
                id="fecha"
                placeholder="Ingresar fecha"
                type="text"
                className="mb-1"
                style={{ width: "40%", marginLeft:"10%" }}
              /> */}
          <h6>Fecha inicial</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
          <br/>   
          <h6>Fecha final</h6>
            <DatePicker value={fechaSeleccionada3} onChange={setfechaSeleccionada3}/>
          <br/>   

        <Button style={{ marginRight:"10%" }} onClick={()=>filtrado(Datos)}>
          Aceptar
        </Button>
        
      </Stack>  

    {TablaModalPrincipal?
    <div>
    <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Línea</th>
            <th>Cajas generadas</th>
            {/* <th>Pallets generadas</th> */}
            <th>Costo de producción</th>
            <th>Costo de producción DLS</th>
            <th>Total en MXN de costos pesos + DLS</th>
            <th>Acciones</th>
            
          </tr>
        </thead>
        <tbody>
         
              <tr>
                <td>Tomate</td>
                <td>{Pallets_Total_Tomate}</td>
                {/* <th>{Cajas_Total_Tomate}</th> */}
                <td>{formatter.format(Valor_Costos_Tomate_Pesos)}</td>
                <td>{formatter.format(Valor_Costos_Tomate_Dolares)}</td>
                <td>{formatter.format(Valor_Costos_Tomate_PesosyDoalres)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      Detaller_produccionMODAL("Tomate", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles producción
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      detalles_costos("Tomate");
                    }}
                  >
                    Detalles Costos
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Chile</td>
                <td>{Pallets_Total_Chile}</td>
                {/* <th>{Cajas_Total_Chile}</th> */}
                <td>{formatter.format(Valor_Costos_Chile_Pesos)}</td>
                <td>{formatter.format(Valor_Costos_Chile_Dolares)}</td>
                <td>{formatter.format(Valor_Costos_Chile_PesosyDoalres)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      Detaller_produccionMODAL("Chile", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles producción
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      detalles_costos("Chile");
                    }}
                  >
                    Detalles Costos
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Calabaza</td>
                <td>{Pallets_Total_Calabaza}</td>
                {/* <th>{Cajas_Total_Calabaza}</th> */}
                <td>{formatter.format(Valor_Costos_Calabaza_Pesos)}</td>
                <td>{formatter.format(Valor_Costos_Calabaza_Dolares)}</td>
                <td>{formatter.format(Valor_Costos_Calabaza_PesosyDoalres)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      Detaller_produccionMODAL("Calabaza", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles producción
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      detalles_costos("Calabaza");
                    }}
                  >
                    Detalles Costos
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Tomatillo</td>
                <td>{Pallets_Total_Tomatillo}</td>
                {/* <th>{Cajas_Total_Tomatillo}</th> */}
                <td>{formatter.format(Valor_Costos_Tomatillo_Pesos)}</td>
                <td>{formatter.format(Valor_Costos_Tomatillo_Dolares)}</td>
                <td>{formatter.format(Valor_Costos_Tomatillo_PesosyDoalres)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      Detaller_produccionMODAL("Tomatillo", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles Produccion
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      detalles_costos("Tomatillo");
                    }}
                  >
                    Detalles Costos
                  </Button>
                </td>
              </tr>
        </tbody>
      </Table>
    
     <Container id="Grafica">
     <div>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 16, marginLeft: "20%"  }}>Costos totales de las 4 líneas {formatter.format(COSTOS_TOTAL_4_LINEAS_JUNTAS)} MXN</p>
        <Button style={{ marginRight: "30%",fontSize: 14,marginTop: "0%" }} onClick={() => {Guardar_total_castos(COSTOS_TOTAL_4_LINEAS_JUNTAS);}}>Guardar</Button>        
      </Stack>
     </div> 
     
     {/* ESTA ES LA GRAFICA NOMAS QUE SE QUITO */}
{/*       <Bar
            data={state}
            options={{
              title:{
                display:true,
                responsive:true,
                text:'Average Rainfall per month',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          /> */}
      </Container>
    </div>
    :
    null
    }
      </Modal.Body>
    </Modal>

   {/* -----------------------------------Modal Detalles y costos-------------------------- */}
  
   <Modal id="Modal_Logs" className="container-fluid" show={ModalDetalles} onHide={() => {}}>
   <Modal.Header>
   {Detalles_costos_on? 
   <Modal.Title>Costos de {Cosecha_seleccionada}</Modal.Title>
   :
   <>
      {Detalles_produccion_seleccionada || Detalles_por_tamaño_ON? 
        <Modal.Title>Detalles de {Cosecha_seleccionada}</Modal.Title>
      :
      <Modal.Title>Elegir una opción para ver los detalles de la producción</Modal.Title>
      }
  </>
    }

     

     {Detalles_costos_on && (
      <p>Precio dolar ingresado {Precio_dolar_ingresado} MXN</p>
     )}

     <Button variant="secondary" onClick={() => salir_detalles_produccion()}>
       Regresar
     </Button>

     
     {Detalles_produccion_seleccionada && (
      <>
          <Button  onClick={() => Detalles_por_tamaño()}>
           Detalle por tamaño
          </Button>
{/*           <Button  onClick={() => salir_detalles_produccion()}>
           Detalle de pallets
         </Button> */}
      </>         
     )}
     <hr/>
     
   </Modal.Header>
   <Modal.Body>
    <>

  {Costos_produccion_ON && (
            <div id="Lista_cultivo_en_proceso" style={{ background: "white" }}>
                
                {Cosechas.map((datos,index)=>( 
                    <button id="boton_lista_cultivo_en_proceso" onClick={() => Seleccionar_produccion_costos((datos.nombre))} key={index}>{datos.nombre}</button>
                ))}
           
            </div>
   )}

   {Detalles_costos_on && (
   <Table id="Tabla_logs" className="container-fluid">
       <thead>
     <tr>
       <th>Fecha</th>
       <th>Cartón</th>
       <th>Bolsas</th>
       <th>Tarimas</th>
       <th>Esquineros</th>
       <th>Mano de obra</th>
       <th>Subtotal precio</th>
       <th>Total precio</th>
       
     </tr>
   </thead>
   <tbody>
     {
       Data_Detalles_costos_on.map((producto, index) => (
         <tr key={index}>
           <td>{producto.Fecha}</td>
           <td>{producto.carton}</td>
           <td>{producto.bolsas}</td>
           <td>{producto.tarimas}</td>
           <td>{producto.esquineros}</td>
           <td>{producto.mano_de_obra}</td>
           <td>{producto.subtotal}</td>
           <td>{producto.total}</td>

         </tr>
       ))}
   </tbody>
{/*    <thead>
     <tr>
       <th>Nombre del producto</th>
       <th>Cantidad usada</th>
       <th>Moneda</th>
       <th>Precio</th>
       <th>Subtotal precio</th>
       
     </tr>
   </thead>
   <tbody>
     {
       Data_Detalles_costos_on.map((producto, index) => (
         <tr key={index}>
           <td>{producto.Producto_retirado}</td>
           <td>{producto.Cantidad_retirada}</td>
           <td>{producto.moneda}</td>
           <td>{formatter.format(producto.precio)}</td>
           <td>{formatter.format(((producto.Cantidad_retirada)*(producto.precio)))}</td>
           <td>
           </td>
         </tr>
       ))}
   </tbody> */}
 </Table>
   )}

   {Detalles_produccion_ON && (
            <div id="Lista_cultivo_en_proceso" style={{ background: "white" }}>
                
                {Cosechas.map((datos,index)=>( 
                    <button id="boton_lista_cultivo_en_proceso" onClick={() => Seleccionar_produccion((datos.nombre))} key={index}>{datos.nombre}</button>
                ))}
           
            </div>
   )}

   {Detalles_produccion_seleccionada && (
        <Table id="Tabla_logs" className="container-fluid" style={{textAlign: "center"}}>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>KG AGREGADO</th>
              <th>1X 376</th>
              <th>1X DCAESAR</th>
              <th>1X FULGOR</th>
              <th>2X 376</th>
              <th>2X FULGOR</th>
              <th>2X DCAESAR</th>
              <th>2X JULIUS</th>
              <th>3X FULGOR</th>
              <th>3X DCAESAR</th>
              <th>3X JULIUS</th>
              <th>4X FULGOR</th>
              <th>4X IND</th>
              <th>4X GENERICO</th>
              <th>Total cajas</th>
              <th>Total KG</th>
              <th>Rendimiento KG</th>
              
            </tr>
          </thead>
          <tbody>
            {
              Detalles_Produccion_final.map((producto, index) => (
                <tr key={index} style={{textAlign: "center"}}>
                  <td>{producto.Fecha}</td>
                  <td>{producto.KG_CAMPO}</td>
                  <td>{producto.X1_376}</td>
                  <td>{producto.X1_DCAESAR}</td>
                  <td>{producto.X1_FULGOR}</td>
                  <td>{producto.X2_376}</td>
                  <td>{producto.X2_FULGOR}</td>
                  <td>{producto.X2_DCAESAR}</td>
                  <td>{producto.X2_JULIUS}</td>
                  <td>{producto.X3_FULGOR}</td>
                  <td>{producto.X3_DCAESAR}</td>
                  <td>{producto.X3_JULIUS}</td>
                  <td>{producto.X4_FULGOR}</td>
                  <td>{producto.X4_IND}</td>
                  <td>{producto.X4_GENERICO}</td>
                  <td>{producto.total_cajas_por_todas_etiquetas}</td>
                  <td>{producto.total_KG_por_todas_etiquetas}</td>
                  <td>{producto.Rendimiento}</td>
                </tr>
              ))}
          </tbody>
        </Table> 
   )}

   {Detalles_por_tamaño_ON && (
   <Table id="Tabla_logs" className="container-fluid" style={{textAlign: "center"}}>
   <thead>
     <tr>
       <th>Fecha</th>
       <th>Total de cajas</th>
       <th>1X cajas</th>
       <th>1X %</th>
       <th>2X cajas</th>
       <th>2X %</th>
       <th>3X cajas</th>
       <th>3X %</th>
       <th>4X cajas</th>
       <th>5X %</th>
       
       
     </tr>
   </thead>
   <tbody>
     {
       Detalles_por_tamaño_Data.map((producto, index) => (
         <tr key={index} style={{textAlign: "center"}}>
           <td>{producto.Fecha}</td>
           <td>{producto.Total_cajas}</td>
           <td>{producto.X1}</td>
           <td>{producto.X1_porciento}</td>
           <td>{producto.X2}</td>
           <td>{producto.X2_porciento}</td>
           <td>{producto.X3}</td>
           <td>{producto.X3_porciento}</td>
           <td>{producto.X4}</td>
           <td>{producto.X4_porciento}</td>
           <td>
           </td>
         </tr>
       ))}
   </tbody>
 </Table>
   )}

   </>
   </Modal.Body>
   <Modal.Footer>
     
     {/* <Button variant="secondary" onClick={() => setModalDiario(false)}>
          Salir
     </Button> */}
   </Modal.Footer>
 </Modal> 
 </div> 
  );
}

export default ModalProduccion_semanal;
