import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_Tamaño_cajas(infoProducto) {
  console.log(infoProducto);
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Manifiestos/Tamaños_cajas/Productos/");
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);      
}

export default añadir_Tamaño_cajas;
