import React from "react";
import { Modal, Stack, Form, Button, Table, Container} from "react-bootstrap";
import getProduccion_diaria from "../../functions/Produccion/Presentaciones/getProduccion_diaria";
import añadirInforme_diario_lineas from "../../functions/Produccion/Presentaciones/añadirInforme_diario_lineas";
import {DatePicker} from "@material-ui/pickers";
import "../../../../index.css";
//import {Bar} from "react-chartjs-2";


function ModalProduccion_diaria({
  ModalDiario,
  setModalDiario,
  actualizarEstadoProductos,
  usuario,
  pagina,
  Presentacion,
  setProductosPresentaciones,
}) {

  const [TablaModalPrincipal, setTablaModalPrincipal] = React.useState(false);

  const [Datos, setDatos] = React.useState([]);
  const [Datos_filtrados_Tomate, setDatos_filtrados_Tomate] = React.useState([]);
  const [Datos_filtrados_Chile, setDatos_filtrados_Chile] = React.useState([]);
  const [Datos_filtrados_Calabaza, setDatos_filtrados_Calabaza] = React.useState([]);
  const [Datos_filtrados_Tomatillo, setDatos_filtrados_Tomatillo] = React.useState([]);

  const [Pallets_Total_Tomate, setPallets_Total_Tomate] = React.useState(0);
  const [Cajas_Total_Tomate, setCajas_Total_Tomate] = React.useState(0);
  const [Pallets_Total_Chile, setPallets_Total_Chile] = React.useState(0);
  const [Cajas_Total_Chile, setCajas_Total_Chile] = React.useState(0);
  const [Pallets_Total_Calabaza, setPallets_Total_Calabaza] = React.useState(0);
  const [Cajas_Total_Calabaza, setCajas_Total_Calabaza] = React.useState(0);
  const [Pallets_Total_Tomatillo, setPallets_Total_Tomatillo] = React.useState(0);
  const [Cajas_Total_Tomatillo, setCajas_Total_Tomatillo] = React.useState(0);
  
  const [ModalDetalles, setModalDetalles] = React.useState(false);
  const [filtropresentacion, setfiltropresentacion] = React.useState([]);


//Produccion diaria grafia
const state = {
  labels: ['Tomate', 'Chile', 'Calabaza',
           'Tomatillo'],
  datasets: [
    {
      label: 'Producción diaria (pallets)',
      backgroundColor: [
      '#22B419',
      '#E0F625',
      '#E43C12',
      '#F17F14',
      '#146BF1'],
      hoverBackgroundColor: [
        '#187412',
        '#98A71D',
        '#962C12',
        '#975312',
        '#0D3C85'],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [Pallets_Total_Tomate, Pallets_Total_Chile, Pallets_Total_Calabaza, Pallets_Total_Tomatillo]
    },
    {
      label: 'Producción diaria (Cajas)',
      backgroundColor: [
      '#32EA27',
      '#E6F378',
      '#F07A5C',
      '#F4AA64',
      '#7BAAF3'],
      hoverBackgroundColor: [
        '#187412',
        '#98A71D',
        '#962C12',
        '#975312',
        '#0D3C85'],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [Cajas_Total_Tomate, Cajas_Total_Chile, Cajas_Total_Calabaza, Cajas_Total_Tomatillo]
    }
  ]
}
//-------------------------------------------------------------------------------------

  async function actualizar(Presentacion) {
    await getProduccion_diaria(Presentacion).then((productos) => {
      console.log(productos)
      setDatos(productos);  
     });
   }

   //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());
  const [fechaSeleccionada2, setfechaSeleccionada2] = React.useState("");

  function filtrado(Datos){
      //Esto sirve para la seleccion de la fecha
      let date =fechaSeleccionada;
      let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
      setfechaSeleccionada2(fecha_actual);
    const fecha_ingresada = fecha_actual;
    console.log(fecha_ingresada);
    console.log(Datos)
    var Informediario_Tomate= [];
    var Informediario_Chile= [];
    var Informediario_Calabaza= [];
    var Informediario_Tomatillo= [];
    
    Datos.map((data)=>{

      if(data.fecha==fecha_ingresada){
        if(data.present=="Tomate"){
          Informediario_Tomate.push(data);
        }
      }

      if(data.fecha==fecha_ingresada){
        if(data.present=="Chile"){
          Informediario_Chile.push(data);
        }
      }

      if(data.fecha==fecha_ingresada){
        if(data.present=="Calabaza"){
          Informediario_Calabaza.push(data);
        }
      }

      if(data.fecha==fecha_ingresada){
        if(data.present=="Tomatillo"){
          Informediario_Tomatillo.push(data);
        }
      }
    })
    setDatos_filtrados_Tomate(Informediario_Tomate);
    setDatos_filtrados_Chile(Informediario_Chile);
    setDatos_filtrados_Calabaza(Informediario_Calabaza);
    setDatos_filtrados_Tomatillo(Informediario_Tomatillo);


    //Esto sirve para sacar el total de pallets y cajas en el dia generados
    //Tomate
    var datostotal_Tomate_Pallet= [];
    Informediario_Tomate.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Tomate_Pallet.push(valor.cantidad)}})
    let subtotal_Tomate_Pallet=0;
    for(let i of datostotal_Tomate_Pallet) subtotal_Tomate_Pallet+=parseInt(i);
    setPallets_Total_Tomate(subtotal_Tomate_Pallet);

    var datostotal_Tomate_Caja= [];
    Informediario_Tomate.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomate_Caja.push(valor.cantidad)}})
    let subtotal_Tomate_Caja=0;
    for(let i of datostotal_Tomate_Caja) subtotal_Tomate_Caja+=parseInt(i);
    setCajas_Total_Tomate(subtotal_Tomate_Caja);

    //Chile
    const datostotal_Chile_Pallet=[];
    Informediario_Chile.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Chile_Pallet.push(valor.cantidad)}})
    let subtotal_Chile_Pallet=0;
    for(let i of datostotal_Chile_Pallet) subtotal_Chile_Pallet+=parseInt(i);
    setPallets_Total_Chile(subtotal_Chile_Pallet);

    var datostotal_Chile_Caja= [];
    Informediario_Chile.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Chile_Caja.push(valor.cantidad)}})
    let subtotal_Chile_Caja=0;
    for(let i of datostotal_Chile_Caja) subtotal_Chile_Caja+=parseInt(i);
    setCajas_Total_Chile(subtotal_Chile_Caja);

    //Calabaza
    const datostotal_Calabaza_Pallet=[];
    Informediario_Calabaza.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Calabaza_Pallet.push(valor.cantidad)}})
    let subtotal_Calabaza_Pallet=0;
    for(let i of datostotal_Calabaza_Pallet) subtotal_Calabaza_Pallet+=parseInt(i);
    setPallets_Total_Calabaza(subtotal_Calabaza_Pallet);

    var datostotal_Calabaza_Caja= [];
    Informediario_Calabaza.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Calabaza_Caja.push(valor.cantidad)}})
    let subtotal_Calabaza_Caja=0;
    for(let i of datostotal_Calabaza_Caja) subtotal_Calabaza_Caja+=parseInt(i);
    setCajas_Total_Calabaza(subtotal_Calabaza_Caja);

    //Tomatillo
    const datostotal_Tomatillo_Pallet=[];
    Informediario_Tomatillo.map((valor)=>{ if(valor.unidad=="Pallet"){datostotal_Tomatillo_Pallet.push(valor.cantidad)}})
    let subtotal_Tomatillo_Pallet=0;
    for(let i of datostotal_Tomatillo_Pallet) subtotal_Tomatillo_Pallet+=parseInt(i);
    setPallets_Total_Tomatillo(subtotal_Tomatillo_Pallet);

    var datostotal_Tomatillo_Caja= [];
    Informediario_Tomatillo.map((valor)=>{ if(valor.unidad=="Caja"){datostotal_Tomatillo_Caja.push(valor.cantidad)}})
    let subtotal_Tomatillo_Caja=0;
    for(let i of datostotal_Tomatillo_Caja) subtotal_Tomatillo_Caja+=parseInt(i);
    setCajas_Total_Tomatillo(subtotal_Tomatillo_Caja);
  
    //console.log(subtotal_Tomate, subtotal_Chile, subtotal_Calabaza, subtotal_Tomatillo);
    setTablaModalPrincipal(true);

  } 

  function detalles_presentacion(producto, Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo ){
    var Detalles_data= [];
    const presentacion_data= producto;
    console.log(producto);
    //console.log(Datos);
    console.log(Datos_filtrados_Tomate);

    if(presentacion_data=="Tomate"){
      Datos_filtrados_Tomate.map((data)=>{
          Detalles_data.push(data);
    })
    }

    if(presentacion_data=="Chile"){
      Datos_filtrados_Chile.map((data)=>{
          Detalles_data.push(data);
    })
    }

    if(presentacion_data=="Calabaza"){
      Datos_filtrados_Calabaza.map((data)=>{
          Detalles_data.push(data);
    })
    }

    if(presentacion_data=="Tomatillo"){
      Datos_filtrados_Tomatillo.map((data)=>{
          Detalles_data.push(data);
    })
    }

    setfiltropresentacion(Detalles_data);
    setModalDetalles(true);
    console.log(Detalles_data);

    
  }

  React.useEffect(() => {
    actualizar();
  }, []);

  return (
  <div> 
    <Modal id="Modal_Logs" className="container-fluid" show={!ModalDetalles} onHide={() =>{}}>
      <Modal.Header>
        <Modal.Title>Favor de ingresar fecha a buscar</Modal.Title>
        
        <hr/>
        
      </Modal.Header>
      <Modal.Body>
      <Stack direction="horizontal" className="justify-content-between">
        {/* <Form.Control
                id="fecha"
                placeholder="Ingresar fecha"
                type="text"
                className="mb-1"
                style={{ width: "40%", marginLeft:"10%" }}
              /> */}
          <h6>Seleccionar fecha dando click y despues pulsar boton aceptar</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
          <br/>   
        <Button style={{ marginRight:"10%" }} onClick={()=>filtrado(Datos)}>
          Aceptar
        </Button>
      </Stack>  

    {TablaModalPrincipal?
    <div>
    <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Línea</th>
            <th>Pallets generadas</th>
            <th>Cajas generadas</th>
            <th>Fecha</th>
            
          </tr>
        </thead>
        <tbody>
         
              <tr>
                <td>Tomate</td>
                <td>{Pallets_Total_Tomate}</td>
                <th>{Cajas_Total_Tomate}</th>
                <td>{fechaSeleccionada2}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      detalles_presentacion("Tomate", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Chile</td>
                <td>{Pallets_Total_Chile}</td>
                <th>{Cajas_Total_Chile}</th>
                <td>{fechaSeleccionada2}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      detalles_presentacion("Chile", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Calabaza</td>
                <td>{Pallets_Total_Calabaza}</td>
                <th>{Cajas_Total_Calabaza}</th>
                <td>{fechaSeleccionada2}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      detalles_presentacion("Calabaza", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Tomatillo</td>
                <td>{Pallets_Total_Tomatillo}</td>
                <th>{Cajas_Total_Tomatillo}</th>
                <td>{fechaSeleccionada2}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => {
                      detalles_presentacion("Tomatillo", Datos_filtrados_Tomate, Datos_filtrados_Chile, Datos_filtrados_Calabaza, Datos_filtrados_Tomatillo );
                    }}
                  >
                    Detalles
                  </Button>
                </td>
              </tr>
        </tbody>
      </Table>
    
      {/* <Container id="Grafica">
      <Bar
            data={state}
            options={{
              title:{
                display:true,
                responsive:true,
                text:'Average Rainfall per month',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          />
      </Container> */}
    </div>
    :
    null
    }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalDiario(false)}>
          Salir
        </Button>
      </Modal.Footer>
    </Modal>
   {/* -----------------------------------Modal Detalles-------------------------- */}
  
   <Modal id="Modal_Logs" className="container-fluid" show={ModalDetalles} onHide={() => {}}>
   <Modal.Header>
     <Modal.Title>Detalles de la producción línea</Modal.Title>
     
     <hr/>
     
   </Modal.Header>
   <Modal.Body>
   {/* <Stack direction="horizontal" className="justify-content-between">
     <Form.Control
             id="fecha"
             placeholder="Ingresar fecha"
             type="text"
             className="mb-1"
             style={{ width: "40%", marginLeft:"10%" }}
           />
     <Button style={{ marginRight:"10%" }} onClick={()=>filtrado(Datos)}>
       Aceptar
     </Button>
   </Stack>  */} 

   <Table id="Tabla_logs" className="container-fluid">
     <thead>
       <tr>
         {/* <th>#</th> */}
         <th>Presentación</th>
         <th>Pallets generadas</th>
         <th>Cajas generadas</th>
         <th>Fecha</th>
         
       </tr>
     </thead>
     <tbody>
       {
         filtropresentacion.map((producto, index) => (
           <tr key={index}>
             {/* <td>{index + 1}</td> */}
             <td>{producto.nombre}</td>
             <td>{producto.cantidad}</td>
             <td>NA</td>
             <td>{producto.fecha}</td>
             <td>
             </td>
           </tr>
         ))}
     </tbody>
   </Table>
   </Modal.Body>
   <Modal.Footer>
     <Button variant="secondary" onClick={() => setModalDetalles(false)}>
       Regresar
     </Button>
     {/* <Button variant="secondary" onClick={() => setModalDiario(false)}>
          Salir
     </Button> */}
   </Modal.Footer>
 </Modal> 
 </div> 
  );
}

export default ModalProduccion_diaria;
