import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../functions/añadirProducto";
import filtrarDatosProducto from "../functions/filtrarDatosProducto";
import React from "react";
import getAllProducts from "../functions/getAllProducts";
import getProductos from "../functions/Ordenes_compra/getProductos";
import getFolio_anterior from "../functions/Ordenes_compra/getFolio_anterior";
import añadirFolio from "../functions/Ordenes_compra/añadirFolio";
import {DatePicker} from "@material-ui/pickers";
import getBotones_gastos from "../functions/Ordenes_compra/getBotones_gastos";
import getOrden_agregada from "../functions/Ordenes_compra/getOrden_agregada";

function ModalAñadir_ordenes_principal({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar, 
  usuario,
  setProductos,
}) {


//PRUEBA PARA TRAER EL PRODUCTO AGREGADO Y PONERLO SOLO EL AGREGADO
async function traer_orden_nueva(nombre){
  await getOrden_agregada(nombre).then((OrdenNuevaDB) => {
      console.log(OrdenNuevaDB);
      setProductos(OrdenNuevaDB);
  });
}
  
//Traer el folio anterior para crear un folio nuevo.
const [Folio_nuevo, setFolio_nuevo] = React.useState([]);

async function traer_folio_nuevo(){
  await getFolio_anterior().then((Folio_anteriorDB) => {
    setFolio_nuevo(Folio_anteriorDB[0].valor);
    console.log(Folio_anteriorDB[0].valor);
  });
}

  //Traer productos de base de datos
  const [Datosproveedores, setDatosproducto] = React.useState([]);
  async function productos_basededatos(){
    await getProductos().then((proveedoresDB) => {
      setDatosproducto(proveedoresDB);
      //console.log(proveedoresDB)
    });
  }

 //Traer todos los botones de gastos
 const [Gastos_Botones, setGastos_Botones] = React.useState([]);
 async function botones_gastos_traer(){
   await getBotones_gastos().then((Botones_GastosDB) => {
    setGastos_Botones(Botones_GastosDB);
     //console.log(proveedoresDB)
   });
 }

  //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  function añadirProductoModal() {
    //Esto sirve para la seleccion de la fecha
    let date =fechaSeleccionada;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
    let fecha3 = date;

    //Esto sirve para aumentar en 1 el folio anterior
    const dato_folio_nuevo = parseInt(Folio_nuevo)+1;
    var folio_editar= {
      valor: dato_folio_nuevo,
      };
    console.log(dato_folio_nuevo);
    console.log(folio_editar)
    añadirFolio(folio_editar);
    
    //obtener infor del formulario
    const fecha = fecha_actual;
    //const almacen = document.getElementById("almacen").value;
    const proveedor = document.getElementById("proveedor").value;
    const moneda = document.getElementById("moneda").value;
    const solicitante = document.getElementById("solicitante").value;
    const cargo = document.getElementById("cargo").value;
    const tipo_compra = document.getElementById("tipo_compra").value;
    const factura = "";
    var total_orden = "0";
    const nombre = dato_folio_nuevo.toString();

    // enviar informacion a firebase
    const infoProducto = { fecha, nombre ,proveedor, moneda, solicitante, cargo, tipo_compra, factura, total_orden, fecha3};
    //const infoProducto = { fecha, almacen, proveedor, moneda, subtotal, total, iva, sku };
    añadirProducto(infoProducto, usuario.email);
    // cerrar modal
    setProductoEditar(null);
    traer_orden_nueva(nombre)
    //actualizarEstadoProductos();
    setIsModalAñadir(false); 
  }

  
  React.useEffect(() => {
    /* actualizarEstadoProductos2(); */
    //traer_orden_nueva();
    productos_basededatos();
    traer_folio_nuevo();
    botones_gastos_traer();
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => {
    setIsModalAñadir(false);
    setProductoEditar(null);
    }}>
      <Modal.Header>
        <Modal.Title>Añadir orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
            {/* <br/>
            <h6>Seleccionar almacen</h6>
            <select id="almacen" type="text">
              <option>NA</option>
              <option>Campo</option>
              <option>Empaque</option>
            </select> */}

            <br/>
            <h6>Cargo</h6>
            <select id="cargo" type="text">
              <option>Almacen campo</option>
              <option>Almacen empaque</option>
            {Gastos_Botones.map((datos,index)=>( 
                <option key={index}>{datos.valor}</option>
            ))}
            </select>

            <br/>
            <h6>Seleccionar proveedor</h6>
            <select id="proveedor" type="text">
            {Datosproveedores.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>

            <h6>Tipo de compra</h6>
            <select id="tipo_compra" type="text">
                <option >Contado</option>
                <option >Credito</option>
            </select>
            <br/>

            <h6>Seleccionar moneda</h6>
            <select id="moneda" type="text">
              <option>Pesos</option>
              <option>Dolares</option>
            </select>
            <br/>
            <Form.Control
              id="solicitante"
              placeholder="Agregar solicitante"
              type="text"
              className="mb-1"
            />      
            {/* <Form.Control
              id="sku"
              placeholder="Agregar folio"
              type="number"
              className="mb-1"
            /> */}

          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_ordenes_principal;