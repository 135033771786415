import React from "react";
import { Modal, Stack, Form, Button, Table} from "react-bootstrap";
import añadir_boton_gastos from "../../functions/Gastos/añadir_boton_gastos";
import getAllventas from "../../functions/Cultivo_en_proceso/getAllventas";
import añadir_venta from "../../functions/Cultivo_en_proceso/añadir_venta";
import eliminar_venta from "../../functions/Cultivo_en_proceso/eliminar_venta";
import {DatePicker} from "@material-ui/pickers";
import Swal from "sweetalert2";

function ModalVentas({
  isModalVentas,
  setisModalVentas,
  Boton_seleccionado_costos,
}) {

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

//Eliminar con sweetalert2
function eliminar_sweetalert(producto) {
  Swal.fire({
    title: '¿Esta seguro de eliminar la venta del dia  "'+ producto.fecha_actual+'" ?',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'La venta ha sido eliminada.',
        'success'
      )
        eliminar_venta(producto, Boton_seleccionado_costos).then(
          Categorias() 
        );
      
    }
  })
}



const [Productos, setProductos] = React.useState([]);
const [Ventas_on, setVentas_on] = React.useState(false);

async function Categorias() {
  await getAllventas(Boton_seleccionado_costos).then((ventasDB) => {
    //console.log(productos)
     //Con esto lo ordeno por fechas de forma descendiente
     ventasDB.sort((a,b)=>  (b.date) - (a.date));
     setProductos(ventasDB);    
     console.log(ventasDB)
     setVentas_on(true)
   });
  }

  //Fecha
  const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());

  async function añadirProductoModal() {
    let date =fechaSeleccionada;
    //let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;   
    let fecha_actual =String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear();  
    let date2 = new Date();
    let fecha = String(date2.getDate()).padStart(2, '0') + '-' + String(date2.getMonth() + 1).padStart(2, '0') + '-' + date2.getFullYear() + " " + date2.getHours() + ":" + date2.getMinutes() + ":" + date2.getSeconds() ;
    //obtener infor del formulario
    const cantidad = document.getElementById("cantidad").value;

    // enviar informacion a firebase
    const infoProducto = {cantidad, fecha_actual, date, fecha};
    añadir_venta(infoProducto, Boton_seleccionado_costos);
    // cerrar modal
    //setisModalVentas(false);
    Categorias();
    setVentas_on(true);
   
  }

  React.useEffect(() => {
    Categorias();
  }, []); 

  return (
   <div>
    {Ventas_on ?
     <Modal id="Modal_Logs" className="container-fluid" show={isModalVentas} onHide={() => setisModalVentas(false)}>
     <Modal.Header>
       <Modal.Title>Ventas</Modal.Title>
       <Button variant="primary" onClick={() => setVentas_on(false)}>
         Agregar venta
       </Button>
     </Modal.Header>
     <Modal.Body>
      <Table id="Tabla_logs">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Cantidad pagada</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {Productos.map((producto, index) => (    
              <tr key={index}>
                <td>{producto.fecha_actual}</td>
                <td>{formatter.format(producto.cantidad)}</td>
                  <td>
                          <Button
                            variant="danger"
                            onClick={() => {
                              eliminar_sweetalert(producto)
                            }}
                          >
                            Eliminar
                          </Button>
                  </td>
               </tr>   
          ))}
        </tbody>
      </Table>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={() => setisModalVentas(false)}>
         Salir
       </Button>
     </Modal.Footer>
   </Modal>

      :

    //-----------------------------Agregar venta--------------------------------------------------------

      <Modal className="container-fluid" show={isModalVentas} onHide={() => setisModalVentas(false)}>
      <Modal.Header>
        <Modal.Title>Agregar venta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>        
          
          <br/>

          <h6>Ingresar total de venta</h6>
            <Form.Control
              id="cantidad"
              placeholder="Ingresar cantidad de venta"
              type="number"
              className="mb-1"
            />

          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" onClick={() => añadirProductoModal()}>
          Añadir
        </Button>
        <Button variant="secondary" onClick={() => setVentas_on(true)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  }
   </div> 
   
  );
}

export default ModalVentas;
