import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadir_categoria_cultivo_en_proceso from "../funciones/añadir_categoria_cultivo_en_proceso";
import React from "react";


function ModalAñadir_cultivo_en_proceso({
  Modal_categoria,
  setModal_categoria,
  Categoria,
  actualizar_botones,
}) {


  function añadirProductoModal() {
    //obtener infor del formulario
    const nombre = document.getElementById("categoria").value;
    // enviar informacion a firebase
    const infoProducto = {nombre};
    añadir_categoria_cultivo_en_proceso(infoProducto);
    // cerrar modal
    actualizar_botones();
    setModal_categoria(false); 
  }
 
  return (
    <Modal show={Modal_categoria} onHide={() => setModal_categoria(false)}>
      <Modal.Header>
        <Modal.Title>Nueva categoria, 12 como maximo.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar categoría existente en productos</h6>
            <Form.Control
            id="categoria"
            placeholder="ingresar nueva categoría a crear"
            type="text"
            className="mb-1"
            />  
            
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal_categoria(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>añadirProductoModal()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_cultivo_en_proceso;