import firebaseApp from "../../Firebase/firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_categoria_cultivo_en_proceso(infoProducto) {
  console.log(infoProducto)
  console.log(infoProducto.nombre)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Campo_secciones".replace(/ /g, ""))
  const docRef = doc(collectionRef, infoProducto.nombre);
  setDoc(docRef, infoProducto);
}

export default añadir_categoria_cultivo_en_proceso;
