import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../../functions/Cultivo_en_proceso/añadirProducto";
import getAllproducts from "../../functions/Productos/getAllproducts";
import getAllProducts from "../../functions/Cultivo_en_proceso/getAllProducts";
import Swal from "sweetalert2";


function ModalAñadir_Costo_directo({
  Costo_directo,
  setCosto_directo,
  actualizarEstadoProductos,
  usuario,
  pagina,
  setProductos,
}) {

  async function actualizar(pagina) {
    const pagina2 = pagina;
    await getAllProducts(pagina2).then((productos) => {
      //console.log(productos)
        //Filtrar por fecha
       productos.sort((a,b)=>  (b.fecha3) - (a.fecha3));
       setProductos(productos);    
     });
   }
   
   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

  const [productos_costos, setproductos_costos] = React.useState([]);

  async function Traer_productos() {
    await getAllproducts().then((productos) => {
      console.log(productos)
      var productos_filtrados = [];
      productos.map((datos)=>{
        if(datos.categoria=="Costo directo de cultivo"){
          productos_filtrados.push(datos);
        }
      });
      console.log(productos_filtrados)
      setproductos_costos(productos_filtrados);    
     });
   }


async function añadirProductoModal() {
   //Este if es para validar que se ingrese un valor en el input de cantidad
   const cantidad = document.getElementById("cantidad").value;
    if(cantidad.length > 0){
          //obtener infor del formulario
          let date = new Date();
          let fecha_actual2 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
          let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear();
          const fecha = fecha_actual;
          const fecha2 = fecha_actual2;
          let fecha3 = date;
          const nombre = document.getElementById("producto").value;
          const seccion = pagina;
          var costo_directo_si_no = "Si";
          //Esto es para obtener el producto seleccionado y tener la unidad de el
          var Producto_seleccionado_por_usuario = [];
          productos_costos.map((valor)=>{
            if(valor.nombre==nombre){
              Producto_seleccionado_por_usuario.push(valor);
            }
          })
          var total = 0;
          const unidad = Producto_seleccionado_por_usuario[0].unidad;
          const usuario2 = usuario.email;



          //Esto es para ver si es en dolar o peso
          var producto_seleccionado = [];

          productos_costos.map((valor_producto)=>{
            if(valor_producto.nombre == nombre){
              producto_seleccionado.push(valor_producto);
            }
          })
          
          console.log(producto_seleccionado[0].moneda);
          console.log(producto_seleccionado);
          if(producto_seleccionado[0].moneda=="Dolares"){
            var tiene_valor_dolar = document.getElementById("precio_dolar").value;

            if(tiene_valor_dolar == ""){
              Swal.fire({
                icon: 'error',
                text: "El precio del dolar no se ingreso. ",
                footer: '¿Que hacer? <br/> Se debe agregar el precio del dolar actual ya que el producto es en dolares.'
              })
            }else{
              //Dolares
              var total_dolar = (Producto_seleccionado_por_usuario[0].precio) * tiene_valor_dolar;
              total = total_dolar * cantidad;
              console.log("Producto en dolares")
              const categoria = Producto_seleccionado_por_usuario[0].categoria;
              const infoProducto = { fecha, fecha2, fecha3,  nombre, cantidad, unidad, usuario2, seccion, total, costo_directo_si_no, categoria};
              añadirProducto(infoProducto, usuario.email,pagina);
              // cerrar modal
              await timeout(500);
              actualizar(pagina);
              setCosto_directo(false);
              
            }
            
          }else{
          //Pesos
          console.log("Producto en pesos")
           total = (Producto_seleccionado_por_usuario[0].precio) * cantidad;
           const categoria = Producto_seleccionado_por_usuario[0].categoria;
          const infoProducto = { fecha, fecha2, fecha3, nombre, cantidad, unidad, usuario2, seccion, total, costo_directo_si_no, categoria};
          añadirProducto(infoProducto, usuario.email,pagina);
          // cerrar modal
          await timeout(500);
          actualizar(pagina);
          setCosto_directo(false);
          }

          /* console.log(fecha, fecha2, nombre, cantidad, unidad, usuario2, seccion );
          const infoProducto = { fecha, fecha2, nombre, cantidad, unidad, usuario2, seccion, total, costo_directo_si_no};
          añadirProducto(infoProducto, usuario.email,pagina);
          // cerrar modal
          await timeout(500);
          actualizar(pagina);
          setCosto_directo(false); */
      }else{
        //alert("Cantidad no ingresada")
        Swal.fire({
          icon: 'error',
          text: "Cantidad o categoría no ingresado en el formulario. ",
          footer: '¿Que hacer? <br/> Se debe agregar cantidad y seleccionar una categoría adecuada al articulo o producto que desea consumir en la sección de almacen correspondiente'
        })
      }

}

  React.useEffect(() => {
    Traer_productos();
  }, []);

  return (
    <Modal show={Costo_directo} onHide={() => setCosto_directo(false)}>
      <Modal.Header>
        <Modal.Title>Retirar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar producto a retirar</h6>
            <select id="producto" type="text">
              {productos_costos.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
            </select>
            <br/>

            <Form.Control
              id="cantidad"
              placeholder="Cantidad"
              type="number"
              className="mb-1"
            />
               <br/>
        <h6>Ingresar el precio del dolar si el producto es en Dolares</h6>
            <Form.Control
              id="precio_dolar"
              placeholder="Precio"
              type="number"
              className="mb-1"
            />
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setCosto_directo(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={añadirProductoModal}>
          Retirar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_Costo_directo;
