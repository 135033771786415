import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import escribirLog_productos_orden from "./escribirLog_productos_orden";

function añadirProducto_orden(infoProducto, autor,productofolio) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Ordenes_productos/"+productofolio+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.producto);
  /* console.log(docRef)
  console.log(infoProducto) */
  setDoc(docRef, infoProducto);

  escribirLog_productos_orden("Edición", infoProducto, autor,productofolio);
}

export default añadirProducto_orden;
