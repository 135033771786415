import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllCarton() {
    console.log("Hola");
    const CartonDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_empaque/Carton/Productos/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      CartonDB.push(doc.data());
    });
    return CartonDB;
    

}
