import firebaseApp from "../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);


export default async function getGastos_ordenes(productocargo) {
    const GastosDB =  [];
    console.log(productocargo);
    const collectionRef = collection(db, "Gastos_productos_tablas/"+productocargo+"/Productos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      GastosDB.push(doc.data());
    });
    return GastosDB;
    

}


//"+productofolio+"
