import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getManifiesto_lugares_caja(producto) {
    console.log(producto.manifiesto);
    const Lugares_manifiestoDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+producto.manifiesto+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Lugares_manifiestoDB.push(doc.data());
    });
    return Lugares_manifiestoDB;
    

}
