import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import '../../index.css'
import CRUD_ordenes from '../Firebase/CRUDS/CRUD_ordenes';

const Ordenes_compra = () =>{
    return(
        <div>
            <div >
            <div id="listas">  
               
            </div>  
            <CRUD_ordenes/>
            </div>
        </div>
    )
}
//<CRUD/>
export default Ordenes_compra;