import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import React, {useState} from 'react';

function añadirProducto_update( productos_actual, pagina2, auxiliar4) {
  console.log("HOLAAA")
  //Con esto evito que me borre la cantidad actual en el almacen al actualizar el precio
  productos_actual["cantidad"] = auxiliar4.cantidad;

  //console.log(productos_actual.nombre)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Almacen_campo/"+pagina2+"/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, productos_actual.nombre);
  updateDoc(docRef, productos_actual);
  
}

export default añadirProducto_update;
