import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function añadirTarima_manifiesto(infoProducto, lugar_caja_vacio, valor_manifiesto, añadir_ok) {
  const db = getFirestore(firebaseApp);
  
  /* console.log(datos.lugar_caja);
  console.log(infoProducto )
  console.log(lugar_caja_vacio)
  console.log(valor_manifiesto.manifiesto); */
  let auxiliar = {lugar_caja_vacio};
  //console.log(auxiliar);
  //Este sirve para poner en false los 22 campos de la caja cuando no se tienen declarados aun en la DB
  if(infoProducto == ""){
    console.log("Vacio")
    const collectionRef2 = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+valor_manifiesto.manifiesto+"/".replace(/ /g, ""));
    const docRef2 = doc(collectionRef2, (valor_manifiesto.manifiesto).toString());
    setDoc(docRef2, auxiliar); 
  }

  //Este sirve para cambiar de false a true o al revez el lugar seleccionado por los checkbox
  if(infoProducto == "checkbox"){
    console.log("checkbox")
    const collectionRef3 = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+valor_manifiesto.manifiesto+"/".replace(/ /g, ""));
    const docRef3 = doc(collectionRef3, (valor_manifiesto.manifiesto).toString());
    updateDoc(docRef3, auxiliar);
  }

//Este sirve para agregar el nombre del producto a los checkbox
if(infoProducto == "producto_nombre"){
  console.log("producto_nombre")
  const collectionRef3 = collection(db, "Manifiestos/Lugares_caja_Tarimas/"+valor_manifiesto.manifiesto+"/".replace(/ /g, ""));
  const docRef3 = doc(collectionRef3, (valor_manifiesto.manifiesto).toString());
  updateDoc(docRef3, auxiliar);
}

  console.log(infoProducto)
  if(añadir_ok == "añadir_final"){
    console.log("Final")
    const collectionRef = collection(db, "Manifiestos/Tarimas/"+infoProducto.manifiesto+"/".replace(/ /g, ""));
    const docRef = doc(collectionRef, infoProducto.fecha);
    setDoc(docRef, infoProducto); 
  }
    
    
  
}

export default añadirTarima_manifiesto;
