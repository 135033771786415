/* import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getOrden_agregada(nombre) {
    console.log(nombre)
    const OrdenNuevaDB =  [];
    const collectionRef = collection(db, "Ordenes/"+nombre+"/".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      OrdenNuevaDB.push(doc.data());
    });
    return OrdenNuevaDB;
    

} */

import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getOrden_agregada(nombre) {
    console.log(nombre)
    const OrdenNuevaDB =  [];
    const collectionRef = collection(db, "Ordenes/");
    const q =  query(collectionRef, where("nombre", "==", nombre));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        OrdenNuevaDB.push(doc.data());
      });
    console.log(OrdenNuevaDB)
    return OrdenNuevaDB;
} 





