import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_retirar_producto(accion, usuario, pagina,valor_cantidad_ingresado,nombreproducto, cosecha, fecha_actual, precio2, fecha_actual3, date2) {
  const persona= usuario.email;
  const agregado = "NA";
  let date = new Date();
  let fecha_actual2 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  let fecha_seleccionada = "";
  if(date2==undefined){
  }else{
    fecha_seleccionada = date2;
  }
  const collectionRef = collection(db, "Almacen_campo/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, nombreproducto+","+new Date().toString());
  const data = {
    accion,
    fecha: fecha_actual3,
    fecha2: fecha_actual2,
    fecha3: fecha_seleccionada,
    Hora: hora_actual,
    Autor: persona,
    Seccion: pagina,
    Cantidad_retirada: valor_cantidad_ingresado,
    Cantidad_agregada: agregado,
    Producto_retirado: nombreproducto,
    Titulo: nombreproducto+","+new Date().toString(),
    Cosecha: cosecha,
    Precio: precio2.toString(),
  };

  setDoc(docuRef, data)
  //setDoc(docuRef, data);
}
