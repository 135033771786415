import React from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';
import General from '../Formularios/General';
import Listaproduccion from '../Listas/Lista_almacen_empaque';
import CRUD from '../Firebase/CRUDS/CRUD';
import {Link} from 'react-router-dom'

//<Listaproduccion/> , va en la linea 14
const Fertilizantesyagroquimicos = () =>{
    return(
        <div>
            <div >
             
            <div id="listas">  
                
            </div>  
            <CRUD/>
            </div>
        </div>
    )
}

export default Fertilizantesyagroquimicos;