import firebaseApp from "../firebase/credenciales";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
const db = getFirestore();

async function filtrarDatosProducto(busqueda) {
  const docusFiltradoProducto = [];

  const collecionRef = collection(db, "Productos");
  const queryNombre = query(
    collecionRef,
    where("nombre", "==", busqueda)
  );
  const querySku = query(collecionRef, where("sku", "==", busqueda));

  const arraySnapshots = await Promise.all([
    getDocs(queryNombre),
    getDocs(querySku),
  ]);

  arraySnapshots.forEach((snapshot) => {
    snapshot.forEach((doc) => {
      docusFiltradoProducto.push(doc.data());
    });
  });
  return docusFiltradoProducto;
}

export default filtrarDatosProducto;
