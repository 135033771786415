import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc} from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default function escribirLog_agregar_producto_empaque2(accion, usuario, valor_cantidad_ingresado,nombreproducto,Presentacion, date, movimiento, producto_terminado_seleccionada_filtrada, cosecha_seleccionada, orden_compra ) {
  console.log(accion, usuario, valor_cantidad_ingresado, nombreproducto, producto_terminado_seleccionada_filtrada, cosecha_seleccionada);
  var persona= usuario.email;
  if(orden_compra == undefined){
    orden_compra = "";
  }else{persona= "Se genero desde la orden de compra con folio "+orden_compra}

  const cosecha= "NA";
  const retirado= "NA";
  //let date = new Date();
  let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() ;
  let hora_actual=  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  let fecha_nombre = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + date.getSeconds();
  const collectionRef = collection(db, "Almacen_empaque/LOGS/LOGS".replace(/ /g, ""));
  const docuRef = doc(collectionRef, fecha_nombre);
  const data = {
    accion,
    fecha: fecha_actual,
    fecha3: date,
    Hora: hora_actual,
    Autor: persona,
    Seccion: Presentacion,
    Cantidad_retirada: retirado,
    Cantidad_agregada: valor_cantidad_ingresado,
    Producto_retirado: nombreproducto,
    Titulo: fecha_nombre,
    Cosecha: cosecha,
    Orden_folio: orden_compra,
    Movimiento: movimiento,
    precio: producto_terminado_seleccionada_filtrada[0].precio,
    lleva_iva: producto_terminado_seleccionada_filtrada[0].lleva_iva,
    moneda: producto_terminado_seleccionada_filtrada[0].moneda,
    categoria: producto_terminado_seleccionada_filtrada[0].categoria,
    tamaño: producto_terminado_seleccionada_filtrada[0].tamaño,
    etiqueta: producto_terminado_seleccionada_filtrada[0].etiqueta,
    tamaño_mas_etiqueta: producto_terminado_seleccionada_filtrada[0].tamaño_mas_etiqueta,
    Cosecha_seleccionada_retirada: cosecha_seleccionada,
  };

  setDoc(docuRef, data)
  //setDoc(docuRef, data);
}
