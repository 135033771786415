
import CRUD_Nominas from '../Firebase/CRUDS/CRUD_Nominas';
import React, {useState} from 'react';
import Navbartrabajo from '../Navbars/Navbartrabajo';


//<Listaproduccion/> , va en la linea 14
const Nominas = () =>{
    const [Start, setStart] = useState(false);
    const [valor, setvalor] = useState(null);
    const [botones, setbotones] = useState([]);
    const [continuar, setcontinuar] = useState(false);

    function operacion(){
        if(valor===null){
            setStart(false);
            setcontinuar(false);
        }else{
            setStart(true);
            setcontinuar(true);
        }
    }

    function Pagina_principal(){
        setStart(false);
        setcontinuar(false);
    }

    function Captura_diaria(){
        setvalor("Captura_diaria");
        console.log(valor);
        operacion()
    }

    function Concentrado_por_cuadrillero(){
        setvalor("Concentrado_por_cuadrillero");
        console.log(valor);
        operacion()
    }

    function Concentrado_general(){
        setvalor("Concentrado_general");
        console.log(valor);
        operacion()
    }

       React.useEffect(() => {
        operacion();
        
       }, [valor]);

    return(
        <div id="Trabajo"> 
            <div >
                    {Start? null : 
                      <div>
                            <Navbartrabajo/>
                            <br/>
                            <br/>
                            <br/>

                             <div  id="Lista_cultivo_en_proceso" >   
                                <button id="boton_lista_cultivo_en_proceso" onClick={() => Captura_diaria()} key={1}>Captura diaria</button>
                                <button id="boton_lista_cultivo_en_proceso" onClick={() => Concentrado_por_cuadrillero()} key={2}>Concentrado por cuadrillero</button>
                                <button id="boton_lista_cultivo_en_proceso" onClick={() => Concentrado_general()} key={3}>Concentrado general</button>
                            </div>
                      </div>
                    }
            </div>                  
                

                    {continuar? <CRUD_Nominas botones={botones} pagina={valor} start={Start} Pagina_principal={()=>Pagina_principal()}/> : null}
                
            </div>
    )
}


export default Nominas;


/* import React from 'react';
import CRUD_Seguro_social from '../Firebase/CRUDS/CRUD_Seguro_social';


const Seguro_social = () =>{
    return(
        <div>
            <div >
             
            <div id="listas">  
               
            </div>  
            <CRUD_Seguro_social/>
            </div>
        </div>
    )
}

export default Seguro_social; */