import firebaseApp from "../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarProductoOrden(producto, productofolio, productocargo, Gasto_filtrado_a_eliminar) {
  //Elimina en la orden de compra
  const coleccionRef = collection(db, "Ordenes_productos/"+productofolio+"/Productos".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, producto.producto);
  var eliminado = await deleteDoc(docuRef);

  //Con esto lo añado en el almacen de campo
  if(productocargo=="Almacen campo"){
    console.log("Campo")
  }
  //Con esto lo añado en el almacen de empaque
  if(productocargo=="Almacen empaque"){
    console.log("empaque")
  }

  //se evalua que el cargo no sea almacen campo o empaque para poder añadir en gastos
  if((productocargo=="Almacen campo") || (productocargo=="Almacen empaque") ){
  }else{
      //Elimina en el concentrado
    var coleccionRef2 = collection(db, "Gastos_productos_tablas/"+productocargo+"/Productos");
    var docuRef2 = doc(coleccionRef2, (Gasto_filtrado_a_eliminar[0].nombre).toString());
    eliminado =  await deleteDoc(docuRef2);
  }

  return eliminado;
}
