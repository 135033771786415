import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_bancos_afectaciones_fiscales(infoProducto) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Bancos/Afectaciones_fiscales/Productos/");
  const docRef = doc(collectionRef, infoProducto.nombre_afectacion_fiscal);
  setDoc(docRef, infoProducto);      
}

export default añadir_bancos_afectaciones_fiscales;
