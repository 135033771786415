import React from "react";
import { Modal, Button, Table} from "react-bootstrap";
import "../../../index.css"
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import getLogs_almacen_campo from "../functions/getLogs_almacen_campo";
import eliminarLog_almacen from "../functions/Almacen_campo/eliminarLog_almacen";
import getAllProducts from "../functions/getAllProducts";
import Swal from "sweetalert2";

function ModalLogs({
  setIsModalLogs,
  isModalLogs,
  Producto_seleccionado,
  pagina,
  start,
  setProductos,
}) {

   //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, pagina, start) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.Producto_retirado+'"',
      text: "Esta acción no se puede restablecer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado.',
          'success'
        )
        Traer_productos(producto, pagina, start).then(
          (confirmacion) => {
            Historial2(Producto_seleccionado);
          }
        );
      }
    })
  }

  //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

  const [Datos, setDatos] = React.useState([]);

  async function Historial2(Producto_seleccionado){    
    await getLogs_almacen_campo(Producto_seleccionado).then((Logs) => {
      //console.log(Producto_seleccionado);
      //console.log(Logs);
      //Con esto lo ordeno por fechas de forma descendiente
      Logs.sort((a,b)=>  (b.fecha3) - (a.fecha3));
      setDatos(Logs);
    });
  }

  //ver los productos de la categoria seleccionada en el almacen
  async function Traer_productos(producto, pagina, start) {
    var pagina2=pagina;
    await getAllProducts(pagina2, start).then((productos) => {
      var cantidad_actual = 0;
      productos.map((dato)=>{
      if(dato.nombre==producto.Producto_retirado){
        cantidad_actual=dato.cantidad;
      }
    });
    eliminarLog_almacen(producto, pagina, cantidad_actual);
      restar(pagina, start);  
     });
   }


   async function restar(pagina, start) {
    await timeout(1000);
    actualizarEstadoProductos2(pagina, start);
    }

    const [Actualiza, setActualiza] = React.useState([]);

    async function actualizarEstadoProductos2(pagina, start) {
      await getAllProducts(pagina, start).then((productos) => {
        console.log(productos)
         setProductos(productos);    
         setActualiza(productos); 
       });
     }



  function salir(){
    setIsModalLogs(false)
  }

  React.useEffect(() => {
    Historial2(Producto_seleccionado);
  }, [Actualiza]);

  const valor = (Datos == "");
  //console.log(valor)
  return (
   <div >
    <Modal id="Modal_Logs" className="container-fluid" show={isModalLogs} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Historico de movimientos</Modal.Title>
        {valor?
        null : 
        <ReactHTMLTableToExcel
        id="botonExportarExcel"
        className="btn btn-success"
        table="Tabla_logs"
        filename={Datos[0].Producto_retirado}
        sheet="Movimientos"
        buttonText="Descargar Excel"
        />}
        
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            <th>#</th>
            <th>Autor</th>
            <th>Producto retirado</th>
            <th>Cantidad retirada</th>
            <th>Cantidad agregada</th>
            <th>Zona o tabla donde se uso</th>
            {/* <th>Cosecha</th> */}
            <th>Fecha</th>
            <th>Hora</th>
            
          </tr>
        </thead>
        <tbody>
          {
            Datos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.Autor}</td>
                <td>{producto.Producto_retirado}</td>
                <td>{producto.Cantidad_retirada}</td>
                <td>{producto.Cantidad_agregada}</td>
                <td>{producto.Seccion}</td>
                {/* <td>{producto.Cosecha}</td> */}
                <td style={{ width: "10%" }}>{producto.fecha}</td>
                <td>{producto.Hora}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      eliminar_sweetalert(producto, pagina, start);
                    }}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  );
}

export default ModalLogs;
