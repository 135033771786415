import React from "react";
import signOut from "../functions/cerrarSesion";
import "../../../index.css"
import { Container, Stack, Button, Form, Table } from "react-bootstrap";
import getAllProducts from "../functions/Cultivo_en_proceso/getAllProducts";
import eliminarProductoHome from "../functions/Cultivo_en_proceso/eliminarProductoHome";
import filtrarDatos from "../functions/filtrarDatos";
import Navbartrabajo from '../../Navbars/Navbartrabajo'
import getLogs_almacen_campo from "../functions/getLogs_almacen_campo";
import getCategorias from "../functions/Productos/getCategorias";
import getAllventas from "../functions/Cultivo_en_proceso/getAllventas";
//Costos
import getProductos_costos from "../functions/Cultivo_en_proceso/getProductos_costos";
import getProductos_cultivo_proceso from "../functions/Cultivo_en_proceso/getProductos_cultivo_proceso";
import getLogs_almacen_campo_costos from "../functions/Cultivo_en_proceso/getLogs_almacen_campo_costos";
import añadir_total_costos from "../functions/Cultivo_en_proceso/añadir_total_costos";
import ModalCostos_desglose from "../components/Cultivo_en_proceso/ModalCostos_desglose";
//Gastos
import getProductos_gastos from "../functions/Cultivo_en_proceso/getProductos_gastos";
import añadir_total_gastos from "../functions/Cultivo_en_proceso/añadir_total_gastos";
//modales
import ModalAñadir from "../components/Cultivo_en_proceso/ModalAñadir";
import ModalEditar from "../components/Cultivo_en_proceso/ModalEditar";
import ModalLogs from "../components/Cultivo_en_proceso/ModalLogs";
import ModalVentas from "../components/Cultivo_en_proceso/ModalVentas";
import ModalAñadir_Costo_directo from "../components/Cultivo_en_proceso/ModalAñadir_Costo_directo";
import Swal from "sweetalert2";

function Home_Cultivo_en_proceso({ usuario, botones, pagina, start, Pagina_principal }) {

  //Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

//Eliminar con sweetalert2
function eliminar_sweetalert(producto, usuario, start) {
  Swal.fire({
    title: '¿Esta seguro de eliminar el producto?  "'+ producto.nombre+'"',
    text: "Esta acción no se puede restablecer!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar.'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Eliminado!',
        'El producto ha sido eliminado.',
        'success'
      )
      console.log(pagina, producto)
        eliminarProductoHome(producto, pagina, usuario.email).then(
          (confirmacion) => {
            actualizarEstadoProductos(pagina, start);
            setBusquedaON(false);
            setBusqueda_datos([]);
            setTotal_Producto_filtrados(0);
          }
        );
      
    }
  })
}


//Todo esto es para obtener los costos de produccion de los cultivos sin contar los gastos indirectos
const [Secciones_costos, setSecciones_costos] = React.useState([]);
const [Secciones_costos_totales, setSecciones_costos_totales] = React.useState([]);
const [costos_totales_final, setcostos_totales_final] = React.useState(0);
const [Auxiliar_costo_botones, setAuxiliar_costo_botones] = React.useState(false);

async function actualizar_datos_costos() {
  await getProductos_costos().then((InformesBD) => {
    setSecciones_costos(InformesBD);
     console.log(InformesBD);
     actualizar_datos_costos2(InformesBD)
   });
 }

 async function actualizar_datos_costos2(InformesBD) {
  //console.log(InformesBD);
  var todos_los_productos = [];
  var Total_precio = 0;
  InformesBD.map((valor)=>{
   getProductos_cultivo_proceso(valor).then((productosDB) => {
    //console.log(productosDB);
      productosDB.map((dato)=>{
        const str = Total_precio.toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const nuevaStr = str.replace("$", "");
        const nuevaStr2 = nuevaStr.replace(",", "");
        const nuevaStr3 = nuevaStr2.replace(",", "");
        var nuevaStr4 = nuevaStr3.replace(",", "");
        Total_precio = formatter.format(+nuevaStr4 + +dato.total)
        todos_los_productos.push(dato);
      })
    });
  })
  //Tiene que esperar a que termine de cargar todos los productos
  await timeout(1200);
  //console.log(todos_los_productos);
  setcostos_totales_final(Total_precio);
  setAuxiliar_costo_botones(true);
  setSecciones_costos_totales(todos_los_productos);
 }

function Guardar_total_costo(costos_totales_final){
  const str = costos_totales_final.toString();
  //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
  const nuevaStr = str.replace("$", "");
  const nuevaStr2 = nuevaStr.replace(",", "");
  const nuevaStr3 = nuevaStr2.replace(",", "");
  var nuevaStr4 = nuevaStr3.replace(",", "");
  const dato = nuevaStr4;
    var costo= {
      valor: dato,
      nombre: "Costos producción campo",
      };
  añadir_total_costos(costos_totales_final,costo);
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'El total ha sido guardado correctamente.',
    showConfirmButton: false,
    timer: 1500
  })
}

//-----------------------------------------------------------------------------------------------------------------
//Gastos indirectos de produccion
const [Gastos_LOGS, setGastos_LOGS] = React.useState([]);
const [gastos_totales_final, setgastos_totales_final] = React.useState(0);

async function actualizar_datos_gastos() {
  await getProductos_gastos().then((GastosDB) => {
     setGastos_LOGS(GastosDB);
     //console.log(GastosDB);
     Gastos(GastosDB);
   });
 }

 function Gastos(GastosDB){
var gastos_total=0;
  GastosDB.map((datos)=>{
    var sumar=0;
    if(datos.Seccion=="Gastos indirectos de producción"){
      sumar=parseInt(datos.Precio)*parseInt(datos.Cantidad_retirada);
      gastos_total=gastos_total+sumar;
    }
  })
Guardar_total_gastos(gastos_total);
 }

function Guardar_total_gastos(gastos_total){
  const dato = parseInt(gastos_total);
    var costo= {
      valor: dato,
      nombre: "Gastos indirectos de producción campo",
      };
  añadir_total_gastos(gastos_total,costo);
  setgastos_totales_final(gastos_total);
}

//-----------------------------------------------------------------------------------------------------------------
  const [productos, setProductos] = React.useState([]);
  const [isModalAñadir, setIsModalAñadir] = React.useState(false);
  const [isModalVentas, setisModalVentas] = React.useState(false);

  const [isModalEditar, setIsModalEditar] = React.useState(false);
  const [productoEditar, setProductoEditar] = React.useState(null);

//------------------------------COSTOS-----------------------------------------------------------------------------------
  const [Costo_directo, setCosto_directo] = React.useState(false);
  const [isModalCostos_desglose, setisModalCostos_desglose] = React.useState(false);

//Costos
const [Costoson, setCostoson] = React.useState(false);

//Gastos
const [Gastoson, setGastoson] = React.useState(false);
const [Total_productos_NO_costos, setTotal_productos_NO_costos] = React.useState(0);

async function actualizarEstadoProductos(pagina2, start) {
  await getAllProducts(pagina2, start).then((productos) => {
    //Con esto lo ordeno por fechas de forma descendiente
    productos.sort((a,b)=>  (b.fecha3) - (a.fecha3));
    setProductos(productos);

    if(pagina=="Costos"){
      actualizar_datos_costos();
      setCostoson(true);
    }

    if(pagina=="Gastos indirectos de producción"){
      //actualizar_datos_costos();
      setGastoson(true);
    }
    console.log(pagina)
    if((pagina == "Costos") || (pagina == "Gastos indirectos de producción")){

    }else{
      var total_productos = 0; 
      productos.map((valor)=>{
       const str = (total_productos).toString();
       //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
       const nuevaStr = str.replace("$", "");
       const nuevaStr2 = nuevaStr.replace(",", "");
       const nuevaStr3 = nuevaStr2.replace(",", "");
       var nuevaStr4 = nuevaStr3.replace(",", "");
       //console.log(nuevaStr4, dato_boton.total)
       total_productos= formatter.format(+nuevaStr4 + +valor.total)
       //total_productos = nuevaStr4 + valor.total;
      }) 
      setTotal_productos_NO_costos(total_productos)
    }
   });
 }
 
 const [Boton_costo, setBoton_costo] = React.useState(false);
 const [Boton_seleccionado_costos, setBoton_seleccionado_costos] = React.useState([]);
 const [Boton_costo_productos, setBoton_costo_productos] = React.useState([]);
 const [Total_por_siembra, setTotal_por_siembra] = React.useState([]);
 
 //Aqui guardo los productos de la siembra seleccionada para costos
 const [productos_boton, setproductos_boton] = React.useState([]);

 async function select_boton(prop){
  //Con nesto filtro por nombre del boton seleccionado en costos
  setBoton_seleccionado_costos(prop)
  console.log(prop)
  var datos_boton = []
  var Costos_directos_de_cultivo = []
  Secciones_costos_totales.map((valor)=>{
    if(valor.seccion==prop){
      if(valor.categoria=="Costo directo de cultivo"){
        Costos_directos_de_cultivo.push(valor)
      }else{
        datos_boton.push(valor)
      }
    }
  })

//Con esto filtro por categoria los productos antes filtrados
await getCategorias().then((CategoriasDB) => {
console.log(CategoriasDB);
//Guardo los datos para desglozar si se selecciona
setproductos_boton(datos_boton);
  CategoriasDB.map((dato)=>{
    datos_boton.map((dato_boton)=>{
      if(dato.categoria==dato_boton.categoria){
        //console.log(dato.total, nuevaStr4)
          if(dato.total==undefined){
            dato['total'] = formatter.format(dato_boton.total);
          }else{
            const str = (dato.total).toString();
            //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
            const nuevaStr = str.replace("$", "");
            const nuevaStr2 = nuevaStr.replace(",", "");
            const nuevaStr3 = nuevaStr2.replace(",", "");
            var nuevaStr4 = nuevaStr3.replace(",", "");
            //console.log(nuevaStr4, dato_boton.total)
            dato['total'] = formatter.format(+nuevaStr4 + +dato_boton.total)
          }
      }
    })//Fin segundo map
  })//Fin primer map

//Con esto quito todas las categorias que no tengan total
var productos_filtrados_por_categoria = [];
  CategoriasDB.map((dato_final)=>{
    if(dato_final.total==undefined){
    }else{
        productos_filtrados_por_categoria.push(dato_final);
    }
  })

  //Con este le agrego todos los productos de costos indirectos de campo ya que estos si tienen que salir los productos y no en total
  var auxiliar2 = [];
  var producto_encontrado = false;
  Costos_directos_de_cultivo.map((auxiliar)=>{
    if(auxiliar2.length > 0){
          auxiliar2.map((dato_auxiliar)=>{
                if(dato_auxiliar.nombre==auxiliar.nombre){
                  producto_encontrado = true;
                      if(auxiliar.total==undefined){
                        dato_auxiliar['total'] = formatter.format(auxiliar.total);
                      }else{
                        //console.log(auxiliar.total)
                        const str = (auxiliar.total).toString();
                        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                        const nuevaStr = str.replace("$", "");
                        const nuevaStr2 = nuevaStr.replace(",", "");
                        const nuevaStr3 = nuevaStr2.replace(",", "");
                        var nuevaStr4 = nuevaStr3.replace(",", "");

                        const str2 = (dato_auxiliar.total).toString();
                        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
                        const nuevaStr21 = str2.replace("$", "");
                        const nuevaStr22 = nuevaStr21.replace(",", "");
                        const nuevaStr32 = nuevaStr22.replace(",", "");
                        var nuevaStr44 = nuevaStr32.replace(",", "");

                        //console.log(nuevaStr4, nuevaStr44)
                        dato_auxiliar['total'] = formatter.format(+nuevaStr4 + +nuevaStr44)
                      }
                }
           })
        if(producto_encontrado==true){
          producto_encontrado = false;
        }else{
          auxiliar2.push(auxiliar)
          //console.log(auxiliar)
        }   
    }
  //Este es porque el auxiliar2 empieza en 0 el arreglo [] entonces se le agrega el primer producto 
    if(auxiliar2.length == 0){
      console.log("Prueba")
      auxiliar2.push(auxiliar);
    }
  })
    console.log(auxiliar2);
    auxiliar2.map((auxiliar_final)=>{
    auxiliar_final['categoria'] = auxiliar_final.nombre;
      const str = (auxiliar_final.total).toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      var nuevaStr4 = nuevaStr3.replace(",", "");
    auxiliar_final['total'] = formatter.format(nuevaStr4);
    productos_filtrados_por_categoria.push(auxiliar_final);
  })

var total_por_siembre = 0;
  productos_filtrados_por_categoria.map((total_sumado)=>{
    const str = total_por_siembre.toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const nuevaStr = str.replace("$", "");
    const nuevaStr2 = nuevaStr.replace(",", "");
    const nuevaStr3 = nuevaStr2.replace(",", "");
    var nuevaStr4 = nuevaStr3.replace(",", "");

    const dato_str = (total_sumado.total).toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
    const dato_nuevaStr = dato_str.replace("$", "");
    const dato_nuevaStr2 = dato_nuevaStr.replace(",", "");
    const dato_nuevaStr3 = dato_nuevaStr2.replace(",", "");
    var dato_nuevaStr4 = dato_nuevaStr3.replace(",", "");
    total_por_siembre = formatter.format(+nuevaStr4 + +dato_nuevaStr4)
  })

  //console.log(productos_filtrados_por_categoria); 
  setTotal_por_siembra(total_por_siembre);
  setBoton_costo_productos(productos_filtrados_por_categoria);
  setBoton_costo(true)
  sumar_ventas(total_por_siembre, prop) 

 });
}

async function sumar_ventas(total_por_siembre, prop) {
  const Boton_seleccionado_costos = prop;
  await getAllventas(Boton_seleccionado_costos).then((ventasDB) => {
     console.log(ventasDB)
     var suma_total_de_ventas = 0;
     ventasDB.map((valor)=>{

        const costoygastos_str = (valor.cantidad).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
        const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
        const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
        var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");

        const dato_str = (suma_total_de_ventas).toString();
        //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
        const dato_nuevaStr = dato_str.replace("$", "");
        const dato_nuevaStr2 = dato_nuevaStr.replace(",", "");
        const dato_nuevaStr3 = dato_nuevaStr2.replace(",", "");
    
        suma_total_de_ventas = formatter.format(+costoygastos_nuevaStr4 + +dato_nuevaStr3);
     });
     
     console.log(suma_total_de_ventas)
     setTotal_ventas(suma_total_de_ventas)

     //---------Con este saco el resultado
     const costoygastos_str = (total_por_siembre).toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
     const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
     const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
     const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
     var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");

     const dato_str = (suma_total_de_ventas).toString();
     //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
     const dato_nuevaStr = dato_str.replace("$", "");
     const dato_nuevaStr2 = dato_nuevaStr.replace(",", "");
     const dato_nuevaStr3 = dato_nuevaStr2.replace(",", "");
 
     var resultado = formatter.format(dato_nuevaStr3 - costoygastos_nuevaStr4);

     setTotal_resultado(resultado)
   });
}
  
const [Total_ventas, setTotal_ventas] = React.useState(0); 
const [Total_resultado, setTotal_resultado] = React.useState(0); 



const [Producto_seleccionado_desglose, setProducto_seleccionado_desglose] = React.useState(""); 

function desglose_productos(producto){
  setProducto_seleccionado_desglose(producto.categoria)
  setisModalCostos_desglose(true);
}

//-----------------------------------------------------------------------------------------------------------------
//--------Buscar producto------------------------------------------------------------
//Busqueda producto por nombre del producto seleccionado
const [BusquedaON, setBusquedaON] = React.useState(false);
const [Busqueda_datos, setBusqueda_datos] = React.useState([]);
const [Producto_filtrados, setProducto_filtrados] = React.useState([]);
const [Total_Producto_filtrados, setTotal_Producto_filtrados] = React.useState([]);

function filtrar_productos(){
//El primer if evita que se repita el filtrado al elegir un producto
if(Busqueda_datos.length==0){
      var auxiliar_100 = []; 
      productos.map((valor)=>{
        var auxiliar_102 = valor.nombre;
        var auxiliar_103= false;

                  if(auxiliar_100.length==0){
                    var auxiliar_101 = valor.nombre;
                    console.log(valor.nombre);
                    auxiliar_100.push(auxiliar_101);
                  }

                  if(auxiliar_100.length>0){
                        auxiliar_100.map((dato)=>{
                          if(valor.nombre == dato){
                            auxiliar_103 = true;
                          }
                        })
                  }

            if(auxiliar_103==false){
              auxiliar_100.push(auxiliar_102);
            }

      })
      console.log(auxiliar_100)
      setBusqueda_datos(auxiliar_100); 
}
 
}

function aceptar_busqueda(){
  const nombre_afectacion_fiscal = document.getElementById("productos_lista").value;  
  var datos_filtrados = [];  
    productos.map((valor)=>{
      if(valor.nombre == nombre_afectacion_fiscal){
        datos_filtrados.push(valor);
      }
    })

  var auxiliar_suma_total = 0;  
    datos_filtrados.map((dato)=>{
      const costoygastos_str = auxiliar_suma_total.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const costoygastos_nuevaStr = costoygastos_str.replace("$", "");
      const costoygastos_nuevaStr2 = costoygastos_nuevaStr.replace(",", "");
      const costoygastos_nuevaStr3 = costoygastos_nuevaStr2.replace(",", "");
      var costoygastos_nuevaStr4 = costoygastos_nuevaStr3.replace(",", "");
  
      auxiliar_suma_total = formatter.format(+costoygastos_nuevaStr4 + +dato.total);
    })
  
  setTotal_Producto_filtrados(auxiliar_suma_total);
  //Filtrar por fecha
  //Logs.sort((a,b)=>  (b.fecha3) - (a.fecha3));
  setProducto_filtrados(datos_filtrados);
  setBusquedaON(true);
}

function reset_busqueda(){
  setBusquedaON(false);
  setBusqueda_datos([]);
  setTotal_Producto_filtrados(0);
  //Filtrar por fecha
  productos.sort((a,b)=>  (b.fecha3) - (a.fecha3));
  setProductos(productos);
}

function regresar_concentrado_principal(){
  setBusqueda_datos([]);
  setTotal_Producto_filtrados(0);
  Pagina_principal()
}
//------------------------------------------------------------------------------------
  



function añadirProductoHome() {
    setIsModalAñadir(true);
  }


  React.useEffect(() => {
    actualizarEstadoProductos(pagina, start);
  }, []);

  return (
    <div>
    {Costoson?
    //Esto es para costos unicamente
      <div id="Tablas_blanco">
      <Navbartrabajo/>
      <Container fluid id="Navbar_trabajo_home">

          {isModalVentas && (
          <ModalVentas
            isModalVentas={isModalVentas}
            setisModalVentas={setisModalVentas}
            Boton_seleccionado_costos={Boton_seleccionado_costos}
          />
        )}

      {isModalCostos_desglose && (
          <ModalCostos_desglose
            isModalCostos_desglose={isModalCostos_desglose}
            setisModalCostos_desglose={setisModalCostos_desglose}
            Boton_seleccionado_costos={Boton_seleccionado_costos}
            productos_boton={productos_boton}
            Producto_seleccionado_desglose={Producto_seleccionado_desglose}
          />
        )}



           <br/>
           <Stack direction="horizontal" className="justify-content-between">
           {Boton_costo ? 
             <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en Costos de {Boton_seleccionado_costos}</p>
             :
             <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en Costos</p>
             }
             
             {Boton_costo ? 
             <Button id="boton_regresar_ordenes" onClick={()=>{setBoton_costo(false)}}> Regresar</Button>
             :
            <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
             }
             <Button onClick={signOut}>Cerrar sesión</Button>
           </Stack>
           <hr />
           <Stack direction="horizontal" className="justify-content-between">
           {Boton_costo ? 
           <>
              <p style={{ fontSize: 16 , marginLeft: "10%" }}>Costos totales {Total_por_siembra} MXN</p>
              <p style={{ fontSize: 16, marginLeft: "25%" }}>Ventas totales {Total_ventas} MXN</p>
              <Button style={{ marginRight: "20%" }} id="boton_regresar_ordenes" onClick={()=>{setisModalVentas(true)}}> Ventas</Button>    
             
             </>
             :
             <>
             <p style={{ fontSize: 16 }}>Costos totales de todas las secciones {costos_totales_final} MXN</p>
                <Button style={{ marginRight: "30%" }} onClick={() => {
                  Guardar_total_costo(costos_totales_final);
                }}>Guardar</Button>
            </>
             }
             

            
           </Stack>
           {Boton_costo ? 
            <>
            <hr/>
            <Stack direction="horizontal" className="justify-content-between">
            <p style={{ fontSize: 16, marginLeft: "40%" }}>Resultado {Total_resultado} MXN</p>
            </Stack>
            </>
            :
            null
          }

           {Boton_costo? 
           <div>
             <hr />
           <Table>
             <thead>
               <tr>
                 <th>#</th>
                 <th>Categoria</th>
                 <th>Total de costos</th>
                 <th>Acciones</th>
               </tr>
             </thead>
             <tbody>
               {Boton_costo_productos &&
                 Boton_costo_productos.map((producto, index) => (
                   <tr key={index}>
                     <td>{index + 1}</td>
                     <td>{producto.categoria}</td>
                     <td>{producto.total}</td>
                     <td>
                          <Button
                            variant="dark"
                            onClick={() => {
                              desglose_productos(producto)
                            }}
                          >
                            Desglose
                          </Button> 
                     </td>
                   </tr>
                 ))}
             </tbody>
           </Table>
           </div>
          :
          <div>
             <hr />
          {Auxiliar_costo_botones && (
            <div id="Lista_cultivo_en_proceso" style={{ background: "white" }} >
                
                {botones.map((datos,index)=>( 
                    <button id="boton_lista_cultivo_en_proceso" onClick={() => select_boton((datos.nombre))} key={index}>{datos.nombre}</button>
                ))}
           
            </div>
          )}   
           
          </div>
          }
          
           <br/>
           <br/>
      </Container>
      </div>
    :
      //Esto es para todo lo demas que no sea costos
    <div id="Tablas_blanco">
    
    <Navbartrabajo/>
    <Container fluid id="Navbar_trabajo_home">
    {isModalAñadir && (
      <ModalAñadir
        isModalAñadir={isModalAñadir}
        setIsModalAñadir={setIsModalAñadir}
        actualizarEstadoProductos={actualizarEstadoProductos}
        usuario={usuario}
        pagina={pagina}
        start={start}
        setProductos={setProductos}
      />
    )}
      {productoEditar && (
        <ModalEditar
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          pagina={pagina}
          start={start}
          setProductos={setProductos}
        />
      )}

      {Costo_directo && (
        <ModalAñadir_Costo_directo
          Costo_directo={Costo_directo}
          setCosto_directo={setCosto_directo}
          actualizarEstadoProductos={actualizarEstadoProductos}
          productoEditar={productoEditar}
          setProductoEditar={setProductoEditar}
          usuario={usuario}
          pagina={pagina}
          start={start}
          setProductos={setProductos}
        />
      )}


      <br/>
      <Stack direction="horizontal" className="justify-content-between">
        <p style={{ fontSize: 20 }}>Bienvenido {usuario.email}, Actualmente estas en {window.location.pathname}  {pagina}</p>
        <Button onClick={añadirProductoHome}> Retirar de almacen</Button>
        <Button id="boton_regresar_ordenes" onClick={()=>{setCosto_directo(true)}}> Retirar costo directo</Button>
        <Button id="boton_regresar_ordenes" onClick={()=>{regresar_concentrado_principal()}}> Regresar</Button>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Stack>
      <hr />
           <Stack direction="horizontal" className="justify-content-between">
             <p style={{ fontSize: 16 }}>Total de todos los productos de {pagina}  {Total_productos_NO_costos} MXN</p>
{/*              <Button onClick={() => {
                           Guardar_total_costo(costos_totales_final);
                         }}>Guardar</Button> */}
           </Stack>

      <hr/>
      {/* Con este busco por selección */}
      <Stack direction="horizontal" className="justify-content-between">
           <>
            <select 
            id="productos_lista"
            style={{ width: "35%" , marginLeft: "10%" }}
            type="text"
            onClick={() => {
              filtrar_productos()
                    }} 
             >
              <option>Seleccionar</option>
            {Busqueda_datos.map((datos,index)=>( 
                <option key={index}>{datos}</option>
            ))}
            </select>
            <Button style={{ fontSize: 16, marginRight: "2%", marginLeft: "1%" }} onClick={()=>{aceptar_busqueda()}}> Buscar</Button>
            <Button variant= "secondary" style={{ fontSize: 16, marginRight: "1%", marginLeft: "1%" }} onClick={()=>{reset_busqueda()}}> Reset</Button>
            <p style={{ fontSize: 14, marginRight: "5%", marginLeft: "0%" }}> Total del producto {(Total_Producto_filtrados)} MXN</p>
            </> 
          </Stack>

      {Gastoson &&(
      <div>
        <hr />
        <Stack direction="horizontal" className="justify-content-between">
              <p style={{ fontSize: 16, marginLeft: "20%"  }}>Gastos totales indirectos del campo {formatter.format(gastos_totales_final)} MXN</p>
              <Button style={{ marginRight: "30%" }} onClick={() => {actualizar_datos_gastos();}}>Guardar</Button>        
        </Stack>
      </div>
      )}

      <hr />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Total MXN</th>
            <th>Unidad</th>
            <th>Usuario</th>
            {/* <th>Cosecha</th> */}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {BusquedaON? 
             /* Con este muestra los productos filtrados */ 
             <>
              {Producto_filtrados &&
            Producto_filtrados.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.fecha}</td>
                <td>{producto.nombre}</td>
                <td>{producto.cantidad}</td>
                <td>{formatter.format(producto.total)}</td>
                <td>{producto.unidad}</td>
                <td>{producto.usuario2}</td>
                {/* <td>{producto.cosecha}</td> */}
                <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                      {producto.costo_directo_si_no? 
                        <Button
                          variant="danger"
                          onClick={() => {
                            eliminar_sweetalert(producto, usuario, start);
                          }}
                        >
                          Eliminar
                        </Button>
                      
                      :       
                      <p>Fue producto retirado del almacen</p>   
                      }
                        
                      </td>
              </tr>
            ))}
             </>
             :
             <>
              {productos &&
            productos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.fecha}</td>
                <td>{producto.nombre}</td>
                <td>{producto.cantidad}</td>
                <td>{formatter.format(producto.total)}</td>
                <td>{producto.unidad}</td>
                <td>{producto.usuario2}</td>
                {/* <td>{producto.cosecha}</td> */}
                <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                      {producto.costo_directo_si_no? 
                        <Button
                          variant="danger"
                          onClick={() => {
                            eliminar_sweetalert(producto, usuario, start);
                          }}
                        >
                          Eliminar
                        </Button>
                      
                      :       
                      <p>Fue producto retirado del almacen</p>   
                      }
                        
                      </td>
              </tr>
            ))}
             </>
            }
         
        </tbody>
      </Table>
{/*       <Button onClick={añadirProductoHome}> Retirar Producto</Button>
      <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
 */}      <br/>
      <br/>
    </Container>
    </div> 

        }
     </div>   





    
  );
}

export default Home_Cultivo_en_proceso;