import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, getDocs, document } from "firebase/firestore";
const db = getFirestore(firebaseApp);
   

export default async function getProductos_terminados_almacen(nombrecategoria) {
  console.log(nombrecategoria);
    const Productos_terminadosDB =  [];
    const collectionRef = collection(db, "Almacen_empaque/"+nombrecategoria+"/Productos".replace(/ /g, ""));    
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      Productos_terminadosDB.push(doc.data());
    });
    return Productos_terminadosDB;
}