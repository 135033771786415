import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllventas(Boton_seleccionado_costos) {
  //console.log(valor);
    const ventasDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Cultivo_en_proceso/"+Boton_seleccionado_costos+"/Ventas".replace(/ /g, ""));
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      ventasDB.push(doc.data());
    });
    return ventasDB;
    

}
