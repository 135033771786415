import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

async function editarCantidad_consumibles_tarima_esquinero({ 
  auxiliar11,
   auxiliar12
   }) {

  console.log(auxiliar12)
  console.log(auxiliar11)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Almacen_empaque/Consumibles/Productos".replace(/ /g, ""));
  const docRef = doc(collectionRef, (auxiliar12).toString());
  await updateDoc(docRef, {
    cantidad: auxiliar11
  })
}

export default editarCantidad_consumibles_tarima_esquinero;
