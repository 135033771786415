import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editarManifiesto from "../../functions/Produccion/editarManifiesto";
import getAllmanifiestos from "../../functions/Produccion/getAllmanifiestos";

function ModalEditar_Embarque({
  isModalEditar,
  setIsModalEditar,
  actualizarEstadoManifiestos,
  productoEditar,
  setproductos_manifiestos,
  usuario,
  pagina,
}) {

  const [exportacion_nacional, setexportacion_nacional] = React.useState(false);


  function prueba(){
    console.log(productoEditar);
    if(productoEditar.nacional_exportacion2=="Exportación"){
      setexportacion_nacional(true);
    }else{
      setexportacion_nacional(false);
    }
  }

  async function actualizar() {

    await getAllmanifiestos().then((ManifiestosDB) => {
      console.log(ManifiestosDB)
      setproductos_manifiestos(ManifiestosDB);    
     });
   }

   //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

 React.useEffect(() => {
    prueba();
  }, []);


 async function editarProductoModal() {
    //obtener infor del formulario
    const fecha = productoEstado.fecha;
    const fecha_actual2 = productoEstado.fecha_actual2;
    const fecha_actual3 = productoEstado.fecha_actual3;
    const cliente = productoEstado.cliente;
    const chofer = document.getElementById("chofer").value;
    const placas = document.getElementById("placas").value;
    const caja = document.getElementById("caja").value;
    const placas_caja = document.getElementById("placas_caja").value;
    const celular = document.getElementById("celular").value;
    const total_flete = document.getElementById("total_flete").value;
    const manifiesto = productoEstado.manifiesto;
    const embarque = document.getElementById("embarque").value;
    //const usuario2 = productoEstado.email;
    const nacional_exportacion2 = productoEstado.nacional_exportacion2;
    const pagado = productoEstado.pagado;

    const rfc = document.getElementById("rfc").value;
    const datos_transporte = document.getElementById("datos_transporte").value;
    const tem_f = document.getElementById("tem_f").value;
    const fecha_salida = document.getElementById("fecha_salida").value;
    const hora_salida = document.getElementById("hora_salida").value;
    const factura = document.getElementById("factura").value;
    var scac = productoEstado.scac;
    var agencia_aduanal_mx = productoEstado.agencia_aduanal_mx;
    var pedimiento_semanal = productoEstado.pedimiento_semanal;
    var id_trib = productoEstado.id_trib;
    if(exportacion_nacional==true){
     scac = document.getElementById("scac").value;
     id_trib = document.getElementById("id_trib").value;
     agencia_aduanal_mx = document.getElementById("agencia_aduanal_mx").value;
     pedimiento_semanal = document.getElementById("pedimiento_semanal").value;
    }
    // enviar informacion a firebase
    const infoProducto = { fecha, cliente, chofer, placas, caja, 
      placas_caja, celular, total_flete, manifiesto, embarque,
      nacional_exportacion2,
       rfc, datos_transporte, scac, id_trib, agencia_aduanal_mx, pedimiento_semanal,
      tem_f, fecha_salida, hora_salida, factura, fecha_actual3, fecha_actual2, pagado,
      };
  
    editarManifiesto(infoProducto);
    // cerrar modal
    setproductos_manifiestos(null);
    await timeout(500);
    actualizar();
    setIsModalEditar(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  return (
    <Modal
      show={isModalEditar}
      onHide={() => {
        setIsModalEditar(false);
        setproductos_manifiestos(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar manifiesto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>

        {exportacion_nacional && (
         <div>
          <Form.Control
              id="scac"
              placeholder="scac"
              type="text"
              className="mb-1"
              value={productoEstado?.scac}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  scac: e.target.value,
                })
              }
            />
          <Form.Control
              id="id_trib"
              placeholder="id_trib"
              type="text"
              className="mb-1"
              value={productoEstado?.id_trib}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  id_trib: e.target.value,
                })
              }
            />
          <Form.Control
              id="agencia_aduanal_mx"
              placeholder="agencia_aduanal_mx"
              type="text"
              className="mb-1"
              value={productoEstado?.agencia_aduanal_mx}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  agencia_aduanal_mx: e.target.value,
                })
              }
            />
          <Form.Control
              id="pedimiento_semanal"
              placeholder="pedimiento_semanal"
              type="text"
              className="mb-1"
              value={productoEstado?.pedimiento_semanal}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  pedimiento_semanal: e.target.value,
                })
              }
            />
          </div> 
        )}

           <Form.Control
              id="rfc"
              placeholder="rfc"
              type="text"
              className="mb-1"
              value={productoEstado?.rfc}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  rfc: e.target.value,
                })
              }
            />
          <Form.Control
              id="datos_transporte"
              placeholder="datos_transporte"
              type="text"
              className="mb-1"
              value={productoEstado?.datos_transporte}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  datos_transporte: e.target.value,
                })
              }
            />
            <Form.Control
              id="chofer"
              placeholder="chofer"
              type="text"
              className="mb-1"
              value={productoEstado?.chofer}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  chofer: e.target.value,
                })
              }
            />
            <Form.Control
              id="placas"
              placeholder="placas"
              type="text"
              className="mb-1"
              value={productoEstado?.placas}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  placas: e.target.value,
                })
              }
            />
          <Form.Control
              id="caja"
              placeholder="caja"
              type="text"
              className="mb-1"
              value={productoEstado?.caja}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  caja: e.target.value,
                })
              }
            />
             <Form.Control
              id="placas_caja"
              placeholder="placas_caja"
              type="text"
              className="mb-1"
              value={productoEstado?.placas_caja}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  placas_caja: e.target.value,
                })
              }
            />
             <Form.Control
              id="celular"
              placeholder="celular"
              type="text"
              className="mb-1"
              value={productoEstado?.celular}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  celular: e.target.value,
                })
              }
            />
            <Form.Control
              id="total_flete"
              placeholder="total_flete"
              type="text"
              className="mb-1"
              value={productoEstado?.total_flete}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  total_flete: e.target.value,
                })
              }
            />
             <Form.Control
              id="tem_f"
              placeholder="tem_f"
              type="text"
              className="mb-1"
              value={productoEstado?.tem_f}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  tem_f: e.target.value,
                })
              }
            />
             <Form.Control
              id="fecha_salida"
              placeholder="fecha_salida"
              type="text"
              className="mb-1"
              value={productoEstado?.fecha_salida}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  fecha_salida: e.target.value,
                })
              }
            />
            <Form.Control
              id="hora_salida"
              placeholder="hora_salida"
              type="text"
              className="mb-1"
              value={productoEstado?.hora_salida}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  hora_salida: e.target.value,
                })
              }
            />
            <Form.Control
              id="factura"
              placeholder="factura"
              type="text"
              className="mb-1"
              value={productoEstado?.factura}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  factura: e.target.value,
                })
              }
            />
            <Form.Control
              id="embarque"
              placeholder="embarque"
              type="text"
              className="mb-1"
              value={productoEstado?.embarque}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  embarque: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 
      </Modal.Body>

      <Modal.Footer>         
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalEditar(false);
            actualizar();;
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_Embarque;
