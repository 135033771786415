import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import getAlltarimas_almacen_empaque from "../../functions/Produccion/getAlltarimas_almacen_empaque";
import añadirTarima_manifiesto from "../../functions/Produccion/añadirTarima_manifiesto";
import getProductos2 from "../../functions/Produccion/getProductos2";
import editarCantidad_articulo_retirado from "../../functions/Produccion/editarCantidad_articulo_retirado"
import getAllPallets from "../../functions/Produccion/getAllPallets";
import getLugares_caja from "../../functions/Produccion/getLugares_caja";
import getAllConsumibles from "../../functions/Produccion/getAllConsumibles";
import getProductos_terminados_almacen from "../../functions/Produccion/Manifiesto_pallets/getProductos_terminados_almacen";
import editarCantidad_consumibles_tarima_esquinero from "../../functions/Produccion/Presentaciones/editarCantidad_consumibles_tarima_esquinero";
import escribirLog_retirar_consumibles_tarima_esquinero from "../../functions/Produccion/escribirLog_retirar_consumibles_tarima_esquinero";
import escribirLog_restar_producto_terminado_embarque from "../../functions/Produccion/escribirLog_restar_producto_terminado_embarque";
import Swal from "sweetalert2";

function ModalAñadir_Manifiesto_pallets({
  ModalAñadir_Manifiesto_palletsON,
  setModalAñadir_Manifiesto_palletsON,
  setManifiestos_pallets_datos,
  Manifiesto_Seleccionado,
  productoEditar,
  setProductoEditar, 
  usuario,
}) {

 //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
 function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}

const [esquineros_BD, setesquineros_BD] = React.useState([]);
const [Tarimas_BD, setTarimas_BD] = React.useState([]);

//----------Con esto filtro de los consumibles los que contengan la palabra tarima y esquinero
async function traer_consumibles(){
  await getAllConsumibles().then((ConsumiblesDB) => {
    console.log(ConsumiblesDB);
    var esquineros = [];
    var tarimas = [];
        ConsumiblesDB.map((dato)=>{
          if((dato.nombre.toLowerCase()).includes("esquinero".toLowerCase()))
            {
              esquineros.push(dato);
            }
          if((dato.nombre.toLowerCase()).includes("tarima".toLowerCase()))
            {
              tarimas.push(dato);
            }  
        })   
    setesquineros_BD(esquineros);
    setTarimas_BD(tarimas);
   });
}

const [Productos_terminados, setProductos_terminados] = React.useState([]);

async function traer_productos_terminados(){
  let nombrecategoria = "Producto terminado";
  await getProductos_terminados_almacen(nombrecategoria).then((Productos_terminadosDB) => {
    console.log(Productos_terminadosDB);
    setProductos_terminados(Productos_terminadosDB);
  });
}

const [cantidad_agregada_ok, setcantidad_agregada_ok] = React.useState(false);
const [cantidad_esquineros_a_retirar, setcantidad_esquineros_a_retirar] = React.useState(0);
const [cantidad_tarimas_a_retirar, setcantidad_tarimas_a_retirar] = React.useState(0);
const [cantidad_esquineros_actual, setcantidad_esquineros_actual] = React.useState(0);
const [cantidad_tarimas_actual, setcantidad_tarimas_actual] = React.useState(0);
const [Esquinero_elegido, setEsquinero_elegido] = React.useState([]);
const [Tarima_elegida, setTarima_elegida] = React.useState([]);

const [cajas_por_pallet, setcajas_por_pallet] = React.useState(0);

function continuar_con_el_proceso(){
  //-----Con esto verifico si tengo suficientes tarimas y esquineros------
  var esquinero_seleccionada = document.getElementById("esquinero_seleccionada").value;
  var esquinero_elegido = [];
  esquineros_BD.map((auxiliar)=>{
    if(auxiliar.nombre == esquinero_seleccionada){
      esquinero_elegido.push(auxiliar);
      setEsquinero_elegido(auxiliar);
    }
  })

  var tarima_a_consumir = document.getElementById("tarima_a_consumir").value;
  var tarima_elegida = [];
  Tarimas_BD.map((auxiliar2)=>{
    if(auxiliar2.nombre == tarima_a_consumir){
      tarima_elegida.push(auxiliar2);
      setTarima_elegida(auxiliar2);
    }
  })

  //-----------------------------------------------------------------------------------
  var tarima_seleccionada = document.getElementById("tarima_seleccionada").value;
  var cantidad_retirada = document.getElementById("cantidad_retirada").value;

  Tipos_tarimas.map((valor)=>{
    if(valor.nombre==tarima_seleccionada){
      var total_cajas_necesitadas = cantidad_retirada * valor.cajas_por_pallet;
      setcajas_por_pallet(valor.cajas_por_pallet);
     //Cajas 
      if(parseFloat(valor.cantidad) >= parseFloat(total_cajas_necesitadas)){
        var total_esquineros_necesitadas = cantidad_retirada * 4;
        //Esquineros
        if(esquinero_elegido[0].cantidad >= total_esquineros_necesitadas){
          setcantidad_esquineros_a_retirar(total_esquineros_necesitadas);
          setcantidad_esquineros_actual(esquinero_elegido[0].cantidad);
                      //tarimas
                      console.log(tarima_elegida[0].cantidad, cantidad_retirada)
                      if(parseFloat(tarima_elegida[0].cantidad) >= parseFloat(cantidad_retirada)){
                        setcantidad_tarimas_a_retirar(cantidad_retirada);
                        setcantidad_tarimas_actual(tarima_elegida[0].cantidad);
                        setcantidad_agregada_ok(true);
                      }else{
                      Swal.fire({
                        icon: 'error',
                        text: "La cantidad de tarimas son insuficientes en el almacen por lo que es necesario agregar, la cantidad actual de tarimas es de "+tarima_elegida[0].cantidad+" y usted quiere embarcar "+cantidad_retirada+" tarimas donde cada tarima necesita 1 tarima por lo que necesita tener por lo menos "+cantidad_retirada+".",
                        footer: '¿Que hacer? <br/> Se debe agregar mas cantidad de tarimas en la sección de Almacen del empaque -> Consumibles.'
                      }) 
                      setModalAñadir_Manifiesto_palletsON(false); 
                      }
              }else{
                Swal.fire({
                  icon: 'error',
                  text: "La cantidad de esquineros son insuficientes en el almacen por lo que es necesario agregar, la cantidad actual de esquineros es de "+esquinero_elegido[0].cantidad+" y usted quiere embarcar "+cantidad_retirada+" tarimas donde cada tarima necesita 4 esquineros por lo que necesita tener por lo menos "+total_esquineros_necesitadas+".",
                  footer: '¿Que hacer? <br/> Se debe agregar mas cantidad de esquineros en la sección de Almacen del empaque -> Consumibles.'
                }) 
                setModalAñadir_Manifiesto_palletsON(false); 
              }
      }else{
        Swal.fire({
          icon: 'error',
          text: "El pallet seleccionado no tiene la cantidad de cajas suficientes para retirar debido a que cuenta actualmente con "+valor.cantidad +" y usted quiere retirar un total de "+cantidad_retirada +" pallets, los cuales necesitan un total de " +total_cajas_necesitadas+" cajas ya que cada pallet necesita "+valor.cajas_por_pallet+" cajas.",
          footer: '¿Que hacer? <br/> Se debe agregar mas cantidad de cajas en la sección de Almacen del empaque -> Producto terminado.'
        }) 
        setModalAñadir_Manifiesto_palletsON(false); 

      }
    }
  })
}







//Crear checkboxs-------------------------------------------------------------------------------------------------
const [Lugares_de_caja, setLugares_de_caja] = React.useState([]);

function checkboxs(lugar_caja_vacio, auxiliar_primera_vez){
  console.log(lugar_caja_vacio);
    //Con esto verifico cuales ya estan en true y los elimino para que no los puedan seleccionar ya
      var checkbox_ya_en_uso = [];
      var auxiliar2 = [];
      if(auxiliar_primera_vez==true){
      }else{
        auxiliar2 = lugar_caja_vacio[0].lugar_caja_vacio;
        auxiliar2.map((valores, posicion)=>{
          //console.log(valores)
          if(valores.ocupado == true){
            checkbox_ya_en_uso.push(posicion);
          }
         })
      }

    //console.log(auxiliar2, auxiliar_primera_vez);
    //console.log(checkbox_ya_en_uso);
    //console.log(checkbox_ya_en_uso.length);
    //Con este if evito que puedan agregar mas pallets de 24 que son los que caben en una caja
    if(checkbox_ya_en_uso.length==24){
      Swal.fire({
        icon: 'error',
        text: "Este manifiesto ya tiene ocupado los 24 lugares de la caja del trailer.",
        footer: '¿Que hacer? <br/> Se debe eliminar alguna tarima para liberar espacio y asi poder volver a agregar.'
      }) 
      setModalAñadir_Manifiesto_palletsON(false); 
    }

  var cantidad_checkboxs = [];
  //console.log(lugar_caja_vacio[0].lugar_caja_vacio);
  for(var i = 0; i<24; i++){
    var lugar_caja = i;
    var auxiliar = {lugar_caja};
    var auxiliar_bool = false;

    //Con este map verifico que si esta en uso no agregue el checkbox
    checkbox_ya_en_uso.map((dato)=>{
      if(dato == i+1){
        auxiliar_bool = true;
      }
    })

    if(auxiliar_bool == true){
    }else{
      cantidad_checkboxs.push(auxiliar);
    }
  }

  
  setLugares_de_caja(cantidad_checkboxs);
  console.log(cantidad_checkboxs);
}

const [Lugares_de_caja_seleccionados, setLugares_de_caja_seleccionados] = React.useState([]);

async function Seleccionar_checkbox(datos){
  await getLugares_caja(Manifiesto_Seleccionado).then((Lugar_cajaDB) => {   
        //console.log(Lugar_cajaDB);
        var prueba = Lugar_cajaDB[0].lugar_caja_vacio;
        var array_nuevo = [];
        prueba.map((dato, numero)=>{
          //console.log(numero, datos.lugar_caja+1);
            if(numero == datos.lugar_caja+1){
              //console.log(dato, numero)
              //console.log(dato)
              //console.log(dato.ocupado)
              var lugar_caja = {ocupado: !dato.ocupado, nombre_producto: ""};
              array_nuevo.push(lugar_caja);
            }else{
              array_nuevo.push(dato);
            } 
        })
        //console.log(array_nuevo);
        //console.log(!Lugares_de_caja_seleccionados[0].lugar_caja_vacio[auxiliar2]);
        var lugar_caja_vacio = array_nuevo;
        var infoProducto = "checkbox";
        var valor_manifiesto = Manifiesto_Seleccionado;
        var añadir_ok = "";
        añadirTarima_manifiesto(infoProducto, lugar_caja_vacio, valor_manifiesto, añadir_ok );

        //Esto es para guardar los checkbox que se seleccionaron
        //console.log(Lugares_de_caja_seleccionados)
        var axuliar100 = false;
        Lugares_de_caja_seleccionados.map((valor)=>{
          //console.log(valor)
          //console.log(datos.lugar_caja);
          const str = valor.toString();
          var nuevaStr = str.replace(",", "");
          if(nuevaStr==(datos.lugar_caja+1)){
            axuliar100 = true;
          }
        })

      //Con esto agrego al array los checkbox que selecciono y elimino si lo llego a desmarcar el mismo checkbox  
        if(axuliar100==true){
           //Poner un buscador de valor del arreglo y eliminarlo cuando auxiliar100 sea true
          //console.log("Entreo")
          var auxiliar120 = Lugares_de_caja_seleccionados;
          //const encontrar= auxiliar120.find(element=>element = datos.lugar_caja)
          auxiliar120.map((valor, index)=>{
            //console.log(index);
           //console.log(valor, ((datos.lugar_caja+1)+","));
            if(valor == ((datos.lugar_caja+1)+",")){
              //console.log("Hola")
              //console.log(auxiliar120);
              //console.log(index);
              auxiliar120.splice(index,1);
             // console.log(auxiliar120);
            }
          })
        }else{
          var auxiliar_cajas_seleccionadas = Lugares_de_caja_seleccionados;
          var auxiliar10 = (datos.lugar_caja+1)+",";
          auxiliar_cajas_seleccionadas.push(auxiliar10);
          setLugares_de_caja_seleccionados(auxiliar_cajas_seleccionadas);
        }
       
    })
}

const [valores_cajas, setvalores_cajas] = React.useState([]);

async function Lugares_en_la_caja(){
  await getLugares_caja(Manifiesto_Seleccionado).then((Lugar_cajaDB) => {
    console.log(Lugar_cajaDB)
    setvalores_cajas(Lugar_cajaDB);
    var lugar_caja_vacio = [""];
  //Si este manifiesto no tiene ningun pallet entonces se asignan todos los lugares del trailer en false
          if(Lugar_cajaDB == ""){
                for(var i = 0; i<24; i++){
                  //console.log(i)
                  var lugar_caja = {ocupado: false, nombre_producto: ""};
                  lugar_caja_vacio.push(lugar_caja);
                     }//Fin for
             var infoProducto = "";
             var valor_manifiesto = Manifiesto_Seleccionado;
             var añadir_ok = "";
             añadirTarima_manifiesto(infoProducto, lugar_caja_vacio, valor_manifiesto, añadir_ok );
             console.log(lugar_caja_vacio);
             var auxiliar_primera_vez = true;
             checkboxs(lugar_caja_vacio, auxiliar_primera_vez);
          }else{
            console.log("Tiene datos")
            checkboxs(Lugar_cajaDB);
            }
  });
}


//-------------------------------------------------------------------------------------------------------


//Con esto traes los pallet que tiene actualmente el manifiesto seleccionado
async function ActualizarPallets(Manifiesto_Seleccionado){
  var producto = Manifiesto_Seleccionado;
  console.log(producto)
  await getAllPallets(producto).then((PalletsDB) => {
    setManifiestos_pallets_datos(PalletsDB);
    setModalAñadir_Manifiesto_palletsON(false);
    console.log(PalletsDB);
    });
}

//Traer todos los productos de la categoria productos terminados del almacen de empaque ya que ahi estan los pallets
//Con este traes todos los tipos de pallet o caja del almacen productos terminado
const [Tipos_tarimas, setTipos_tarimas] = React.useState([]);
async function Traer_productos_almacen_empaque(){
  await getAlltarimas_almacen_empaque().then((TarimasDB) => {
    setTipos_tarimas(TarimasDB);
    console.log(TarimasDB);
    });
}


const [cerrarModal, setcerrarModal] = React.useState(true);

//Este es para la restar esquinero
async function productos_basededatos2(pagina){
  setcerrarModal(false)
  const nombreproducto2 = document.getElementById("tarima_seleccionada").value;
  var linea_donde_se_uso_producto = "";
  var producto_terminado_seleccionado = [];
  Productos_terminados.map((valor_producto)=>{
    if(valor_producto.nombre == nombreproducto2)
    {
      linea_donde_se_uso_producto = valor_producto.linea_de_produccion;
      producto_terminado_seleccionado = valor_producto;
    }
  })

  var esquineros_restados_final = cantidad_esquineros_actual - cantidad_esquineros_a_retirar;
  var auxiliar11 = esquineros_restados_final;
  var auxiliar12 = document.getElementById("esquinero_seleccionada").value;
  var auxiliar13 = cantidad_esquineros_a_retirar;
  editarCantidad_consumibles_tarima_esquinero({auxiliar11, auxiliar12 });
  let date3 = new Date();
  let date2 = "";
  var esquinero_actual = Esquinero_elegido;
  var tarima_actual = "";
  escribirLog_retirar_consumibles_tarima_esquinero("Retiro", usuario, auxiliar13, auxiliar12, date3, date2, Manifiesto_Seleccionado, 
  linea_donde_se_uso_producto, esquinero_actual, tarima_actual, producto_terminado_seleccionado);
  await timeout(1100);
  var esquinero_retirado_seleccionado = document.getElementById("esquinero_seleccionada").value;
  productos_basededatos22(pagina, date3, date2, esquinero_retirado_seleccionado, linea_donde_se_uso_producto, producto_terminado_seleccionado);
}

//Este es para la resta tarima
async function productos_basededatos22(pagina, date3, date2, esquinero_retirado_seleccionado, linea_donde_se_uso_producto, producto_terminado_seleccionado){
  console.log("HOLA")
  var esquineros_restados_final = cantidad_tarimas_actual - cantidad_tarimas_a_retirar;
  var auxiliar11 = esquineros_restados_final;
  var auxiliar12 = document.getElementById("tarima_a_consumir").value;
  var auxiliar13 = cantidad_tarimas_a_retirar;
  console.log(cantidad_tarimas_actual, cantidad_tarimas_a_retirar, cantidad_tarimas_a_retirar)
  editarCantidad_consumibles_tarima_esquinero({auxiliar11, auxiliar12 });
  console.log("HOLA")
  date2 = new Date();
  var esquinero_actual = "";
  var tarima_actual = Tarima_elegida;
  escribirLog_retirar_consumibles_tarima_esquinero("Retiro", usuario, auxiliar13, auxiliar12, date3, date2, Manifiesto_Seleccionado,
   linea_donde_se_uso_producto, esquinero_actual, tarima_actual, producto_terminado_seleccionado);
  await timeout(1000);
  var tarima_retirada_seleccionada = document.getElementById("tarima_a_consumir").value;
  productos_basededatos3(pagina, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, linea_donde_se_uso_producto);
}


//Este es la resta del producto terminado
async function productos_basededatos3(pagina, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, linea_donde_se_uso_producto){
  console.log("Hola")
  const nombreproducto = document.getElementById("tarima_seleccionada").value;
  console.log(Productos_terminados)

  Productos_terminados.map((valor_producto)=>{
    //console.log(valor_producto.nombre, nombreproducto)
    if(valor_producto.nombre == nombreproducto)
    {
      const precio2= valor_producto.precio;
      const moneda2= valor_producto.moneda;
      const unidad2= valor_producto.unidad;
      const cantidad2= valor_producto.cantidad;
      const nombre2= valor_producto.nombre;
      const kilos_por_cajas_por_pallet2= valor_producto.kilos_por_cajas_por_pallet;
      const cajas_por_pallet2= valor_producto.cajas_por_pallet;
      const categoria_pallet2= valor_producto.categoria_pallet;
      console.log(valor_producto)
      retirar_producto({unidad2, cantidad2, nombre2, valor_producto, nombreproducto, pagina, precio2, moneda2,
         kilos_por_cajas_por_pallet2, cajas_por_pallet2, categoria_pallet2, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, linea_donde_se_uso_producto});
    }
  })
} 


async function retirar_producto({unidad2, cantidad2, nombre2,nombreproducto, pagina, precio2, moneda2,
   kilos_por_cajas_por_pallet2, cajas_por_pallet2, categoria_pallet2, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado
  ,linea_donde_se_uso_producto}){
    console.log("Hola")
  let cantidad_restante_en_almacen_campo = 0;
    const valor_cantidad_ingresado2=document.getElementById("cantidad_retirada").value;
    var cantidad_de_cajas_necesarias = cajas_por_pallet * valor_cantidad_ingresado2;
     cantidad_restante_en_almacen_campo = cantidad2 - cantidad_de_cajas_necesarias;
     const valor_cantidad_ingresado = cantidad_de_cajas_necesarias;

      //let nombrecategoria = document.getElementById("categoria").value;
      let nombrecategoria = "Producto terminado";

    await editarCantidad_articulo_retirado({

          nombrecategoria,nombre2,
          cantidad_restante_en_almacen_campo, 
          valor_cantidad_ingresado,
          pagina
          })
        
          agregar_log({unidad2, valor_cantidad_ingresado, nombreproducto, precio2, moneda2, kilos_por_cajas_por_pallet2, cajas_por_pallet2, 
            categoria_pallet2, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, linea_donde_se_uso_producto})
}

async function agregar_log({unidad2, valor_cantidad_ingresado, nombreproducto, precio2, moneda2, kilos_por_cajas_por_pallet2, 
  cajas_por_pallet2, categoria_pallet2, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, linea_donde_se_uso_producto}){

  if(valor_cantidad_ingresado.length <= 0){
    console.log("yes");
    añadirProductoModal({unidad2,valor_cantidad_ingresado});
    }
    else{
    let date = new Date();
    var zona = "Embarque";
    var línea = linea_donde_se_uso_producto;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
    await escribirLog_restar_producto_terminado_embarque("Retiro", usuario,valor_cantidad_ingresado,nombreproducto, línea, fecha_actual, zona, precio2, 
    Manifiesto_Seleccionado, date3, date2, tarima_retirada_seleccionada,  esquinero_retirado_seleccionado, cajas_por_pallet);

    console.log("HOLA");
    añadir1({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, kilos_por_cajas_por_pallet2, cajas_por_pallet2, categoria_pallet2});
    }
}

//----------------------------------------------------------------------------
async function añadir1({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, kilos_por_cajas_por_pallet2, cajas_por_pallet2, categoria_pallet2}){
  await getLugares_caja(Manifiesto_Seleccionado).then((Lugar_cajaDB) => {
    console.log(Lugar_cajaDB)
    añadirProductoModal({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, kilos_por_cajas_por_pallet2, cajas_por_pallet2, Lugar_cajaDB, categoria_pallet2});
  })
}

 async function añadirProductoModal({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, kilos_por_cajas_por_pallet2, cajas_por_pallet2, Lugar_cajaDB, categoria_pallet2}) {
    //Esto sirve para la seleccion de la fecha
    //let date =fechaSeleccionada;
    //let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
    //obtener infor del formulario
    //console.log(kilos_por_cajas_por_pallet2, cajas_por_pallet2);
    console.log(Lugar_cajaDB[0]);
    var tarima_seleccionada = document.getElementById("tarima_seleccionada").value;
    console.log(Lugares_de_caja_seleccionados);
    Lugares_de_caja_seleccionados.map((auxiliar_map)=>{
      //Con esto convierto el saldo a valor flotante ya que tiene un $
      const str = auxiliar_map.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      var nuevaStr = str.replace(",", "");
                (Lugar_cajaDB[0].lugar_caja_vacio).map((auxiliar_map2, valor_numero)=>{
                  //console.log(auxiliar_map2, valor_numero)
                      if(valor_numero == nuevaStr){
                        Lugar_cajaDB[0].lugar_caja_vacio[valor_numero].nombre_producto = tarima_seleccionada;
                        console.log("Verdadero map 2")
                      }
                })//Fin segundo map
    })//Fin primer map

    //Con esto le agrego el nombre del pallet a las caja seleccionadas-------------------
    //console.log(Lugar_cajaDB[0].lugar_caja_vacio);
    var lugar_caja_vacio = Lugar_cajaDB[0].lugar_caja_vacio;
    var infoProducto2 = "producto_nombre";
    var valor_manifiesto = Manifiesto_Seleccionado;
    var añadir_ok = ""; 
    añadirTarima_manifiesto(infoProducto2, lugar_caja_vacio, valor_manifiesto, añadir_ok );
    //-----------------------------------------------------------------------------------

    var checkbox_seleccionados = Lugares_de_caja_seleccionados;
    const fecha = fecha_actual;
    const cantidad_retirada = document.getElementById("cantidad_retirada").value;
    const usuario2 = usuario.email;
    const manifiesto = Manifiesto_Seleccionado.manifiesto;
    const kilos_por_cajas_por_pallet = kilos_por_cajas_por_pallet2;
    const cajas_por_pallet = cajas_por_pallet2;
    const categoria_pallet = categoria_pallet2;
    // enviar informacion a firebase
    const infoProducto = { cantidad_retirada, fecha, usuario2, manifiesto, tarima_seleccionada, kilos_por_cajas_por_pallet,cajas_por_pallet, 
      checkbox_seleccionados, categoria_pallet };
    var añadir_ok = "añadir_final";
    var lugar_caja_vacio = "";
    var valor_manifiesto = "";
    añadirTarima_manifiesto(infoProducto, lugar_caja_vacio, valor_manifiesto, añadir_ok );
    // cerrar modal
    await timeout(500);
    ActualizarPallets(Manifiesto_Seleccionado);
    setModalAñadir_Manifiesto_palletsON(false); 
  }

  
  React.useEffect(() => {
    Traer_productos_almacen_empaque();
    //checkboxs();
    Lugares_en_la_caja();
    traer_consumibles();
    traer_productos_terminados();
    /* actualizarEstadoProductos2(); */
    /* productos_basededatos();
    traer_folio_nuevo(); */
  }, []);

  return (
    <Modal show={ModalAñadir_Manifiesto_palletsON} onHide={() => {
      setModalAñadir_Manifiesto_palletsON(false);
    }}>
      <Modal.Header>
        <Modal.Title>Nuevo pallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
            <h6>Seleccionar producto terminado</h6>
            <select id="tarima_seleccionada" type="text">
            {Tipos_tarimas.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>

            <br/>
            <h6>Seleccionar el tipo de tarima a usar</h6>
            <select id="tarima_a_consumir" type="text">
            {Tarimas_BD.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>

            <h6>Seleccionar el tipo de esquinero a usar</h6>
            <select id="esquinero_seleccionada" type="text">
            {esquineros_BD.map((datos,index)=>( 
                <option key={index}>{datos.nombre}</option>
            ))}
            </select>
            <br/>

          <Form.Control
              id="cantidad_retirada"
              placeholder="Ingresar cantidad de pallets a retirar"
              type="text"
              className="mb-1"
              /* onChange={() => cantidad_ingresada()}  */
            />   
      <br/>
      {cantidad_agregada_ok && (
        <div>
          <h6>Seleccionar lugar en la caja</h6>
            <div id="Embarque" style={{ background: "white" }}>        
                    {Lugares_de_caja.map((datos,index)=>( 
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" key={index+50} value="" id={datos.lugar_caja} onChange={() => Seleccionar_checkbox(datos)} />
                          <label id="checkbox_embarque" className="form-check-label" for="flexCheckDefault" key={index}>
                            {datos.lugar_caja + 1}
                          </label>
                        </div>
                    ))}
            </div>
        </div>
      )}
      

          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {cerrarModal && (
          <>
               <Button variant="secondary" onClick={() => setModalAñadir_Manifiesto_palletsON(false)}>
                  Cancelar
                </Button>

                  {cantidad_agregada_ok && (
                    <Button variant="primary" onClick={productos_basededatos2}>
                      Añadir
                    </Button>
                  )}
                  {!cantidad_agregada_ok && (
                    <Button variant="primary" onClick={continuar_con_el_proceso}>
                      Continuar
                    </Button>
                  )}
        </>

        )}
 
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir_Manifiesto_pallets;