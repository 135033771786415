import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editar_usuario from "../../functions/Usuarios/editar_usuario";

function ModalEditar_usuarios({
  Modal_Editar,
  setModalEditar,
  UsuarioEditar,
  Traer_usuarios,
}) {

  function editarProductoModal() {
    //obtener infor del formulario
    const correo = UsuarioEditar.correo;
    const rol = document.getElementById("rol").value;
    const uid = UsuarioEditar.uid;
    console.log(correo);
    // enviar informacion a firebase
    const infoProducto = {correo, rol, uid};
    //const infoProducto = { fecha, almacen, proveedor, moneda, subtotal, total, iva, sku };
    editar_usuario(infoProducto);
    // cerrar modal
    Traer_usuarios();
    setModalEditar(false);
  }

  return (
    <Modal
      show={Modal_Editar}
      onHide={() => {
        setModalEditar(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            <h6>Seleccionar nivel de acceso del usuario</h6>
            <select id="rol">
              <option >Administrador</option>
              <option >Usuario campo</option>
              <option >Usuario empaque</option>
              <option >RH</option>
              <option >Compras</option>
            </select>
        
      </Modal.Body>

      <Modal.Footer>       
        <Button
          variant="secondary"
          onClick={() => {
            setModalEditar(false);
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={editarProductoModal}>
          Editar
        </Button> 

      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_usuarios;

