import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, getDocs, document } from "firebase/firestore";
const db = getFirestore(firebaseApp);
   

export default async function getProductos_carton() {
    
    const cartonDB =  [];
    const collectionRef = collection(db, "Almacen_empaque/Carton/Productos".replace(/ /g, ""));    
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      cartonDB.push(doc.data());
    });
    return cartonDB;
}