import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllsecciones() {
  const SeccionesDB = [];
  //console.log(pagina2)

  let collectionRef = collection(db, "Campo_secciones/".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  snapshot.forEach((doc) => {
    SeccionesDB.push(doc.data());
  });
  return SeccionesDB;
}
