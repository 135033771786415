import { Modal, Stack, Form, Button } from "react-bootstrap";
import React from "react";
import Crearusuario from "../../functions/Usuarios/Crearusuario"
import firebaseApp from "../../firebase/credenciales";
import Swal from "sweetalert2";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";
const auth = getAuth(firebaseApp);

function ModalAñadir({
  Modal_Añadir,
  setModalAñadir,
  Traer_usuarios,
}) {

async function agregar(){
  var estado= false;
  const firestore = getFirestore(firebaseApp);
  const Correo = document.getElementById("Correo").value;
  const Contraseña = document.getElementById("Contraseña").value;
  const rol = document.getElementById("rol").value;
  const auth = getAuth();
 const infoUsuario = await createUserWithEmailAndPassword(auth, Correo, Contraseña)
  .then((usuarioFirebase) => {
    return usuarioFirebase;
  })
  .catch((error) => {
    console.log(error);
    estado = true;
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
    //alert("Usuario ya existente, intente nuevamente por favor");
    Swal.fire({
      icon: 'error',
      text: "Falta el @ al email, email ya en uso o contraseña muy corta.",
      footer: '¿Que hacer? <br/> Se debe ingresar un email el cual contenga @luipack.com por ejemplo y una contraseña que contenga minimo 6 caracteres.'
    })
    
  });
  console.log(estado);
  if(estado){
  }else{
    Swal.fire({
      icon: 'success',
      text: "Usuario agregado de exitosamente.",
    })
    Traer_usuarios();
  }

  console.log(Correo, Contraseña, rol)
  console.log(infoUsuario.user.uid)
  const docuRef = doc(firestore, `Usuarios/${infoUsuario.user.uid}`);
  setDoc(docuRef, {correo: Correo, rol: rol, uid: infoUsuario.user.uid});
  setModalAñadir(false); 
}


  return (
    <Modal show={Modal_Añadir} onHide={() => setModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Agregar nuevo usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>
          <Form.Label>Correo</Form.Label>
            <Form.Control 
              type="email" 
              id="Correo"
              placeholder="Ingresa tu correo electrónico"  
              name="email"
            />
          <Form.Label>Contraseña</Form.Label>
            <Form.Control
              id="Contraseña"
              placeholder="Contraseña"
              type="text"
              className="mb-1"
            />
            <br/>
            <h6>Seleccionar nivel de acceso del usuario</h6>
            <select id="rol">
              <option >Administrador</option>
              <option >Compras</option>
              <option >Contabilidad</option>
              <option >Usuario campo</option>
              <option >Usuario empaque</option>
              <option >RH</option>
            </select>
            

          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => agregar()}>
          Añadir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
