import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_venta(infoProducto, Boton_seleccionado_costos) {
  console.log(infoProducto)
  console.log(Boton_seleccionado_costos)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Cultivo_en_proceso/"+Boton_seleccionado_costos+"/Ventas/");
  const docRef = doc(collectionRef, (infoProducto.fecha).toString());
  setDoc(docRef, infoProducto);
}

export default añadir_venta;
