import firebaseApp from "../../Firebase/firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadir_categoria_produccion(infoProducto) {
  console.log(infoProducto.valor)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Botones_categorias_produccion".replace(/ /g, ""))
  const docRef = doc(collectionRef, infoProducto.valor);
  setDoc(docRef, infoProducto);
}

export default añadir_categoria_produccion;
