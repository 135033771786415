import firebaseApp from "../firebase/credenciales";
import Swal from "sweetalert2";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const auth = getAuth(firebaseApp);

async function loginEmailPassword (email, password){

  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    //alert("Usuario no encontrado, intente nuevamente por favor");
    Swal.fire({
      icon: 'error',
      text: "El Email o la contraseña estan incorrectos, por favor vuelva a intentar.",
      footer: '¿Que hacer? <br/> Se debe ingresar un email y contraseña los cuales ya se encuentren registrados en la empresa.'
    })

  });
};

export default loginEmailPassword;
