import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import añadirProducto from "../../functions/Produccion/añadirProducto";
import getAllProducts from "../../functions/Produccion/getAllProducts";
import getProductos from "../../functions/Produccion/getProductos";
import getProductos2 from "../../functions/Produccion/getProductos2";
import editarCantidad_articulo_retirado from "../../functions/Produccion/editarCantidad_articulo_retirado"
import escribirLog_retirar_producto from "../../functions/Produccion/escribirLog_retirar_producto";
import getAllCosecha from "../../functions/Produccion/getAllCosecha";
import Swal from "sweetalert2";
import {DatePicker} from "@material-ui/pickers";


function ModalAñadir({
  isModalAñadir,
  setIsModalAñadir,
  actualizarEstadoProductos,
  usuario,
  pagina,
  start,
  setProductos,
  Embarque_cliente,
  Gastoson,
}) {

  console.log(Embarque_cliente);
  async function actualizar(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);    
     });
   }

   const [Cosechas, setCosechas] = React.useState([]);

   async function traer_cosechas() {
    await getAllCosecha().then((CosechaDB) => {
      console.log(CosechaDB)
      setCosechas(CosechaDB);    
     });
   }
 
  //Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
  function timeout(delay){
    return new Promise(res => setTimeout(res, delay));
  }

//Convertir a formato moneda
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

//Traer las categorias de los botones del almacen de empaque
const [Botones_empaque, setBotones_empaque] = React.useState([]);
async function botones(){
  /* await getCategorias_botones_empaque().then((Categorias_botonesDB) => {
      console.log(Categorias_botonesDB) 
      setBotones_empaque(Categorias_botonesDB);
 }); */
}

  //Traer categorias de productos del almacen campo
  const [Datosproducto, setDatosproducto] = React.useState([]);
  
  async function productos_basededatos(pagina){
    //const nombrecategoria = document.getElementById("categoria").value;
    let nombrecategoria = pagina;
    console.log(Embarque_cliente);
    if(Embarque_cliente){
      nombrecategoria = "Producto terminado";
     }else{nombrecategoria = pagina;}
     console.log(nombrecategoria);
    await getProductos(nombrecategoria).then((productosDB) => {
      setDatosproducto(productosDB);
      console.log(productosDB);
    });
  }

//aqui se trea la informacion del producto seleccionado
async function productos_basededatos2(pagina){
  const nombreproducto = document.getElementById("producto").value;
  //const nombrecategoria = document.getElementById("categoria").value;
  let nombrecategoria = pagina;
    if(Embarque_cliente){
      nombrecategoria = "Producto terminado";
     }else{nombrecategoria = pagina;}
  console.log(nombrecategoria);
  await getProductos2({nombreproducto,nombrecategoria}).then((productosDB2) => {
    const precio2= productosDB2[0].precio;
    const moneda2= productosDB2[0].moneda;
    const unidad2= productosDB2.map(dato=> dato.unidad);
    const cantidad2= productosDB2.map(dato=> dato.cantidad);
    const nombre2= productosDB2.map(dato=> dato.nombre);

    retirar_producto({unidad2, cantidad2, nombre2, productosDB2, nombreproducto, pagina, precio2, moneda2});
    //añadirProductoModal({unidad2,valor_cantidad_ingresado});
  });
}


async function retirar_producto({unidad2, cantidad2, nombre2,productosDB2,nombreproducto, pagina, precio2, moneda2}){
  let cantidad_restante_en_almacen_campo = 0;
    const valor_cantidad_ingresado=document.getElementById("cantidad").value;

    //Con esto hago la resta para que no me de error
    if(valor_cantidad_ingresado <= parseFloat(cantidad2)){
      var auxiliar = formatter.format(cantidad2 - valor_cantidad_ingresado);
      const str = auxiliar.toString();
      //Con esto le quito el signo de $ y con los otros le quito 3 comas a la cantidad
      const nuevaStr = str.replace("$", "");
      const nuevaStr2 = nuevaStr.replace(",", "");
      const nuevaStr3 = nuevaStr2.replace(",", "");
      cantidad_restante_en_almacen_campo = nuevaStr3.replace(",", "");

    //Crear nuevo array del producto pero con la cantidad retirada restada  
    const producto_retirado= productosDB2.map(dato=> [{
      cantidad: cantidad_restante_en_almacen_campo,
      medida: dato.medida,
      moneda: dato.moneda,
      nombre: dato.nombre,
      precio: dato.precio,
      },]
      )

       
      const producto_retirado_final=producto_retirado[0];
      //let nombrecategoria = document.getElementById("categoria").value;
      let nombrecategoria = pagina;
    if(Embarque_cliente){
      nombrecategoria = "Producto terminado";
     }else{nombrecategoria = pagina;}

    await editarCantidad_articulo_retirado({
          producto_retirado_final,
          nombrecategoria,nombre2,
          cantidad_restante_en_almacen_campo, 
          valor_cantidad_ingresado,
          pagina
          })
        
          agregar_log({unidad2, valor_cantidad_ingresado, nombreproducto, precio2, moneda2})
          

    }else {
      //alert("La cantidad solicitada a retirar es menor a la existente actualmente en almacen o falto ingresar algun dato, la cantidad disponible en almacen del articulo que se desea retirar es "+cantidad2)
      Swal.fire({
        icon: 'error',
        text: "La cantidad solicitada a retirar es menor a la existente actualmente en almacen o falto ingresar algun dato, la cantidad disponible en almacen del articulo que se desea retirar es "+cantidad2 +
        " y usted quizo retirar "+ valor_cantidad_ingresado,
        footer: '¿Que hacer? <br/> Se debe agregar cantidad adecuada al articulo o producto que desea consumir en la sección de almacen correspondiente'
      })
    }
}


async function agregar_log({unidad2, valor_cantidad_ingresado, nombreproducto, precio2, moneda2}){
  var línea = "";
  if(Embarque_cliente){línea = "Embarque"}else{ if(Gastoson){línea = "Gastos indirectos de producción"}else{línea = document.getElementById("linea").value;}}
  
  console.log(valor_cantidad_ingresado.length);
  if((línea=="Seleccionar") || (valor_cantidad_ingresado.length <= 0)){
    console.log("yes");
    añadirProductoModal({unidad2,valor_cantidad_ingresado});
    }
    else{
    let date = new Date();
    var zona = "";
    var Manifiesto_Seleccionado = "";
    //Esto sirve para la seleccion de la fecha
    let date2 =fechaSeleccionada;
    let fecha_actual3 = String(date2.getDate()).padStart(2, '0') + '-' + String(date2.getMonth() + 1).padStart(2, '0') + '-' + date2.getFullYear() ;

    if(Embarque_cliente){zona = pagina;}
    var cosecha_seleccionada_retirada = document.getElementById("cosecha_seleccionada").value;
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
    await escribirLog_retirar_producto("Retiro", usuario, valor_cantidad_ingresado,nombreproducto, línea, fecha_actual, zona, precio2, Manifiesto_Seleccionado, fecha_actual3, moneda2, pagina, cosecha_seleccionada_retirada);
    console.log("HOLA");
      añadirProductoModal({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, cosecha_seleccionada_retirada});
    }
}

//Fecha
const [fechaSeleccionada, setfechaSeleccionada] = React.useState(new Date());


async function añadirProductoModal({unidad2, valor_cantidad_ingresado, precio2, moneda2, fecha_actual, línea, cosecha_seleccionada_retirada}) {

   //Este if es para validar que se ingrese un valor en el input de cantidad
    if(valor_cantidad_ingresado.length > 0){
      var linea = "";
     if(Embarque_cliente){linea = "Embarque"}else{ if(Gastoson){linea = "Gastos indirectos de producción"}else{linea = document.getElementById("linea").value;}}
      if(linea=="Seleccionar"){
        Swal.fire({
          icon: 'error',
          text: "No se selecciono LÍNEA",
          footer: '¿Que hacer? <br/> Se debe agregar la línea a cargar el producto en el formulario.'
        })}else{

      
          //obtener infor del formulario
          let date = new Date();
          let fecha_actual2 = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() ;
          
          //Esto sirve para la seleccion de la fecha
          let date2 =fechaSeleccionada;
          let fecha_actual3 = String(date2.getDate()).padStart(2, '0') + '-' + String(date2.getMonth() + 1).padStart(2, '0') + '-' + date2.getFullYear() ;
          const fecha = fecha_actual3;  
          const fecha2 = fecha_actual2;
          const fecha3 = date2;
          const precio = precio2;
          const nombre = document.getElementById("producto").value;
          const cantidad = document.getElementById("cantidad").value;
          const unidad = unidad2.toString();
          const usuario2 = usuario.email;
          const moneda = moneda2;
          const subtotal = precio*cantidad;
          const categoria = pagina;
          var Cosecha_seleccionada_retirada = cosecha_seleccionada_retirada;
          console.log(categoria);
          let cliente = "";
          var zona = "";
          if(Embarque_cliente){
           zona = pagina;
           cliente = document.getElementById("cliente").value;
          }else{ cliente = "";}

          // enviar informacion a firebase
          const infoProducto = { fecha, fecha2, fecha3, nombre, cantidad, unidad, usuario2, cliente, linea, precio, moneda, subtotal, zona, categoria, Cosecha_seleccionada_retirada};
          añadirProducto(infoProducto, usuario.email,pagina);
          // cerrar modal
          await timeout(500);
          actualizar(pagina,start);
          setIsModalAñadir(false);
      }}else{
        //alert("Cantidad no ingresada")
        Swal.fire({
          icon: 'error',
          text: "Cantidad no ingresada",
          footer: '¿Que hacer? <br/> Se debe agregar una cantidad en el formulario.'
        })
      }

}


/* 
  function añadirProductoModal({precio2, unidad2, cantidad2, productosDB2,nombre2}) {

    let cantidad_restante_en_almacen_campo = 0;
    const valor_cantidad_ingresado=document.getElementById("cantidad").value;

    if(valor_cantidad_ingresado <= parseInt(cantidad2)){
     cantidad_restante_en_almacen_campo = cantidad2 - valor_cantidad_ingresado;

    //Crear nuevo array del producto pero con la cantidad retirada restada  
    const producto_retirado= productosDB2.map(dato=> [{
      cantidad: cantidad_restante_en_almacen_campo,
      medida: dato.medida,
      moneda: dato.moneda,
      nombre: dato.nombre,
      precio: dato.precio,
      },]
      )
      
      const producto_retirado_final=producto_retirado[0];
      let nombrecategoria = document.getElementById("categoria").value;
      editarCantidad_articulo_retirado({
        producto_retirado_final,
        nombrecategoria,nombre2,
        cantidad_restante_en_almacen_campo, 
        valor_cantidad_ingresado,
         pagina
        })

        agregar_log()

     //Este if es para validar que se ingrese un valor en el input de cantidad
      if(valor_cantidad_ingresado.length > 0){
            //obtener infor del formulario
            let date = new Date();
            let fecha_actual = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
            const fecha = fecha_actual;
            const nombre = document.getElementById("producto").value;
            const cantidad = document.getElementById("cantidad").value;
            const unidad = unidad2.toString();
            const usuario2 = usuario.email;
            const sku = document.getElementById("sku").value;
            // enviar informacion a firebase
            const infoProducto = { fecha, nombre, cantidad, unidad, usuario2, sku };
            añadirProducto(infoProducto, usuario.email,pagina);
            // cerrar modal
            actualizarEstadoProductos();
            setIsModalAñadir(false);
        }else{alert("Cantidad no ingresada")}

    }else {
      alert("La cantidad solicitada a retirar es menor a la existente actualmente en almacen o falto ingresar algun dato, la cantidad disponible en almacen del articulo que se desea retirar es "+cantidad2)
    }

    
  } */

  React.useEffect(() => {
    botones();
    productos_basededatos(pagina);
    traer_cosechas();
  }, []);

  return (
    <Modal show={isModalAñadir} onHide={() => setIsModalAñadir(false)}>
      <Modal.Header>
        <Modal.Title>Retirar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack>

            {Embarque_cliente || Gastoson ? 
            null
            :
            <div>
            <h6>Seleccionar línea en la que se consumira</h6>
            <select
                    id="linea" 
                    type="text" 
                    >
                <option>Seleccionar</option>
                <option>Tomate</option>
                <option>Calabaza</option>
                <option>Chile</option>
                <option>Tomatillo</option>
            </select>
            <br/>
            <br/>
            <h6>Seleccionar categoría</h6>
            <select 
                id="cosecha_seleccionada" 
                type="text"
                >
                  {Cosechas.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select> 

            </div>
            }
            <br/>
            <h6>Seleccionar fecha dando click</h6>
            <DatePicker value={fechaSeleccionada} onChange={setfechaSeleccionada}/>
           
            
            <br/>
            <h6>Seleccionar producto</h6>
            {Datosproducto ? 
                <select 
                id="producto" 
                type="text"
                >
                  {Datosproducto.map((datos,index)=>( 
                      <option key={index}>{datos.nombre}</option>
                  ))}
                </select>                
            :
            null
            }  
            <br/>

            <Form.Control
              id="cantidad"
              placeholder="Cantidad"
              type="text"
              className="mb-1"
            />
            <br/>
          {Embarque_cliente &&
          <Form.Control
          id="cliente"
          placeholder="Ingresar cliente"
          type="text"
          className="mb-1"
            />
          }  
            
            {/* <Form.Control
              id="sku"
              placeholder="sku"
              type="text"
              className="mb-1"
            /> */}
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalAñadir(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>productos_basededatos2(pagina)}>
          Retirar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAñadir;
