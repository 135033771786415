import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, getDocs, document } from "firebase/firestore";
const db = getFirestore(firebaseApp);
   

export default async function getProductos_cosecha() {
    
    const cosechaDB =  [];
    const collectionRef = collection(db, "Almacen_empaque/Cosecha/Productos".replace(/ /g, ""));    
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      cosechaDB.push(doc.data());
    });
    return cosechaDB;
}