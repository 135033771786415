import firebaseApp from "../../firebase/credenciales";
import React from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

//const {contextData} = useDataContext();
    

export default async function getProductos(nombrecategoria) {
    

  console.log(nombrecategoria);
    const productosDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_empaque/"+nombrecategoria+"/Productos".replace(/ /g, ""));
    console.log(collectionRef)
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      productosDB.push(doc.data());
    });
    return productosDB;
    

}


//"+productofolio+"
