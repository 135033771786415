import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle , Dropdown} from 'reactstrap';
import "../../index.css"

const Navbar = () =>{
  const [dropdown, setDropdown ] = useState(false);

  const abrirCerrarDropdown = () =>{
    setDropdown(!dropdown);
  }

//<img src='./Imagenes/tomate2.png' width='50' />
    return(
<div id="Navbar">
  <nav className="navbar navbar-expand-lg navbar-light bg-light" >
      <div className="container-fluid" >
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <Link to='/'>
                 <img src='./Imagenes/luipack.ico' width='55' id="img_luipack" />
                </Link>
                <li className="nav-item">
                  <Link className="nav-link active" to='/' >Inicio</Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link active" to='/Login'>Login</Link>
                </li>

                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#" 
                  data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls
                  >Contactos</a>
                </li>

                <Dropdown isOpen= {dropdown} toggle = {abrirCerrarDropdown} >
                  <DropdownToggle caret className="nav-item" id="Dropdown-nav-inicio" style={{ background: "#F8F9F7"  }} >
                    Productos
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem><Link className="nav-link active" to='/Tomate' >Tomate</Link></DropdownItem>
                    <DropdownItem><Link className="nav-link active" to='/Calabazas'>Calabaza</Link></DropdownItem>
                    <DropdownItem><Link className="nav-link active" to='/Jalapenos'>Chile</Link></DropdownItem>
                    <DropdownItem><Link className="nav-link active" to='/Trigo'>Trigo</Link></DropdownItem>
                    
                  </DropdownMenu>

                </Dropdown>

                
                
              </ul>
        </div>
      </div>
      
    </nav>
        <div className='Contenedor_canvas'>
           {/* Donde dice tab tabIndex iba  tabindex*/}
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h2 className="offcanvas-title" id="offcanvasExampleLabel">Directorio</h2>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div id="Canvas_contactos" className="offcanvas-body">
                <div>
                      <br/>
                      <div>
                        Celular:
                      </div>
                      <div>
                        6444-11-11-11.
                      </div>

                      <br/>
                      <br/>

                      <div>
                        Dirección
                      </div>
                      <div>BLVD Emiliano zapata #804 PTE</div>
                      <div>Pueblo Yaqui, Sonora.</div>

                      <br/>
                      <br/>

                      <div>
                        Correo
                      </div>
                      <div>horticolaluipack@luipack.com.mx</div>
                </div>
            </div>
            </div>
        </div> 
  </div> 
    );
}

export default Navbar;