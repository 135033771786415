import React from "react";
import { Modal, Button, Table} from "react-bootstrap";
import "../../../../index.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import eliminarLog from "../../functions/Almacen_empaque/eliminarLog";
import getLogs_almacen_empaque2 from "../../functions/Almacen_empaque/getLogs_almacen_empaque2";
import getProductos_empaque from "../../functions/Almacen_empaque/getProductos_empaque";
import getAllProducts from "../../functions/Almacen_empaque/getAllProducts";
import Swal from "sweetalert2";

function ModalLogs({
  setIsModalLogs,
  Producto_seleccionado,
  pagina,
  start,
  setProductos,
}) {

  //Eliminar con sweetalert2
  function eliminar_sweetalert(producto, pagina) {
    Swal.fire({
      title: '¿Esta seguro de eliminar el producto?  "'+ producto.Producto_retirado+'"',
      text: "Esta acción no se puede restablecer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto ha sido eliminado.',
          'success'
        )
        restar_tarimas(producto, pagina).then(
          (confirmacion) => {
            Historial2(Producto_seleccionado);
          }
        );
      }
    })
  }

//Este sirve para esperar el tiempo que quieras ya que no se actualizaba correcamente
function timeout(delay){
  return new Promise(res => setTimeout(res, delay));
}


  const [Datos, setDatos] = React.useState([]);

  async function Historial2(Producto_seleccionado){    
    await getLogs_almacen_empaque2(Producto_seleccionado).then((Logs) => {
/*       console.log(Producto_seleccionado);
      console.log(Logs); */
      //Con esto lo ordeno por fechas de forma descendiente
      Logs.sort((a,b)=>  (b.fecha3) - (a.fecha3));
      console.log(Logs);
      setDatos(Logs);
      Iniciar_modal();
    });
  }

  async function restar_tarimas(producto, pagina, start) {
    console.log("PRUBA")
    await getProductos_empaque(pagina).then((productos) => {
      console.log(productos);
      var cantidad_actual = 0;
      productos.map((dato)=>{
      if(dato.nombre==producto.Producto_retirado){
        cantidad_actual=dato.cantidad;
      }
     }); //Fin map
     console.log(cantidad_actual);
     console.log(producto);
      eliminarLog(producto, pagina, cantidad_actual);
      restar_tarimas2(pagina, start);
    });
  }

  async function restar_tarimas2(pagina, start) {
      await timeout(1000);
      actualizarEstadoProductos2(pagina, start);
  }

  async function actualizarEstadoProductos2(pagina, start) {
    await getAllProducts(pagina, start).then((productos) => {
      console.log(productos)
       setProductos(productos);    
     });
   }

  //Produccion diaria grafia
const state = {
  labels: ['Tomate', 'Chile', 'Calabaza',
           'Tomatillo'],
  datasets: [
    {
      label: 'Producción diaria (pallets)',
      backgroundColor: [
      '#22B419',
      '#E0F625',
      '#E43C12',
      '#F17F14',
      '#146BF1'],
      hoverBackgroundColor: [
        '#187412',
        '#98A71D',
        '#962C12',
        '#975312',
        '#0D3C85'],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [1, 2, 3, 4]
    },
    {
      label: 'Producción diaria (Cajas)',
      backgroundColor: [
      '#32EA27',
      '#E6F378',
      '#F07A5C',
      '#F4AA64',
      '#7BAAF3'],
      hoverBackgroundColor: [
        '#187412',
        '#98A71D',
        '#962C12',
        '#975312',
        '#0D3C85'],
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [1, 2, 3, 4]
    }
  ]
}
//-------------------------------------------------------------------------------------

  function salir(){
    setIsModalLogs(false)
  }

  const [Graficas_on, setGraficas_on] = React.useState(false);

  function Graficas(){
    setGraficas_on(false);
  }

  function Iniciar_modal(){
    setGraficas_on(true);
  }

  React.useEffect(() => {
    Historial2(Producto_seleccionado);
  }, []);

  const valor = (Datos == "");
  return (
   <div >
    <Modal id="Modal_Logs"className="container-fluid" show={Graficas_on} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Historico de movimientos</Modal.Title>
        {valor?
        null : 
        <ReactHTMLTableToExcel
        id="botonExportarExcel"
        className="btn btn-success"
        table="Tabla_logs"
        filename={Datos[0].Producto_retirado}
        sheet="Movimientos"
        buttonText="Descargar Excel"
        />}

       {/* <Button variant="primary" onClick={() => {
                      Graficas();
                    }}>
          Gráficas
        </Button> */}

       <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" className="container-fluid">
        <thead>
          <tr>
            <th>#</th>
            <th>Autor</th>
            <th>Producto retirado</th>
            <th>Cantidad retirada</th>
            <th>Cantidad agregada</th>
            <th>Línea donde se uso</th>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Acción o movimiento</th>
            <th>Manifiesto</th>
          </tr>
        </thead>
        <tbody>
          {
            Datos.map((producto, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{producto.Autor}</td>
                <td>{producto.Producto_retirado}</td>
                <td>{producto.Cantidad_retirada}</td>
                <td>{producto.Cantidad_agregada}</td>
                <td>{producto.Seccion}</td>
                <td>{producto.fecha}</td>
                <td>{producto.Hora}</td>
                <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                  {producto.Movimiento? 
                      producto.Movimiento
                      :  
                          <>
                              {producto.Manifiesto? 
                                <>
                                </>
                                :  
                                <Button
                                variant="danger"
                                onClick={() => {
                                  eliminar_sweetalert(producto, pagina);
                                }}
                              >
                                Eliminar
                              </Button>
                              }
                          </>   

                    }
                </td>
                <td>
                      {/* Con esto solo agrego el boton de eliminar a los que fueron agregados desde el concentrado y no desde las ordenes de compra */}
                  {producto.Manifiesto? 
                      producto.Manifiesto
                      :  
                      <>
                        <p>{producto.Manifiesto}</p>
                      </>
                    }
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>

{/* //------------------------------------------------------------------------- */}
<Modal id="Modal_Logs"className="container-fluid" show={!Graficas_on} onHide={() => null}>
      <Modal.Header  >
        <Modal.Title>Historico de movimientos con gráficas</Modal.Title>

       <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button>

      </Modal.Header>
      <Modal.Body>
      {/* <Container id="Grafica">
      <Bar
            data={state}
            options={{
              title:{
                display:true,
                responsive:true,
                text:'Average Rainfall per month',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          />
      </Container> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
                      salir();
                    }}>
          Cerrar
        </Button> 
      </Modal.Footer>
    </Modal>


  </div> 
  );
}

export default ModalLogs;
