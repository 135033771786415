import firebaseApp from "../../firebase/credenciales";
import { getFirestore, deleteDoc, collection, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function eliminarpallets_embarque(datos) {
  const coleccionRef = collection(db, "Productos/".replace(/ /g, ""));
  const docuRef = doc(coleccionRef, datos.nombre);
  const eliminado = await deleteDoc(docuRef);

  return eliminado;
}
