import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editarCodigo from "../../functions/codigos_QR/editarCodigo";
import Swal from "sweetalert2";

function ModalEditar_QR({
  isModalEditar,
  setisModalEditar,
  ProductoEditar,
  actualizardatos,
}) {

 function añadirProductoModal(){
  const direccion = document.getElementById("direccion").value;
  const telefono = document.getElementById("telefono").value;
  const producto = document.getElementById("producto").value;
  const nombre = ProductoEditar.nombre;
  // enviar informacion a firebase
  const infoProducto = {nombre, direccion, telefono, producto };
  editarCodigo(infoProducto)
  // cerrar modal
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Se edito correctamente.',
    showConfirmButton: false,
    timer: 1500
  })
  actualizardatos();
  setisModalEditar(false);

 }
   
 const [productoEstado, setProductoEstado] = React.useState({
  ...ProductoEditar,
});

  return (
    <Modal show={isModalEditar} onHide={() => setisModalEditar(false)}>
      <Modal.Header>
        <Modal.Title>Editar etiqueta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Stack> 
          <Form.Control
              id="direccion"
              placeholder="Agregar dirección"
              type="text"
              className="mb-1"
              value={productoEstado?.direccion}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  direccion: e.target.value,
                })
              }
            />
          <Form.Control
              id="telefono"
              placeholder="Agregar dirección"
              type="text"
              className="mb-1"
              value={productoEstado?.telefono}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  telefono: e.target.value,
                })
              }
            />
         <Form.Control
              id="producto"
              placeholder="Agregar dirección"
              type="text"
              className="mb-1"
              value={productoEstado?.producto}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  producto: e.target.value,
                })
              }
            /> 
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setisModalEditar(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=> añadirProductoModal()}>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_QR;
