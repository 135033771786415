import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";
import editarProducto from "../../functions/Produccion/Presentaciones/editarProducto";

function ModalEditar_presentaciones({
  ModalEditar_presentaciones_productos,
  setModalEditar_presentaciones_productos,
  actualizarEstadoProductos,
  productoEditar,
  setProductoEditar,
  usuario,
  pagina,
  Presentacion,
}) {

  function editarProductoModal(Presentacion) {
    console.log(productoEditar.usuario2)
    //obtener infor del formulario
    let date = new Date();
    let fecha_actual = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
    const fecha = fecha_actual;
    const nombre = document.getElementById("producto").value;
    const cantidad = document.getElementById("cantidad").value;
    const unidad = document.getElementById("unidad").value;
    const usuario2 = productoEditar.usuario;
    // enviar informacion a firebase
    const infoProducto = { fecha, nombre, cantidad, unidad, usuario2};
    editarProducto(infoProducto, Presentacion);
    // cerrar modal
    setProductoEditar(null);
    actualizarEstadoProductos();
    setModalEditar_presentaciones_productos(false);
  }

  const [productoEstado, setProductoEstado] = React.useState({
    ...productoEditar,
  });

  React.useEffect(() => {
  }, []);


  return (
    <Modal
      show={ModalEditar_presentaciones_productos}
      onHide={() => {
        setModalEditar_presentaciones_productos(false);
        setProductoEditar(null);
      }}
    >
      <Modal.Header>
        <Modal.Title>Editar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p id="Modal_movimiento">Producto actual (se puede editar nombre, cantidad, etc. cambiando el valor y presionando el botón de editar)</p> 
        <Form>
          <Stack>
            <Form.Control
              id="fecha"
              placeholder="Fecha"
              type="text"
              className="mb-1"
              value={productoEstado?.fecha}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  fecha: e.target.value,
                })
              }
            />
            <Form.Control
              id="producto"
              placeholder="Producto"
              type="text"
              className="mb-1"
              value={productoEstado?.nombre}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  nombre: e.target.value,
                })
              }
            />
            <Form.Control
              id="cantidad"
              placeholder="cantidad"
              type="number"
              className="mb-1"
              value={productoEstado?.cantidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  cantidad: e.target.value,
                })
              }
            />
            <Form.Control
              id="unidad"
              placeholder="Ingresar unidad de medida del producto"
              type="text"
              className="mb-1"
              value={productoEstado?.unidad}
              onChange={(e) =>
                setProductoEstado({
                  ...productoEstado,
                  unidad: e.target.value,
                })
              }
            />
          </Stack>
        </Form>
        <Button variant="primary" onClick={()=>editarProductoModal(Presentacion)}>
          Editar
        </Button> 
      </Modal.Body>

      <Modal.Footer>         
        <Button
          variant="secondary"
          onClick={() => {
            setModalEditar_presentaciones_productos(false);
            setProductoEditar(null);
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditar_presentaciones;
