import { Modal, Stack, Form, Button, Table } from "react-bootstrap";
import React from "react";
import getProductos_manifiesto from "../../functions/Produccion/getProductos_manifiesto";
import añadirprecio_pallets from "../../functions/Produccion/añadirprecio_pallets";
import getAllPrecios_pallets from "../../functions/Produccion/getAllPrecios_pallets";
import editarTotal_manifiesto from "../../functions/Produccion/editarTotal_manifiesto";

function Modalagregar_precios_pallets({
  agregar_precios_pallets,
  setagregar_precios_pallets,
  actualizarEstadoManifiestos,
  productoEditar,
  setProductoEditar, 
  Manifiesto_Seleccionado,
  usuario,
}) {

  //Convertir a formato moneda
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

//Traer clientes de embarque.
const [Productos_total, setProductos_total] = React.useState([]);

async function Clientes_actuales(){
  //console.log(Manifiesto_Seleccionado)
  await getProductos_manifiesto(Manifiesto_Seleccionado).then((productos_manifiestoDB) => {
    //console.log(productos_manifiestoDB);
  //Tabla de resultados del manifiesto-------------------------------------------
//Con estos map consigo todos los tipos diferentes de pallets que se ingresaron sin repetir y se guarda en productos_resultados-----------------
var productos_resultados = [];
productos_manifiestoDB.map((dato, auxiliar)=>{
var auxiliar100 = false;  
 //Con este if me aseguro que el primer dato se mande directo ya que esta vacio el arreglo 
        if(auxiliar==0){
          //dato['Resultado_pallets']= dato.cantidad_retirada;
        }else{
        //Con este map comparo si ya existe en el arreglo creado el producto que se esta analizando y si ya existe solo se suma la cantidad si no se agrega directo
        productos_resultados.map((valor)=>{
            if(dato.tarima_seleccionada==valor.tarima_seleccionada){
              auxiliar100 = true;
              //auxiliar101 = valor;
            }
          })//Fin segundo map
        }//Fin else del primer if

  if(auxiliar100==true){

  }else{
    productos_resultados.push(dato);
  }
})//Fin primer map
//------------------------------------------------------------------------------------------------------------------------------------------------
//Con estos maps sumo la cantidad de pallets de cada tipo de pallet-------------------------------------------------------------------------------
productos_resultados.map((dato2)=>{
    productos_manifiestoDB.map((valor2)=>{
      if(dato2.tarima_seleccionada==valor2.tarima_seleccionada){
        if(dato2.Resultado_pallets==undefined){
          dato2['Resultado_pallets']= parseInt(valor2.cantidad_retirada);
        }else{
          dato2['Resultado_pallets']= parseInt(dato2.Resultado_pallets) + parseInt(valor2.cantidad_retirada);
        }
      }
    })//Fin segundo map
})//Fin primer map
//------------------------------------------------------------------------------------------------------------------------------------------------
//Con este map le agrego los resultados de cajas total de cada pallet y resultado de kg
productos_resultados.map((dato3)=>{
  var total_cajas = (dato3.cajas_por_pallet) * (dato3.Resultado_pallets);
  dato3['Resultado_cajas'] = total_cajas;
  dato3['Resultado_kg'] = (dato3.kilos_por_cajas_por_pallet) * (total_cajas);
})
//productos_resultados----------------------------------------------------------------------------------------------------------------------------
//console.log(productos_resultados)
asignar_total_a_pallets(productos_resultados)
  })
}


const [Total_manifiesto, setTotal_manifiesto] = React.useState([]);

async function asignar_total_a_pallets(productos_resultados){
  console.log(productos_resultados);
  await getAllPrecios_pallets(Manifiesto_Seleccionado).then((PreciosDB) => {
      if(PreciosDB==""){
      }else{
            productos_resultados.map((dato)=>{
                  PreciosDB[0].Pallets.map((valor)=>{
                    if(valor.Pallet==dato.tarima_seleccionada){
                      dato['Total_Precio']=valor.Total;
                      dato['Precio_KG']=valor.Precio_KG;
                      dato['Precio_caja']=valor.Precio_caja;
                    }
                  })//Fin segundo map
            })//Fin primer map
       
      }

    var total_precios_manifiesto = 0;  
    productos_resultados.map((Auxiliar)=>{
      total_precios_manifiesto = total_precios_manifiesto + Auxiliar.Total_Precio;
      })

   setTotal_manifiesto(total_precios_manifiesto);
   editarTotal_manifiesto(total_precios_manifiesto, Manifiesto_Seleccionado);
   setProductos_total(productos_resultados);
  });
}

async function calcular_precios(datos, index){
  var precio_por_caja = 0;
  var precio_por_KG = 0;
  var pallets_con_total = [];
  //Con este for traego los valores de precio caja y precio por KG del pallet seleccionado
  for(var i = 0; i<Productos_total.length; i++){
    if(i==index){
      //console.log(document.getElementById(i+1).value, document.getElementById(i+101).value)
      precio_por_caja = document.getElementById(i+1).value;
      precio_por_KG = document.getElementById(i+101).value;

      var total = 0;
      if(precio_por_caja>0){
        total = precio_por_caja * datos.Resultado_cajas;
        precio_por_KG = 0;
      }
      if(precio_por_KG>0){
        total = precio_por_KG * datos.Resultado_kg; 
        precio_por_caja = 0;
      }

      console.log(total);
      Productos_total.map((valor, pallet)=>{
        //console.log(pallet, i)
        if(pallet == i){
          console.log(pallet, i)
          var auxiliar = {Total: total, Pallet: valor.tarima_seleccionada, Precio_KG: precio_por_KG, Precio_caja: precio_por_caja }
          pallets_con_total.push(auxiliar);
        }
      })

    //Con esto me traego los valores de los pallets del manifiesto seleccionado actuales y los actualizo segun agrego el encargado de los precios
      await getAllPrecios_pallets(Manifiesto_Seleccionado).then((PreciosDB) => {
        //console.log(PreciosDB);
        var actualizar = ""; 
        if(PreciosDB==""){
          PreciosDB.push(pallets_con_total[0]);
          //console.log(PreciosDB)
          actualizar = "Primera"; 
          añadirprecio_pallets(Manifiesto_Seleccionado, PreciosDB, actualizar);
          Clientes_actuales();
        }else{
          (PreciosDB[0].Pallets).map((dato)=>{
            //console.log(pallets_con_total[0].Pallet);
            //console.log(dato.Pallet);
            //console.log(dato.Pallet, pallets_con_total.Pallet)
                if(dato.Pallet==pallets_con_total[0].Pallet){
                  actualizar = true;
                  dato.Total=pallets_con_total[0].Total;
                  dato.Precio_KG=pallets_con_total[0].Precio_KG;
                  dato.Precio_caja=pallets_con_total[0].Precio_caja;
                }
          }) //Fin Map

            if(actualizar==true){
              //console.log(PreciosDB[0]);
              añadirprecio_pallets(Manifiesto_Seleccionado, PreciosDB, actualizar);
              Clientes_actuales();
            }else{
              (PreciosDB[0].Pallets).push(pallets_con_total[0]);
              actualizar = "Segunda";
              añadirprecio_pallets(Manifiesto_Seleccionado, PreciosDB, actualizar);
              Clientes_actuales();
            }
        }

        
      })
    }
  }
}
  
  React.useEffect(() => {
    Clientes_actuales();
  }, []);

  return (
    <Modal id="Modal_Logs"  show={agregar_precios_pallets} onHide={() => {
    setagregar_precios_pallets(false);
    }}>
      <Modal.Header>
        <Modal.Title>Agregar precios a pallets</Modal.Title>
        <p>El total del manifiesto es: {formatter.format(Total_manifiesto)}</p>
        <Button variant="secondary" onClick={() => setagregar_precios_pallets(false)}>
          Salir
        </Button>
      </Modal.Header>
      <Modal.Body>
      <Table id="Tabla_logs" style={{ fontSize: 14 }} className="container-fluid">
         <thead>
             <tr >
             <th>Resumen de carga</th>
             <th>Pallets</th>
             <th>Cajas</th> 
             <th>KG</th> 
             <th>Precio por caja</th>
             <th>Precio KG</th>  
             <th>Total</th> 
             <th>Acciones</th> 
                </tr>
            </thead>
        <tbody>
        {Productos_total &&
            Productos_total.map((datos, index) => (
              <tr key={index}>
                <td>{datos.tarima_seleccionada}</td>
                <td>{datos.Resultado_pallets}</td>
                <td>{datos.Resultado_cajas}</td>
                <td>{datos.Resultado_kg}</td>
                <td style={{ width: "10%" }}>
                <Form.Control
                    id= {index+1}
                    placeholder={datos.Precio_caja}
                    type="number"
                    className="mb-1"
                  /> 
                </td>
                <td style={{ width: "10%" }}>
                <Form.Control
                    id={index+101}
                    placeholder={datos.Precio_KG}
                    type="number"
                    className="mb-1"
                  /> 
                </td>
                <td style={{ width: "15%", alignContent: "center" }}>{formatter.format(datos.Total_Precio)}</td>
                <td>
                <Button variant="success" onClick={() => calcular_precios(datos, index)}>
                  Calcular
                </Button>
                </td>
              </tr>
            ))}
        </tbody> 
      </Table>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={() => setagregar_precios_pallets(false)}>
          Cancelar
        </Button> */}
        {/* <Button variant="primary" onClick={añadirProductoModal}>
          Añadir
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default Modalagregar_precios_pallets;