import React from 'react';

const Carousel = () =>{
    return(
<div>
 <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
{/*     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button> */}
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="./Imagenes/Imagenes_comprimidas/foto campo.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Chile_recoleccion.jpeg" className="d-block w-100" alt="..."/>
    </div>
    {/* <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Tomate_campo.jpeg" className="d-block w-100" alt="..."/>
    </div> */}
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Cosecha.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Luipack_empaque.jpeg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Empaque_3.jpeg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Empaque_1.jpeg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/Imagenes_comprimidas/Empaque_2.jpeg" className="d-block w-100" alt="..."/>
    </div>

{/*     <div className="carousel-item active">
      <img src="./Imagenes/campo.png" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/campo2.png" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/campo.png" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="./Imagenes/campo2.png" className="d-block w-100" alt="..."/>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
  </div>
</div>
    )
}

export default Carousel