import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAlltarimas_almacen_empaque() {
    

  //console.log(valor);
    const TarimasDB =  [];
    //console.log(valor);
    const collectionRef = collection(db, "Almacen_empaque/Producto terminado/Productos/");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      TarimasDB.push(doc.data());
    });
    return TarimasDB;
    

}
