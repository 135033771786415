import firebaseApp from "../../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadirProducto(infoProducto, Presentacion) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db,"Produccion/Lineas_produccion/"+Presentacion.replace(/ /g, ""));
  const docRef = doc(collectionRef, infoProducto.sku);
  setDoc(docRef, infoProducto);
  //escribirLog("Edición", infoProducto, autor);
}

export default añadirProducto;
