import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";
import getCategorias from '../Firebase/functions/Productos/getCategorias';
import ModalAñadir_empaque from './Modales/ModalAñadir_empaque';
import getCategorias_botones_empaque from './funciones/getCategorias_botones_empaque';
import ModalEliminar_empaque from './Modales/ModalEliminar_empaque';

const Listainventario = ({setvalor,}) =>{   
    const [Modal_categoria, setModal_categoria] = React.useState(false);
    const [Modal_eliminar, setModal_eliminar] = React.useState(false);
    const [Categoria, setCategoria] = React.useState([]);
    const [Botones_campo, setBotones_campo] = React.useState([]);
    
   async function Categorias(){
        await getCategorias().then((CategoriasDB) => {
            console.log(CategoriasDB) 
            setCategoria(CategoriasDB);
       });
       setModal_categoria(true);
     }

     async function eliminar(){
        setModal_eliminar(true);
      }

     async function botones(){
        await getCategorias_botones_empaque().then((Categorias_botonesDB) => {
            console.log(Categorias_botonesDB) 
            setBotones_campo(Categorias_botonesDB);
       });
     }

    async function Seleccionar_almacen(prop){
       console.log(prop);
       setvalor(prop);
     }
     
    
     React.useEffect(() => {
       botones();
      }, []);

    return(
      <div> 
        {Modal_categoria && (
        <ModalAñadir_empaque
          Modal_categoria={Modal_categoria}
          setModal_categoria={setModal_categoria}
          Categoria={Categoria}
          actualizar_botones={botones}
          className="modal-dialog modal-fullscreen-sm-down"
        />
      )}

        {Modal_eliminar && (
        <ModalEliminar_empaque
          Modal_eliminar={Modal_eliminar}
          setModal_eliminar={setModal_eliminar}
          Categoria={Categoria}
          actualizar_botones={botones}
          Botones_campo={Botones_campo}
          className="modal-dialog modal-fullscreen-sm-down"
        />)}
        
      <div id="Botones_agregareliminar_categoria_almacen_campo">
         <Button id="Botones_01" onClick={()=>{Categorias()}}> Agregar categoría de almacén</Button>
         <Button id="Botones_02" onClick={()=>{eliminar()}}> Eliminar categoría de almacén</Button>
      </div>
            <div id="Lista_empaque">
                
                {Botones_campo.map((datos,index)=>( 
                    <button id="boton_lista_campo_grid" onClick={() => Seleccionar_almacen((datos.valor))} key={index}>{datos.valor}</button>
                ))}
           
            </div>
      </div>    
    )
}

export default Listainventario;

//-----------------------------------------------------------------------------

/* import React from 'react';
import '../../index.css'
import { Button } from "react-bootstrap";


const Listainventario = (props) =>{   
    const {Fertilizantes, Ferreteria, Semillas, Rafia}=props;
    

    return(
        <div id="Trabajo">
            <Button id="boton_regresar_ordenes" onClick={()=>{Pagina_principal()}}> Regresar</Button>
            <br/>
            <br/>
            <br/>
            <br/>
                <div  id="Lista_empaque" className="list-group">
                    <button onClick={() => Fertilizantes()}>Fertilizantes y Agroquimicos</button>
                    <br/>
                    <br/>
                    <button onClick={() => Ferreteria()}>Ferreteria</button>
                    <br/>
                    <br/>
                    <button onClick={() => Semillas()}>Semillas</button>
                    <br/>
                    <br/>
                    <button onClick={() => Rafia()}>Rafia</button>
                    
                </div>    
        </div>
    )
}

export default Listainventario */

/* import React from 'react';
import '../../index.css'

const Lista_almacen_empaque = (props) =>{
    const {Carton, Producto_final, Producto_inicial, Limpieza, Consumible}=props;
    

    return(
        <div >
            <br/>
            <br/>
            <br/>
            <br/>
                <div  id="Lista_empaque" className="list-group">
                    <button onClick={() => Carton()}>Carton</button>
                    <br/>
                    <br/>
                    <button onClick={() => Producto_final()}>Producto terminado</button>
                    <br/>
                    <br/>
                    <button onClick={() => Producto_inicial()}>Cosecha</button>
                    <br/>
                    <br/>
                    <button onClick={() => Limpieza()}>Limpieza</button>
                    <br/>
                    <br/>
                    <button onClick={() => Consumible()}>Consumibles</button>
                </div>    
        </div>
    )
}

export default Lista_almacen_empaque */