import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(firebaseApp);

export default async function getAllproducts() {
  const SeccionesDB = [];
  let collectionRef = collection(db, "/Productos".replace(/ /g, ""))
  const snapshot = await getDocs(collectionRef);
  console.log("Productos")
  snapshot.forEach((doc) => {
    SeccionesDB.push(doc.data());
  });

  //console.log(SeccionesDB)
  console.log("Prueba")
  return SeccionesDB;
}
