import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

function añadirProducto(infoProducto, autor, pagina) {
  console.log(infoProducto)
  console.log(pagina)
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "Cultivo_en_proceso/"+pagina+"/Productos/");
  const docRef = doc(collectionRef, infoProducto.fecha2);
  setDoc(docRef, infoProducto);
}

export default añadirProducto;
