import firebaseApp from "../../firebase/credenciales";
import { getFirestore, collection, doc, setDoc, updateDoc } from "firebase/firestore";

function editarComentario_orden(Enviarproducto, comentarios_de_la_orden) {
  console.log(Enviarproducto.nombre, comentarios_de_la_orden)
  const db = getFirestore(firebaseApp);
  let collectionRef = collection(db, "Ordenes/");
  const docRef = doc(collectionRef, Enviarproducto.nombre);
  updateDoc(docRef, comentarios_de_la_orden);
}

export default editarComentario_orden;
